import { fetchOptimizedRoutePlan } from "../api/calls/valhalla_calls";
import {
  Valhalla_CostingModel,
  type Valhalla_Location,
  type Valhalla_OptimizedTrip,
  Valhalla_OptimizedRouteRes,
} from "../types/valhalla_types";
import generateUniqueLocalID from "../utils/generateUniqueLocalID";
import TripLocation from "./tripLocation";
import * as polyline from "@mapbox/polyline";
import Vehicle from "./vehicle";
import { PartialObj } from "../types/generic_types";
import {
  updatedSavedOptimisedTrip,
  type Directus_SavedOptimisedTrip,
  type Directus_SavedOptimisedTrip_Location,
  createSavedOptimisedTrip,
} from "../api/calls/directus_calls/savedOptimisedTrips";
import { getNiceDuration } from "../utils/timeUtils";
import TspTripComparison from "./tspTripComparison";

export interface TspTripOptions {
  localId?: string;
  directusId?: number | string;
  vehicleId?: number;
  locations?: TripLocation[];
  costingModel?: Valhalla_CostingModel;
  comparisons?: TspTripComparison[];
  name?: string;
}

const TspTripDefaults = {
  localId: undefined,
  directusId: undefined,
  vehicleId: undefined,
  locations: [],
  costingModel: Valhalla_CostingModel.truck,
  name: undefined,
};

export default class TspTrip {
  // -------------------------------------------------------------------- //
  // ------------------------- Global class state ----------------------- //
  // -------------------------------------------------------------------- //

  /** global record of class instance ids this session. */
  static usedIds: string[] = [];

  // -------------------------------------------------------------------- //
  // ------------------------------ State ------------------------------- //
  // -------------------------------------------------------------------- //

  /**
   * Directus `SavedTrip` collection record id.
   *
   * NOTE: will only be populated once a successful save response have
   * been received, however will be needed for every update after that.
   */
  directusId?: string | number;

  /** local unique id. */
  localId: string;

  /**
   * Id of the vehicle used in the trip.
   *
   * NOTE: will match the directus `Vehicles` collection record id.
   */
  vehicleId?: number;

  /**
   * The locations stopped at along the trip including the starting
   * location (always at index 0) and the destination location
   * (always at the last index).
   *
   * Note: the stops in between the origin and destination can be
   * sorted into a different order in the planning.
   */
  locations: TripLocation[];

  /** The Valhalla costing model used to plan this trip */
  costingModel: Valhalla_CostingModel;

  /**
   * The successfully planned Valhalla optimized route trip data for this
   * trip
   */
  tripData?: Valhalla_OptimizedTrip;

  /** the whole `Vehicle` object of the vehicle used to plan this trip. */
  vehicle?: Vehicle;

  /** the optional display name for the trip */
  name?: string;

  /**
   * Type of trip.
   *
   * Note: this is mainly used for itinerary conditional rendering.
   */
  status = "optimised-trip"; // TODO: alter this property and its same named counterpart in the `Trip` class to be clearer on what they represent.

  /**
   * The outcome of the trip planning operation.
   *
   * NOTE: not to be confused with status that needs a rename in both `Trip` class and `TspTrip` class.
   */
  tripPlanningStatus?: TspTripPlanningStatus;

  /** List of calculated comparisons. */
  comparisons: TspTripComparison[];

  // -------------------------------------------------------------------- //
  // --------------------------- Constructor ---------------------------- //
  // -------------------------------------------------------------------- //

  constructor({
    localId = undefined,
    directusId = undefined,
    vehicleId = undefined,
    locations = [],
    costingModel = Valhalla_CostingModel.truck, // note default is truck as this was created as part of a truck routing solution piece of work.
    comparisons = [],
    name = undefined,
  }: TspTripOptions | undefined = TspTripDefaults) {
    this.localId =
      localId ?? generateUniqueLocalID(TspTrip.usedIds, "tsp-trip");
    this.directusId = directusId;
    this.vehicleId = vehicleId;
    this.locations = locations;
    this.costingModel = costingModel;
    this.comparisons = comparisons;
    this.name = name;

    // add id to list of used unique ids
    TspTrip.usedIds.push(this.localId);
  }

  static fromDirectusData(directusData: Directus_SavedOptimisedTrip): TspTrip {
    return new TspTrip({
      directusId: directusData.id,
      locations: directusData.Locations.map(
        (directusLocation) =>
          new TripLocation({
            address: directusLocation.address,
            coordinates: {
              latitude: directusLocation.latitude,
              longitude: directusLocation.longitude,
            },
          })
      ),
      costingModel: Valhalla_CostingModel[directusData.Costing_Model],
      name: directusData.Name ?? undefined,
    });
  }

  // -------------------------------------------------------------------- //
  // ------------------------------ Getters ----------------------------- //
  // -------------------------------------------------------------------- //

  public get totalTime(): number | undefined {
    return this.tripData?.summary.time;
  }

  public get displayableTotalTime(): string {
    return getNiceDuration(this.totalTime ?? 0) + " travel time";
  }

  public get totalDistance(): number | undefined {
    return this.tripData?.summary.length;
  }

  public get displayableTotalDistance(): string {
    return `${Math.round(this.totalDistance ?? 0)}km`;
  }

  public get fullTripPolyline(): string {
    // get polyline points
    const points = this.getPolylinePoints().flat();
    return polyline.encode(points, 6);
  }

  // -------------------------------------------------------------------- //
  // --------------------------- Public Methods ------------------------- //
  // -------------------------------------------------------------------- //

  /**
   * Returns a decoded polylines in an array retaining there order.
   *
   * Note: these points are in lat lon order
   */
  public getPolylinePoints(): [number, number][][] {
    return (
      this.tripData?.legs.map((leg) => this.decodePolyline(leg.shape)) ?? []
    );
  } // TODO: refactor this an Trip as should be a getter but matching more complex code in the Trip class forced this to be a public method so less complex components and

  /**
   * Plan a traveling salesman problem type of trip.
   *
   * @returns stats of trip planing operations outcome
   */
  public async planTrip(): Promise<TspTripPlanningStatus> {
    // clear previous status
    this.tripPlanningStatus = undefined;

    // check at least two locations have been added to the trip
    if (this.locations.length < 2) {
      this.tripPlanningStatus = TspTripPlanningStatus.errorNotEnoughLocations;
      return TspTripPlanningStatus.errorNotEnoughLocations;
    }

    // plan trip
    const res = await fetchOptimizedRoutePlan(
      this.locations.map((location) =>
        this.TripLocationToValhallaLocation(location)
      ),
      this.costingModel
    );

    // check res was successful and error if not
    if (!res) {
      this.tripPlanningStatus = TspTripPlanningStatus.errorUnknown;
      return TspTripPlanningStatus.errorUnknown;
    }

    if (Object.hasOwn(res, "trip")) {
      this.tripData = (res as unknown as Valhalla_OptimizedRouteRes).trip;
      this.tripPlanningStatus = TspTripPlanningStatus.success;
      return TspTripPlanningStatus.success;
    }

    if (Object.hasOwn(res, "error")) {
      this.tripPlanningStatus = TspTripPlanningStatus.errorNotRoutable;
      return TspTripPlanningStatus.errorNotRoutable;
    }

    this.tripPlanningStatus = TspTripPlanningStatus.errorUnknown;
    return TspTripPlanningStatus.errorUnknown;
  }

  /**
   * Saves this individual trip to directus.
   */
  public async saveTrip(): Promise<"failed" | "ok"> {
    // format trip for saving
    const saveData: PartialObj<Directus_SavedOptimisedTrip> = {
      Costing_Model: this.costingModel,
      Locations: this.locations.map((location) =>
        this.TripLocationToDirectusOptimisedTripLocation(location)
      ),
    };
    if (this.name) saveData.Name = this.name;

    // check if trip has been saved before and needs to be overwritten.
    if (this.directusId) {
      // overwrite trip in directus.
      const updateOperationRes = await updatedSavedOptimisedTrip(
        this.directusId,
        saveData
      );
      if (updateOperationRes) return "ok";
      // operation was unsuccessful if it reaches this point, return failed.
      return "failed";
    }

    // create new trip record in directus
    const saveOperationRes = await createSavedOptimisedTrip(saveData);
    if (saveOperationRes) {
      this.directusId = saveOperationRes.id;
      return "ok";
    }

    // operation was unsuccessful if it reaches this point, return failed.
    return "failed";
  }

  // -------------------------------------------------------------------- //
  // -------------------------- Private Methods ------------------------- //
  // -------------------------------------------------------------------- //

  /**
   * Converts a `TripLocation` class object to a `Valhalla_Location` object.
   *
   * @param location the whole `TripLocation` object.
   * @returns a `Valhalla_Location` object.
   */
  private TripLocationToValhallaLocation(
    location: TripLocation
  ): Valhalla_Location {
    return {
      lat: location.coordinates.latitude,
      lon: location.coordinates.longitude,
    };
  }

  /**
   * Converts a `TripLocation` class object to a
   * `Directus_SavedOptimisedTrip_Location` object.
   *
   * @param location the whole `TripLocation` object.
   * @returns a `Directus_SavedOptimisedTrip_Location` object.
   */
  private TripLocationToDirectusOptimisedTripLocation(
    location: TripLocation
  ): Directus_SavedOptimisedTrip_Location {
    return {
      address: location.address,
      ...location.coordinates,
    };
  }

  /**
   * Converts a polyline into a coordinates array.
   * @param encodedPolyline an encoded polyline string
   * @returns Array of arrays each containing a latitude longitude coordinate.
   */
  private decodePolyline(encodedPolyline: string): [number, number][] {
    return polyline.decode(encodedPolyline, 6);
  }
}

export enum TspTripPlanningStatus {
  errorUnknown = "errorUnknown",
  errorNotEnoughLocations = "errorNotEnoughLocations",
  errorNotRoutable = "errorNotRoutable",
  success = "success",
}
