<template>
  <v-card
    class="rounded-lg primary"
    elevation="4"
    v-if="trip && trip.locations && trip.parameters"
  >
    <v-row no-gutters class="pt-3">
      <v-col cols="1">
        <div
          class="primary ml-3 mt-1 rounded-circle"
          style="height: 20px; width: 20px; border: 3px solid white !important"
        ></div>
      </v-col>
      <v-col cols="10">
        <v-card-title
          class="font-weight-bold text-body-1 pt-0 white--text"
          style="word-break: normal"
        >
          {{ trip.locations[0].address }}
        </v-card-title>
        <v-card-subtitle v-if="trip.locations[0].time" class="white--text">
          {{ getNiceTime(trip.locations[0].time) }} Departure
        </v-card-subtitle>
        <v-card-text class="d-flex justify-space-between pr-10 white--text">
          <span>Departure charge</span>
          <span>{{ Math.floor(trip.parameters.SOCAct * 100) }}%</span>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>
<script lang="ts">
import Vue from "vue";
import { mapGetters } from "vuex";
import { DateTime } from "luxon";
import { GettersTypes } from "@/logic/store/store_types";

export default Vue.extend({
  name: "FallbackDepartureCard",
  methods: {
    getNiceTime(time: string): string {
      return DateTime.fromISO(time).toLocaleString(DateTime.TIME_SIMPLE);
    },
  },
  computed: {
    ...mapGetters({
      trip: GettersTypes.selectedTripData,
    }),
  },
});
</script>
