<template>
  <v-card flat class="pa-5">
    <v-card-title class="tertiary--text">
      {{ !!trip ? "Edit Your Optimised Trip" : "Plan a New Optimised Trip" }}
    </v-card-title>
    <v-card-text v-if="!trip">
      Plan a trip that optimises your stops into the most efficient order to
      travel from your origin to your destination visiting all your stops along
      the way.
    </v-card-text>
    <!-- origin/destination card -->
    <v-card-text>
      <!-- trip name card -->
      <v-card class="rounded-lg mb-5 px-5" v-if="trip">
        <v-card-text>
          <v-text-field
            v-model="tripName"
            label="Trip name"
            clearable
            :append-icon="tripName ? '' : 'mdi-pencil'"
          ></v-text-field>
        </v-card-text>
      </v-card>
      <!-- Origin and Destination card -->
      <v-card class="primary white--text rounded-lg pa-5">
        <v-card-title>Origin and Destination</v-card-title>
        <v-card-text class="pb-0">
          <AddressAutocompleteInput
            dark
            :label="roundTrip ? 'origin/destination' : 'origin'"
            :loading="planning"
            id="origin"
            :initialValue="{
              address: origin.address,
              waypoint: origin.coordinates,
            }"
            @update="handleAddressChange"
            :errorMsg="getAddressError(origin.localId)"
          />
          <AddressAutocompleteInput
            dark
            label="destination"
            v-if="!roundTrip"
            :loading="planning"
            id="destination"
            :initialValue="{
              address: destination.address,
              waypoint: destination.coordinates,
            }"
            @update="handleAddressChange"
            :errorMsg="getAddressError(destination.localId)"
          />
        </v-card-text>
        <v-card-actions class="px-5 pt-0">
          <v-switch inset v-model="roundTrip" :color="pwtDarkBlue" dense>
            <template v-slot:label>
              <span class="white--text">
                {{
                  roundTrip ? "This is a round trip" : "Make this a round trip"
                }}
              </span>
            </template>
          </v-switch>
        </v-card-actions>
      </v-card>
    </v-card-text>
    <!-- stops section -->
    <v-card-title>Stops</v-card-title>
    <v-card-text>
      <template v-for="(stop, index) in additionalStops">
        <v-row :key="'additional-stop-' + index" no-gutters align="center">
          <v-col cols="11">
            <AddressAutocompleteInput
              :loading="planning"
              :id="stop.localId"
              :initialValue="{
                address: stop.address,
                waypoint: stop.coordinates,
              }"
              @update="handleAddressChange"
              :errorMsg="getAddressError(stop.localId)"
            />
          </v-col>
          <v-col cols="1">
            <v-btn icon @click="removeStop(index)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <v-btn
        block
        text
        color="primary"
        class="text-none rounded-lg"
        @click="addStop"
      >
        Add Another Stop
      </v-btn>
    </v-card-text>
    <v-card-text v-if="!!errorMsg">
      <v-alert v-if="!!errorMsg" type="error" color="error" class="rounded-lg">
        {{ errorMsg }}
      </v-alert>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="primary"
        block
        class="rounded-lg text-none"
        :loading="planning"
        :disabled="planning || !!addressErrors.length || !!errorMsg"
        @click="planOptimizedTrip"
      >
        {{ !!trip ? "Recalculate Trip" : "Optimise Trip" }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import TripLocation from "@/logic/classes/tripLocation";
import TspTrip, { TspTripPlanningStatus } from "@/logic/classes/tspTrip";
import { powerTripDarkBlue } from "@/logic/data/const";
import { RouteNames } from "@/logic/router";
import { ActionTypes, MutationTypes } from "@/logic/store/store_types";
import AddressAutocompleteInput, {
  type AddressAutocompleteInputUpdateObj,
} from "@/ui/components/ui-elements/AddressAutocompleteInput.vue";
import Vue, { PropType } from "vue";

enum AddressErrorMsg {
  nullIsland = "This address is invalid",
}

enum GeneralErrorMsg {
  roundTripNoStops = "A round trip needs one or more stops to plan a trip",
  toFewLocations = "Need at least two locations to plan a trip",
  notRoutable = "This trip is not routable",
}

interface LocalAddressErrorObj {
  localId: string;
  errorMsg: AddressErrorMsg;
}

export default Vue.extend({
  name: "TspTripPlanningForm",
  data() {
    return {
      pwtDarkBlue: powerTripDarkBlue,
      originID: "origin",
      destinationID: "destination",
      roundTrip: false,
      planning: false,
      origin: new TripLocation(),
      destination: new TripLocation(),
      additionalStops: [new TripLocation()],
      addressErrors: [] as LocalAddressErrorObj[],
      errorMsg: null as string | null,
      tripName: null as string | null,
    };
  },
  components: { AddressAutocompleteInput },
  methods: {
    addStop(): void {
      this.additionalStops.push(new TripLocation());
      if (this.errorMsg === GeneralErrorMsg.roundTripNoStops) {
        this.errorMsg = null;
      }
    },
    removeStop(index: number): void {
      this.additionalStops.splice(index, 1);
    },
    handleAddressChange(val: AddressAutocompleteInputUpdateObj): void {
      // clear general error
      this.errorMsg = null;

      // not null guard clause
      if (!val.addressData) return;

      // find location to update
      const location = this.isOrigin(val)
        ? this.origin
        : this.isDestination(val)
        ? this.destination
        : this.additionalStops.find((location) => location.localId === val.id);
      const locationIndex = this.additionalStops.findIndex(
        (location) => location.localId === val.id
      );

      // find operation failed guard clause
      if (!location) return;

      // create new object
      const tempObj: TripLocation = new TripLocation({
        ...location,
        address: val.addressData.address,
        coordinates: {
          latitude: val.addressData.coordinates.Latitude,
          longitude: val.addressData.coordinates.Longitude,
        },
      });

      // clear invalid error if needed
      if (this.getAddressError(tempObj.localId) && !tempObj.isNullIsland) {
        this.addressErrors = this.addressErrors.filter(
          (error) => error.localId !== tempObj.localId
        );
      }

      // update local state

      // check if origin
      if (this.isOrigin(val)) {
        this.origin = tempObj;
        return;
      }

      // check if destination
      if (this.isDestination(val)) {
        this.destination = tempObj;
        return;
      }

      // assume this is an additional stop

      // swap out maintaining list order if known
      if (locationIndex !== -1) {
        this.additionalStops.splice(locationIndex, 1, tempObj);
        return;
      }

      // Redundancy incase for some unforeseen reason this makes it to this point in the code.
      this.additionalStops = [
        ...this.additionalStops.filter((stop) => stop.localId !== val.id),
        tempObj,
      ];
    },
    isOrigin(val: AddressAutocompleteInputUpdateObj): boolean {
      return val.id === "origin";
    },
    isDestination(val: AddressAutocompleteInputUpdateObj): boolean {
      return val.id === "destination";
    },
    async planOptimizedTrip() {
      // indicate async process is in action
      this.planning = true;

      // clear previous attempts errors
      this.addressErrors = [];
      this.errorMsg = null;

      // check addresses are valid
      if (this.origin.isNullIsland) {
        this.addressErrors.push({
          localId: this.origin.localId,
          errorMsg: AddressErrorMsg.nullIsland,
        });
        this.planning = false;
        return;
      }
      if (this.destination.isNullIsland && !this.roundTrip) {
        this.addressErrors.push({
          localId: this.destination.localId,
          errorMsg: AddressErrorMsg.nullIsland,
        });
        this.planning = false;
        return;
      }
      if (this.additionalStops.some((stop) => stop.isNullIsland)) {
        this.additionalStops.forEach((stop) => {
          if (stop.isNullIsland) {
            this.addressErrors.push({
              localId: stop.localId,
              errorMsg: AddressErrorMsg.nullIsland,
            });
          }
        });
        this.planning = false;
        return;
      }

      // check is not a round trip with out any stops
      if (this.roundTrip && !this.additionalStops.length) {
        this.errorMsg = GeneralErrorMsg.roundTripNoStops;
        this.planning = false;
        return;
      }

      // plan trip
      const newTrip = this.trip ?? new TspTrip();

      newTrip.locations = [
        this.origin,
        ...this.additionalStops,
        this.roundTrip ? this.origin : this.destination,
      ];

      newTrip.name = this.tripName ?? undefined;

      const outcome = await newTrip.planTrip();

      // check outcome for errors

      if (outcome === TspTripPlanningStatus.errorNotRoutable) {
        this.errorMsg = GeneralErrorMsg.notRoutable;
        this.planning = false;
        return;
      }

      if (outcome === TspTripPlanningStatus.errorNotEnoughLocations) {
        this.errorMsg = GeneralErrorMsg.toFewLocations;
        this.planning = false;
        return;
      }

      // indicate async process has been completed
      this.planning = false;
      // save state to store
      this.$store.commit(MutationTypes.updateIndividualTrip, newTrip);
      this.$store.commit(MutationTypes.setSelectedTrip, newTrip.localId);
      this.$store.commit(MutationTypes.setShowNearbyChargersOnly, false);
      if (this.trip) {
        this.$store.dispatch(ActionTypes.saveTrip, newTrip.localId);
      }

      // navigate to itinerary
      this.$router.push({ name: RouteNames.itinerary });
    },
    getAddressError(localId: string): string | null {
      return (
        this.addressErrors.find((error) => error.localId === localId)
          ?.errorMsg ?? null
      );
    },
  },
  props: {
    trip: {
      type: Object as PropType<TspTrip | undefined>,
    },
  },
  mounted() {
    if (this.trip) {
      this.origin = this.trip.locations[0];
      this.destination = this.trip.locations[this.trip.locations.length - 1];
      this.additionalStops = this.trip.locations.filter(
        (location, index) =>
          index !== 0 && index !== (this.trip as TspTrip).locations.length - 1
      );
      if (
        this.origin.coordinates.latitude ===
          this.destination.coordinates.latitude &&
        this.origin.coordinates.longitude ===
          this.destination.coordinates.longitude
      )
        this.roundTrip = true;
    }
  },
});
</script>
