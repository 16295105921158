import type {
  EVSE as apiEVSE,
  Capability,
  DisplayText,
  EVSEExtensionData,
  Image,
  ParkingRestriction,
  Status,
  StatusSchedule,
} from "../types/charger_Db_types";
import type { isCompatibleReturn } from "../types/sheared_local_types";

import Connector from "./connector";
import generateUniqueLocalID from "../utils/generateUniqueLocalID";
import Vehicle from "./vehicle";
import GeoLocation from "./geoLocation";

export default class EVSE {
  // -------------------------------------------------------------------- //
  // ------------------------- Global class state ----------------------- //
  // -------------------------------------------------------------------- //

  /** global record of class instance ids this session. */
  static usedIds: string[] = [];

  // -------------------------------------------------------------------- //
  // ------------------------------- State ------------------------------ //
  // -------------------------------------------------------------------- //

  /**
   * Uniquely identifies the EVSE within the CPOs platform (and sub-operator platforms). For example a database ID or the actual "EVSE ID".
   * This field can never be changed, modified or renamed. This is the 'technical' identification of the EVSE, not to be used as 'human readable'
   * identification, use the field evse id for that.
   * This field is named uid instead of id, because id could be confused with evse id which is an eMI3 defined field.
   */
  uid: string;

  /**
   * Compliant with the following specification for EVSE ID from "eMI3 standard version V1.0" (http://emi3group.com/documents-links/)
   * "Part 2: business objects." Optional because: if an evse id is to be re-used in the real world, the evse id can be removed from
   * an EVSE object if the status is set to REMOVED.
   */
  evseId?: number;

  /** Indicates the current status of the EVSE. */
  status: Status;

  /** Indicates a planned status update of the EVSE. */
  statusSchedule: StatusSchedule[];

  /** List of functionalities that the EVSE is capable of. */
  capabilities: Capability[];

  /** List of available connectors on the EVSE. */
  connectors: Connector[];

  /** Level on which the Charge Point is located (in garage buildings) in the locally displayed numbering scheme.
   * In the format of a stringified number
   */
  floorLevel?: number;

  /** Coordinates of the EVSE. */
  coordinates?: GeoLocation;

  /** A number/string printed on the outside of the EVSE for visual identification. */
  physicalReference?: string;

  /** Multi-language human-readable directions when more detailed information on how to reach the EVSE from the Location is required. */
  directions: DisplayText[];

  /** The restrictions that apply to the parking spot. */
  parkingRestrictions?: ParkingRestriction[];

  /** Links to images related to the EVSE such as photos or logos. */
  images: Image[];

  /** Timestamp when this EVSE or one of its Connectors was last updated (or created). In UTC string format */
  lastUpdated: string;

  /** locations UUID */
  locationId?: string;

  /** Data source information for this EVSE. */
  extensionData: EVSEExtensionData;

  // -------------------------------------------------------------------- //
  // --------------------------- Constructor ---------------------------- //
  // -------------------------------------------------------------------- //

  constructor({
    uid = undefined,
    evseId = undefined,
    status = "AVAILABLE",
    statusSchedule = [],
    capabilities = [],
    connectors = [],
    floorLevel = undefined,
    coordinates = undefined,
    physicalReference = undefined,
    directions = [],
    parkingRestrictions = [],
    images = [],
    lastUpdated = undefined,
    locationId = undefined,
    extensionData = {},
  }: {
    uid?: string;
    evseId?: number;
    status?: Status;
    statusSchedule?: StatusSchedule[];
    capabilities?: Capability[];
    connectors?: Connector[];
    floorLevel?: number;
    coordinates?: GeoLocation;
    physicalReference?: string;
    directions?: DisplayText[];
    parkingRestrictions?: ParkingRestriction[];
    images?: Image[];
    lastUpdated?: string;
    locationId?: string;
    extensionData?: EVSEExtensionData;
  }) {
    // require extra computation for defaults
    this.uid = uid ?? generateUniqueLocalID(EVSE.usedIds, "evse");
    this.lastUpdated = lastUpdated ?? new Date().toUTCString();

    // optional/with basic default values
    this.extensionData = extensionData;
    this.status = status;
    this.evseId = evseId;
    this.statusSchedule = statusSchedule;
    this.capabilities = capabilities;
    this.connectors = connectors;
    this.floorLevel = floorLevel;
    this.coordinates = coordinates;
    this.physicalReference = physicalReference;
    this.directions = directions;
    this.parkingRestrictions = parkingRestrictions;
    this.images = images;
    this.locationId = locationId;

    // add id to list of used unique ids
    EVSE.usedIds.push(this.uid);
  }

  /**
   * Creates a new `EVSE` object from the expected OCPI compliant returned
   * data form the charger DB api.
   *
   * @param data the whole data object for the `EVSE` returned by the charger DB api.
   * @returns new `EVSE` class object.
   *
   * NOTE: if data does not have what is required to generate a useful object it
   * will throw an error. Make sure this is caught and handled for production.
   */
  static fromChargerDbData(data: apiEVSE): EVSE | undefined {
    // parse coordinates.
    let parsedCoordinates: GeoLocation | undefined = undefined;
    if (data.coordinates)
      parsedCoordinates = GeoLocation.fromChargerDBData(data.coordinates);
    const connectors: Connector[] = [];
    data.connectors?.forEach((apiConnector) => {
      const connectorClassObj = Connector.fromChargerDbData(apiConnector);
      if (connectorClassObj) connectors.push(connectorClassObj);
    });

    if (!connectors.length) return;

    // Generate new class object.
    return new EVSE({
      uid: data.uid,
      evseId: data.evse_id,
      status: data.status !== "StatusEnum(0)" ? data.status : undefined,
      statusSchedule: data.status_schedule?.filter(
        (scheduleItem) => scheduleItem.status !== "StatusEnum(0)"
      ),
      capabilities: data.capabilities?.filter(
        (capability) => capability !== "CapabilityEnum(0)"
      ),
      floorLevel: data.floor_level,
      coordinates: parsedCoordinates,
      physicalReference: data.physical_reference,
      directions: data.directions ?? undefined,
      parkingRestrictions: data.parking_restrictions?.filter(
        (restriction) => restriction !== "ParkingRestrictionEnum(0)"
      ),
      images: data.images ?? undefined,
      lastUpdated: data.last_updated,
      locationId: data.location_id,
      extensionData: data.extension_data,
      connectors: connectors,
    });
  }

  // -------------------------------------------------------------------- //
  // ------------------------------ Getters ----------------------------- //
  // -------------------------------------------------------------------- //

  public get isUseable(): boolean {
    return !unusableStatusTypes.includes(this.status);
  }

  // -------------------------------------------------------------------- //
  // ------------------------------ Methods ----------------------------- //
  // -------------------------------------------------------------------- //

  /**
   * Checks if a passed id relates to this `EVSE` or one of its attached `Connectors`.
   *
   * @param id the id to be checked against.
   * @returns true if id matches this EVSE ot any of its attached connectors including if it is one of the ids it is known by in the known data sources.
   */
  public isThisEVSE(id: string | number): boolean {
    // check if matches local ids.
    if (id === this.uid) return true;
    if (id === this.evseId) return true;

    // check if matches source ids.
    if (this.extensionData) {
      const listOfValues = Object.values(this.extensionData);
      if (listOfValues.includes(id)) return true;
    }

    // check if matches one of this evse's connectors.
    let isOneOfTheConnectors = false;
    this.connectors.forEach((connector) => {
      if (connector.isThisConnector(id)) isOneOfTheConnectors = true;
    });
    if (isOneOfTheConnectors) return true;

    // default return if no conditions are fulfilled.
    return false;
  }

  /**
   * Returns weather or not an EV is compatible with this EVSE.
   *
   * @param vehicle the whole `Vehicle` class object for the selected vehicle.
   * @returns weather the EV is compatible with this EVSE or not as one of the following:
   * - "compatible" - can use one or more of this EVSE's tethered connectors to charge.
   * - "compatible with cable only" - no hard point connectors are compatible however can charge here with one or more of the cabled adapters the driver has access to.
   * - "incompatible"- this EV can not use this EVSE.
   */
  public isCompatible(vehicle: Vehicle): isCompatibleReturn {
    const compatibilities = this.connectors.map((connector) =>
      connector.isCompatible(vehicle)
    );
    if (compatibilities.includes("compatible")) return "compatible";
    if (compatibilities.includes("compatible with cable only"))
      return "compatible with cable only";
    return "incompatible";
  }
}

/** List of status types that are considered unusable. */
const unusableStatusTypes: Status[] = [
  "INOPERATIVE",
  "OUTOFORDER",
  "PLANNED",
  "REMOVED",
];
