<template>
  <v-autocomplete
    no-filter
    v-model="address"
    clearable
    :search-input.sync="query"
    :items="items"
    item-text="address"
    item-value="waypoint"
    return-object
    @change="flagForUpdate"
    :label="label"
    :dark="dark"
    :append-icon="
      address ? (address.address == '' ? 'mdi-pencil' : '') : 'mdi-pencil'
    "
    attach
    placeholder="Enter an address"
    persistent-placeholder
    hide-no-data
    :error-messages="errorMsg"
    :loading="loading"
  />
</template>
<script lang="ts">
import { fetchLocationAutoCompleteDetails } from "@/logic/api/calls/maps_little_monkey_calls";
import { State } from "@/logic/store/store_types";
import processAddressSearchResults, {
  AutoCompleteReturnObject,
  processedAddressObj,
} from "@/logic/utils/processAddressSearchResults";
import Vue, { PropType } from "vue";
import { mapState } from "vuex";

export interface AddressAutocompleteInputUpdateObj {
  id: string;
  addressData: processedAddressObj | undefined;
}

/** Vue component: renders a reusable address auto complete input filed that
 * emits an object with a passed ID a selected address and its coordinate
 * values.
 *
 * @prop `id` - string - passed identifier included in the emitted return.
 * @prop `initialValue` - processedAddressObj
 * @prop `label` - string - the label to be displayed on the input element.
 * @prop `dark` - (optional) boolean - flags for input field to be rendered in dark mode, false by default.
 * @emits update - with a value in the shape of:
 * `{
 *    id: string \\ tha passed id value
 *    address: processedAddressObj \\ the selected value
 * }`
 */
export default Vue.extend({
  name: "AddressAutocompleteInput",
  props: {
    id: String,
    initialValue: Object as PropType<processedAddressObj | undefined>,
    label: String,
    dark: {
      type: Boolean as PropType<boolean | undefined>,
      default: false,
    },
    errorMsg: {
      type: String as PropType<string | null>,
      default: null,
    },
    loading: {
      type: Boolean as PropType<boolean | undefined>,
      default: false,
    },
  },
  computed: {
    ...mapState({
      userGeoLocation: (state: unknown) => (state as State).userGeoIPData,
    }),
  },
  data() {
    return {
      address: undefined as processedAddressObj | undefined,
      items: [] as Array<processedAddressObj>,
      query: null,
      results: null as Array<AutoCompleteReturnObject> | null,
      initialLoad: true,
    };
  },
  watch: {
    query(val) {
      if (this.initialLoad) {
        this.initialLoad = false;
        return;
      }
      if (val) {
        fetchLocationAutoCompleteDetails(val, this.userGeoLocation).then(
          (data) => {
            this.results = data;
          }
        );
      }
    },
    results() {
      if (this.results && this.query)
        // process API search results into usable items
        this.items = processAddressSearchResults(this.results, this.query);
    },
  },
  methods: {
    flagForUpdate() {
      this.$emit("update", {
        id: this.id,
        addressData: this.address,
      } as AddressAutocompleteInputUpdateObj);
    },
    setInitialValues() {
      if (this.initialValue) {
        this.address = this.initialValue;
        if (this.address.address != "") {
          this.items = [this.initialValue];
        }
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setInitialValues();
    });
  },
});
</script>
