<template>
  <LHotline
    :latLngs="latLngArray"
    :min="0"
    :max="1"
    :palette="{
      0.2: '#F44336',
      0.3: '#9C27B0',
      0.4: '#0D47A1',
      0.9: '#2196F3',
    }"
    outline-color="white"
    :weight="5"
    :outline-width="1"
    :interactive="false"
  />
</template>
<script lang="ts">
// eslint-disable-next-line
// @ts-nocheck
// Flagged for ts not to check this file due to limited type support for the frameworks utilized in this component.
// TODO: custom build type support for this component.
import Vue, { PropType } from "vue";
import LHotline from "../../../logic/libs/vue2-leaflet-hotline/Vue2LeafletHotline.vue";

/** Vue Leaflet Component: renders the trips polyline on the `MapPanel` component. This shows a color change based
 * on expected charge at that point in the polyline. */
export default Vue.extend({
  name: "GradientPolyline",
  components: {
    LHotline,
  },
  props: {
    latLngList: Array as PropType<[number, number][]>,
    startCharge: Number,
    endCharge: Number,
    hasNoChargeData: {
      default: false,
      type: Boolean as PropType<boolean>,
    },
  },
  computed: {
    latLngArray(): [number, number, number][] {
      if (this.hasNoChargeData) {
        return this.latLngList.map((item) => [item[0], item[1], 1]);
      }

      return (this.latLngList as [number, number][]).map((item, index) => {
        const positionValue = (1 / this.latLngList.length) * index;
        const zValue = this.linierInterpolation(
          this.startCharge,
          positionValue,
          this.endCharge
        );
        const expandedItem: [number, number, number] = [
          item[0],
          item[1],
          zValue,
        ];
        return expandedItem;
      });
    },
  },
  methods: {
    linierInterpolation(start: number, position: number, end: number): number {
      return (1 - position) * start + position * end;
    },
  },
});
</script>
