<template>
  <v-card flat class="pa-5">
    <v-card-title><BackBtn /> Optimised Trip stats </v-card-title>
    <v-card-text>
      <v-row justify="space-between" no-gutters>
        <span> Distance </span>
        <span> {{ trip.displayableTotalDistance }} </span>
      </v-row>
      <v-row justify="space-between" no-gutters>
        <span> Time </span>
        <span> {{ trip.displayableTotalTime }} </span>
      </v-row>
    </v-card-text>
    <v-card-title> EV Comparison </v-card-title>
    <v-card-text> See how an EV would preform in this trip. </v-card-text>
    <v-card-text>
      <v-btn
        block
        color="primary"
        class="rounded-lg text-none"
        @click="addComparison"
      >
        Add a Comparison
      </v-btn>
      <TspComparisonCard
        v-for="(comparison, index) in trip.comparisons"
        :key="'comparison-' + index"
        :comparison="comparison"
        :polyline="trip.fullTripPolyline"
        @update="updateComparison($event, index)"
      />
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import TspTrip from "@/logic/classes/tspTrip";
import TspTripComparison from "@/logic/classes/tspTripComparison";
import { MutationTypes } from "@/logic/store/store_types";
import BackBtn from "@/ui/components/ui-elements/BackBtn.vue";
import Vue, { PropType } from "vue";
import TspComparisonCard from "./TspComparisonCard.vue";

export default Vue.extend({
  name: "TspTripStatsContent",
  components: { BackBtn, TspComparisonCard },
  props: {
    trip: {
      required: true,
      type: Object as PropType<TspTrip>,
    },
  },
  methods: {
    addComparison() {
      const updatedTrip = this.trip;
      updatedTrip.comparisons.push(new TspTripComparison());
      this.updateTrip(updatedTrip);
    },
    updateTrip(trip: TspTrip) {
      this.$store.commit(MutationTypes.updateIndividualTrip, trip);
    },
    updateComparison(updatedComparison: TspTripComparison, index: number) {
      const updatedTrip = this.trip;
      updatedTrip.comparisons[index] = updatedComparison;
      this.updateTrip(updatedTrip);
    },
  },
});
</script>
