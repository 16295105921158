<template>
  <v-card class="px-5 pb-5" flat>
    <!-- normal details -->
    <template v-if="selectedVehicle.evModel">
      <v-card-text class="grey--text font-weight-regular text-h6 py-0">
        selected EV model {{ selectedVehicle.evModel.name }}
      </v-card-text>
      <v-card-text class="pt-0">
        These settings override the default settings based on your selected EV
        model.
      </v-card-text>
      <v-card-text class="pt-0">
        <span class="primary--text font-weight-regular text-h6">
          Tethered charging stations
        </span>
        <ul style="list-style-type: none" class="pl-0">
          <li
            v-for="(tether, index) in tetheredConnectors"
            :key="`tether-${index}`"
          >
            <v-checkbox
              v-model="selectedConnectors"
              :label="tether.displayName + ' - Tethered'"
              :value="tether.displayName + ' - Tethered'"
              class="my-0"
              color="info"
              dense
              hide-details
            />
          </li>
        </ul>
      </v-card-text>
      <v-card-text>
        <span class="primary--text font-weight-regular text-h6">
          Cables you're bringing with you
        </span>
        <ul style="list-style-type: none" class="pl-0">
          <li
            v-for="(cable, index) in socketedConnectors"
            :key="`cable-${index}`"
          >
            <v-checkbox
              v-model="selectedConnectors"
              :label="cable.displayName + ' - Socketed'"
              :value="cable.displayName + ' - Socketed'"
              class="my-0"
              color="info"
              dense
              hide-details
            />
          </li>
        </ul>
      </v-card-text>
      <!-- conditionally rendered user feedback based on action status -->
      <template v-if="status">
        <v-card-text class="d-flex" v-if="status === 'default'">
          <v-icon color="success" class="mr-1"> mdi-check-circle </v-icon>
          settings successfully restored to defaults.
        </v-card-text>
        <v-card-text class="d-flex" v-if="status === 'updated'">
          <v-icon color="success" class="mr-1"> mdi-check-circle </v-icon>
          settings updated successfully.
        </v-card-text>
        <v-card-text
          class="d-flex align-items-center"
          v-if="status === 'processing'"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            class="mr-1"
            size="22"
          />
          <span>Processing ...</span>
        </v-card-text>
      </template>
      <!-- modal actions, hidden while feedback from actions is being displayed -->
      <v-card-actions v-else>
        <v-btn
          color="primary"
          class="text-none rounded-lg"
          @click="handleUpdateSelection"
        >
          Save settings
        </v-btn>
        <v-btn
          outlined
          :color="powerTripDarkBlue"
          class="text-none rounded-lg flex-grow-1"
          @click="handleRestoreDefaults"
        >
          {{
            $vuetify.breakpoint.xsOnly ? "Defaults" : "Restore model default"
          }}
        </v-btn>
      </v-card-actions>
    </template>
    <!-- failed to find EV model warning -->
    <template v-else>
      <v-card-text>
        You currently have no EV model selected. Please select an EV model and
        try again.
      </v-card-text>
    </template>
  </v-card>
</template>
<script lang="ts">
import Vehicle from "@/logic/classes/vehicle";
import {
  getAllSocketedConnectors,
  getAllTetheredConnectors,
} from "@/logic/data/connectorDetailsData";
import { GettersTypes, MutationTypes } from "@/logic/store/store_types";
import Vue from "vue";
import { mapGetters } from "vuex";
import type { SelectedPlug } from "../../../../logic/types/sheared_local_types";
import {
  getConnectorFriendlyName,
  getOCPIStandard,
} from "@/logic/utils/connector_name_utils";
import { powerTripDarkBlue } from "@/logic/data/const";

export default Vue.extend({
  name: "ConnectorsContent",
  computed: {
    tetheredConnectors() {
      return getAllTetheredConnectors();
    },
    socketedConnectors() {
      return getAllSocketedConnectors();
    },
    ...mapGetters({
      selectedVehicle: GettersTypes.displayedVehicle,
    }),
  },
  data() {
    return {
      powerTripDarkBlue: powerTripDarkBlue,
      selectedConnectors: [] as string[],
      status: undefined as
        | "default"
        | "updated"
        | "processing"
        | "failed"
        | undefined,
    };
  },
  methods: {
    convertDisplayNameToSelectedPlug(
      displayName: string
    ): SelectedPlug | undefined {
      return getOCPIStandard(displayName);
    },
    convertSelectedPlugToDisplayName(
      selectedPlug: SelectedPlug
    ): string | undefined {
      const data = getConnectorFriendlyName(selectedPlug.standard);
      if (Array.isArray(data)) {
        const name = data.find((item) =>
          item.includes(
            selectedPlug.format === "CABLE" ? " - Tethered" : " - Socketed"
          )
        );
        if (name) return name;
      } else {
        return data;
      }
    },
    getUserSelectedPlugs(): SelectedPlug[] | undefined {
      const tempArray = (this.selectedVehicle as Vehicle | undefined)
        ?.userSelectedPlugs;
      if (tempArray?.length) return tempArray;
      return;
    },
    getModelsDefaultPlugs(): SelectedPlug[] | undefined {
      const tempArray = (this.selectedVehicle as Vehicle).evModel
        ?.compatibleConnectors;
      return tempArray?.filter((connector) => connector.format !== "SOCKET");
    },
    getInitialConnectors(): void {
      // check data exists and step through hierarchy of data sources.
      const connectors: SelectedPlug[] =
        this.getUserSelectedPlugs() ?? this.getModelsDefaultPlugs() ?? [];
      const tempArray: string[] = [];
      connectors.forEach((connector) => {
        const displayName = this.convertSelectedPlugToDisplayName(connector);
        if (displayName) tempArray.push(displayName);
      });

      this.selectedConnectors = tempArray;
    },
    addOrRemoveSelectedPlug(val: string) {
      if (val) {
        this.selectedConnectors.push(val);
      } else {
        this.selectedConnectors = this.selectedConnectors.filter(
          (connector) => connector !== val
        );
      }
    },
    clearStatus() {
      setTimeout(() => {
        this.status = undefined;
      }, 2000);
    },
    async handleUpdateSelection() {
      // update status
      this.status = "processing";

      const tempArray: SelectedPlug[] = [];

      this.selectedConnectors.forEach((connector) => {
        const plug = getOCPIStandard(connector);
        if (plug) tempArray.push(plug);
      });

      // check if there is a vehicle object to update.
      if (this.selectedVehicle as Vehicle | undefined) {
        const outcome = await (
          this.selectedVehicle as Vehicle
        ).setSelectedPlugs(tempArray);
        if (outcome === "SUCCESS") {
          // update global state
          this.$store.commit(
            MutationTypes.updateIndividualVehicle,
            this.selectedVehicle
          );
          // update status
          this.status = "updated";
          this.clearStatus();
          return;
        }
      }
      // update status
      this.status = "failed";
      this.clearStatus();
      return;
    },
    async handleRestoreDefaults() {
      // update status
      this.status = "processing";
      // check if there is a vehicle object to update.
      if (this.selectedVehicle as Vehicle | undefined) {
        const outcome = await (
          this.selectedVehicle as Vehicle
        ).setSelectedPlugs([]);
        if (outcome === "SUCCESS") {
          // update global state
          this.$store.commit(
            MutationTypes.updateIndividualVehicle,
            this.selectedVehicle
          );
          const tempArray: string[] = [];
          const defaults = this.getModelsDefaultPlugs();
          if (defaults)
            defaults.forEach((connector) => {
              const displayName =
                this.convertSelectedPlugToDisplayName(connector);
              if (displayName) tempArray.push(displayName);
            });
          // update local state
          this.selectedConnectors = tempArray;
          // update status
          this.status = "default";
          this.clearStatus();
          return;
        }
      }
      // update status
      this.status = "failed";
      this.clearStatus();
      return;
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.selectedVehicle) this.getInitialConnectors();
    });
  },
});
</script>
