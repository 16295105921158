<template>
  <TspTripPlanningForm :trip="trip" v-if="trip" />
  <NoTripContent v-else />
</template>
<script lang="ts">
import Vue from "vue";
import TspTripPlanningForm from "./TspTripPlanningForm.vue";
import { mapGetters } from "vuex";
import { GettersTypes } from "@/logic/store/store_types";
import NoTripContent from "../../NoTripContent.vue";

export default Vue.extend({
  name: "TspTripEditWrapper",
  components: { TspTripPlanningForm, NoTripContent },
  computed: {
    ...mapGetters({
      trip: GettersTypes.selectedTripData,
    }),
  },
});
</script>
