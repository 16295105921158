<template>
  <v-card-text>
    <v-card class="rounded-lg mb-3 primary white--text">
      <v-expansion-panels flat v-model="expanded">
        <v-expansion-panel class="transparent">
          <v-expansion-panel-header class="pl-0" color="transparent">
            <v-card flat class="transparent">
              <v-card-title class="text-body-1 font-weight-medium pt-0">
                <v-icon color="white"> mdi-currency-usd </v-icon>
                <span class="pwt-number-font white--text">
                  {{
                    (
                      fiveYearProjections.totalEquivalentPetrolCost +
                      fiveYearProjections.totalRoadUserCharges +
                      fiveYearProjections.totalPetrolMaintenanceCost -
                      fiveYearProjections.totalPublicChargingCost -
                      fiveYearProjections.totalPrivateChargingCost -
                      fiveYearProjections.totalEVMaintenanceCost
                    ).toLocaleString()
                  }}
                </span>
              </v-card-title>
              <v-card-subtitle class="pl-6 white--text">
                Total 5 years savings
              </v-card-subtitle>
            </v-card>
            <template v-slot:actions>
              <v-icon color="white"> $expand </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ul
              style="list-style-type: none"
              class="ml-0 pl-0 mb-5 white--text"
            >
              <li class="d-flex justify-space-between">
                <span class="font-weight-bold">
                  <v-icon color="white"> $stationsIcon </v-icon> Fuel savings
                </span>
                <span class="pwt-number-font">
                  ${{
                    (
                      fiveYearProjections.totalEquivalentPetrolCost +
                      fiveYearProjections.totalRoadUserCharges -
                      fiveYearProjections.totalPublicChargingCost -
                      fiveYearProjections.totalPrivateChargingCost
                    ).toLocaleString()
                  }}
                </span>
              </li>
              <li class="d-flex justify-space-between">
                <span>Total equivalent {{ calcVs.toLowerCase() }} cost</span>
                <span class="pwt-number-font">
                  ${{
                    fiveYearProjections.totalEquivalentPetrolCost.toLocaleString()
                  }}
                </span>
              </li>
              <li
                v-if="calcVs === 'DIESEL'"
                class="d-flex justify-space-between"
              >
                <span>Total equivalent diesel road user charges</span>
                <span class="pwt-number-font">
                  ${{
                    fiveYearProjections.totalRoadUserCharges.toLocaleString()
                  }}
                </span>
              </li>
              <li class="d-flex justify-space-between">
                <span>Total public charging cost</span>
                <span class="pwt-number-font">
                  ${{
                    fiveYearProjections.totalPublicChargingCost.toLocaleString()
                  }}
                </span>
              </li>
              <li class="d-flex justify-space-between">
                <span>Total private charging cost</span>
                <span class="pwt-number-font">
                  ${{
                    fiveYearProjections.totalPrivateChargingCost.toLocaleString()
                  }}
                </span>
              </li>
            </ul>
            <ul
              style="list-style-type: none"
              class="ml-0 pl-0 mb-5 white--text"
            >
              <li class="d-flex justify-space-between">
                <span class="font-weight-bold">
                  <v-icon color="white"> mdi-wrench </v-icon>
                  Maintenance savings
                </span>
                <span class="pwt-number-font">
                  ${{
                    (
                      fiveYearProjections.totalPetrolMaintenanceCost -
                      fiveYearProjections.totalEVMaintenanceCost
                    ).toLocaleString()
                  }}
                </span>
              </li>
              <li class="d-flex justify-space-between">
                <span>
                  Estimated {{ calcVs.toLowerCase() }} vehicle maintenance cost
                </span>
                <span class="pwt-number-font">
                  ${{
                    fiveYearProjections.totalPetrolMaintenanceCost.toLocaleString()
                  }}
                </span>
              </li>
              <li class="d-flex justify-space-between">
                <span>Estimated EV maintenance cost</span>
                <span class="pwt-number-font">
                  ${{
                    fiveYearProjections.totalEVMaintenanceCost.toLocaleString()
                  }}
                </span>
              </li>
            </ul>
            <ul
              style="list-style-type: none"
              class="ml-0 pl-0 mb-5 white--text"
            >
              <li class="d-flex justify-space-between">
                <span class="font-weight-bold">
                  <v-icon color="white"> mdi-leaf </v-icon>
                  Emissions avoided
                </span>
                <span class="pwt-number-font">
                  {{
                    fiveYearProjections.totalAvoidedEmissions.toLocaleString()
                  }}
                  kg
                </span>
              </li>
            </ul>
            <ul
              style="list-style-type: none"
              class="ml-0 pl-0 mb-5 white--text"
            >
              <li class="d-flex justify-space-between">
                <span class="font-weight-bold">
                  <v-icon color="white"> $vehiclesIcon </v-icon>
                  Total trips
                </span>
                <span class="pwt-number-font">
                  {{ fiveYearProjections.totalNumberOfTrips.toLocaleString() }}
                </span>
              </li>
            </ul>
            <div class="mb-5 white--text">
              The five year savings are projected based on the expected
              frequency of your saved trips and your current saving settings.
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </v-card-text>
</template>
<script lang="ts">
import { GettersTypes, type State } from "@/logic/store/store_types";
import Vue from "vue";
import { mapGetters, mapState } from "vuex";

export default Vue.extend({
  name: "FiveYearSavings",
  computed: {
    ...mapGetters({
      fiveYearProjections: GettersTypes.getFiveYearProjections,
    }),
    ...mapState({
      calcVs: (state: unknown) => (state as State).calcVs,
    }),
  },
  data() {
    return {
      expanded: false,
    };
  },
  watch: {
    expanded(val: 0 | undefined) {
      if (val === 0) {
        // Notify analytics server
        // eslint-disable-next-line
        // @ts-ignore
        Vue.prototype.$Countly.q.push([
          "add_event",
          {
            key: "5yr Savings Viewed",
            count: 1,
          },
        ]);
      }
    },
  },
});
</script>
