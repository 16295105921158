import TripLocation from "../classes/tripLocation";
import Vehicle from "../classes/vehicle";
import { CalcVsType } from "../store/store_types";
import { EVNavRouteParams, EVNavRouteParamsLegacy } from "./ev_nav_types";

/** Data shape for JSON saved trip files. */
export interface TripSaveData {
  /** The parameters used to generate this trip.*/
  parameters: EVNavRouteParams | EVNavRouteParamsLegacy;

  /** The locations stopped at along the trip including the starting
   * location (always at index 0) and the destination location
   * (always at the last index).*/
  locations: Location[];

  /** Fuel type of the vehicle this was planned in. */
  tripFuelType?: TripFuelType;

  /** ID for the directus SavedTrips collection record. */
  directusID?: string | number;

  /** The frequency of this trip if it is a frequent trip. This is
   * used for calculating the 5 year savings.
   */
  frequency?: TripFrequency;

  /** The location id of the user selected most important set time. e.g.
   * leaving at X is most important so will by `location-start` etc. */
  primaryTimeLocation?: string;

  /** The ID of the selected EV if there was one not just a generic model. */
  vehicleID?: number;

  /** the optional display name for the trip */
  name?: string;

  /** The total estimated cost of public charging for this trip in NZD.
   *
   * NOTE: failed trips and ICE trips will not have a value.
   */
  totalPublicChargingCost?: number;

  /** The total estimated cost of private charging for this trip in NZD.
   *
   * NOTE: failed trips and ICE trips will not have a value.
   */
  totalPrivateChargingCost?: number;

  /** The total distance covered by this trip if in an EV in meters.
   *
   * NOTE: failed trips and ICE trips will not have a value.
   */
  totalEVDistance?: number;

  /** The total estimated energy consumed for this trip in kWh.
   *
   * NOTE: failed trips and ICE trips will not have a value.
   */
  totalEnergy?: number;
}

/** data shape for local trip frequency data objects.
 * Note: this is normally part of a larger data structure.
 */
export interface TripFrequency {
  numberOfTimes: number;
  timeFrame:
    | "day"
    | "weekday"
    | "week"
    | "fortnight"
    | "month"
    | "quarter"
    | "half-year"
    | "year";
}

export type TripFuelType = "ELECTRIC" | "ICE";

/** A data obj representing a location in a trip. */
export interface Location {
  /** Unique identifier in scope of this trip.
   *
   * NOTE: This will be passed to `EV Nav` at the point
   * of calculation as well as returned from `EV Nav` in
   * the as the `Name` property on the waypoint object.
   */
  id: string;
  /** Display String for location. */
  address: string;
  /** Lat/Lon coordinate object for this location.
   *
   * NOTE: The properties and values with in this object
   * forms part of the `EV Nav` waypoint object. See db
   * types `Waypoint` for details.
   */
  coordinates: Coordinates;
  /** The arrival time for this location. If no time is
   * set it will appear as an empty string */
  time: string;
  /** Number of seconds the user is intending to stay at this
   * location. This is an optional property only used for
   * additional stops calculating times taking into account
   * this extra duration of the stay at this location. */
  stay?: number;
  /** Optional property used for calculations of overnight
   * trips. */
  date?: string;
  /** Optional property used for flagging this location as a
   * waypoint that the user will charge at.
   *
   * NOTE: This is not used for the starting or destination
   * locations, only additional stops. */
  chargeHere?: boolean;
  /** State Of Charge after charging at this location. Used as
   * SOCAct for planning the next leg of the trip.
   *
   * This should be between 0 and 1 as float representation of
   * a percentage e.g. 80% = 0.8
   * */
  SOC_AfterCharge?: number;
}

/** Data object representing a coordinate
 * with latitude and longitude properties
 * and values. */
export interface Coordinates {
  Latitude: number;
  Longitude: number;
}

/** Data object expected to be passed from a trip planning form to store to create a new trip. */
export interface TripPlanningFormData {
  name?: string;
  locations: TripLocation[];
  frequency?: TripFrequency;
  vehicle: Vehicle;
  SOCAct?: number;
  SOCEnd?: number;
  SOCMin?: number;
  SOCMax?: number;
  primaryTimeLocation?: string;
}

/** Data object for expected out put from trip stats get method */
export interface TripStats {
  /** Avoided CO2 in kgs */
  avoidedCO2?: string;
  /** Distance driven in kms */
  drivingKms: string;
  /** Total time driving this trip */
  drivingTime: string;
  /** Total time charging this trip */
  chargingTime?: string;
  /** Energy charged this trip */
  chargeKWh?: string;
  /** equivalent CO2 emitted based on resources to generate charge consumed*/
  emittedCO2: string;
  /** Estimated cost of public charing in NZD */
  publicChargingCost?: string;
  /** Estimated cost of private charing in NZD  */
  privateChargingCost?: number;
  battery?: string;
  /** Equivalent cost if using a petrol card */
  fuelCost: string;
  /** Total time including driving time and charging time */
  totalTime: string;
  /** Total time spend at scheduled stops */
  stayDuration?: string;

  totalEnergyUsed?: number;
}

/** Expected shape of the data object to be passed to the getTripStats method */
export interface TripStatsSettings {
  calcVs: CalcVsType;
  petrolKmPerLitre: number;
  petrolCostPerLitre: number;
  dieselKmPerLitre: number;
  dieselCostPerLitre: number;
  kWhCostHome: number;
}

/** Data model for trip fallback cause data. */
export interface TripFallbackCause {
  displayStr: string;
  reason: TripFallbackCauseReasons;
}

export enum TripFallbackCauseReasons {
  ACT_SOC_BELOW_MIN_SOC = "ACT_SOC_BELOW_MIN_SOC",
  NO_CHARGER_IN_RANGE_OF_START = "NO_CHARGER_IN_RANGE_OF_START",
  ACT_SOC_TO_LOW_TO_REACH_FIRST_CHARGER = "ACT_SOC_TO_LOW_TO_REACH_FIRST_CHARGER",
  LONGEST_LEAP_MORE_THAN_FULL_CHARGE = "LONGEST_LEAP_MORE_THAN_FULL_CHARGE",
  NO_CHARGER_IN_RANGE_OF_DESTINATION = "NO_CHARGER_IN_RANGE_OF_DESTINATION",
  UNKNOWN_REASON = "UNKNOWN_REASON",
}
