<template>
  <v-card flat class="pa-5">
    <v-card-title class="tertiary--text d-flex justify-space-between pt-5">
      <span>
        <BackBtn />
        Trip Stats Summary
      </span>
      <v-btn icon @click="handleCheckSettingsClick">
        <v-icon color="grey lighten-1"> mdi-cog </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <!-- Conditionally rendered default section if no selected trip. -->
      <v-card flat>
        <v-card-text class="px-0">
          You currently have no trip selected{{
            !hasStoredTrips ? " and no stored trips" : ""
          }}. Please add a new trip{{
            hasStoredTrips ? " or select one of your stored trips" : ""
          }}.
        </v-card-text>
        <v-card-actions class="px-0 d-flex flex-column">
          <v-btn
            color="primary"
            block
            class="text-none rounded-lg mb-3"
            @click="toPlanning"
          >
            Plan a New Trip
          </v-btn>
          <v-btn
            :color="powerTripDarkBlue"
            block
            outlined
            class="text-none rounded-lg mb-3"
            @click="toOptimisedTripPlanning"
          >
            Plan a New Optimised Trip
          </v-btn>
          <v-btn
            color="primary"
            block
            text
            class="text-none rounded-lg"
            @click="toTrips"
          >
            Select previous trips
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import { powerTripDarkBlue } from "@/logic/data/const";
import { RouteNames } from "@/logic/router";
import {
  MainDialogContent,
  MutationTypes,
  type State,
} from "@/logic/store/store_types";
import BackBtn from "@/ui/components/ui-elements/BackBtn.vue";
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "TripStatsEmptyStateContent",
  data() {
    return {
      powerTripDarkBlue: powerTripDarkBlue,
    };
  },
  methods: {
    handleCheckSettingsClick() {
      this.$store.commit(
        MutationTypes.setMainDialogContent,
        MainDialogContent.SAVINGS_SETTINGS
      );
    },
    pushRoute(routeName: RouteNames) {
      this.$router.push({ name: routeName });
    },
    toPlanning() {
      this.pushRoute(RouteNames.planning);
    },
    toTrips() {
      this.pushRoute(RouteNames.trips);
    },
    toOptimisedTripPlanning() {
      this.pushRoute(RouteNames.optimisedPlanning);
    },
  },
  computed: {
    ...mapState({
      hasStoredTrips: (state: unknown) => !!(state as State).trips.length,
    }),
  },
  components: { BackBtn },
});
</script>
