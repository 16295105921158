import Trip from "../classes/trip";
import accumulatedTimeSeconds from "./accumulatedTimeSeconds";
import { DateTime, Duration, Interval } from "luxon";

export interface calcTimeDataReturnObj {
  displayArrivalTime?: string;
  displayDepartureTime?: string;
  timeDifference?: string;
  differenceType?: "none" | "before" | "after";
}

export default function calcTimeData(
  storedTrip: Trip,
  stepIndex: number,
  locationID?: string
): calcTimeDataReturnObj {
  const expectedTime = storedTrip.locations.find(
    (location) => location.localId === locationID
  )?.time;

  const arrivalTimeObj = getTime(storedTrip, stepIndex);
  const departureTimeObj = getTime(storedTrip, stepIndex, true);

  if (!expectedTime || !arrivalTimeObj || !departureTimeObj || !locationID) {
    return {
      displayArrivalTime: arrivalTimeObj
        ? arrivalTimeObj.toLocaleString(DateTime.TIME_SIMPLE)
        : undefined,
      displayDepartureTime: departureTimeObj
        ? departureTimeObj.toLocaleString(DateTime.TIME_SIMPLE)
        : undefined,
    };
  }

  const expectedTimeObj = DateTime.fromISO(expectedTime);
  const differenceData = getDifference(
    expectedTimeObj,
    locationID === "location-start" ? departureTimeObj : arrivalTimeObj
  );

  return {
    displayArrivalTime: arrivalTimeObj.toLocaleString(DateTime.TIME_SIMPLE),
    displayDepartureTime: departureTimeObj.toLocaleString(DateTime.TIME_SIMPLE),
    timeDifference: differenceData.differenceObj
      // correct rounding as luxon only supports floor rounding in its current version.
      .set({ minutes: Math.round(differenceData.differenceObj.minutes) })
      .normalize()
      .toHuman({ listStyle: "narrow" })
      .replace(",", ""),
    differenceType: differenceData.differenceType,
  };
}

function getTime(
  storedTrip: Trip,
  stepIndex: number,
  departureTime = false
): DateTime | undefined {
  const baseTime = storedTrip.locations.find(
    (location) => location.localId === storedTrip.primaryTimeLocation
  )?.time;
  const steps = storedTrip.evTripData?.flatMap((plan) => plan.steps);

  // case for no primary time
  if (
    storedTrip.primaryTimeLocation === undefined ||
    baseTime === undefined ||
    !steps
  ) {
    return undefined;
  }

  const isWaypoint = steps[stepIndex].From.includes("location-stop");

  // case for initial departure time as the primary time
  if (storedTrip.primaryTimeLocation === "location-start") {
    return DateTime.fromISO(baseTime).plus({
      hours: 0,
      minutes: 0,
      seconds:
        accumulatedTimeSeconds(steps, stepIndex, storedTrip.locations) +
        (departureTime
          ? (steps[stepIndex].ChargeTime ?? 0) +
            (isWaypoint
              ? storedTrip.locations.find(
                  (location) => location.localId === steps[stepIndex].From
                )?.stay || 0
              : 0)
          : 0),
    });
  }

  // case for final destination arrival time as the primary time
  if (storedTrip.primaryTimeLocation === "location-end") {
    let totalTime = 0;

    storedTrip.evTripData?.forEach((plan) => {
      totalTime += plan.time;
    });

    storedTrip.locations.forEach((location) => {
      if (location.stay) {
        totalTime += location.stay;
      }
    });

    return DateTime.fromISO(baseTime).minus({
      hours: 0,
      minutes: 0,
      seconds:
        totalTime -
        accumulatedTimeSeconds(steps, stepIndex, storedTrip.locations) -
        (departureTime ? steps[stepIndex].ChargeTime ?? 0 : 0),
    });
  }

  // case for a waypoints arrival time as the primary time

  // find index of waypoint selected as primary time in steps array
  const waypointStepsIndex = steps.findIndex(
    (step) => step.From === storedTrip.primaryTimeLocation
  );
  if (waypointStepsIndex === -1) return undefined;

  // find index of waypoint selected as primary time in locations array
  const waypointLocationsIndex = storedTrip.locations.findIndex(
    (location) => location.localId === storedTrip.primaryTimeLocation
  );
  if (waypointLocationsIndex === -1) return undefined;

  // compare steps indexes to find if step is before or after the waypoints step.
  if (stepIndex < waypointStepsIndex) {
    // calculate total time till this point
    let totalTimeToWaypoint = 0;

    for (let i = 0; i < waypointStepsIndex; i++) {
      const stepTotalTime: number =
        (steps[i].ChargeTime ?? 0) + steps[i].TravelTime + steps[i].FerryTime;
      totalTimeToWaypoint += stepTotalTime;
    }

    for (let i = 0; i < waypointLocationsIndex; i++) {
      const stayDuration: number = storedTrip.locations[i].stay || 0;
      totalTimeToWaypoint += stayDuration;
    }

    // calculate accumulated time till this point
    let accumulatedTime = 0;

    for (let i = 0; i < stepIndex; i++) {
      let totalStayDurationTillStep = 0;
      if (steps[i].From.includes("location-stop")) {
        // find scheduled stop
        const scheduledStop = storedTrip.locations.find(
          (stop) => stop.localId === steps[i].From
        );
        totalStayDurationTillStep += scheduledStop?.stay || 0;
      }
      const stepTotalTime: number =
        (steps[i].ChargeTime ?? 0) +
        steps[i].TravelTime +
        steps[i].FerryTime +
        totalStayDurationTillStep;
      accumulatedTime += stepTotalTime;
    }

    // deduct accumulated time to this from total time till this point and deduct outcome from base time
    return DateTime.fromISO(baseTime).minus({
      hours: 0,
      minutes: 0,
      seconds:
        totalTimeToWaypoint -
        (accumulatedTime +
          (departureTime ? steps[stepIndex].ChargeTime ?? 0 : 0)),
    });
  } else if (stepIndex === waypointStepsIndex) {
    return DateTime.fromISO(baseTime).plus({
      seconds: departureTime
        ? storedTrip.locations[waypointLocationsIndex].stay || 0
        : 0,
    });
  } else {
    // calculate accumulated time since
    let accumulatedTimeSinceWaypoint = 0;

    for (let i = 0; i < stepIndex; i++) {
      // exclude steps before waypoint
      if (i >= waypointStepsIndex) {
        let totalStayDurationTillStep = 0;
        if (steps[i].From.includes("location-stop")) {
          // find scheduled stop
          const scheduledStop = storedTrip.locations.find(
            (stop) => stop.localId === steps[i].From
          );
          totalStayDurationTillStep += scheduledStop?.stay || 0;
        }
        const stepTotalTime: number =
          (steps[i].ChargeTime ?? 0) +
          steps[i].TravelTime +
          steps[i].FerryTime +
          totalStayDurationTillStep;
        accumulatedTimeSinceWaypoint += stepTotalTime;
      }
    }

    // take into account waypoints stay duration
    const waypointStayDuration = storedTrip.locations.find(
      (location) => location.localId === storedTrip.primaryTimeLocation
    );
    accumulatedTimeSinceWaypoint += waypointStayDuration?.stay || 0;

    // add this to base time
    return DateTime.fromISO(baseTime).plus({
      hours: 0,
      minutes: 0,
      seconds:
        accumulatedTimeSinceWaypoint +
        (departureTime ? steps[stepIndex].ChargeTime ?? 0 : 0),
    });
  }
}

interface differenceDataObj {
  differenceObj: Duration;
  differenceType: "none" | "before" | "after";
}

export function getDifference(
  expectedTimeObj: DateTime,
  actualTimeObj: DateTime
): differenceDataObj {
  let interval = Interval.fromDateTimes(actualTimeObj, expectedTimeObj);
  let type: "before" | "after" = "before";

  if (!interval.isValid) {
    interval = Interval.fromDateTimes(expectedTimeObj, actualTimeObj);
    type = "after";
  }

  let duration = interval.toDuration(["hours", "minutes"]);

  if (!duration.hours) {
    duration = interval.toDuration(["minutes"]);
  }

  return {
    differenceObj: duration,
    differenceType: interval.isEmpty() ? "none" : type,
  };
}
