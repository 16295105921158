import { Duration } from "luxon";
import type {
  Location as apiLocation,
  DisplayText,
  ParkingType,
  BusinessDetails,
  Facility,
  Hours,
  Image,
  EnergyMix,
  LocationExtensionData,
  ConnectorType,
} from "../types/charger_Db_types";
import type {
  ChargingDetails,
  isCompatibleReturn,
} from "../types/sheared_local_types";
import generateUniqueLocalID from "../utils/generateUniqueLocalID";
import EVSE from "./evse";
import Trip from "./trip";
import Vehicle from "./vehicle";
import Connector, { ACPowerTypeList } from "./connector";
import { ChargerFilters } from "../store/store_types";
import getAssetSrc from "../utils/getAssetSrc";
import GeoLocation from "./geoLocation";

/** */
export default class Charger {
  // -------------------------------------------------------------------- //
  // ------------------------- Global class state ----------------------- //
  // -------------------------------------------------------------------- //

  // global record of class instance ids this session.
  static usedIds: string[] = [];

  // -------------------------------------------------------------------- //
  // ------------------------------- State ------------------------------ //
  // -------------------------------------------------------------------- //

  /** Uniquely identifies the location within the CPOs platform (and sub-operator platforms). This field can never be changed, modified or renamed. */
  id: string;

  /** Display name of the location. */
  name?: string;

  /** Street/block name and house number if available. */
  address?: string;

  /** City or town. */
  city?: string;

  /** Postal code of the location, may only be omitted when the location has no postal code: in some countries charging locations at highways don’t have postal codes. */
  postalCode?: string;

  /** State or province of the location, only to be used when relevant. */
  state?: string;

  /** ISO 3166-1 alpha-3 code for the country of this location. */
  country?: string;

  /** Coordinates of the location. */
  coordinates: GeoLocation;

  /** Geographical location of related points relevant to the user. */
  relatedLocations: GeoLocation[];

  /** The general type of parking at the charge point location. */
  parkingType?: ParkingType;

  /** List of EVSEs that belong to this Location. */
  evses: EVSE[];

  /** Human-readable directions on how to reach the location. */
  directions: DisplayText[];

  /** Information of the operator. When not specified, the information retrieved from the Credentials module, selected by the `country_code` and `party_id` of this Location, should be used instead. */
  operator?: BusinessDetails;

  /** Information of the sub-operator if available. */
  suboperator?: BusinessDetails;

  /** Information of the owner if available. */
  owner?: BusinessDetails;

  /** Optional list of facilities this charging location directly belongs to. */
  facilities: Facility[];

  /** One of IANA tzdata’s TZ-values representing the time zone of the location. Examples: "Europe/Oslo", "Europe/Zurich". (http://www.iana.org/time-zones) */
  TimeZone: string;

  /** The times when the EVSEs at the location can be accessed for charging. */
  openingTimes?: Hours;

  /** Indicates if the EVSEs are still charging outside the opening hours of the location. E.g. when the parking garage closes its barriers over night, is it allowed to charge till the next morning?
   *
   * Default: true
   * */
  chargingWhenClosed?: boolean;

  /** Links to images related to the location such as photos or logos. */
  images: Image[];

  /** Details on the energy supplied at this location. */
  energyMix?: EnergyMix;

  /** Timestamp when this Location or one of its EVSEs or Connectors were last updated (or created).
   *
   * In UTC string format.
   */
  lastUpdated: string;

  /** Data source information for this Charger. */
  extensionData: LocationExtensionData;

  /** The raw data from the api.
   *
   * NOTE: this is included if the methods in the three linked classes are not able to provide the required data.
   */
  rawData?: apiLocation;

  /** Flag to indicate if this charger is private. */
  isPrivate: boolean;

  // -------------------------------------------------------------------- //
  // --------------------------- Constructor ---------------------------- //
  // -------------------------------------------------------------------- //

  constructor({
    id = undefined,
    coordinates,
    rawData = undefined,
    name = undefined,
    address = undefined,
    city = undefined,
    postalCode = undefined,
    relatedLocations = [],
    parkingType = undefined,
    evses = [],
    directions = [],
    operator = undefined,
    suboperator = undefined,
    owner = undefined,
    facilities = [],
    TimeZone = undefined,
    openingTimes = undefined,
    chargingWhenClosed = true,
    images = [],
    energyMix = undefined,
    lastUpdated = undefined,
    extensionData = {},
    isPrivate = false,
  }: {
    id?: string;
    coordinates: GeoLocation;
    rawData?: apiLocation;
    name?: string;
    address?: string;
    city?: string;
    postalCode?: string;
    relatedLocations?: GeoLocation[];
    parkingType?: ParkingType;
    evses?: EVSE[];
    directions?: DisplayText[];
    operator?: BusinessDetails;
    suboperator?: BusinessDetails;
    owner?: BusinessDetails;
    facilities?: Facility[];
    TimeZone?: string;
    openingTimes?: Hours;
    chargingWhenClosed?: boolean;
    images?: Image[];
    energyMix?: EnergyMix;
    lastUpdated?: string;
    extensionData?: LocationExtensionData;
    isPrivate?: boolean;
  }) {
    // required
    this.id = id ?? generateUniqueLocalID(Charger.usedIds, "charger"); // Future proofing for user added chargers.
    this.coordinates = coordinates;
    this.TimeZone =
      TimeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.lastUpdated = lastUpdated ?? new Date().toUTCString();

    // optional/with basic default values
    this.relatedLocations = relatedLocations;
    this.directions = directions;
    this.facilities = facilities;
    this.chargingWhenClosed = chargingWhenClosed;
    this.images = images;
    this.rawData = rawData;
    this.name = name;
    this.address = address;
    this.city = city;
    this.postalCode = postalCode;
    this.parkingType = parkingType;
    this.operator = operator;
    this.suboperator = suboperator;
    this.owner = owner;
    this.openingTimes = openingTimes;
    this.energyMix = energyMix;
    this.evses = evses;
    this.extensionData = extensionData;
    this.isPrivate = isPrivate;

    // add id to list of used unique ids
    Charger.usedIds.push(this.id);
  }

  /**
   * Creates a new `Charger` object from the expected OCPI compliant returned
   * data form the charger DB api.
   *
   * @param data the whole data object for the `Location` returned by the charger DB api.
   * @returns new Charger class object.
   */
  static fromChargerDbData(data: apiLocation) {
    // parse coordinates.
    const parsedCoordinates = GeoLocation.fromChargerDBData(data.coordinates);

    // parse additional geo-locations.
    const tempRelatedLocations: GeoLocation[] | undefined =
      data.related_locations?.map((relatedLocation) =>
        GeoLocation.fromChargerDBData(relatedLocation)
      );

    // map eves's.
    const evses: EVSE[] = [];
    data.evses?.forEach((apiEVSE) => {
      const evse = EVSE.fromChargerDbData(apiEVSE);
      if (evse) evses.push(evse);
    });

    if (!evses.length) throw new Error("Charger has no charging capabilities");

    // create new `Charger` object.
    return new Charger({
      id: data.id,
      name: data.name,
      address: data.address,
      city: data.city,
      postalCode: data.postal_code,
      parkingType:
        data.parking_type !== "ParkingTypeEnum(0)"
          ? data.parking_type
          : undefined,
      evses: evses,
      directions: data.directions ?? undefined,
      operator: data.operator ?? undefined,
      suboperator: data.suboperator ?? undefined,
      owner: data.owner ?? undefined,
      facilities: data.facilities?.filter(
        (facility) => facility !== "FacilityEnum(0)"
      ),
      TimeZone: data.TimeZone,
      openingTimes: data.opening_times,
      chargingWhenClosed: data.charging_when_closed,
      images: data.images ?? undefined,
      energyMix:
        data.energy_mix?.energy_sources?.source !==
          "EnergySourceCategoryEnum(0)" &&
        data.energy_mix?.environmental_impact?.category !==
          "EnvironmentalImpactCategoryEnum(0)"
          ? data.energy_mix
          : undefined,
      lastUpdated: data.last_updated,
      coordinates: parsedCoordinates,
      relatedLocations: tempRelatedLocations,
      extensionData: data.extension_data,
      rawData: data,
      isPrivate: !data.publish,
    });
  }

  // -------------------------------------------------------------------- //
  // ------------------------------ Getters ----------------------------- //
  // -------------------------------------------------------------------- //

  /** Returns true if lat/lon coordinates are null island. */
  public get isNullIsland(): boolean {
    return this.coordinates.latitude === 0 && this.coordinates.longitude === 0;
  }

  /** Returns chargers full address in a displayable string. */
  public get addressString(): string {
    return (
      (this.address ? this.address + ", " : "") +
      (this.city ? this.city + ", " : "") +
      (this.postalCode ? this.postalCode + ", " : "") +
      (this.country ? this.country + ", " : "")
    );
  }

  /** Returns the number of devices (EVSE) for this charger. */
  public get numberOfDevices(): number {
    return this.evses.length;
  }

  /** Returns a flattened array of all `Connectors` attached to evses at this site. */
  public get allConnectors(): Connector[] {
    return this.evses.flatMap((evse) => evse.connectors);
  }

  /** Returns a list of tethered connectors accessible at this location. */
  public get tetheredConnectors(): ConnectorType[] {
    const tempArray: ConnectorType[] = [];
    this.evses.forEach((evse) => {
      if (evse.isUseable) {
        evse.connectors.forEach((connector) => {
          if (connector.standard) {
            if (
              !tempArray.includes(connector.standard) &&
              connector.format === "CABLE"
            ) {
              tempArray.push(connector.standard);
            }
          }
        });
      }
    });
    return tempArray;
  }

  /** Returns a list of socketed connectors accessible at this location. */
  public get socketedConnectors(): ConnectorType[] {
    const tempArray: ConnectorType[] = [];
    this.evses.forEach((evse) => {
      if (evse.isUseable) {
        evse.connectors.forEach((connector) => {
          if (connector.standard) {
            if (
              !tempArray.includes(connector.standard) &&
              connector.format === "SOCKET"
            ) {
              tempArray.push(connector.standard);
            }
          }
        });
      }
    });
    return tempArray;
  }

  /** Returns true if this is a private charger and one or more of its connectors are AC. */
  public get isPrivateAC(): boolean {
    if (!this.isPrivate) return false;
    return this.evses
      .flatMap((evse) => evse.connectors)
      .some((connector) => ACPowerTypeList.includes(connector.powerType));
  }

  /** Returns true if this is a private charger and one or more of its connectors are DC. */
  public get isPrivateDC(): boolean {
    if (!this.isPrivate) return false;
    return this.evses
      .flatMap((evse) => evse.connectors)
      .some((connector) => connector.powerType === "DC");
  }

  /** Returns true if this is a public charger and one or more of its connectors are AC. */
  public get isPublicAC(): boolean {
    if (this.isPrivate) return false;
    return this.evses
      .flatMap((evse) => evse.connectors)
      .some((connector) => ACPowerTypeList.includes(connector.powerType));
  }

  /** Returns true if this is a public charger and one or more of its connectors are DC. */
  public get isPublicDC(): boolean {
    if (this.isPrivate) return false;
    return this.evses
      .flatMap((evse) => evse.connectors)
      .some((connector) => connector.powerType === "DC");
  }

  /** Returns the img src for the chargers map pin/ */
  public get pinIconSrc(): string {
    const flatConnectors = this.evses.flatMap((evse) => evse.connectors);

    const hasDC = flatConnectors.some(
      (connector) => connector.powerType === "DC"
    );

    // 0-24999 w is default of 1 lightning bolt
    const twoLightningBoltsThreshold = 25000;
    const threeLightningBoltsThreshold = 100000;

    if (hasDC) {
      const flatDCConnectors = flatConnectors.filter(
        (connector) => connector.powerType === "DC"
      );
      let maxPower = 0;
      flatDCConnectors.forEach((connector) => {
        if (connector.maxElectricPower && connector.maxElectricPower > maxPower)
          maxPower = connector.maxElectricPower;
      });

      // three lightning bolt DC charger pin
      if (maxPower >= threeLightningBoltsThreshold)
        return getAssetSrc("icons/DCCharger3.svg");
      // two lightning bolt DC charger pin
      if (maxPower >= twoLightningBoltsThreshold)
        return getAssetSrc("icons/DCCharger2.svg");
      // one lightning bolt DC charger pin
      return getAssetSrc("icons/DCCharger1.svg");
    } else {
      const flatACConnectors = flatConnectors.filter((connector) =>
        ACPowerTypeList.includes(connector.powerType)
      );
      let maxPower = 0;
      flatACConnectors.forEach((connector) => {
        if (connector.maxElectricPower && connector.maxElectricPower > maxPower)
          maxPower = connector.maxElectricPower;
      });

      // three lightning bolt AC charger pin
      if (maxPower >= threeLightningBoltsThreshold)
        return getAssetSrc("icons/ACCharger3.svg");
      // two lightning bolt AC charger pin
      if (maxPower >= twoLightningBoltsThreshold)
        return getAssetSrc("icons/ACCharger2.svg");
      // one lightning bolt AC charger pin
      return getAssetSrc("icons/ACCharger1.svg");
    }
  }

  /** Returns a simple display string for the chargers best capability e.g. `250KW DC charger` */
  public get simpleDisplayRating(): string {
    let ratingInWatts = 0;
    let bestCurrent = "";

    if (this.isPrivateDC || this.isPublicDC) {
      bestCurrent = "DC";
      const dcConnectors = this.evses
        .flatMap((evse) => evse.connectors)
        .filter((connector) => connector.powerType === "DC");
      dcConnectors.forEach((connector) => {
        if (
          connector.maxElectricPower &&
          connector.maxElectricPower > ratingInWatts
        )
          ratingInWatts = connector.maxElectricPower;
      });
    } else if (this.isPrivateAC || this.isPublicAC) {
      bestCurrent = "AC";
      const acConnectors = this.evses
        .flatMap((evse) => evse.connectors)
        .filter((connector) => ACPowerTypeList.includes(connector.powerType));
      acConnectors.forEach((connector) => {
        if (
          connector.maxElectricPower &&
          connector.maxElectricPower > ratingInWatts
        )
          ratingInWatts = connector.maxElectricPower;
      });
    }

    if (ratingInWatts && bestCurrent) {
      return `${ratingInWatts / 1000}KW ${bestCurrent} charger`;
    }
    return "";
  }

  // -------------------------------------------------------------------- //
  // ------------------------------ Methods ----------------------------- //
  // -------------------------------------------------------------------- //

  /**
   * Checks if a passed id relates to this `Charger` one of its `EVSE`'s or one of their attached `Connectors`.
   *
   * @param id the id to be checked against.
   * @returns true if there is a match.
   */
  public isThisCharger(id: string | number): boolean {
    // check if matches local id.
    if (id === this.id) return true;

    // check if matches source ids.
    if (this.extensionData) {
      const listOfValues = Object.values(this.extensionData);
      if (listOfValues.includes(id)) return true;
    }

    // check if matches one of this chargers evses or attached connectors.
    let isOneOfTheEVSEs = false;
    this.evses.forEach((evse) => {
      if (evse.isThisEVSE(id)) isOneOfTheEVSEs = true;
    });
    if (isOneOfTheEVSEs) return true;

    // default return if no conditions are fulfilled.
    return false;
  }

  /**
   * Returns weather or not an EV is compatible with this charger.
   *
   * @param vehicleConnectors the list of connectors in hard points on the vehicle.
   * @param vehicleCabledConnectors the list of cabled adapters the driver has access to.
   * @returns weather the EV is compatible with this charger or not as one of the following:
   * - "compatible" - can use one or more of this chargers tethered connectors to charge.
   * - "compatible with cable only" - no hard point connectors are compatible however can charge here with one or more of the cabled adapters the driver has access to.
   * - "incompatible"- this EV can not use this charger.
   */
  public isCompatible(vehicle: Vehicle): isCompatibleReturn {
    const compatibilities = this.evses.map((evse) =>
      evse.isCompatible(vehicle)
    );
    if (compatibilities.includes("compatible")) return "compatible";
    if (compatibilities.includes("compatible with cable only"))
      return "compatible with cable only";
    return "incompatible";
  }

  public getTripChargingStopData(trip: Trip): ChargingDetails | undefined {
    // find trip plan
    const plan = trip.evTripData?.find((plan) =>
      plan.steps.some((step) => step.Charger?.CDBID === this.id)
    );
    // find stop
    const stop = plan?.steps.find((step) => step.Charger?.CDBID === this.id);
    // no stop found guard clause.
    if (!stop) return;

    // calculated charing stop details.

    return {
      chargingTime: Duration.fromObject({
        hours: 0,
        minutes:
          stop.ChargeTime && stop.ChargeTime !== 0
            ? Math.floor(stop.ChargeTime / 60)
            : 0,
      })
        .normalize()
        .toHuman({ unitDisplay: "narrow" })
        .replace(",", ""),
      chargingCost: stop.ChargeCost ? Math.round(stop.ChargeCost) : 0,
      percentageBeforeCharging: Math.round(stop.ArrivalCharge * 100),
      percentageCharged: Math.round(
        (stop.StartCharge - stop.ArrivalCharge) * 100
      ),
      percentageAfterCharging: Math.round(stop.StartCharge * 100),
      energyAdded: stop.Charge ? Math.round(stop.Charge) : 0,
    };
  }

  /**
   * Checks if this charger should be excluded based on passed filter criteria
   * @param filters the list of filter type provided by stores `filterChargingStations` logic.
   * @returns true if should be excluded.
   */
  public excludeByFilters(filters: ChargerFilters[]): boolean {
    // check if private chargers are to be excluded.
    if (
      filters.includes(ChargerFilters.NO_PRIVATE_AC) &&
      filters.includes(ChargerFilters.NO_PRIVATE_DC)
    ) {
      // check if this is a private charger therefore needs to be excluded.
      if (this.isPrivate) return true;
    }

    // check if public chargers are to be excluded.
    if (
      filters.includes(ChargerFilters.NO_PUBLIC_AC) &&
      filters.includes(ChargerFilters.NO_PUBLIC_DC)
    ) {
      // check if this is a public charger therefore needs to be excluded.
      if (!this.isPrivate) return true;
    }

    // find if charger has at lest on AC connector.
    let hasAC = false;
    this.evses.forEach((evse: EVSE) => {
      evse.connectors.forEach((connector: Connector) => {
        if (ACPowerTypeList.includes(connector.powerType)) hasAC = true;
      });
    });

    // find if charger has at lest on DC connector.
    let hasDC = false;
    this.evses.forEach((evse: EVSE) => {
      evse.connectors.forEach((connector: Connector) => {
        if (connector.powerType === "DC") hasDC = true;
      });
    });

    // check if case of charger only has AC and is private with private AC chargers to be filtered out.
    if (
      filters.includes(ChargerFilters.NO_PRIVATE_AC) &&
      !filters.includes(ChargerFilters.NO_PRIVATE_DC) &&
      this.isPrivate &&
      hasAC &&
      !hasDC
    )
      return true;

    // check if case of charger only has DC and is private with private DC chargers to be filtered out.
    if (
      !filters.includes(ChargerFilters.NO_PRIVATE_AC) &&
      filters.includes(ChargerFilters.NO_PRIVATE_DC) &&
      this.isPrivate &&
      !hasAC &&
      hasDC
    )
      return true;

    // check if case of charger only has AC and is public with public AC chargers to be filtered out.
    if (
      filters.includes(ChargerFilters.NO_PUBLIC_AC) &&
      !filters.includes(ChargerFilters.NO_PUBLIC_DC) &&
      !this.isPrivate &&
      hasAC &&
      !hasDC
    )
      return true;

    // check if case of charger only has DC and is public with public DC chargers to be filtered out.
    if (
      !filters.includes(ChargerFilters.NO_PRIVATE_AC) &&
      filters.includes(ChargerFilters.NO_PRIVATE_DC) &&
      this.isPrivate &&
      !hasAC &&
      hasDC
    )
      return true;

    // check if case of filter out all AC chargers.
    if (
      filters.includes(ChargerFilters.NO_PRIVATE_AC) &&
      filters.includes(ChargerFilters.NO_PUBLIC_AC) &&
      hasAC &&
      !hasDC
    )
      return true;

    // check if case of filter out all DC chargers.
    if (
      filters.includes(ChargerFilters.NO_PRIVATE_DC) &&
      filters.includes(ChargerFilters.NO_PUBLIC_DC) &&
      !hasAC &&
      hasDC
    )
      return true;

    // no filters have allied
    return false;
  }

  /** upload passed image to cloud storage and update reference to image. */
  public async uploadImage(imageFile: File): Promise<"failed" | "success"> {
    // todo: get api call for uploading images to the charger DB.
    return "failed";
  }
}
