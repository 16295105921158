<template>
  <card flat>
    <card-title>I am the ICE content</card-title>
  </card>
</template>
<script lang="ts">
import Trip from "@/logic/classes/trip";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "ItineraryICEContent",
  props: {
    trip: {
      required: true,
      type: Object as PropType<Trip>,
    },
  },
});
</script>
