import { Alias, DirectusNetworkBranding } from "../types/directus_types";
import generateUniqueLocalID from "../utils/generateUniqueLocalID";
import getDirectusImgUrl from "../utils/getDirectusImgUrl";

export default class NetworkBranding {
  // -------------------------------------------------------------------- //
  // ------------------------- Global class state ----------------------- //
  // -------------------------------------------------------------------- //

  // global record of class instance ids this session.
  static usedIds: string[] = [];

  // -------------------------------------------------------------------- //
  // ------------------------------- State ------------------------------ //
  // -------------------------------------------------------------------- //

  /** local unique id. */
  localId: string;

  /** Directus `NetworkBranding` collection record id. */
  directusId?: number;

  /** the network name for comparison with charger DB data. */
  networkName: string;

  /** Display name for this driver. */
  displayName: string;

  /** Networks logo icon pic asset file UUID. */
  iconLogoUUID?: string;

  /** Networks text baked in logo pic asset file UUID. */
  textBakedLogoUUID?: string;

  /** the hash value for the color the network requires there display name to be in if any. */
  textColor?: string;

  /** The WYSIWYG html string for the charging networks instructions. */
  instructions?: string;

  /** The list of aliases this network is also known by. */
  aliases: Alias[];

  // -------------------------------------------------------------------- //
  // --------------------------- Constructor ---------------------------- //
  // -------------------------------------------------------------------- //

  constructor({
    localId = undefined,
    directusId = undefined,
    displayName = undefined,
    networkName,
    iconLogoUUID = undefined,
    textBakedLogoUUID = undefined,
    textColor = undefined,
    instructions = undefined,
    aliases = [],
  }: {
    localId?: string;
    directusId?: number;
    displayName?: string;
    networkName: string;
    iconLogoUUID?: string;
    textBakedLogoUUID?: string;
    textColor?: string;
    instructions?: string;
    aliases?: Alias[];
  }) {
    this.localId =
      localId ??
      generateUniqueLocalID(NetworkBranding.usedIds, "network-branding");
    this.directusId = directusId;
    this.networkName = networkName;
    this.displayName = displayName ?? networkName;
    this.iconLogoUUID = iconLogoUUID;
    this.textBakedLogoUUID = textBakedLogoUUID;
    this.textColor = textColor;
    this.instructions = instructions;
    this.aliases = aliases;

    // add id to list of used unique ids
    NetworkBranding.usedIds.push(this.localId);
  }

  static fromDirectusData(data: DirectusNetworkBranding) {
    return new NetworkBranding({
      directusId: data.id,
      networkName: data.Network ?? undefined,
      displayName: data.DisplayName ?? undefined,
      iconLogoUUID: data.IconLogo ?? undefined,
      textBakedLogoUUID: data.TextBakedLogo ?? undefined,
      textColor: data.TextColor ?? undefined,
      instructions: data.Instructions ?? undefined,
      aliases: data.Aliases ?? undefined,
    });
  }

  // -------------------------------------------------------------------- //
  // ------------------------------ Getters ----------------------------- //
  // -------------------------------------------------------------------- //

  /** Returns the src path for this networks icon logo pic if they have one. */
  public get iconLogoSrc(): string | undefined {
    if (this.iconLogoUUID) return getDirectusImgUrl(this.iconLogoUUID);
  }

  /** Returns the src path for this networks text baked in logo pic if they have one. */
  public get textBakedLogoSrc(): string | undefined {
    if (this.textBakedLogoUUID)
      return getDirectusImgUrl(this.textBakedLogoUUID);
  }

  /** Returns a list of all known names this network has been referred to as in charger DB records. */
  public get allKnownAliases(): string[] {
    const tempArray: string[] = [];

    tempArray.push(this.networkName);
    this.aliases.forEach((aliasObj) => {
      if (tempArray.includes(aliasObj.alias)) return;
      tempArray.push(aliasObj.alias);
    });

    return tempArray;
  }

  // -------------------------------------------------------------------- //
  // ------------------------------ Methods ----------------------------- //
  // -------------------------------------------------------------------- //

  /**
   * Takes a network operator name and checks if it relates to this network
   *
   * Checks the official network name and any other aliases that this network
   * has also been referred to in other third party data sources. Please note
   * some of the data sauces are publicly sourced so therefore this list will
   * grow including with misspelled of oddly phrased variations on the
   * network name.
   */
  public isThisNetwork(nameToQuery: string): boolean {
    if (nameToQuery === this.networkName) return true;
    const alias = this.aliases.find(
      (aliasObj) => aliasObj.alias === nameToQuery
    );
    return !!alias;
  }
}
