<template>
  <v-btn icon>
    <v-icon class="primary--text" @click="open" small dense>
      mdi-help-circle
    </v-icon>
    <template>
      <v-dialog
        v-model="showDialog"
        :content-class="$vuetify.breakpoint.xs ? '' : 'rounded-lg'"
        style="z-index: 1500"
        @click:outside="close()"
        @keydown="keyDown($event)"
        :width="$vuetify.breakpoint.mdAndUp ? '50%' : '70%'"
        max-width="600px"
        min-width="300px"
        :fullscreen="$vuetify.breakpoint.xs"
        scrollable
      >
        <v-card>
          <v-card-tittle
            class="d-flex justify-space-between pa-5"
            style="word-break: normal"
          >
            <span>
              <v-icon color="primary" class="pb-2"> mdi-help-circle </v-icon>
              <span class="primary--text text-h5"> {{ data.Name }} </span>
            </span>
            <v-btn icon @click="close()">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-card-tittle>
          <v-card-text v-html="data.Content"></v-card-text>
        </v-card>
      </v-dialog>
    </template>
  </v-btn>
</template>
<script lang="ts">
import { ManagedContent } from "@/logic/types/directus_types";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "StyledToolTip",
  data() {
    return {
      showDialog: false,
    };
  },
  methods: {
    open(): void {
      this.showDialog = true;
    },
    close(): void {
      this.showDialog = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    keyDown(event: any) {
      if (event.key === "Escape") this.close();
    },
  },
  props: {
    data: {
      type: Object as PropType<ManagedContent>,
      required: true,
    },
  },
});
</script>
