<template>
  <v-col cols="12" sm="6" md="4" :order="isSelected ? 'first' : undefined">
    <v-card
      height="100%"
      :max-height="vehicle ? '350px' : '250px'"
      :class="
        isSelected
          ? 'd-flex flex-column primary rounded-lg'
          : 'd-flex flex-column rounded-lg'
      "
      @click.stop="showDetails"
      :style="vehicle ? '' : 'cursor: unset;'"
    >
      <v-img
        :lazy-src="getSrc('car_images/No_Image_Powersell.png')"
        :src="getImageSrc()"
        max-height="50%"
        contain
      />
      <v-card-title
        v-if="vehicle.vehicleType !== 'GENERIC_VEHICLE'"
        :class="isSelected ? 'white--text' : ''"
      >
        {{ vehicle.licensePlate ? vehicle.licensePlate : "no plate" }}
      </v-card-title>
      <v-card-subtitle :class="isSelected ? 'white--text pb-0' : 'pb-0'">
        {{ vehicle.evModel ? vehicle.evModel.name : "model not specified" }}
      </v-card-subtitle>
      <v-card-subtitle
        :class="isSelected ? 'white--text py-0' : 'py-0'"
        v-if="vehicle.stateOfCharge"
      >
        {{ vehicle.stateOfCharge + "% Charge" }}
      </v-card-subtitle>
      <v-card-text
        v-if="selectedTrip && vehicle && vehicle.latitude && vehicle.longitude"
        :class="isSelected ? 'white--text' : ''"
      >
        {{ calcDistance([vehicle.longitude, vehicle.latitude], selectedTrip) }}
        from start
      </v-card-text>
      <v-card-actions class="mt-auto">
        <v-card class="rounded-lg flex-grow-1 d-flex" v-if="isSelected">
          <v-card-text class="pa-2 text-center">Selected</v-card-text>
        </v-card>
        <v-btn
          class="text-none rounded-lg flex-grow-1"
          color="primary"
          @click.stop="select"
          v-else
        >
          Select
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-col>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { mapGetters } from "vuex";
import {
  ActionTypes,
  GettersTypes,
  MainDialogContent,
  MutationTypes,
  State,
} from "@/logic/store/store_types";
import Vehicle from "@/logic/classes/vehicle";
import getDirectusImgUrl from "@/logic/utils/getDirectusImgUrl";
import Trip from "@/logic/classes/trip";
import haversineDistance from "@/logic/utils/haversineDistance";
import getAssetSrc from "@/logic/utils/getAssetSrc";

/** `Vue component:` renders a list tile card with vehicle details
 * to be displayed in the `VehicleGalleryContent` component.
 *
 * @prop `vehicle` - the full `VehicleData` object. See types for more details.
 * @prop `model' - the full 'EVModel' object. See types for more details.
 */
export default Vue.extend({
  name: "VehicleGalleryCard",
  props: {
    vehicle: Object as PropType<Vehicle>,
  },
  methods: {
    select() {
      this.$store.dispatch(ActionTypes.selectVehicle, this.vehicle);
      this.$store.commit(MutationTypes.setMainDialogContent, undefined);
    },
    showDetails() {
      if (this.vehicle) {
        this.$store.commit(
          MutationTypes.setViewedFleetVehicle,
          this.vehicle.localId
        );
        this.$store.commit(
          MutationTypes.setMainDialogContent,
          MainDialogContent.FLEET_VEHICLE_DETAILS
        );
      }
    },
    calcDistance(vehicleCoordinate: [number, number], tripData: Trip) {
      const tripStartCoordinate: [number, number] = [
        tripData.locations[0].coordinates.longitude,
        tripData.locations[0].coordinates.latitude,
      ];

      const distance = haversineDistance(
        vehicleCoordinate,
        tripStartCoordinate
      );
      return `${Math.round(distance)}km`;
    },
    getImageSrc() {
      if (this.vehicle.imageSrcPaths.length)
        return this.vehicle.imageSrcPaths[0];
      if (this.vehicle.evModel?.imageUUID)
        return getDirectusImgUrl(this.vehicle.evModel.imageUUID);
      return this.getSrc("car_images/No_Image_Powersell.png");
    },
    getSrc(partialFilePath: string): string {
      return getAssetSrc(partialFilePath);
    },
  },
  computed: {
    isSelected(): boolean {
      return (
        (this.$store.state as State).selectedVehicle === this.vehicle.localId
      );
    },
    ...mapGetters({
      selectedTrip: GettersTypes.selectedTripData,
    }),
  },
  data() {
    return {
      coordinates: null as [number, number] | null,
    };
  },
});
</script>
