var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{class:_vm.classes,attrs:{"id":"pwt-info-panel","width":_vm.$vuetify.breakpoint.xsOnly ? 'initial' : '40%',"height":_vm.$vuetify.breakpoint.xsOnly ? '100%' : 'calc(100% - 16px)',"min-width":_vm.$vuetify.breakpoint.xs ? `100%` : 400,"max-width":"600"}},[(_vm.$vuetify.breakpoint.xsOnly)?_c('v-toolbar',{staticClass:"rounded-0",attrs:{"color":"primary","height":"50px"}},[_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.emitMinimize}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1):_vm._e(),_c('div',{staticClass:"d-flex elevation-5",staticStyle:{"height":"100px","position":"relative"}},[_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],class:_vm.currentRouteName === 'home'
          ? 'primary--text d-flex flex-column align-center flex-grow-1 flex-shrink-1 pl-2 pr-1 pt-3'
          : 'grey--text d-flex flex-column align-center flex-grow-1 flex-shrink-1 pl-2 pr-1 pt-3',staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.handelRouteChange('home')}}},[_c('v-icon',{staticClass:"mb-3",attrs:{"color":_vm.currentRouteName === 'home' ? 'primary' : 'grey',"large":""}},[_vm._v(" $itineraryIcon ")]),_c('p',{staticClass:"text-caption text-capitalize"},[_vm._v("Dashboard")])],1),_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],class:_vm.currentRouteName === 'trips' ||
        _vm.currentRouteName === 'planning' ||
        _vm.currentRouteName === 'itinerary' ||
        _vm.currentRouteName === 'stats' ||
        _vm.currentRouteName === 'saved' ||
        _vm.currentRouteName === 'unsaved'
          ? 'primary--text d-flex flex-column align-center flex-grow-1 flex-shrink-1 px-1 pt-3'
          : 'grey--text d-flex flex-column align-center flex-grow-1 flex-shrink-1 px-1 pt-3',staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.viewingTrip
          ? _vm.handelRouteChange('itinerary')
          : _vm.handelRouteChange('trips')}}},[_c('v-icon',{staticClass:"mb-3 pt-2 pl-1",attrs:{"color":_vm.currentRouteName === 'trips' ||
          _vm.currentRouteName === 'planning' ||
          _vm.currentRouteName === 'itinerary' ||
          _vm.currentRouteName === 'stats' ||
          _vm.currentRouteName === 'saved' ||
          _vm.currentRouteName === 'unsaved'
            ? 'primary'
            : 'grey',"large":""}},[_vm._v(" $tripsIcon ")]),_c('p',{staticClass:"text-caption text-none"},[_vm._v(" "+_vm._s(_vm.viewingTrip ? "Itinerary" : "Plan a Trip")+" ")])],1),_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],class:_vm.currentRouteName === 'stations'
          ? 'primary--text d-flex flex-column align-center flex-grow-1 flex-shrink-1 px-2 pt-3'
          : 'grey--text d-flex flex-column align-center flex-grow-1 flex-shrink-1 px-2 pt-3',staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.handelRouteChange('stations')}}},[(_vm.loadingStations)?_c('v-img',{staticClass:"mb-3",attrs:{"contain":"","max-height":"36","max-width":"36","src":_vm.getSrc('Loading-Blue.gif')}}):_c('v-icon',{staticClass:"mb-3",attrs:{"color":_vm.currentRouteName === 'stations' ? 'primary' : 'grey',"large":""}},[_vm._v(" $stationsIcon ")]),_c('p',{staticClass:"text-caption text-capitalize"},[_vm._v("chargers")])],1),_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],class:_vm.currentRouteName === 'vehicles'
          ? 'primary--text d-flex flex-column align-center flex-grow-1 flex-shrink-1 px-2 pt-3'
          : 'grey--text d-flex flex-column align-center flex-grow-1 flex-shrink-1 px-2 pt-3',staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.handelRouteChange('vehicles')}}},[_c('v-icon',{staticClass:"mb-3",attrs:{"color":_vm.currentRouteName === 'vehicles' ? 'primary' : 'grey',"large":""}},[_vm._v(" $vehiclesIcon ")]),_c('p',{staticClass:"text-caption text-capitalize"},[_vm._v("vehicles")])],1)]),_c('v-divider'),_c('v-card',{staticClass:"pwt-scroll-container overflow-y-auto pwt-scrollbar-styles",staticStyle:{"padding-bottom":"100px"},attrs:{"flat":""}},[_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }