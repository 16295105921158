<template>
  <div class="pwt-custom-overlay">
    <v-card
      :class="$vuetify.breakpoint.xsOnly ? 'px-4 pb-3' : 'px-4 pb-3 rounded-lg'"
      light
      :max-width="$vuetify.breakpoint.xsOnly ? '' : '800px'"
      :min-width="$vuetify.breakpoint.xsOnly ? '' : '350px'"
      :min-height="$vuetify.breakpoint.xsOnly ? '' : '100px'"
      :width="$vuetify.breakpoint.xsOnly ? '100vw' : ''"
      :height="$vuetify.breakpoint.xsOnly ? '100vh' : ''"
    >
      <template v-if="checkingToken">
        <v-card-text
          v-if="$vuetify.breakpoint.xsOnly"
          class="d-flex flex-column align-items-center justify-center"
        >
          <span class="text-h5">Cheeking authentication</span>
          <LoadingCard />
        </v-card-text>
        <v-card-text
          style="height: 100%; min-height: 100px; position: relative"
          class="d-flex justify-center align-center px-8 pt-5"
          v-else
        >
          <v-progress-circular indeterminate color="primary" class="mr-2" />
          <span class="text-h5">Checking authentication</span>
          <PulseLoader />
        </v-card-text>
      </template>
      <template v-else>
        <v-card-title class="primary--text font-weight-regular">
          <v-icon class="mr-3 ml-n2" color="primary" large> mdi-login </v-icon>
          Login to Power Trip
        </v-card-title>
        <v-card-text class="text-body-1 font-weight-medium pl-10">
          Login to a Power Trip account to save your settings and planned trips.
        </v-card-text>
        <template v-if="status === 'FAILED' || status === undefined">
          <v-card-text class="grey--text pb-1 pl-10">
            Your email address
          </v-card-text>
          <v-text-field
            v-model="email"
            placeholder="example@e-mail.com"
            outlined
            rounded
            color="primary"
            class="mx-6 mb-5"
            hide-details
            type="email"
            style="position: relative; z-index: 1401"
          ></v-text-field>
          <v-card-text class="grey--text pb-1 pl-10"> Password </v-card-text>
          <v-text-field
            v-model="password"
            outlined
            rounded
            color="primary"
            :type="passwordType"
            append-icon="mdi-eye"
            @click:append="togglePassword"
            class="mx-6 mb-6"
            hide-details
            style="position: relative; z-index: 1401"
          ></v-text-field>
        </template>
        <v-card-text class="d-flex red--text" v-if="status === 'FAILED'"
          ><v-icon color="red" class="mr-1">mdi-alert-circle</v-icon> Whoops!
          Looks like your login details are not correct. Please check your
          details and try again.</v-card-text
        >
        <v-card-text class="d-flex" v-if="status === 'SUCCESS'">
          <v-icon color="green" class="mr-1">mdi-check-circle</v-icon>
          Successfully authenticated.
        </v-card-text>
        <template v-if="status === 'PROCESSING'">
          <v-card-text
            v-if="$vuetify.breakpoint.xsOnly"
            class="d-flex align-items-center justify-center"
          >
            <LoadingCard />
          </v-card-text>
          <v-card-text v-else class="d-flex align-items-center">
            <v-progress-circular
              indeterminate
              color="primary"
              class="mr-1"
              size="22"
            />
            <span class="d-flex align-center">
              <span> Processing </span>
              <PulseLoader />
            </span>
          </v-card-text>
        </template>
        <v-card-actions v-if="status === 'FAILED' || status === undefined">
          <v-btn color="primary" class="text-none rounded-lg" @click="login">
            Login
          </v-btn>
          <v-btn
            :href="`https://fleet.powertrip.earth/auth/login/sso?prompt=login&redirect=${currentUrl}`"
            color="primary"
            class="text-none rounded-lg"
          >
            Login with SSO
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            class="text-none rounded-lg"
            @click="toggleForgotPassword"
          >
            Forgot password
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
    <!-- forgot password wrapper -->
    <v-dialog
      v-model="showForgotPassword"
      z-index="1401"
      @click:outside="toggleForgotPassword"
      :width="$vuetify.breakpoint.mdAndUp ? '50%' : '70%'"
      :content-class="$vuetify.breakpoint.xs ? '' : 'rounded-lg'"
      max-width="600px"
      min-width="300px"
      :fullscreen="$vuetify.breakpoint.xs"
      scrollable
    >
      <v-card>
        <v-card-title
          class="d-flex justify-space-between primary--text font-weight-regular"
        >
          <span>
            <v-icon color="primary"> mdi-lock-reset </v-icon>
            Reset Password
          </span>
          <v-btn icon @click="toggleForgotPassword">
            <v-icon color="grey">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle>
          Don't worry, it happens to the best of us.
        </v-card-subtitle>
        <ResetPasswordContent />
      </v-card>
    </v-dialog>
  </div>
</template>
<script lang="ts">
import PulseLoader from "../../ui-elements/PulseLoader.vue";
import Vue from "vue";
import { mapState } from "vuex";
import ResetPasswordContent from "./dialog-content/ResetPasswordContent.vue";
import LoadingCard from "../../ui-elements/LoadingCard.vue";
import { State } from "@/logic/store/store_types";
import { ActionTypes } from "@/logic/store/store_types";

export default Vue.extend({
  name: "LoginOverlay",
  computed: {
    ...mapState({
      display(state: unknown) {
        const display = (state as State).showLogin;
        if (!display) {
          // eslint-disable-next-line
          // @ts-ignore
          this.email = "";
          // eslint-disable-next-line
          // @ts-ignore
          this.password = "";
        }
        return display;
      },
      status: (state: unknown) => (state as State).authStatus,
      checkingToken: (state: unknown) => (state as State).checkingToken,
    }),
    currentUrl() {
      return window.location.origin + window.location.pathname;
    },
  },
  data() {
    return {
      email: "",
      password: "",
      passwordType: "password",
      showForgotPassword: false,
    };
  },
  methods: {
    togglePassword() {
      if (this.passwordType === "password") {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },
    login() {
      this.$store.dispatch(ActionTypes.login, {
        email: this.email,
        password: this.password,
      });
    },
    toggleForgotPassword() {
      this.showForgotPassword = !this.showForgotPassword;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    keyDown(event: any) {
      if (event.key === "Enter") this.login();
    },
  },
  components: {
    PulseLoader,
    ResetPasswordContent,
    LoadingCard,
  },
});
</script>
<style scoped>
.pwt-custom-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1400;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
