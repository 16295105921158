import DriverData from "@/logic/types/directus_driver";
import { PartialObj } from "@/logic/types/generic_types";
import { directusCreateOne, directusUpdateOne } from "./helperFunctions";
import { type CollectionNames } from "../../clients/directusClient";

const collectionName: CollectionNames = "Driver";
const query = {
  fields: ["id", "Name", "Driving", "ProfilePic", "Bio"],
};

/**
 * Creates a `Driver` collection record in directus.
 *
 * @param data partial object to create.
 * @returns the newly created record if successful, undefined if not.
 */
export async function createDriverRecord(
  data: PartialObj<DriverData>
): Promise<DriverData | undefined> {
  return directusCreateOne<DriverData>(collectionName, data, query);
}

/**
 * Updates an existing directus `Driver` collection record.
 *
 * @param recordId the directus `Driver` collection record id.
 * @param dataToUpdate partial object to update
 * @returns the updates record if successful, undefined if not.
 */
export async function updateDriverRecord(
  recordId: string | number,
  dataToUpdate: PartialObj<DriverData>
) {
  return directusUpdateOne<DriverData>(
    collectionName,
    recordId,
    dataToUpdate,
    query
  );
}
