<template>
  <v-card flat class="pa-5">
    <v-card-title class="tertiary--text d-flex justify-space-between pt-5">
      <span>
        <BackBtn />
        Trip Stats Summary
      </span>
      <v-btn icon @click="handleCheckSettingsClick">
        <v-icon color="grey lighten-1"> mdi-cog </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <!-- Conditionally rendered trip stats section if a trip is selected. -->
      <v-card flat v-if="tripStats">
        <!-- Savings -->
        <v-card class="rounded-lg mb-3 primary white--text">
          <v-row no-gutters class="pt-3">
            <v-col cols="1" class="pl-3">
              <v-icon color="white"> mdi-currency-usd </v-icon>
            </v-col>
            <v-col cols="11">
              <v-card-title
                class="d-flex justify-space-between text-body-1 font-weight-medium pt-0"
              >
                <span> Estimated savings </span>
                <span class="pwt-number-font">
                  ${{
                    tripStats.publicChargingCost
                      ? (
                          parseFloat(tripStats.fuelCost) -
                          parseFloat(tripStats.publicChargingCost) -
                          (tripStats.privateChargingCost || 0)
                        ).toFixed(2)
                      : "0.00"
                  }}
                </span>
              </v-card-title>
              <v-card-text>
                <ul style="list-style-type: none" class="ml-0 pl-0">
                  <li
                    class="d-flex justify-space-between"
                    v-if="trip && trip.tripFuelType !== 'ice'"
                  >
                    <span> Estimated cost of public charging </span>
                    <span class="pwt-number-font">
                      ${{ tripStats.publicChargingCost }}
                    </span>
                  </li>
                  <li class="d-flex justify-space-between">
                    <span>
                      Estimated
                      {{
                        trip && trip.tripFuelType !== "ice"
                          ? "equivalent "
                          : ""
                      }}{{ calcVs.toLowerCase() }} cost
                    </span>
                    <span class="pwt-number-font">
                      ${{ tripStats.fuelCost }}
                    </span>
                  </li>
                  <li class="d-flex justify-space-between">
                    <span> Estimated cost of private charging </span>
                    <span class="pwt-number-font">
                      ${{ tripStats.privateChargingCost || "0.00" }}
                    </span>
                  </li>
                </ul>
              </v-card-text>
              <v-card-text class="pt-0">
                <ul
                  style="list-style-type: none"
                  class="ml-0 pl-0"
                  v-if="trip && trip.tripFuelType !== 'ice'"
                >
                  <li class="d-flex justify-space-between">
                    <span> Estimated savings if only private charging </span>
                    <span class="pwt-number-font">
                      ${{
                        tripStats.chargeKWh
                          ? (
                              parseFloat(tripStats.fuelCost) -
                              parseFloat(tripStats.chargeKWh) *
                                privateChargingCost -
                              (tripStats.privateChargingCost || 0)
                            ).toFixed(2)
                          : "0.00"
                      }}
                    </span>
                  </li>
                </ul>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
        <!-- Emissions avoided -->
        <v-card class="rounded-lg mb-3">
          <v-row no-gutters class="pt-3">
            <v-col cols="1" class="pl-3">
              <v-icon color="primary"> mdi-leaf </v-icon>
            </v-col>
            <v-col cols="11">
              <v-card-title
                class="d-flex justify-space-between text-body-1 font-weight-medium pt-0"
                v-if="trip && trip.tripFuelType !== 'ice'"
              >
                <span> Emissions avoided </span>
                <span class="pwt-number-font">
                  {{
                    tripStats.avoidedCO2
                      ? parseInt(tripStats.avoidedCO2) -
                        parseInt(tripStats.emittedCO2)
                      : "0"
                  }}
                  kg of CO₂
                </span>
              </v-card-title>
              <v-card-title
                class="d-flex justify-space-between text-body-1 font-weight-medium pt-0"
                v-else
              >
                <span> Emissions emitted </span>
                <span class="pwt-number-font">
                  {{ parseInt(tripStats.emittedCO2) }} kg of CO₂
                </span>
              </v-card-title>
            </v-col>
          </v-row>
        </v-card>
        <!-- Duration Section -->
        <v-card class="rounded-lg mb-3">
          <v-row no-gutters class="pt-3">
            <v-col cols="1" class="pl-3">
              <v-icon color="primary"> mdi-clock-outline </v-icon>
            </v-col>
            <v-col cols="11">
              <v-card-title
                class="d-flex justify-space-between text-body-1 font-weight-medium pt-0"
              >
                <span> Duration </span>
                <span>{{ tripStats.totalTime }}</span>
              </v-card-title>
              <v-card-text class="grey--text text--darken-1">
                <ul style="list-style-type: none" class="ml-0 pl-0">
                  <li class="d-flex justify-space-between">
                    <span> Driving for {{ tripStats.drivingKms }} km </span>
                    <span class="pwt-number-font">{{
                      tripStats.drivingTime
                    }}</span>
                  </li>
                  <li
                    class="d-flex justify-space-between"
                    v-if="trip && trip.EVTrip"
                  >
                    <span>
                      Charged {{ tripStats.chargeKWh }} kWh
                      <span class="grey--text">
                        ({{ tripStats.battery }} % battery)
                      </span>
                    </span>
                    <span class="pwt-number-font">{{
                      tripStats.chargingTime
                    }}</span>
                  </li>
                  <li
                    class="d-flex justify-space-between"
                    v-if="tripStats.stayDuration"
                  >
                    <span> Time spent at scheduled stops </span>
                    <span class="pwt-number-font">{{
                      tripStats.stayDuration
                    }}</span>
                  </li>
                </ul>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
        <!-- Charging Section -->
        <v-card
          class="rounded-lg mb-3"
          v-if="trip && trip.tripFuelType !== 'ice'"
        >
          <v-row no-gutters class="pt-3">
            <v-col cols="1" class="pl-3">
              <v-icon color="primary"> mdi-lightning-bolt </v-icon>
            </v-col>
            <v-col cols="11">
              <v-card-title
                class="d-flex justify-space-between text-body-1 font-weight-medium pt-0"
              >
                Charging
              </v-card-title>
              <v-card-text class="grey--text text--darken-1">
                <ul style="list-style-type: none" class="ml-0 pl-0">
                  <li
                    class="d-flex justify-space-between"
                    v-if="trip && numberOfChargersOnRoute !== 'unknown'"
                  >
                    <span>Fast charging stations along this route</span>
                    <span class="pwt-number-font">{{
                      numberOfChargersOnRoute
                    }}</span>
                  </li>
                  <li v-else>Find number of fast chargers along the route</li>
                  <li>
                    <span
                      v-if="loadingAllChargers"
                      class="d-flex align-center primary--text font-weight-medium"
                    >
                      <LoadingCard size="30" />
                      Loading
                      <PulseLoader color="#2EAEFF" />
                    </span>
                    <v-btn
                      v-else
                      text
                      class="text-none px-1"
                      color="primary"
                      @click="handleToggleShowAll"
                    >
                      {{
                        isShowingAll
                          ? "hide charging stations"
                          : "view charging stations"
                      }}
                    </v-btn>
                  </li>
                </ul>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-card>
      <!-- Conditionally rendered default section if no selected trip. -->
      <v-card flat v-else>
        <v-card-text class="px-0">
          You currently have no trip selected{{
            !hasStoredTrips ? " and no stored trips" : ""
          }}. Please add a new trip{{
            hasStoredTrips ? " or select one of your stored trips" : ""
          }}.
        </v-card-text>
        <v-card-actions class="px-0">
          <v-btn
            color="primary"
            class="text-none rounded-lg"
            dark
            @click="handleViewTripsClick"
          >
            View your trips
          </v-btn>
          <v-btn
            :color="powerTripDarkBlue"
            class="text-none rounded-lg flex-grow-1"
            @click="openNewTripForm"
            outlined
          >
            Plan a new trip
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import Trip from "@/logic/classes/trip";
import { powerTripDarkBlue } from "@/logic/data/const";
import { RouteNames } from "@/logic/router";
import { ActionTypes } from "@/logic/store/store_types";
import {
  GettersTypes,
  MainDialogContent,
  MutationTypes,
  State,
} from "@/logic/store/store_types";
import BackBtn from "@/ui/components/ui-elements/BackBtn.vue";
import LoadingCard from "@/ui/components/ui-elements/LoadingCard.vue";
import PulseLoader from "@/ui/components/ui-elements/PulseLoader.vue";
import Vue from "vue";
import { mapGetters, mapState } from "vuex";

/** `Vue component:` renders trip statistics to be displayed
 * in the `MainDialog` and `MobileDialog` components*/
export default Vue.extend({
  name: "TripStatsContent",
  data() {
    return {
      powerTripDarkBlue: powerTripDarkBlue,
    };
  },
  computed: {
    numberOfChargersOnRoute(): string {
      // find trip specific data.
      const tripDetails: Trip | undefined =
        this.$store.getters[GettersTypes.selectedTripData];
      // confirm find operation was successful if not exit early.
      if (!tripDetails) return "unknown";
      if (!tripDetails.chargersAlongRouteCDBIDs.length) return "unknown";
      return tripDetails.chargersAlongRouteCDBIDs.length.toFixed();
    },
    ...mapGetters({
      trip: GettersTypes.selectedTripData,
      tripStats: GettersTypes.selectedTripStats,
    }),
    ...mapState({
      hasStoredTrips: (state: unknown) => !!(state as State).trips.length,
      isShowingAll: (state: unknown) =>
        (state as State).displayAllChargersAlongRoute,
      highlightLongestStep: (state: unknown) =>
        (state as State).displayLongestStep,
      privateChargingCost: (state: unknown) =>
        (state as State).defaultHomeCostPerKWh,
      calcVs: (state: unknown) => (state as State).calcVs,
      loadingAllChargers: (state: unknown) =>
        (state as State).radarFetchingFlag,
    }),
  },
  methods: {
    prettyTime(seconds: number): string {
      return seconds + "seconds";
    },
    handleCheckSettingsClick() {
      this.$store.commit(
        MutationTypes.setMainDialogContent,
        MainDialogContent.SAVINGS_SETTINGS
      );
    },
    handleViewTripsClick() {
      this.$router.push({ name: RouteNames.trips });
    },
    openNewTripForm() {
      this.$router.push({ name: RouteNames.planning });
    },
    handleToggleShowAll() {
      if (!this.trip?.chargersAlongRouteCDBIDs.length) {
        this.$store.dispatch(ActionTypes.showAllChargersOnRoute);
      }
      if (!this.isShowingAll) {
        // assumes changing form false to true is toggling on.
        // Notify analytics server
        Vue.prototype.$Countly.q.push([
          "add_event",
          {
            key: "View all chargers along trip feature used",
            count: 1,
          },
        ]);
      }
      this.$store.commit(
        MutationTypes.setDisplayAllChargersAlongRoute,
        !this.isShowingAll
      );
    },
  },
  components: { LoadingCard, PulseLoader, BackBtn },
});
</script>
