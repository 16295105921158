<template>
  <v-container
    class="primary lighten-2 white--text pa-0 rounded-lg overflow-hidden"
    fluid
    style="height: 100%; position: relative"
  >
    <l-map
      :zoom="zoom"
      :center="center"
      :options="{ attributionControl: true, zoomControl: false }"
      ref="mainMap"
      :min-zoom="3"
    >
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      <l-marker-cluster
        :options="{
          showCoverageOnHover: false,
        }"
        id="pwt-marker-cluster-main"
      >
        <template v-for="(chargingStation, index) in filteredStations">
          <ChargingPin
            v-if="!!chargingStation.coordinates"
            :key="`charger-pin-${index}-${chargingStation.id}`"
            :chargingStation="chargingStation"
          />
        </template>
      </l-marker-cluster>
      <GradientPolyline
        v-for="(polyline, index) in polylineList"
        :key="`gradient-polyline-${index}`"
        :latLngList="polyline"
        :startCharge="steps.length ? steps[index].StartCharge : undefined"
        :endCharge="steps.length ? steps[index].EndCharge : undefined"
        :hasNoChargeData="!steps.length"
      />
      <template v-if="showFallbackLongestLeap">
        <LongestLeapPolyline
          v-for="(polyline, index) in longestLeapPolyline"
          :latLngList="polyline"
          :key="'longestLeapPolyline-' + index"
        ></LongestLeapPolyline>
      </template>
      <CarPin
        v-if="!!tripDetails && !!tripDetails.vehicle"
        :location="tripDetails.locations[0]"
        :id="tripDetails.vehicle.localId"
      />
      <template v-if="!!tripDetails">
        <LocationPin
          v-for="location in tripDetails.locations"
          :key="location.id"
          :locationData="location"
        />
      </template>
      <template v-if="showingConnectedVehicles">
        <template v-for="(vehicle, index) in connectedVehicles">
          <CarPin
            v-if="vehicle && vehicle.latitude && vehicle.longitude"
            :key="`car-pin-${index}`"
            :location="getGeoLocation(vehicle.latitude, vehicle.longitude)"
            :id="vehicle.localId"
          />
        </template>
      </template>
    </l-map>
    <BottomLogo></BottomLogo>
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
import { LMap, LTileLayer } from "vue2-leaflet";
import { mapGetters, mapState } from "vuex";
import ChargingPin from "./ChargingPin.vue";
import CarPin from "./CarPin.vue";
import GradientPolyline from "./GradientPolyline.vue";
import LocationPin from "./LocationPin.vue";
import { GettersTypes, type State } from "@/logic/store/store_types";
import Trip from "@/logic/classes/trip";
import TspTrip from "@/logic/classes/tspTrip";
import type { EVNavStep } from "@/logic/types/ev_nav_types";
import GeoLocation from "@/logic/classes/geoLocation";
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
import Charger from "@/logic/classes/charger";
import LongestLeapPolyline from "./LongestLeapPolyline.vue";
import BottomLogo from "../logo/BottomLogo.vue";

/**
 * `Vue leaflet component:` the main container for rendering the map and all its children components
 */
export default Vue.extend({
  name: "MapPanel",
  components: {
    LMap,
    LTileLayer,
    ChargingPin,
    CarPin,
    GradientPolyline,
    LocationPin,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    "l-marker-cluster": Vue2LeafletMarkerCluster,
    LongestLeapPolyline,
    BottomLogo,
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 7,
      center: [-41.270634, 173.283966],
      markerLatLng: [51.504, -0.159],
    };
  },
  computed: {
    ...mapGetters({
      filteredStations: GettersTypes.filterChargingStations,
      tripDetails: GettersTypes.selectedTripData,
      connectedVehicles: GettersTypes.connectedVehicles,
      showFallbackLongestLeap: GettersTypes.showFallbackLongestLeap,
    }),
    polylineList(): [number, number][][] {
      const trip: Trip | undefined =
        this.$store.getters[GettersTypes.selectedTripData];
      if (!trip) return [];

      // convert steps to leaflet polyline lat-longs polyline property for conditional rendering.
      const convertedArray = trip.getPolylinePoints();
      // get full list of lat-longs for map centering
      const collapsedArray = convertedArray.flat();

      // center map
      this.reCenterMap(collapsedArray);

      // return array of polyline lat-longs list for conditional rendering.
      return convertedArray;
    },
    steps(): EVNavStep[] {
      const trip: Trip | undefined =
        this.$store.getters[GettersTypes.selectedTripData];
      if (!trip) return [];
      return trip.evTripData?.flatMap((plan) => plan.steps) ?? [];
    },
    longestLeapPolyline(): [number, number][][] {
      const trip: Trip | undefined =
        this.$store.getters[GettersTypes.selectedTripData];
      if (!trip || trip instanceof TspTrip) return [];
      return trip.getFallbackLongestLeapPoints();
    },
    ...mapState({
      highLightLongestLeap: (state: unknown): boolean =>
        (state as State).displayLongestStep,
      showingConnectedVehicles: (state: unknown) =>
        (state as State).displayConnectedVehicles,
      userGeoLocation: (state: unknown): GeoLocation | undefined =>
        (state as State).userGeoIPData,
      showingNearbyChargers: (state: unknown): boolean =>
        (state as State).showNearbyChargersOnly,
    }),
  },
  mounted() {
    window.addEventListener("resize", this.resizeMap);
    this.$nextTick(() => {
      if (this.userGeoLocation && !this.userGeoLocation.isNullIsland) {
        this.center = this.userGeoLocation.asLatLng;
        // center map
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this.$refs.mainMap as any)?.mapObject.flyTo(
          this.userGeoLocation.asLatLng
        );
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeMap);
  },
  methods: {
    resizeMap() {
      // timeout used to let browser handel resize animation before resetting
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this.$refs.mainMap as any)?.mapObject.invalidateSize();
      }, 200);
    },
    getGeoLocation(latitude: number, longitude: number): GeoLocation {
      return new GeoLocation({ latitude, longitude });
    },
    reCenterMap(pointsList: [number, number][]) {
      // get map padding (40% in pixels)
      let options = {
        paddingTopLeft: [(this.$vuetify.breakpoint.width / 100) * 40, 0],
      };

      // center map
      if (pointsList.length)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this.$refs.mainMap as any)?.mapObject.fitBounds(pointsList, options);
    },
  },
  watch: {
    showingNearbyChargers(val) {
      if (val) {
        const chargerLatLngList = (this.filteredStations as Charger[]).map(
          (charger) => charger.coordinates.asLatLng
        );
        // center map
        this.reCenterMap(chargerLatLngList);
      }
    },
  },
});
</script>
<style>
@import "~leaflet.markercluster/dist/MarkerCluster.css";
/* @import "~leaflet.markercluster/dist/MarkerCluster.Default.css"; */

/* copied stiles from  "~leaflet.markercluster/dist/MarkerCluster.Default.css" for better control over colors*/
.marker-cluster-small {
  background-color: #42a5f5;
}

.marker-cluster-small div {
  background-color: #2196f3;
}

.marker-cluster-medium {
  background-color: #1e88e5;
}

.marker-cluster-medium div {
  background-color: #1976d2;
}

.marker-cluster-large {
  background-color: #1565c0;
}

.marker-cluster-large div {
  background-color: #0d47a1;
}

.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
}

.marker-cluster div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;
  text-align: center;
  border-radius: 15px;
  font:
    12px "Helvetica Neue",
    Arial,
    Helvetica,
    sans-serif;
}

.marker-cluster span {
  line-height: 30px;
}
</style>
