<template>
  <v-card-text :class="dark ? 'white--text px-0' : 'px-0'">
    <span class="d-flex justify-space-between">
      <span>{{ label }}</span>
      <span class="pwt-number-font">{{ Math.round(SOC) }}%</span>
    </span>
    <v-slider
      v-model="SOC"
      min="1"
      max="100"
      :track-color="dark ? undefined : 'grey lighten-2'"
      @end="updateSOC"
      :dark="dark"
      hide-details
    />
  </v-card-text>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";

export interface SOCSliderUpdateObj {
  identifier: string;
  SOC: number;
}

/** Vue component: renders a reusable slider input that emits an object with a passed identifier and a selected SOC.
 *
 * @prop `identifier` - string - passed identifier included in the emitted return.
 * @prop `initialValue` - number - the starting charge as a decimal representation of a % e.g. 80% = 0.8.
 * @prop `label` - string - the label to be displayed on the input element.
 * @prop `dark` - (optional) boolean - flags for input field to be rendered in dark mode, false by default.
 * @emits update - with a value in the shape of:
 * `{
 *    identifier: string \\ tha passed id value
 *    SOC: string \\ the selected value
 * }`
 */
export default Vue.extend({
  name: "SOCSlider",
  props: {
    initialValue: Number as PropType<number | undefined>,
    identifier: String,
    label: String,
    dark: {
      type: Boolean as PropType<boolean | undefined>,
      default: false,
    },
  },
  data() {
    return {
      SOC: 50,
    };
  },
  methods: {
    setInitialValue() {
      if (this.initialValue) {
        this.SOC = this.initialValue * 100;
      }
    },
    updateSOC() {
      this.$emit("update", {
        identifier: this.identifier,
        SOC: Math.round(this.SOC) / 100,
      } as SOCSliderUpdateObj);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setInitialValue();
    });
  },
});
</script>
<style scoped>
* >>> .v-slider--horizontal {
  margin-left: unset;
  margin-right: unset;
}
* >>> .v-slider--horizontal .v-slider__track-container {
  height: 6px; /* override default slider thickness */
}

* >>> .v-slider__track-fill {
  border-radius: 2px; /* override default slider border-radius */
}

* >>> .v-slider__track-background {
  border-radius: 2px; /* override default slider border-radius */
}
</style>
