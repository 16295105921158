<template>
  <l-polyline
    :lat-lngs="latLngList"
    :color="$vuetify.theme.currentTheme.error"
    :weight="5"
    :opacity="1"
    :interactive="false"
  />
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import { LPolyline } from "vue2-leaflet";

/** Vue Leaflet Component: renders the longest leap polyline on the `MapPanel` component. */
export default Vue.extend({
  name: "LongestLeapPolyline",
  props: {
    latLngList: Array as PropType<[number, number][]>,
    isLongestLeap: Boolean,
  },
  components: {
    LPolyline,
  },
});
</script>
