<template>
  <l-marker
    :lat-lng="[
      locationData.coordinates.latitude,
      locationData.coordinates.longitude,
    ]"
    @click="pinClick"
  ></l-marker>
</template>

<script lang="ts">
import TripLocation from "@/logic/classes/tripLocation";
import {
  MutationTypes,
  TertiaryDialogContent,
} from "@/logic/store/store_types";
import Vue, { PropType } from "vue";
import { LMarker } from "vue2-leaflet";

/** `Vue Leaflet Component:` renders map pins for locations, intended for use in the `MapPanel` component.
 *
 * @props `locationData` - the whole `TripLocation` class object for an individual trip location.
 */
export default Vue.extend({
  name: "LocationPin",
  props: {
    locationData: Object as PropType<TripLocation>,
  },
  components: {
    LMarker,
  },
  methods: {
    pinClick() {
      this.$store.commit(
        MutationTypes.setSelectedLocation,
        this.locationData.localId
      );
      this.$store.commit(
        MutationTypes.setTertiaryDialogContent,
        TertiaryDialogContent.LOCATION_DETAILS
      );
    },
  },
});
</script>
