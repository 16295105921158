<template>
  <v-card flat>
    <v-card flat class="d-flex flex-column justify-center align-center">
      <v-card-title class="tertiary--text">No Trip Selected</v-card-title>
      <v-card-subtitle>
        Plan a new trip or click on a previous one
      </v-card-subtitle>
    </v-card>
    <v-card-text>
      <v-btn
        color="primary"
        block
        class="text-none rounded-lg mb-3"
        @click="toPlanning"
      >
        Plan a New Trip
      </v-btn>
      <v-btn
        :color="powerTripDarkBlue"
        block
        outlined
        class="text-none rounded-lg"
        @click="toOptimisedTripPlanning"
      >
        Plan a New Optimised Trip
      </v-btn>
    </v-card-text>
    <v-card-title class="d-flex justify-space-between pt-5 tertiary--text">
      <span>Trip History</span>
      <v-btn
        outlined
        :color="powerTripDarkBlue"
        class="rounded-lg text-none"
        @click="toUnsaved"
      >
        View All
      </v-btn>
    </v-card-title>
    <v-card-text>
      <StoredTripCard
        v-if="unsavedTrips.length"
        :tripDetail="unsavedTrips[0]"
      />
      <StoredTripCard
        v-if="unsavedTrips.length >= 2"
        :tripDetail="unsavedTrips[1]"
      />
      <StoredTripCard
        v-if="unsavedTrips.length >= 3"
        :tripDetail="unsavedTrips[2]"
      />
    </v-card-text>
    <v-card-title class="d-flex justify-space-between pt-5 tertiary--text">
      <span>Favorite Trips</span>
      <v-btn
        outlined
        :color="powerTripDarkBlue"
        class="rounded-lg text-none"
        @click="toSaved"
      >
        View All
      </v-btn>
    </v-card-title>
    <v-card-text>
      <StoredTripCard v-if="savedTrips.length" :tripDetail="savedTrips[0]" />
      <StoredTripCard
        v-if="savedTrips.length >= 2"
        :tripDetail="savedTrips[1]"
      />
      <StoredTripCard
        v-if="savedTrips.length >= 3"
        :tripDetail="savedTrips[2]"
      />
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import Trip from "@/logic/classes/trip";
import { powerTripDarkBlue } from "@/logic/data/const";
import Vue from "vue";
import { GettersTypes } from "@/logic/store/store_types";
import StoredTripCard from "@/ui/components/trips/history/StoredTripCard.vue";
import { RouteNames } from "@/logic/router";
export default Vue.extend({
  name: "HistoryMain",
  data() {
    return {
      powerTripDarkBlue: powerTripDarkBlue,
    };
  },
  computed: {
    savedTrips(): Trip[] {
      return this.$store.getters[GettersTypes.savedTrips];
    },
    unsavedTrips(): Trip[] {
      return this.$store.getters[GettersTypes.unsavedTrips];
    },
  },
  components: { StoredTripCard },
  methods: {
    pushRoute(routeName: RouteNames) {
      this.$router.push({ name: routeName });
    },
    toPlanning() {
      this.pushRoute(RouteNames.planning);
    },
    toUnsaved() {
      this.pushRoute(RouteNames.unsaved);
    },
    toSaved() {
      this.pushRoute(RouteNames.saved);
    },
    toOptimisedTripPlanning() {
      this.pushRoute(RouteNames.optimisedPlanning);
    },
  },
});
</script>
