<template>
  <v-card flat>
    <v-card flat class="d-flex flex-column justify-center align-center">
      <v-card-title class="tertiary--text">No Trip Selected</v-card-title>
      <v-card-subtitle>
        Plan a new trip or select a previous one
      </v-card-subtitle>
    </v-card>
    <v-card-text>
      <v-btn
        color="primary"
        block
        class="text-none rounded-lg mb-3"
        @click="toPlanning"
      >
        Plan a New Trip
      </v-btn>
      <v-btn
        :color="powerTripDarkBlue"
        block
        outlined
        class="text-none rounded-lg mb-3"
        @click="toOptimisedTripPlanning"
      >
        Plan a New Optimised Trip
      </v-btn>
      <v-btn
        color="primary"
        block
        text
        class="text-none rounded-lg"
        @click="toTrips"
      >
        Select previous trips
      </v-btn>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import Vue from "vue";
import { powerTripDarkBlue } from "@/logic/data/const";
import { RouteNames } from "@/logic/router";

export default Vue.extend({
  name: "NoTripContent",
  methods: {
    pushRoute(routeName: RouteNames) {
      this.$router.push({ name: routeName });
    },
    toPlanning() {
      this.pushRoute(RouteNames.planning);
    },
    toTrips() {
      this.pushRoute(RouteNames.trips);
    },
    toOptimisedTripPlanning() {
      this.pushRoute(RouteNames.optimisedPlanning);
    },
  },
  data() {
    return {
      powerTripDarkBlue: powerTripDarkBlue,
    };
  },
});
</script>
