<template>
  <v-dialog
    :value="!!content"
    :content-class="$vuetify.breakpoint.xs ? '' : 'rounded-lg'"
    style="z-index: 1300"
    @click:outside="close()"
    @keydown="keyDown($event)"
    :width="$vuetify.breakpoint.mdAndUp ? '50%' : '70%'"
    max-width="600px"
    min-width="300px"
    :fullscreen="$vuetify.breakpoint.xs"
    scrollable
  >
    <v-card
      class="overflow-hidden py-5"
      id="pwt-card"
      :style="$vuetify.breakpoint.xs ? 'height: 100vh;' : 'height: 75vh;'"
    >
      <v-card-title
        class="d-flex justify-space-between"
        style="word-break: normal"
      >
        <v-row no-gutters>
          <v-col cols="2" md="1">
            <v-icon color="primary" class="pb-1">
              {{ icon }}
            </v-icon>
          </v-col>
          <v-col cols="8" md="10">
            <span class="primary--text"> {{ title }} </span>
          </v-col>
          <v-col cols="2" md="1">
            <v-btn icon @click="close()">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text
        class="pwt-scroll-container pwt-scrollbar-styles overflow-y-auto overflow-x-hidden pb-0"
        style="height: calc(100% - 96px)"
      >
        <!-- conditionally rendered content. -->
        <DeleteTripConfirmationCard
          v-if="content === 'storedTripDeleteConfirmation'"
        />
        <NetworkSettings v-if="content === 'networkSettings'" />
        <BatteryHealthHelpContent v-if="content === 'batteryHealthHelp'" />
        <ConnectorsContent v-if="content === 'connectorsAndCableSettings'" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import Vue from "vue";
import { mapState } from "vuex";
import DeleteTripConfirmationCard from "../trips/planing/dialog-content/DeleteTripConfirmationCard.vue";
import NetworkSettings from "../settings/dialog-content/NetworkSettings.vue";
import BatteryHealthHelpContent from "../help-content/dialog-content/BatteryHealthHelpContent.vue";
import ConnectorsContent from "../vehicles/dialog-content/ConnectorsContent.vue";
import {
  MutationTypes,
  SecondaryDialogContent,
  type State,
} from "@/logic/store/store_types";

/** Vue Component: renders the smaller dialog wrapper to
 * display conditional content. To be used in the `App`
 * component. */
export default Vue.extend({
  name: "SecondaryDialog",
  computed: {
    ...mapState({
      content: (state: unknown): SecondaryDialogContent | undefined =>
        (state as State).secondaryDialogContent,
      title: (
        state: unknown
      ):
        | ""
        | "Range adjustment and battery health"
        | "Connectors and cables"
        | "Network settings"
        | "Confirm deletion"
        | undefined => {
        switch ((state as State).secondaryDialogContent) {
          case SecondaryDialogContent.BATTERY_HEALTH_HELP:
            return "Range adjustment and battery health";
          case SecondaryDialogContent.CONNECTORS_AND_CABLE_SETTINGS:
            return "Connectors and cables";
          case SecondaryDialogContent.NETWORK_SETTINGS:
            return "Network settings";
          case SecondaryDialogContent.STORED_TRIP_DELETE_CONFIRMATION:
            return "Confirm deletion";
          case SecondaryDialogContent.TRIP_PLANNING_PRIMARY_TIME_SELECT:
            return "";
          default:
            return;
        }
      },
      icon: (
        state: unknown
      ):
        | ""
        | "mdi-help-circle"
        | "$stationsIcon"
        | "mdi-trash-can"
        | undefined => {
        switch ((state as State).secondaryDialogContent) {
          case SecondaryDialogContent.BATTERY_HEALTH_HELP:
            return "mdi-help-circle";
          case SecondaryDialogContent.CONNECTORS_AND_CABLE_SETTINGS:
            return "$stationsIcon";
          case SecondaryDialogContent.NETWORK_SETTINGS:
            return "$stationsIcon";
          case SecondaryDialogContent.STORED_TRIP_DELETE_CONFIRMATION:
            return "mdi-trash-can";
          case SecondaryDialogContent.TRIP_PLANNING_PRIMARY_TIME_SELECT:
            return "";
          default:
            return;
        }
      },
    }),
  },
  components: {
    DeleteTripConfirmationCard,
    NetworkSettings,
    BatteryHealthHelpContent,
    ConnectorsContent,
  },
  methods: {
    close() {
      this.$store.commit(MutationTypes.setSecondaryDialogContent, undefined);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    keyDown(event: any): void {
      if (event.key === "Escape") this.close();
    },
  },
  watch: {
    content(val: SecondaryDialogContent) {
      if (val) {
        // Notify analytics server
        // eslint-disable-next-line
        // @ts-ignore
        Vue.prototype.$Countly.q.push(["track_pageview", val]);
      }
    },
  },
});
</script>
<style scoped>
#pwt-card {
  background-color: var(--v-background-base);
}

.pwt-scroll-container {
  width: 100%;
  height: 100%;
}

.pwt-scrollbar-styles {
  scrollbar-color: #ffffff #e0e0e0;
  scrollbar-width: thin;
}

.pwt-scrollbar-styles:hover {
  scrollbar-color: #eeeeee #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar {
  width: 6px;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track:hover {
  background: #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb:hover {
  background: #eeeeee;
}
</style>
