import type {
  EVNavCharger,
  EVNavLeg,
  EVNavLongestLeap,
  EVNavRadarResponse,
} from "../types/ev_nav_types";
import generateUniqueLocalID from "../utils/generateUniqueLocalID";

export default class TripRadarData {
  // -------------------------------------------------------------------- //
  // ------------------------- Global class state ----------------------- //
  // -------------------------------------------------------------------- //

  // global record of class instance ids this session.
  static usedIds: string[] = [];

  // -------------------------------------------------------------------- //
  // ------------------------------- State ------------------------------ //
  // -------------------------------------------------------------------- //

  /** local unique id. */
  localId: string;

  chargers: EVNavCharger[];
  longestLeap?: EVNavLongestLeap;
  legs?: EVNavLeg[];
  distance: number;
  energy: number;
  travelTime: number;

  // -------------------------------------------------------------------- //
  // --------------------------- Constructor ---------------------------- //
  // -------------------------------------------------------------------- //
  constructor({
    localId = undefined,
    chargers = [],
    longestLeap = undefined,
    legs = [],
    distance = 0,
    energy = 0,
    travelTime = 0,
  }: {
    localId?: string;
    chargers?: EVNavCharger[];
    longestLeap?: EVNavLongestLeap;
    legs?: EVNavLeg[];
    distance?: number;
    energy?: number;
    travelTime?: number;
  }) {
    this.localId =
      localId ?? generateUniqueLocalID(TripRadarData.usedIds, "radar");
    this.chargers = chargers;
    this.longestLeap = longestLeap;
    this.legs = legs;
    this.distance = distance;
    this.energy = energy;
    this.travelTime = travelTime;

    // add id to list of used unique ids
    TripRadarData.usedIds.push(this.localId);
  }

  static fromEVNavData(data: EVNavRadarResponse) {
    return new TripRadarData({
      chargers: data.Chargers,
      longestLeap: data.LongestLeap,
      legs: data.Legs,
      distance: data.Distance,
      energy: data.Energy,
      travelTime: data.TravelTime,
    });
  }

  // -------------------------------------------------------------------- //
  // ------------------------------ Getters ----------------------------- //
  // -------------------------------------------------------------------- //

  // -------------------------------------------------------------------- //
  // ------------------------------ Methods ----------------------------- //
  // -------------------------------------------------------------------- //
}
