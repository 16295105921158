<template>
  <div v-if="networkBranding">
    <!-- conditionally rendered operators logo -->

    <!-- full image logo -->
    <span v-if="networkBranding.textBakedLogoUUID">
      <v-img :src="textBakedLogo" class="pwt-full-img-logo" contained></v-img>
    </span>

    <!-- image and text logo -->
    <span
      v-else-if="networkBranding.iconLogoUUID"
      class="pwt-img-and-text-logo-wrapper"
      :style="
        networkBranding.textColor ? `color: ${networkBranding.textColor}` : ''
      "
    >
      <span class="pwt-circular-logo-img-wrapper">
        <v-img :src="iconLogo" class="pwt-circular-logo-img" contained></v-img>
      </span>
      {{ networkBranding.displayName }}
    </span>

    <!-- default to only network name as text if no known styling exists -->
    <span v-else>{{ networkBranding.displayName }}</span>
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import NetworkBranding from "@/logic/classes/networkBranding";

/** Vue Component: renders Charging station network operators logos, intended to
 * be displayed in the `ItineraryContent` and `ChargingStationDetailsContent`
 * components.
 *
 * @props `network` - the network name recognized by the DB.
 * */
export default Vue.extend({
  name: "OperatorLogo",
  props: {
    networkBranding: Object as PropType<NetworkBranding | undefined>,
  },
  computed: {
    iconLogo(): string | undefined {
      return (this.networkBranding as NetworkBranding | undefined)?.iconLogoSrc;
    },
    textBakedLogo(): string | undefined {
      return (this.networkBranding as NetworkBranding | undefined)
        ?.textBakedLogoSrc;
    },
  },
});
</script>

<style scoped>
.pwt-full-img-logo {
  width: 100px;
  height: auto;
}

.pwt-circular-logo-img-wrapper {
  overflow: hidden;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 5px;
}

.pwt-circular-logo-img {
  width: 24px;
  height: 24px;
}

.pwt-img-and-text-logo-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
