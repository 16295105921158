<template>
  <v-card
    id="pwt-info-panel"
    :width="$vuetify.breakpoint.xsOnly ? 'initial' : '40%'"
    :height="$vuetify.breakpoint.xsOnly ? '100%' : 'calc(100% - 16px)'"
    :class="classes"
    :min-width="$vuetify.breakpoint.xs ? `100%` : 400"
    max-width="600"
  >
    <!-- mobile banner -->
    <v-toolbar
      color="primary"
      height="50px"
      class="rounded-0"
      v-if="$vuetify.breakpoint.xsOnly"
    >
      <v-spacer></v-spacer>
      <v-btn icon dark @click="emitMinimize">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <!-- nav bar -->
    <div class="d-flex elevation-5" style="height: 100px; position: relative">
      <div
        v-ripple
        :class="
          currentRouteName === 'home'
            ? 'primary--text d-flex flex-column align-center flex-grow-1 flex-shrink-1 pl-2 pr-1 pt-3'
            : 'grey--text d-flex flex-column align-center flex-grow-1 flex-shrink-1 pl-2 pr-1 pt-3'
        "
        @click="handelRouteChange('home')"
        style="cursor: pointer"
      >
        <v-icon
          :color="currentRouteName === 'home' ? 'primary' : 'grey'"
          class="mb-3"
          large
        >
          $itineraryIcon
        </v-icon>
        <p class="text-caption text-capitalize">Dashboard</p>
      </div>
      <div
        v-ripple
        :class="
          currentRouteName === 'trips' ||
          currentRouteName === 'planning' ||
          currentRouteName === 'itinerary' ||
          currentRouteName === 'stats' ||
          currentRouteName === 'saved' ||
          currentRouteName === 'unsaved'
            ? 'primary--text d-flex flex-column align-center flex-grow-1 flex-shrink-1 px-1 pt-3'
            : 'grey--text d-flex flex-column align-center flex-grow-1 flex-shrink-1 px-1 pt-3'
        "
        @click="
          viewingTrip
            ? handelRouteChange('itinerary')
            : handelRouteChange('trips')
        "
        style="cursor: pointer"
      >
        <v-icon
          class="mb-3 pt-2 pl-1"
          :color="
            currentRouteName === 'trips' ||
            currentRouteName === 'planning' ||
            currentRouteName === 'itinerary' ||
            currentRouteName === 'stats' ||
            currentRouteName === 'saved' ||
            currentRouteName === 'unsaved'
              ? 'primary'
              : 'grey'
          "
          large
        >
          $tripsIcon
        </v-icon>
        <p class="text-caption text-none">
          {{ viewingTrip ? "Itinerary" : "Plan a Trip" }}
        </p>
      </div>
      <div
        v-ripple
        :class="
          currentRouteName === 'stations'
            ? 'primary--text d-flex flex-column align-center flex-grow-1 flex-shrink-1 px-2 pt-3'
            : 'grey--text d-flex flex-column align-center flex-grow-1 flex-shrink-1 px-2 pt-3'
        "
        @click="handelRouteChange('stations')"
        style="cursor: pointer"
      >
        <v-img
          v-if="loadingStations"
          contain
          max-height="36"
          max-width="36"
          class="mb-3"
          :src="getSrc('Loading-Blue.gif')"
        ></v-img>
        <v-icon
          v-else
          :color="currentRouteName === 'stations' ? 'primary' : 'grey'"
          class="mb-3"
          large
        >
          $stationsIcon
        </v-icon>
        <p class="text-caption text-capitalize">chargers</p>
      </div>
      <div
        v-ripple
        :class="
          currentRouteName === 'vehicles'
            ? 'primary--text d-flex flex-column align-center flex-grow-1 flex-shrink-1 px-2 pt-3'
            : 'grey--text d-flex flex-column align-center flex-grow-1 flex-shrink-1 px-2 pt-3'
        "
        @click="handelRouteChange('vehicles')"
        style="cursor: pointer"
      >
        <v-icon
          :color="currentRouteName === 'vehicles' ? 'primary' : 'grey'"
          class="mb-3"
          large
        >
          $vehiclesIcon
        </v-icon>
        <p class="text-caption text-capitalize">vehicles</p>
      </div>
    </div>
    <v-divider />
    <v-card
      flat
      class="pwt-scroll-container overflow-y-auto pwt-scrollbar-styles"
      style="padding-bottom: 100px"
    >
      <router-view />
    </v-card>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { mapState } from "vuex";
import { type State } from "@/logic/store/store_types";
import getAssetSrc from "@/logic/utils/getAssetSrc";
import { RouteNames } from "@/logic/router";

/**Vue Component: renders the main UI panel for users to operate the app. To be used in the 'App' component. */
export default Vue.extend({
  name: "InfoPanel",
  props: {
    animation: String as PropType<"slide-left" | "slide-right" | null>,
  },
  computed: {
    ...mapState({
      loadingStations: (state: unknown) => (state as State).chargerFetchingFlag,
      viewingTrip: (state: unknown) => !!(state as State).selectedTrip,
    }),
    classes(): string {
      if (this.animation == "slide-left") {
        return this.$vuetify.breakpoint.xs
          ? "overflow-hidden ma-0 ma-sm-2 rounded-0"
          : "overflow-hidden ma-0 ma-sm-2 rounded-lg pwt-info-panel-right-to-left-animation";
      }

      if (this.animation == "slide-right") {
        return this.$vuetify.breakpoint.xs
          ? "overflow-hidden ma-0 ma-sm-2 rounded-0"
          : "overflow-hidden ma-0 ma-sm-2 rounded-lg pwt-info-panel-left-to-right-animation";
      }

      return this.$vuetify.breakpoint.xs
        ? "overflow-hidden ma-0 ma-sm-2 rounded-0"
        : "overflow-hidden ma-0 ma-sm-2 rounded-lg";
    },
    currentRouteName(): RouteNames | undefined {
      return (this.$route.name as RouteNames | null | undefined) ?? undefined;
    },
  },
  methods: {
    getSrc(partialFilePath: string): string {
      return getAssetSrc(partialFilePath);
    },
    handelRouteChange(routeName: string) {
      // attempting to navigate to the route name that is currently being displayed guard clause.
      if (this.$router.currentRoute.name === routeName) return;
      this.$router.push({ name: routeName });
      this.notifyAnalytics();
    },
    emitMinimize() {
      this.$emit("minimize");
    },
    notifyAnalytics() {
      // Notify analytics server
      Vue.prototype.$Countly.q.push(["track_pageview", this.currentRouteName]);
    },
  },
  data() {
    return {
      minAnimation: false,
    };
  },
});
</script>

<style scoped>
#pwt-info-panel {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1102;
  border-radius: 10px;
}

#pwt-info-panel-expand-btn {
  z-index: 1102;
  position: absolute;
  bottom: 8px;
  left: 8px;
}

#pwt-info-panel-expand-btn-mobile {
  z-index: 1102;
  position: absolute;
  top: 5px;
  left: 5px;
}

#pwt-info-panel-minimize-btn {
  z-index: 1102;
  position: absolute;
  top: 0;
  left: clamp(410px, calc(40% + 10px), 610px);
}

#pwt-info-panel-floating-controls-overlay {
  z-index: 1102;
  position: relative;
  min-width: 400px;
  max-width: 600px;
  width: 40%;
  height: calc(100% - 30px) !important;
  background-color: transparent;
}

.pwt-info-panel-left-to-right-animation {
  left: -41%;
  -webkit-animation: left-to-right 700ms linear forwards;
  animation: left-to-right 700ms linear forwards;
}

.pwt-info-panel-right-to-left-animation {
  left: 0;
  -webkit-animation: right-to-left 1s linear forwards;
  animation: right-to-left 1s linear forwards;
}

@-webkit-keyframes left-to-right {
  from {
    left: -41%;
  }
  to {
    left: 0;
  }
}
@-webkit-keyframes right-to-left {
  from {
    left: 0;
  }
  to {
    left: -100%;
  }
}

@keyframes left-to-right {
  from {
    left: -41%;
  }
  to {
    left: 0;
  }
}
@keyframes right-to-left {
  from {
    left: 0;
  }
  to {
    left: -100%;
  }
}

.pwt-scroll-container {
  width: 100%;
  height: 100%;
}

.pwt-scrollbar-styles {
  scrollbar-color: #ffffff #e0e0e0;
  scrollbar-width: thin;
}

.pwt-scrollbar-styles:hover {
  scrollbar-color: #eeeeee #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar {
  width: 6px;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track:hover {
  background: #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb:hover {
  background: #eeeeee;
}
</style>
