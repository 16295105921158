import { reverseGeocode } from "../api/calls/maps_little_monkey_calls";
import {
  AdditionalGeoLocation as ApiAdditionalGeoLocation,
  GeoLocation as ApiGeoLocation,
} from "../types/charger_Db_types";
import { EVNavWaypoint } from "../types/ev_nav_types";
import { MapsGeoIPRes } from "../types/maps_little_monkey_types";
import { AddressObj } from "../types/sheared_local_types";

export default class GeoLocation {
  // -------------------------------------------------------------------- //
  // ------------------------------- State ------------------------------ //
  // -------------------------------------------------------------------- //

  /** The locations latitude. */
  latitude: number;

  /** The locations longitude. */
  longitude: number;

  /** Optional identifier name for this location. */
  name?: string;

  /** Optional UI displayable name/string for this location. */
  displayName?: string;

  /** Optional address object. */
  address?: AddressObj;

  // -------------------------------------------------------------------- //
  // --------------------------- Constructor ---------------------------- //
  // -------------------------------------------------------------------- //

  constructor({
    latitude = 0,
    longitude = 0,
  }: {
    latitude?: number;
    longitude?: number;
  }) {
    this.latitude = latitude;
    this.longitude = longitude;
  }

  /**
   * Returns a new GeoLocation class object populated with data from charger DB object various geo locations types.
   *
   * @param data either the whole 'ApiGeoLocation' or 'ApiAdditionalGeoLocation' object.
   * @returns a new GeoLocation class object
   */
  static fromChargerDBData(
    data: ApiGeoLocation | ApiAdditionalGeoLocation
  ): GeoLocation {
    const newObj = new GeoLocation({
      latitude: parseFloat(data.latitude),
      longitude: parseFloat(data.longitude),
    });
    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty("name")) {
      newObj.displayName = (data as ApiAdditionalGeoLocation).name.Text;
    }
    return newObj;
  }

  /**
   * Returns a new GeoLocation class object populated with data from the little monkey maps reverse geo coding service.
   *
   * @param data the whole 'MapsGeoIPRes' object.
   * @returns a new GeoLocation class object
   */
  static fromMapsLittleMonkeyData(data: MapsGeoIPRes): GeoLocation {
    const newObj = new GeoLocation({
      latitude: data.Location.Latitude,
      longitude: data.Location.Longitude,
    });
    newObj.address = {
      city: data.City.Names.en,
      country: data.Country.Names.en,
      countryCode: data.Country.IsoCode,
      postcode: data.Postal.Code,
      county: "",
      houseNumber: "",
      road: "",
      state: "",
      suburb: "",
    };
    newObj.displayName = data.City.Names.en + " " + data.Country.Names.en;
    return newObj;
  }

  /**
   * Returns a new GeoLocation class object populated with data from the legacy coordinates system.
   *
   * @param data a legacy coordinates object.
   * @returns a new GeoLocation class object
   */
  static fromLegacyCoordinates(data: { Latitude: number; Longitude: number }) {
    return new GeoLocation({
      latitude: data.Latitude,
      longitude: data.Longitude,
    });
  }

  // -------------------------------------------------------------------- //
  // ------------------------------ Getters ----------------------------- //
  // -------------------------------------------------------------------- //

  /** Returns coordinates in the leaflet `[lat,lng]` format. */
  get asLatLng(): [number, number] {
    return [this.latitude, this.longitude];
  }

  /** Returns coordinates in the `EVNavWaypoint` format. see types for details. */
  get asWaypoint(): EVNavWaypoint {
    return {
      Latitude: this.latitude,
      Longitude: this.longitude,
      Name: this.name,
    };
  }

  /** Returns true if the coordinates are for null island e.g. 0,0. */
  get isNullIsland(): boolean {
    return this.latitude === 0 && this.longitude === 0;
  }

  // -------------------------------------------------------------------- //
  // ------------------------------ Methods ----------------------------- //
  // -------------------------------------------------------------------- //

  /** Reverse geo code the latitude and longitude to get an address for the coordinates. */
  public async reverseGeocodeAddress(): Promise<string | undefined> {
    const reverseGeocodeRes = await reverseGeocode(
      this.latitude,
      this.longitude
    );

    if (reverseGeocodeRes) {
      this.displayName = reverseGeocodeRes.display_name;
      this.address = {
        ...reverseGeocodeRes.address,
        houseNumber: reverseGeocodeRes.address.house_number,
        countryCode: reverseGeocodeRes.address.country_code,
      };
      return reverseGeocodeRes.display_name;
    }
  }
}
