/**
 * returns a number of the passed value if it is a number, stringified int or stringified float without erroring out.
 *
 * @param value target stringified number.
 * @returns a number if successful, undefined if not.
 */
export default function parseIntOrFloat(
  value: string | number
): number | undefined {
  // check if is already a number
  if (typeof value === "number") return value;
  // try as int
  try {
    const number = parseInt(value);
    return number;
  } catch (error) {
    // try as float
    try {
      const number = parseFloat(value);
      return number;
    } catch (error) {
      return undefined;
    }
  }
}
