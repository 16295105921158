<template>
  <v-col justify="center" cols="6" sm="4" md="3">
    <v-img
      :src="displayImg"
      contain
      width="40px"
      height="auto"
      class="mx-auto"
    />
    <p class="text-center" style="word-break: none">{{ displayText }}</p>
  </v-col>
</template>

<script lang="ts">
import getAssetSrc from "@/logic/utils/getAssetSrc";
import Vue from "vue";

/** Vue Component: renders the reminder item for BYO cables to be used in the `ItineraryBringSection` component.
 *
 * @props cablePlug - the string for the type of socket a cable is required for.
 */
export default Vue.extend({
  name: "CableRequiredCard",
  props: {
    cablePlug: String,
  },
  computed: {
    displayText(): string {
      // get the appropriate wording based on plug type
      if (this.cablePlug === "Blue Commando") return "Blue commando cable";
      if (this.cablePlug === "Wall Plug (NZ & Australia)")
        return "Wall plug cable";
      if (this.cablePlug === "Three Phase") return "Three phase cable";
      if (this.cablePlug === "Type 2 Socketed") return "Type 2 cable";
      if (this.cablePlug === "Type 1 - Socketed") return "Type 1 cable";

      // should never be reached unless cable types has not been kept up to date.
      return "cable not recognized";
    },
    displayImg(): string {
      // get the appropriate image based on plug type
      if (this.cablePlug === "Blue Commando")
        return getAssetSrc("plug_images/blue_commando.png");
      if (this.cablePlug === "Wall Plug (NZ & Australia)")
        return getAssetSrc("plug_images/wall_plug.png");
      if (this.cablePlug === "Three Phase")
        return getAssetSrc("plug_images/three_phase.png");
      if (this.cablePlug === "Type 2 Socketed")
        return getAssetSrc("plug_images/type_2_socketed.png");
      if (this.cablePlug === "Type 1 - Socketed")
        return getAssetSrc("plug_images/type_1_socketed.png");

      // should never be reached unless cable types has not been kept up to date.
      return "#";
    },
  },
});
</script>
