<template>
  <v-container>
    <ItineraryHeader :trip="trip" />

    <!-- conditional rendering of steps -->
    <template v-for="(leg, legIndex) in trip.evTripData">
      <v-card
        v-for="(step, index) in leg.steps"
        :key="`step-${legIndex}-${index}`"
        flat
        class="px-5"
      >
        <!-- conditionally rendered fist card  -->
        <DepartureCard
          v-if="step.From === trip.locations[0].localId"
          :step="step"
          :location="startingLocations[legIndex]"
          :legIndex="legIndex"
          :storedTrip="trip"
          :stepIndex="getStepIndex(index, legIndex, trip)"
        />

        <!-- charging stops (every card except first) -->
        <ChargingStopCard
          v-if="!!step.Charger"
          :step="step"
          :stepIndex="getStepIndex(index, legIndex, trip)"
          :legIndex="legIndex"
          :storedTrip="trip"
        />

        <!-- conditionally rendered additional stop card -->
        <PlannedStopCard
          v-if="
            step.From.includes('location-') &&
            step.From !== trip.locations[0].localId &&
            step.From !== trip.locations[trip.locations.length - 1].localId
          "
          :waypointName="step.From"
          :step="step"
          :stepIndex="getStepIndex(index, legIndex, trip)"
          :storedTrip="trip"
        />

        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="11" class="py-10">
            <!-- inset distance traveled trailing card -->
            <DrivingLegDetailsCard
              :step="step"
              :isFinaleStep="index === steps.length - 1"
            />
          </v-col>
        </v-row>
      </v-card>
    </template>
    <!-- destination card (only appears after the last charging stop card)-->
    <div class="px-5">
      <ArrivalCard
        v-if="trip.evTripData"
        :step="
          trip.evTripData[trip.evTripData.length - 1].steps[
            trip.evTripData[trip.evTripData.length - 1].steps.length - 1
          ]
        "
        :storedTrip="trip"
      />
    </div>

    <div class="px-5 mt-5">
      <!-- remember to bring section -->
      <ItineraryBringSection />
    </div>
  </v-container>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import ArrivalCard from "./ArrivalCard.vue";
import DepartureCard from "./DepartureCard.vue";
import DrivingLegDetailsCard from "./DrivingLegDetailsCard.vue";
import ItineraryBringSection from "./ItineraryBringSection.vue";
import ItineraryHeader from "./ItineraryHeader.vue";
import ChargingStopCard from "./ChargingStopCard.vue";
import PlannedStopCard from "./PlannedStopCard.vue";
import Trip from "@/logic/classes/trip";
import { EVNavStep } from "@/logic/types/ev_nav_types";
import TripLocation from "@/logic/classes/tripLocation";

export default Vue.extend({
  name: "ItineraryEVContent",
  props: {
    trip: {
      required: true,
      type: Object as PropType<Trip>,
    },
  },
  components: {
    DepartureCard,
    ArrivalCard,
    DrivingLegDetailsCard,
    ItineraryBringSection,
    ItineraryHeader,
    ChargingStopCard,
    PlannedStopCard,
  },
  computed: {
    steps(): EVNavStep[] {
      const steps = (this.trip as Trip).evTripData?.flatMap(
        (plan) => plan.steps
      );
      return steps ?? [];
    },
    startingLocations(): TripLocation[] {
      const returnArray: TripLocation[] = [];
      // add initial starting location
      returnArray.push((this.trip as Trip).locations[0]);
      // filter out all non-charging waypoint locations
      const chargingWaypointLocations = (this.trip as Trip).locations.filter(
        (location) => location.chargeHere
      );
      chargingWaypointLocations.forEach((location) => {
        returnArray.push(location);
      });

      return returnArray;
    },
  },
  methods: {
    getStepIndex(index: number, tripIndex: number, tripDetails: Trip) {
      let totalPreviousTripsLength = 0;
      if (tripDetails.evTripData) {
        for (let i = 0; i < tripIndex; i++) {
          totalPreviousTripsLength += tripDetails.evTripData[i].steps.length;
        }
      }
      return totalPreviousTripsLength + index;
    },
  },
});
</script>
