<template>
  <v-dialog
    :value="open"
    :content-class="$vuetify.breakpoint.xs ? '' : 'rounded-lg'"
    style="z-index: 1400"
    :width="$vuetify.breakpoint.mdAndUp ? '50%' : '70%'"
    max-width="400px"
    min-width="200px"
    :fullscreen="$vuetify.breakpoint.xs"
    scrollable
    @click:outside="emitClose"
  >
    <v-card>
      <v-card-title class="primary white--text d-flex justify-space-between">
        <span>
          <v-icon color="white">mdi-calendar</v-icon>
        </span>
        <span> Confirm Your Booking </span>
        <span>
          <v-btn icon @click="emitClose">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </span>
      </v-card-title>
      <v-card-text class="d-flex flex-column pt-3">
        <span class="text-subtitle-1 grey--text"> Start </span>
        <BookingDateInput
          v-if="!!event"
          :time="event.start"
          :isStart="true"
          @update="updateDate"
        />
        <BookingTimeInput
          v-if="!!event"
          :initialValue="event.start"
          timeName="Start Time"
          @update="updateTime"
          @clearError="removeError('Issue with start time')"
          @error="addError('Issue with start time')"
          class="mb-5"
        />
        <span class="text-subtitle-1 grey--text"> End </span>
        <BookingDateInput
          v-if="!!event"
          :time="event.end"
          :isStart="false"
          @update="updateDate"
        />
        <BookingTimeInput
          v-if="!!event"
          :initialValue="event.end"
          timeName="End Time"
          @update="updateTime"
          @clearError="removeError('Issue with end time')"
          @error="addError('Issue with end time')"
        />
        <v-alert v-if="!!errors" type="error" color="error" class="rounded-lg">
          {{ errorMessage }}
        </v-alert>
      </v-card-text>
      <v-card-actions class="pb-3">
        <v-btn
          block
          color="primary"
          class="text-none rounded-lg"
          @click="emitCreate"
          :loading="loading"
          :disabled="loading || !!errors"
        >
          Confirm Booking
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import { CalEvent } from "@/logic/types/booking_types";
import Vue, { PropType } from "vue";
import BookingTimeInput, {
  BookingTimeInputUpdateObj,
} from "./BookingTimeInput.vue";
import { epochToNiceDate } from "@/logic/utils/timeUtils";
import BookingDateInput, {
  BookingDateInputUpdateObj,
} from "./BookingDateInput.vue";

export default Vue.extend({
  name: "BookingConfirmationModal",
  props: {
    event: {
      type: Object as PropType<CalEvent | null>,
      default: null,
    },
    open: {
      type: Boolean,
      required: true,
      default: false,
    },
    error: {
      type: String as PropType<string | null>,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      errors: null as string | string[] | null,
    };
  },
  computed: {
    date(): string {
      if (!this.event) return "";
      return epochToNiceDate(this.event.start);
    },
    errorMessage(): string {
      if (!this.errors) return "";
      return Array.isArray(this.errors) ? this.errors[0] : this.errors;
    },
  },
  components: { BookingTimeInput, BookingDateInput },
  methods: {
    emitUpdate(newEvent: CalEvent) {
      this.$emit("update", newEvent);
    },
    emitCreate() {
      this.$emit("create");
    },
    emitClose() {
      this.$emit("close");
    },
    emitClearError() {
      this.$emit("clearError");
    },
    updateDate(val: BookingDateInputUpdateObj): void {
      if (!this.event) return;
      this.emitClearError();
      const newEvent = this.event;
      if (val.newStartTime) newEvent.start = val.newStartTime;
      if (val.newEndTime) newEvent.end = val.newEndTime;
      this.emitUpdate(newEvent);
      return;
    },
    updateTime(val: BookingTimeInputUpdateObj): void {
      if (!this.event) return;

      if (val.timeName === "start") {
        this.emitUpdate({
          ...this.event,
          start: val.newTime,
        });
        this.emitClearError();
        return;
      }

      if (val.timeName === "end") {
        this.emitClearError();
        this.emitUpdate({
          ...this.event,
          end: val.newTime,
        });
        return;
      }
    },
    addError(errorMsg: string) {
      if (!this.errors) {
        this.errors = errorMsg;
        return;
      }

      if (Array.isArray(this.errors)) {
        this.errors.push(errorMsg);
        return;
      }

      // ASSUMES: that errors is only type string if it reaches this point
      this.errors = [this.errors, errorMsg];
    },
    removeError(errorMsg: string) {
      if (this.errors === errorMsg) {
        this.errors = null;
        this.emitClearError();
        return;
      }

      if (Array.isArray(this.errors)) {
        const tempArray = this.errors.filter((error) => error !== errorMsg);
        if (!tempArray.length) {
          // Redundancy guard clause, should be caught buy other code if its working correctly.
          this.errors = null;
          this.emitClearError();
          return;
        }

        if (tempArray.length > 1) {
          // more than one error is left errors should still be an array.
          this.errors = tempArray;
          return;
        }

        // ASSUMES: temp array only contains one entry if this block was reached.
        this.errors = tempArray[0];
      }
    },
  },
  watch: {
    error(val: string | null) {
      if (val) {
        this.addError(val);
      } else {
        this.removeError("This slot is taken, please select another time");
        this.removeError(
          "Whoops something went wrong! Please refresh and try again"
        );
        this.removeError("This booking can't be updated");
        this.removeError("Nothing to update");
        this.removeError(
          "Failed to update server, please refresh and try again"
        );
        this.removeError("End time is before start time");
      }
    },
  },
});
</script>
