<template>
  <v-carousel
    v-model="slide"
    height="unset"
    hide-delimiters
    :show-arrows="groups.length > 1"
  >
    <v-carousel-item
      v-for="(group, index) in groups"
      :key="`slide-group-card-${index}`"
    >
      <v-card flat :class="groups.length > 1 ? 'px-15' : 'px-3'">
        <v-row>
          <v-col
            v-for="(UUID, index) in group"
            cols="4"
            :key="`slide-group-image-card-${index}`"
            style="max-height: 300px; min-height: 150px"
          >
            <v-card
              v-if="UUID === 'default'"
              class="rounded-lg secondary d-flex justify-center align-center"
              style="height: 100%"
            >
            </v-card>
            <v-img
              v-else
              :lazy-src="getSrc('car_images/No_Image_Powersell.png')"
              :src="getImageSrc(UUID)"
              width="100%"
              height="100%"
              aspect-ration="1"
              contain
            />
          </v-col>
        </v-row>
      </v-card>
    </v-carousel-item>
  </v-carousel>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import Vehicle from "@/logic/classes/vehicle";
import getDirectusImgUrl from "@/logic/utils/getDirectusImgUrl";
import getAssetSrc from "@/logic/utils/getAssetSrc";

export default Vue.extend({
  name: "VehicleImageCarousel",
  props: {
    vehicle: {
      type: Object as PropType<Vehicle | undefined>,
      required: false,
    },
  },
  data() {
    return {
      slide: 0,
    };
  },
  computed: {
    groups(): [string, string, string][] {
      const returnArray: [string, string, string][] = [];
      const numberOfGroups = this.getNumberOfGroups(
        this.vehicle?.images?.length ?? 0
      );
      for (let i = 0; i < numberOfGroups; i++) {
        returnArray.push([
          this.vehicle?.images?.[0 + i] ?? "default",
          this.vehicle?.images?.[0 + i + 1] ?? "default",
          this.vehicle?.images?.[0 + i + 2] ?? "default",
        ]);
      }
      // see if the ev model has an image to display also
      if (this.vehicle?.evModel?.imageUUID) {
        // find first default panel
        const firstDefaultIndex = returnArray[returnArray.length - 1].findIndex(
          (str) => str === "default"
        );
        if (firstDefaultIndex === -1) {
          // if no default panel add a new group with model img and default panels to fill
          returnArray.push([
            this.vehicle.evModel.imageUUID,
            "default",
            "default",
          ]);
        } else {
          // over write first default panel to model img
          returnArray[returnArray.length - 1].splice(
            firstDefaultIndex,
            1,
            this.vehicle.evModel.imageUUID
          );
        }
      }

      return returnArray;
    },
  },
  methods: {
    getNumberOfGroups(numberOfImages: number) {
      if (numberOfImages === 0) return 1;
      if (numberOfImages <= 2) return 1;
      // ASSUMES: more than 2 so therefore more than one group
      // NOTE: three images are displayed at once and the final image is the default add new image display
      return numberOfImages - 1;
    },
    getImageSrc(UUID: string) {
      if (UUID) return getDirectusImgUrl(UUID);
      else return this.getSrc("car_images/No_Image_Powersell.png");
    },
    getSrc(partialFilePath: string): string {
      return getAssetSrc(partialFilePath);
    },
  },
});
</script>
