<template>
  <v-card class="mb-10 rounded-lg" elevation="4">
    <v-card-subtitle> Remember to bring </v-card-subtitle>
    <v-row class="px-2 px-md-4">
      <v-col justify="center" cols="6" sm="4" md="3">
        <v-img
          :src="getSrc('icons/standard_car.svg')"
          contain
          width="40px"
          height="auto"
          class="mx-auto"
          style="fill: darkgreen"
        />
        <p class="text-center">
          {{
            trip.vehicle.evModel.name
              ? trip.vehicle.evModel.name
              : "unknown EV model"
          }}
        </p>
      </v-col>

      <!-- conditionally rendered cables -->
      <CableRequiredCard
        v-for="(cable, index) in renderedCables"
        :cablePlug="cable"
        :key="index"
      />

      <!-- conditionally rendered network access -->
      <OperatorAccessCard
        v-for="(network, index) in renderedNetworks"
        :network="network"
        :key="index"
      />
    </v-row>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import OperatorAccessCard from "./OperatorAccessCard.vue";
import CableRequiredCard from "./CableRequiredCard.vue";
import { GettersTypes, type State } from "@/logic/store/store_types";
import getAssetSrc from "@/logic/utils/getAssetSrc";
import Trip from "@/logic/classes/trip";
import { ConnectorType } from "@/logic/types/charger_Db_types";
import {
  ConnectorDetailsData,
  connectorDetailsDataMap,
} from "@/logic/data/connectorDetailsData";
import { mapGetters } from "vuex";

/** Vue Component: conditionally renders what a user needs to bring for
 * the current trip. To be displayed with in the `ItineraryContent`
 * component.
 *
 * @props `route` - the full route plan object, see `routePlanningStore` for details.
 * */
export default Vue.extend({
  name: "ItineraryBringSection",
  computed: {
    ...mapGetters({
      trip: GettersTypes.selectedTripData,
    }),
    renderedNetworks(): string[] {
      const tempArray: string[] = [];

      const trip: Trip | undefined =
        this.$store.getters[GettersTypes.selectedTripData];
      if (trip && trip.evTripData) {
        // iterate through steps and get each unique charger network.
        trip.evTripData
          .flatMap((plan) => plan.steps)
          .forEach((step) => {
            if (step.Charger) {
              if (!tempArray.includes(step.Charger.Network)) {
                tempArray.push(step.Charger.Network);
              }
            }
          });
      }

      return tempArray;
    },
    renderedCables(): ConnectorDetailsData[] {
      const trip: Trip | undefined =
        this.$store.getters[GettersTypes.selectedTripData];

      // ASSUMES: vehicles do not come with cables by default and a user will only have access to one if they have specified that they do.
      if (!trip?.vehicle?.userSelectedPlugs) return [];

      const stops: string[] = [];
      if (trip && trip.evTripData) {
        // iterate through steps and get each charger CDBID.
        trip.evTripData
          .flatMap((plan) => plan.steps)
          .forEach((step) => {
            if (step.Charger) stops.push(step.Charger.CDBID);
          });
      } else {
        return [];
      }

      const filteredChargers = (this.$store.state as State).chargers.filter(
        (charger) => stops.includes(charger.id)
      );
      if (
        trip.vehicle &&
        filteredChargers.some(
          (charger) =>
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            charger.isCompatible(trip.vehicle!) === "compatible with cable only"
        )
      ) {
        const cableStandardsNeeded: ConnectorType[] = [];
        filteredChargers.forEach((charger) => {
          if (
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            charger.isCompatible(trip.vehicle!) === "compatible with cable only"
          ) {
            // find relevant connector.
            const connectors = charger.evses.flatMap((evse) => evse.connectors);
            connectors.forEach((connector) => {
              if (
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                connector.isCompatible(trip.vehicle!) ===
                  "compatible with cable only" &&
                !cableStandardsNeeded.includes(connector.standard)
              )
                cableStandardsNeeded.push(connector.standard);
            });
            const returnArray: ConnectorDetailsData[] = [];
            cableStandardsNeeded.forEach((standard) => {
              const data = connectorDetailsDataMap.get(standard);
              if (data) returnArray.push(data);
            });
            return returnArray;
          }
        });
      }

      return [];
    },
  },
  components: { OperatorAccessCard, CableRequiredCard },
  methods: {
    getSrc(partialFilePath: string): string {
      return getAssetSrc(partialFilePath);
    },
  },
});
</script>
