<template>
  <div v-if="trip">
    <v-container
      v-for="(leg, index) in trip.fallbackTripData.legs"
      :key="index"
      flat
      class="ma-0 pa-0"
    >
      <!-- driving leg card -->
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="11" class="py-10">
          <v-card flat class="pa-3 rounded-lg">
            <v-card-title
              class="font-weight-bold text-body-2 pt-0 d-flex justify-space-between"
              style="word-break: normal"
            >
              <span>
                Driving {{ getNiceDuration(leg.TravelTime) }} for
                {{ Math.round(leg.Distance / 1000) }}km
              </span>
              <span>
                Battery {{ chargeUsed(leg.Energy, trip.parameters.Battery) }}%
              </span>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
      <!-- conditionally rendered additional stop -->
      <v-card
        v-if="index !== trip.fallbackTripData.legs.length - 1"
        class="rounded-lg pb-5"
        elevation="4"
      >
        <v-row no-gutters class="pt-3">
          <v-col cols="1">
            <v-icon color="primary" large class="ml-1"> mdi-map-marker </v-icon>
          </v-col>
          <v-col cols="11">
            <v-card-title class="pt-0 font-weight-bold text-body-2">
              Scheduled stop
            </v-card-title>
            <v-card-subtitle class="d-flex font-weight-bold text-body-2">
              <span>
                {{
                  trip.locations[index + 1].address
                    ? trip.locations[index + 1].address
                    : "unknown location"
                }}
              </span>
            </v-card-subtitle>
            <v-card-text
              class="d-flex justify-space-between text-body-2 font-weight-medium"
              v-if="trip.primaryTimeLocation && timeDataList"
            >
              <span>
                <v-icon color="primary lighten-4">$vehiclesIcon</v-icon>
                <v-icon class="ml-n3" color="primary">mdi-map-marker</v-icon>
                Arrive at
              </span>
              <span>{{ timeDataList[index].arrivalTime }}</span>
            </v-card-text>
            <v-card-text
              v-if="trip.primaryTimeLocation && timeDataList"
              class="text-caption pt-0 mt-n2 ml-10"
            >
              <span
                v-if="timeDataList[index].differenceType === 'before'"
                class="primary--text"
              >
                {{ timeDataList[index].timeDifference }} earlier than expected
              </span>
              <span
                v-if="timeDataList[index].differenceType === 'after'"
                class="error--text"
              >
                <v-icon color="error" small>mdi-alert-circle</v-icon>
                {{ timeDataList[index].timeDifference }} later than expected
              </span>
            </v-card-text>
            <v-card-text
              class="d-flex justify-space-between text-body-2 font-weight-medium pt-0"
              v-if="!trip.primaryTimeLocation"
            >
              <span>
                <v-icon color="primary" class="ml-2 mr-1">
                  mdi-clock-outline
                </v-icon>
                Stay for
              </span>
              <span>
                {{ getNiceDuration(trip.locations[index + 1].stay) }}
              </span>
            </v-card-text>
            <v-card-text
              class="d-flex justify-space-between text-body-2 font-weight-medium pt-0"
              v-if="trip.primaryTimeLocation && timeDataList"
            >
              <span>
                <v-icon color="primary lighten-4">mdi-map-marker</v-icon>
                <v-icon class="ml-n3" color="primary">$vehiclesIcon</v-icon>
                Depart at
              </span>
              <span>{{ timeDataList[index].departureTime }}</span>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>
<script lang="ts">
import Trip from "@/logic/classes/trip";
import { GettersTypes } from "@/logic/store/store_types";
import simpleTripTimeData, {
  type simpleTripTimeDataReturnObj,
} from "@/logic/utils/simpleTripTimeData";
import { Duration } from "luxon";
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "FallbackTravelSection",
  computed: {
    timeDataList(): simpleTripTimeDataReturnObj[] {
      const trip: Trip | undefined =
        this.$store.getters[GettersTypes.selectedTripData];

      return (
        trip?.fallbackTripData?.legs?.map((leg, index: number) =>
          simpleTripTimeData(trip, index)
        ) ?? []
      );
    },
    ...mapGetters({
      trip: GettersTypes.selectedTripData,
    }),
  },
  methods: {
    getNiceDuration(seconds: number | undefined): string {
      const time = seconds ?? 0;
      return Duration.fromObject({
        hours: 0,
        minutes: Math.round(time / 60),
      })
        .normalize()
        .toHuman({ listStyle: "narrow" })
        .replace(",", "");
    },
    chargeUsed(energy: number, battery: number): number {
      return Math.round((100 / battery) * energy);
    },
  },
});
</script>
