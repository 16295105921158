var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"pa-5",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"tertiary--text"},[_vm._v(" "+_vm._s(!!_vm.trip ? "Edit Your Optimised Trip" : "Plan a New Optimised Trip")+" ")]),(!_vm.trip)?_c('v-card-text',[_vm._v(" Plan a trip that optimises your stops into the most efficient order to travel from your origin to your destination visiting all your stops along the way. ")]):_vm._e(),_c('v-card-text',[(_vm.trip)?_c('v-card',{staticClass:"rounded-lg mb-5 px-5"},[_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Trip name","clearable":"","append-icon":_vm.tripName ? '' : 'mdi-pencil'},model:{value:(_vm.tripName),callback:function ($$v) {_vm.tripName=$$v},expression:"tripName"}})],1)],1):_vm._e(),_c('v-card',{staticClass:"primary white--text rounded-lg pa-5"},[_c('v-card-title',[_vm._v("Origin and Destination")]),_c('v-card-text',{staticClass:"pb-0"},[_c('AddressAutocompleteInput',{attrs:{"dark":"","label":_vm.roundTrip ? 'origin/destination' : 'origin',"loading":_vm.planning,"id":"origin","initialValue":{
            address: _vm.origin.address,
            waypoint: _vm.origin.coordinates,
          },"errorMsg":_vm.getAddressError(_vm.origin.localId)},on:{"update":_vm.handleAddressChange}}),(!_vm.roundTrip)?_c('AddressAutocompleteInput',{attrs:{"dark":"","label":"destination","loading":_vm.planning,"id":"destination","initialValue":{
            address: _vm.destination.address,
            waypoint: _vm.destination.coordinates,
          },"errorMsg":_vm.getAddressError(_vm.destination.localId)},on:{"update":_vm.handleAddressChange}}):_vm._e()],1),_c('v-card-actions',{staticClass:"px-5 pt-0"},[_c('v-switch',{attrs:{"inset":"","color":_vm.pwtDarkBlue,"dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.roundTrip ? "This is a round trip" : "Make this a round trip")+" ")])]},proxy:true}]),model:{value:(_vm.roundTrip),callback:function ($$v) {_vm.roundTrip=$$v},expression:"roundTrip"}})],1)],1)],1),_c('v-card-title',[_vm._v("Stops")]),_c('v-card-text',[_vm._l((_vm.additionalStops),function(stop,index){return [_c('v-row',{key:'additional-stop-' + index,attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"11"}},[_c('AddressAutocompleteInput',{attrs:{"loading":_vm.planning,"id":stop.localId,"initialValue":{
              address: stop.address,
              waypoint: stop.coordinates,
            },"errorMsg":_vm.getAddressError(stop.localId)},on:{"update":_vm.handleAddressChange}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removeStop(index)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)]}),_c('v-btn',{staticClass:"text-none rounded-lg",attrs:{"block":"","text":"","color":"primary"},on:{"click":_vm.addStop}},[_vm._v(" Add Another Stop ")])],2),(!!_vm.errorMsg)?_c('v-card-text',[(!!_vm.errorMsg)?_c('v-alert',{staticClass:"rounded-lg",attrs:{"type":"error","color":"error"}},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")]):_vm._e()],1):_vm._e(),_c('v-card-actions',[_c('v-btn',{staticClass:"rounded-lg text-none",attrs:{"color":"primary","block":"","loading":_vm.planning,"disabled":_vm.planning || !!_vm.addressErrors.length || !!_vm.errorMsg},on:{"click":_vm.planOptimizedTrip}},[_vm._v(" "+_vm._s(!!_vm.trip ? "Recalculate Trip" : "Optimise Trip")+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }