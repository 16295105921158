import {
  fetchRoutePlan,
  evnavRadarCall,
  fetchMultiRoutePlans,
  fetchNearbyChargers,
  fetchEnergyNeeded,
} from "../api/calls/ev_nav_calls";
import OptimiserDefaultData from "../data/optimiserDefaultData";
import type {
  EVNavCar,
  EVNavCharger,
  EVNavCompatibleConnector,
  EVNavLeg,
  EVNavRadarParams,
  EVNavRouteParams,
  EVNavRouteParamsLegacy,
  EVNavRoutePlan,
  EVNavStep,
  EVNavWaypoint,
  PlugsLegacy,
} from "../types/ev_nav_types";
import {
  type TripFrequency,
  type TripFuelType,
  type TripStats,
  type TripSaveData,
  type TripFallbackCause,
  TripFallbackCauseReasons,
} from "../types/trip_specific_types";
import generateUniqueLocalID from "../utils/generateUniqueLocalID";
import haversineDistance from "../utils/haversineDistance";
import { TripEVRoutePlan } from "./tripEVRoutePlan";
import TripLocation from "./tripLocation";
import TripRadarData from "./tripRadarData";
import Vehicle from "./vehicle";
import * as polyline from "@mapbox/polyline";
import { CalcVsType } from "../store/store_types";
import { Duration } from "luxon";
import to2DP from "../utils/to2DP";
import evNavDefaultData from "../data/eVNavDefaultData";
import hardcodedStandardEfficiencyBackup from "../data/standardEfficiencyDefaultData";
import Vue from "vue";
import {
  Directus_SavedRoutePlan,
  createSavedRoutePlan,
  updatedSavedRoutePlans,
} from "../api/calls/directus_calls/savedRoutePlans";
import { PartialObj } from "../types/generic_types";

export default class Trip {
  // -------------------------------------------------------------------- //
  // ------------------------- Global class state ----------------------- //
  // -------------------------------------------------------------------- //

  // global record of class instance ids this session.
  static usedIds: string[] = [];

  // -------------------------------------------------------------------- //
  // --------------------------- Savable State -------------------------- //
  // -------------------------------------------------------------------- //

  /** Directus `SavedTrip` collection record id.
   *
   * NOTE: will only be populated once a successful save response have been
   * received, however will be needed for every update after that.
   */
  directusId?: number | string;

  /** The parameters used to generate this trip.*/
  parameters?: EVNavRouteParams;

  /** Id of the vehicle used in the trip.
   *
   * NOTE: will match the directus `Vehicles` collection record id.
   */
  vehicleId?: number;

  /** The locations stopped at along the trip including the starting
   * location (always at index 0) and the destination location
   * (always at the last index).*/
  locations: TripLocation[];

  /** Fuel type of the vehicle this was planned in. */
  tripFuelType?: TripFuelType;

  /** The total distance traveled in this trip in km.
   *
   * NOTE: this is for calculation of 5 year savings and will need to be updated every
   * time the trip is recalculated.
   */
  totalDistance?: number;

  /** The total estimated energy consumed for this trip in kWh. */
  totalEnergy?: number;

  /** The location id of the user selected most important set time. e.g. leaving at X is
   * most important so will be `location-start` etc. */
  primaryTimeLocation?: string;

  /** The reoccurring frequency of the trip if it has one.
   *
   * NOTE: trips with a reoccurring frequency are used in the 5 year savings. A one off
   * trip with no reoccurring frequency will not be used for 5 year savings calculations.
   */
  frequency?: TripFrequency;

  // -------------------------------------------------------------------- //
  // ------------------------- Session only State ----------------------- //
  // -------------------------------------------------------------------- //

  /** local unique id. */
  localId: string;

  /** the whole `Vehicle` object of the vehicle used to plan this trip. */
  vehicle?: Vehicle;

  /** Data for a successfully planned EV trip. */
  evTripData?: TripEVRoutePlan[];

  /** Data for the longest leap including polyline. */
  longestLeapData?: TripRadarData;

  /** Data for chargers along the route from radar call. */
  radarData?: TripRadarData;

  /** Data for a fallback alternative EV trip when planning was unsuccessful. */
  fallbackTripData?: TripRadarData;

  /** The reason the EV trip failed and the fallback was used. */
  fallbackCause?: TripFallbackCause;

  /** Data for a successfully planned ICE trip. */
  ICETripData?: TripRadarData;

  /** `Min` level the `State Of Charge` is allowed to reach at any point during the trip */
  stateOfChargeMin: number;

  /** `Max` level the `State Of Charge` is allowed to reach at any point during the trip */
  stateOfChargeMax: number;

  /** `State Of Charge` required at the `End` of the trip */
  stateOfChargeEnd: number;

  /** `State Of Charge` at the `Start` of the trip */
  stateOfChargeAct: number;

  /** the optional display name for the trip */
  name?: string;

  /** The total estimated cost of public charging for this trip in NZD.
   *
   * NOTE: failed trips and ICE trips will not have a value.
   */
  totalPublicChargingCost?: number;

  /** The total estimated cost of private charging for this trip in NZD.
   *
   * NOTE: failed trips and ICE trips will not have a value.
   */
  totalPrivateChargingCost?: number;

  /** Display name of the charger where the longest leap starts. */
  longestLeapStart?: string;

  /** Display name of the charger where the longest leap ends. */
  longestLeapEnd?: string;

  /** The current status of the trip. */
  status?: "ICE-SUCCESS" | "FAILED" | "FALLBACK" | "EV-SUCCESS";

  /** extra weight to include in planning if any. */
  extraWeight?: number;

  /** Networks to limit the trip include in planning if any. */
  userSpecifiedNetworks?: string[];

  /** flag indicating if this trip is from a legacy file structure that needs converting and saving as a new version. */
  needsConverting: boolean;

  // -------------------------------------------------------------------- //
  // --------------------------- Constructor ---------------------------- //
  // -------------------------------------------------------------------- //

  constructor({
    localId = undefined,
    directusId = undefined,
    locations = [],
    parameters = undefined,
    vehicleId = undefined,
    tripFuelType = undefined,
    totalEnergy = undefined,
    totalDistance = undefined,
    frequency = undefined,
    primaryTimeLocation = undefined,
    stateOfChargeMin = OptimiserDefaultData.SOCMin,
    stateOfChargeMax = OptimiserDefaultData.SOCMax,
    stateOfChargeEnd = OptimiserDefaultData.SOCEnd,
    stateOfChargeAct = OptimiserDefaultData.SOCAct,
    name = undefined,
    totalPublicChargingCost = undefined,
    totalPrivateChargingCost = undefined,
    extraWeight = undefined,
    userSpecifiedNetworks = undefined,
    needsConverting = false,
  }: {
    localId?: string;
    directusId?: number | string;
    locations?: TripLocation[];
    parameters?: EVNavRouteParams;
    vehicleId?: number;
    tripFuelType?: TripFuelType;
    totalEnergy?: number;
    totalDistance?: number;
    frequency?: TripFrequency;
    primaryTimeLocation?: string;
    stateOfChargeMin?: number;
    stateOfChargeMax?: number;
    stateOfChargeEnd?: number;
    stateOfChargeAct?: number;
    name?: string;
    totalPublicChargingCost?: number;
    totalPrivateChargingCost?: number;
    extraWeight?: number;
    userSpecifiedNetworks?: string[];
    needsConverting?: boolean;
  }) {
    this.localId = localId ?? generateUniqueLocalID(Trip.usedIds, "trip");
    this.directusId = directusId;
    this.locations = locations;
    this.parameters = parameters;
    this.vehicleId = vehicleId;
    this.tripFuelType = tripFuelType;
    this.totalEnergy = totalEnergy;
    this.totalDistance = totalDistance;
    this.frequency = frequency;
    this.primaryTimeLocation = primaryTimeLocation;
    this.stateOfChargeMax = stateOfChargeMax;
    this.stateOfChargeMin = stateOfChargeMin;
    this.stateOfChargeEnd = stateOfChargeEnd;
    this.stateOfChargeAct = stateOfChargeAct;
    this.name = name;
    this.totalPrivateChargingCost = totalPrivateChargingCost;
    this.totalPublicChargingCost = totalPublicChargingCost;
    this.extraWeight = extraWeight;
    this.userSpecifiedNetworks = userSpecifiedNetworks;
    this.needsConverting = needsConverting;
    // add id to list of used unique ids
    Trip.usedIds.push(this.localId);
  }

  /**
   * Takes a legacy saved trip json data and returns a new populated `Trip` class object from the details.
   * @param data a `TripSaveData` object.
   * @returns a new `Trip` class object populated with details form the saved trip.
   */
  static convertFromLegacySavedData(data: TripSaveData): Trip {
    return new Trip({
      directusId: data.directusID,
      locations: data.locations.map((location) =>
        TripLocation.fromLegacySavedData(location)
      ),
      parameters: Trip.convertLegacyParamsToCurrentParams(data.parameters),
      vehicleId: data.vehicleID,
      tripFuelType: data.tripFuelType,
      totalEnergy: data.totalEnergy,
      totalDistance: data.totalEVDistance,
      frequency: data.frequency,
      primaryTimeLocation: data.primaryTimeLocation,
      stateOfChargeMax: data.parameters.SOCMax,
      stateOfChargeMin: data.parameters.SOCMin,
      name: data.name,
      totalPrivateChargingCost: data.totalPrivateChargingCost,
      totalPublicChargingCost: data.totalPublicChargingCost,
      needsConverting: true,
    });
  }

  /**
   * Takes a `Saved_Route_Plans` directus collection record and returns a new populated `Trip` class object from the details.
   * @param data a `Directus_SavedRoutePlan` object.
   * @returns a new `Trip` class object populated with details form the saved trip.
   */
  static fromDirectusData(data: Directus_SavedRoutePlan): Trip {
    return new Trip({
      directusId: data.id,
      locations: data.Locations?.map((location) =>
        TripLocation.fromDirectusData(location)
      ),
      parameters: data.EV_Nav_Parameters ?? undefined,
      vehicleId: data.Vehicle ?? undefined,
      tripFuelType: data.Engin_Type ?? undefined,
      totalEnergy: data.Total_Energy ?? undefined,
      totalDistance: data.Total_Distance ?? undefined,
      frequency: data.Trip_Frequency ?? undefined,
      primaryTimeLocation: data.Primary_Time_Location ?? undefined,
      stateOfChargeMax: data.EV_Nav_Parameters?.SOCMax,
      stateOfChargeMin: data.EV_Nav_Parameters?.SOCMin,
      name: data.Name ?? undefined,
      totalPrivateChargingCost: data.Total_Private_Charging_Cost ?? undefined,
      totalPublicChargingCost: data.Total_Public_Charging_Cost ?? undefined,
    });
  }

  /**
   * Helper function taking a saved trip `parameters` property and ensuring it is converted into the current versions format.
   *
   * @param params either a `EVNavRouteParams` or `EVNavRouteParamsLegacy` object
   * @returns a `EVNavRouteParams` object.
   */
  static convertLegacyParamsToCurrentParams(
    params: EVNavRouteParams | EVNavRouteParamsLegacy
  ): EVNavRouteParams {
    // Check if fits current format.
    // eslint-disable-next-line no-prototype-builtins
    if (params.hasOwnProperty("Vehicle")) return params as EVNavRouteParams; // ASSUMES: is current as the legacy Car param was renamed to Vehicle with a different data structure in the current version.
    // convert legacy format into current format.
    const formattedCompatibleConnectors: EVNavCompatibleConnector[] = [];
    (params as EVNavRouteParamsLegacy).Car.plugs?.forEach((plug) => {
      const connector = Trip.convertLegacyPlugToCompatibleConnectors(plug);
      if (connector) formattedCompatibleConnectors.push(connector);
    });
    const newVehicleFormat: EVNavCar = {
      Id:
        hardcodedStandardEfficiencyBackup.find(
          (data) =>
            data.legacyId === (params as EVNavRouteParamsLegacy).Car.ModelID
        )?.id ?? OptimiserDefaultData.modelId,
      AccelerationAdjustment: (params as EVNavRouteParamsLegacy).Car
        .AccelAdjustment,
      SpeedAdjustment: (params as EVNavRouteParamsLegacy).Car.SpeedAdjustment,
      CompatibleConnectors: formattedCompatibleConnectors,
      Mass: (params as EVNavRouteParamsLegacy).Weight,
    };
    return {
      Battery: params.Battery,
      IncludePolyline: params.IncludePolyline,
      Method: params.Method,
      SOCAct: params.SOCAct,
      SOCEnd: params.SOCEnd,
      SOCMax: params.SOCMax,
      SOCMin: params.SOCMin,
      Vehicle: newVehicleFormat,
      Waypoints: params.Waypoints,
      Name: params.Name,
      Networks: params.Networks,
    };
  }

  /**
   * Helper function taking a legacy plug string and return the currently expected object.
   *
   * @param plug a `PlugsLegacy` string.
   * @returns a `EVNavPlug` if convertible or undefined if not.
   */
  static convertLegacyPlugToCompatibleConnectors(
    plug: PlugsLegacy
  ): EVNavCompatibleConnector | undefined {
    switch (plug) {
      case "Blue Commando":
        return {
          Format: "SOCKET",
          Standard: "IEC_60309_2_single_16",
          PowerType: "AC_1_PHASE",
          MaxElectricPower: null,
        };
      case "CHAdeMO":
        return {
          Format: "CABLE",
          Standard: "CHADEMO",
          PowerType: "DC",
          MaxElectricPower: null,
        };
      case "Tesla Supercharger":
        return {
          Format: "CABLE",
          Standard: "TESLA_S",
          PowerType: "DC",
          MaxElectricPower: null,
        };
      case "Type 1 - Socketed":
        return {
          Format: "SOCKET",
          Standard: "IEC_62196_T1",
          PowerType: "AC_1_PHASE",
          MaxElectricPower: null,
        };
      case "Type 1 CCS":
        return {
          Format: "CABLE",
          Standard: "IEC_62196_T1_COMBO",
          PowerType: "DC",
          MaxElectricPower: null,
        };
      case "Type 1 Tethered":
        return {
          Format: "CABLE",
          Standard: "IEC_62196_T1",
          PowerType: "AC_1_PHASE",
          MaxElectricPower: null,
        };
      case "Type 2 CCS":
        return {
          Format: "CABLE",
          Standard: "IEC_62196_T2_COMBO",
          MaxElectricPower: null,
          PowerType: "DC",
        };
      case "Type 2 Socketed":
        return {
          Format: "SOCKET",
          Standard: "IEC_62196_T2",
          MaxElectricPower: null,
          PowerType: "AC_1_PHASE",
        };
      case "Type 2 Tethered":
        return {
          Format: "CABLE",
          MaxElectricPower: null,
          PowerType: "AC_1_PHASE",
          Standard: "IEC_62196_T2",
        };
      case "Wall Plug (NZ & Australia)":
        return {
          Format: "SOCKET",
          MaxElectricPower: null,
          PowerType: "AC_1_PHASE",
          Standard: "DOMESTIC_I",
        };
      default:
        return;
    }
  }

  // -------------------------------------------------------------------- //
  // ------------------------------ Getters ----------------------------- //
  // -------------------------------------------------------------------- //

  /** Charger DB ids of chargers selected as charging stops.
   *
   * NOTE: can be empty if trip can be completed on a
   * single charge.
   */
  public get chargingStopCDBIDs(): string[] {
    const tempArray: string[] = [];
    if (this.evTripData?.length) {
      const flatArray = this.evTripData.flatMap((plan) =>
        plan.steps.flatMap((step) => step.Charger?.CDBID)
      );
      flatArray.forEach((CDBID) => {
        if (CDBID) tempArray.push(CDBID);
      });
    }
    return tempArray;
  }

  /** Charger DB ids of chargers with in 5km of the planned trips polyline.
   *
   * NOTE: can be empty if there are no charges within the 5km range.
   */
  public get chargersAlongRouteCDBIDs(): string[] {
    const tempArray: string[] = [];
    if (this.radarData) {
      this.radarData.chargers.forEach((charger) => {
        if (charger.CDBID) tempArray.push(charger.CDBID);
      });
    }
    return tempArray;
  }

  /** Returns the full `TripLocation` object if there is one. */
  public get primaryTimeLocationData(): TripLocation | undefined {
    return this.locations.find(
      (location) => location.localId === this.primaryTimeLocation
    );
  }

  /** Returns the total time for the trip in seconds. */
  public get totalTime(): number | undefined {
    let tempTotalTime: number | undefined = undefined;
    if (this.evTripData) {
      this.evTripData.forEach((plan) => {
        if (!tempTotalTime) {
          tempTotalTime = plan.time;
        } else {
          tempTotalTime += plan.time;
        }
      });
    }
    if (this.fallbackTripData) tempTotalTime = this.fallbackTripData.travelTime;
    if (this.ICETripData) tempTotalTime = this.ICETripData.travelTime;
    return tempTotalTime;
  }

  public get displayableTotalTime(): string {
    return (
      Duration.fromObject({
        hours: 0,
        minutes: Math.round((this.totalTime ?? 0) / 60),
      })
        .normalize()
        .toHuman({ listStyle: "long" })
        .replace(",", "") + " travel time"
    );
  }

  // -------------------------------------------------------------------- //
  // --------------------------- Public Methods ------------------------- //
  // -------------------------------------------------------------------- //

  /**
   *
   * @param param0
   * @returns
   */
  public getTripStats({
    calcVs,
    petrolKmPerLitre,
    petrolCostPerLitre,
    dieselKmPerLitre,
    dieselCostPerLitre,
    kWhCostHome,
  }: {
    calcVs: CalcVsType;
    petrolKmPerLitre: number;
    petrolCostPerLitre: number;
    dieselKmPerLitre: number;
    dieselCostPerLitre: number;
    kWhCostHome: number;
  }): TripStats | undefined {
    // create and return tripStats obj
    return {
      avoidedCO2: this.calcAvoidedCO2(
        calcVs,
        petrolKmPerLitre,
        dieselKmPerLitre
      ),
      drivingKms: this.calcDrivingKms(),
      drivingTime: this.calcDrivingTime(),
      chargingTime: this.calcChargingTime(),
      chargeKWh: this.calcChargeKWh(),
      totalEnergyUsed: this.calcTotalEnergy(),
      emittedCO2: this.calcEmittedCO2(
        calcVs,
        petrolKmPerLitre,
        dieselKmPerLitre
      ),
      publicChargingCost: this.calcPublicChargingCost(),
      privateChargingCost: this.calcPrivateChargingCost(kWhCostHome),
      battery: this.calcBattery(),
      fuelCost: this.calcFuelCost(
        calcVs,
        petrolKmPerLitre,
        petrolCostPerLitre,
        dieselKmPerLitre,
        dieselCostPerLitre
      ),
      totalTime: this.calcTotalTime(),
      stayDuration: this.calcStayDuration(),
    };
  }

  public async planRoute(
    isReCalc = false,
    relativeSpeed: undefined | number = undefined
  ): Promise<"ICE-SUCCESS" | "FAILED" | "FALLBACK" | "EV-SUCCESS"> {
    // guard clauses
    if (!isReCalc && this.evTripData) {
      // ASSUMES: trip has already been calculated this session and does not need to be recalculated unless intentionally done with new parameters.
      return "EV-SUCCESS";
    }
    // attempt to compile parameters.
    this.compileParameters(isReCalc, relativeSpeed);
    if (!this.parameters) {
      // trip must have parameters to call routing api endpoint.
      return "FAILED";
    }
    if (this.locations.length < 2) {
      // trip must have at least a starting location and a destination location to plan a route.
      return "FAILED";
    }

    // check if is `ELECTRIC` or `ICE`
    if (this.tripFuelType === "ICE") {
      // Plan route for a non-EV.

      // ASSUMES: is an ICE (internal combustion engin) reliant vehicle or a hybrid.
      // ASSUMES: we will have no network added that matches "I am an ICE I don't care".
      const params: EVNavRadarParams = {
        ...this.parameters,
        Range: 0,
      };
      params.Networks = ["I am an ICE I don't care"];
      const radarRes = await evnavRadarCall(params);
      if (!radarRes) {
        this.status = "FAILED";
        return "FAILED";
      }
      this.ICETripData = TripRadarData.fromEVNavData(radarRes);
      this.status = "ICE-SUCCESS";
      this.totalDistance = radarRes.Distance;
      return "ICE-SUCCESS";
    }

    // Plan route for an EV.

    // check if trip needs to be split into multiple route plans.
    const needsToBeSplit = this.needsToBeSplit();
    let legTripPlans: EVNavRoutePlan[] = [];

    if (needsToBeSplit) {
      // plan for multi route plan trip.

      let legWaypoints: EVNavWaypoint[][] = [];
      // split trip planning data
      const legLocations = this.getLegLocations();
      const legStartingSOC = this.getLegStartingSOC(
        legLocations,
        this.parameters.SOCAct
      );
      legWaypoints = this.getLegWaypoints(legLocations);

      // compile multi route params
      const multiParams: EVNavRouteParams[] = [];
      // fetch trip data for each leg

      for (let index = 0; index < legLocations.length; index++) {
        multiParams.push({
          ...this.parameters,
          SOCAct: legStartingSOC[index],
          Waypoints: legWaypoints[index],
          Name: `leg-${index}`,
        });
      }

      // fetch planned route.
      const data = await fetchMultiRoutePlans(multiParams);

      // check if query returned a successful response.
      if ((data as EVNavRoutePlan[]).every((plan) => plan.Status === "OK")) {
        legTripPlans = data;
      } else {
        // If not plan fallback trip.
        const fallback = await evnavRadarCall({
          ...this.parameters,
          Range: 100000,
        });
        if (!fallback) {
          this.status = "FAILED";
          return "FAILED";
        }
        this.fallbackTripData = TripRadarData.fromEVNavData(fallback);
        this.status = "FALLBACK";
        this.totalDistance = fallback.Distance;
        return "FALLBACK";
      }
    } else {
      // plan for single route plan trip.
      const data = await fetchRoutePlan(this.parameters);
      // check if query returned a successful response.
      if (data?.Status === "OK") {
        legTripPlans.push(data);
      } else {
        // If not plan fallback trip.
        const fallback = await evnavRadarCall({
          ...this.parameters,
          Range: 100000,
        });
        if (!fallback) {
          this.status = "FAILED";
          return "FAILED";
        }
        this.fallbackTripData = TripRadarData.fromEVNavData(fallback);
        this.status = "FALLBACK";
        this.totalDistance = fallback.Distance;
        return "FALLBACK";
      }
    }

    let distance = 0;
    let energy = 0;
    let publicChargingCost = 0;
    legTripPlans.forEach((plan) => {
      distance += plan.Distance;
      energy += plan.Energy;
      publicChargingCost += plan.Cost;
    });
    this.totalDistance = distance;
    this.totalEnergy = energy;
    this.evTripData = legTripPlans.map((plan) =>
      TripEVRoutePlan.fromEVNavData(plan)
    );
    this.status = "EV-SUCCESS";
    this.tripFuelType = "ELECTRIC";
    this.totalPublicChargingCost = publicChargingCost;
    return "EV-SUCCESS";
  }

  /** Finds and returns the most probable cause for this EV trip to have failed and resulted in a fallback trip. */
  public async findCause(): Promise<string> {
    // check if has already been checked this session
    if (this.fallbackCause) return this.fallbackCause.displayStr;

    const startingLocation = this.locations[0];
    const destinationLocation = this.locations[this.locations.length - 1];

    if (
      this.parameters?.Battery &&
      this.parameters.Vehicle.Id &&
      startingLocation &&
      destinationLocation
    ) {
      // Check if min SOC is higher than actual SOC.
      if (this.parameters.SOCAct < this.parameters.SOCMin) {
        const displayString = `We noticed your current charge is lower than you minimum allowed 
        charge. This is preventing us from being able to route you anywhere without ignoring 
        your settings. Please consider either changing your settings, or if able charging before 
        starting this trip.`;

        this.fallbackCause = {
          displayStr: displayString,
          reason: TripFallbackCauseReasons.ACT_SOC_BELOW_MIN_SOC,
        };
        return displayString;
      }

      // Check if cant reach the first charger.

      const firstLocationNearbyRes = await fetchNearbyChargers({
        Battery: this.parameters.Battery,
        Vehicle: {
          Id: this.parameters.Vehicle.Id,
          CompatibleConnectors: this.parameters.Vehicle.CompatibleConnectors,
        },
        Location: {
          Latitude: startingLocation.coordinates.latitude,
          Longitude: startingLocation.coordinates.longitude,
        },
        SOCAct: 1,
        SOCMin: this.parameters.SOCMin,
      });

      if (firstLocationNearbyRes && !firstLocationNearbyRes.length) {
        // CASE: No compatible charges reachable with a full charge of your location.

        const displayString = `There are no known compatible chargers with in a full charge of 
        your current location. Check your vehicles connectors to ensure all physical 
        connectors, charging cables and adaptors are taken into account and try again.`;

        this.fallbackCause = {
          displayStr: displayString,
          reason: TripFallbackCauseReasons.NO_CHARGER_IN_RANGE_OF_START,
        };
        return displayString;
      }

      if (firstLocationNearbyRes?.length) {
        // Calculate energy needed to reach the first charger.
        const energyToFirst: number =
          firstLocationNearbyRes[0].Energy +
          this.parameters.Battery * this.parameters.SOCMin;
        const percentToFirst: number =
          (100 / this.parameters.Battery) * energyToFirst + 1;
        if (percentToFirst > this.parameters.SOCAct * 100) {
          // CASE: percentage of charge needed to reach first charge plus one to avoid
          // rounding errors is grater than the selected EVs starting percentage of
          // charge.

          const displayString = `You need at least ${Math.round(
            percentToFirst
          )}% to 
          make it to your closest fast charger at ${
            firstLocationNearbyRes[0].Charger.ParkName
          }`;

          this.fallbackCause = {
            displayStr: displayString,
            reason:
              TripFallbackCauseReasons.ACT_SOC_TO_LOW_TO_REACH_FIRST_CHARGER,
          };
          return displayString;
        }
      }

      // Check if cant make it through the longest leap
      if (!this.longestLeapData) await this.getLongestLeapData();

      if (this.longestLeapData) {
        // Calculate energy needed to cross through the longest leap.
        const percentForLongestLeap: number =
          // ASSUMES: if one of the above statuses was returned that longestLeapData is
          // present.
          (100 / this.parameters.Battery) * this.longestLeapData.energy;
        if (percentForLongestLeap > 100) {
          // CASE: the longest leap of the trip requires more energy than the selected
          // EV can hold.
          // NOTE: this could mean there are other points in the trip that are shorter than
          // the longest leap that the selected EV still is unable to make.

          const displayString = `We couldn't plan this trip because it looks like you'll need 
          ${Math.round(percentForLongestLeap)}% to travel from ${
            this.longestLeapStart ?? "unknown charger"
          } 
          to ${
            this.longestLeapEnd ?? "unknown charger"
          }, which is more than your current vehicle choice 
          and settings would allow for. Please change your settings or choose a different vehicle 
          and try again.`;

          this.fallbackCause = {
            displayStr: displayString,
            reason: TripFallbackCauseReasons.LONGEST_LEAP_MORE_THAN_FULL_CHARGE,
          };
          return displayString;
        }
      }

      // check if cant reach destination from last charger
      let lastCharger: EVNavCharger | undefined = undefined;

      if (this.longestLeapData?.chargers && this.fallbackTripData) {
        lastCharger = this.findClosestCharger(
          this.collateEVNavWaypoints([destinationLocation])[0],
          this.collateEVNavWaypoints([startingLocation])[0],
          this.longestLeapData.chargers,
          this.fallbackTripData.distance
        );
      }

      if (lastCharger) {
        // plan last leg in correct direction to have correct elevation taken into account
        const params: EVNavRouteParams = {
          ...this.parameters,
          IncludePolyline: false,
          Waypoints: [
            {
              Latitude: lastCharger.Location.Latitude,
              Longitude: lastCharger.Location.Longitude,
            },
            ...this.collateEVNavWaypoints([destinationLocation]),
          ],
        };

        // Calculate energy needed to reach the final destination from the last charger.
        const lastLegData = await fetchEnergyNeeded(params);

        if (lastLegData) {
          const energyFromLast: number =
            lastLegData.Energy +
            this.parameters.Battery * this.parameters.SOCMin;
          const percentFromLast: number =
            (100 / this.parameters.Battery) * energyFromLast + 1;
          if (percentFromLast > this.parameters.SOCAct * 100) {
            // CASE: percentage of charge needed to reach destination from the last charger plus one to avoid
            // rounding errors is grater than the selected EVs maximum percentage of
            // charge.

            const displayString = `We couldn't plan this trip because it looks like you'll need 
            ${Math.round(percentFromLast)}% to travel from ${
              lastCharger.ParkName
            } to your 
            final destination ${
              this.locations[this.locations.length - 1]
            }, which is more than 
            your current vehicle choice and settings would allow for. Please change your 
            settings or choose a different vehicle and try again.`;

            this.fallbackCause = {
              displayStr: displayString,
              reason:
                TripFallbackCauseReasons.NO_CHARGER_IN_RANGE_OF_DESTINATION,
            };
            return displayString;
          }
        }
      }
    }
    // failed to figure out a reason

    Vue.prototype.$Countly.q.push([
      "recordError",
      { stack: "Unknown reason for trip planning failure" },
      true,
      {
        type: "Trip planning",
        trip: JSON.stringify(this.parameters),
      },
    ]);

    const displayString = `Whoops! Something went wrong and we are unable to 
    find a reason for why this trip did not work. Feedback has been sent to 
    our development team to improve our service.`;

    this.fallbackCause = {
      displayStr: displayString,
      reason: TripFallbackCauseReasons.UNKNOWN_REASON,
    };
    return displayString;
  }

  /** Get the radar data for the trip. */
  public async getRadarData(
    range: number | undefined = undefined,
    calcLongestLeap = false,
    includePolyline = false
  ) {
    // Only fetch data if not already fetched this session.
    if (this.radarData) return "SUCCESS";
    // Parameters are not null guard clause.
    if (!this.parameters) return "FAILED";
    // Fetch data from EV Nav.
    const resData = await evnavRadarCall({
      ...this.parameters,
      Range: this.getRange(range),
      CalculateLongestLeap: calcLongestLeap,
      IncludePolyline: includePolyline,
    });
    // Check EV Nav response status.
    if (resData) {
      this.radarData = TripRadarData.fromEVNavData(resData);
      return "SUCCESS";
    }
    return "FAILED";
  }

  /** Get the longest leap leg trip data for this trip. */
  private async getLongestLeapData(
    isReCalc = false
  ): Promise<"SUCCESS" | "FAILED"> {
    if (!isReCalc && this.longestLeapData) return "SUCCESS";

    const resData = await evnavRadarCall({
      ...this.parameters,
      Range: 100000,
      CalculateLongestLeap: true,
      IncludePolyline: false,
    });
    if (resData) {
      const filteredChargers: EVNavCharger[] = [];
      resData.Chargers.forEach((charger) => {
        if (
          !filteredChargers.find(
            (filteredCharger) => charger.CDBID === filteredCharger.CDBID
          )
        ) {
          filteredChargers.push(charger);
        }
      });
      const chargerStart = resData.Chargers?.find(
        (c) => c.ID === resData.LongestLeap?.From
      );
      const chargerEnd = resData.Chargers?.find(
        (c) => c.ID === resData.LongestLeap?.To
      );
      if (!chargerStart || !chargerEnd) return "FAILED";
      this.longestLeapStart = chargerStart.ParkName;
      this.longestLeapEnd = chargerEnd.ParkName;
      const longestLeapRes = await evnavRadarCall({
        ...this.parameters,
        Waypoints: [chargerStart.Location, chargerEnd.Location],
        Range: 0,
        CalculateLongestLeap: false,
        IncludePolyline: true,
      });
      if (longestLeapRes) {
        this.longestLeapData = TripRadarData.fromEVNavData(longestLeapRes);
        this.longestLeapData.chargers = filteredChargers;
        return "SUCCESS";
      }
    }
    return "FAILED";
  }

  /** Adds a vehicle to the trip and also updates vehicle references to point at the same vehicle. */
  public setVehicle(vehicle: Vehicle) {
    this.vehicle = vehicle;
    this.vehicleId = vehicle.directusId;
  }

  /** Returns this trips polyline points as lat/lng format for use by leaflet. */
  public getPolylinePoints(): [number, number][][] {
    if (this.status === "EV-SUCCESS" && this.evTripData) {
      const steps = this.evTripData.flatMap((plan) => plan.steps);
      return this.convertStepToPolyline(steps);
    }
    if (
      this.status === "ICE-SUCCESS" &&
      this.ICETripData &&
      this.ICETripData.legs
    ) {
      return this.convertStepToPolyline(this.ICETripData.legs);
    }
    if (
      this.status === "FALLBACK" &&
      this.fallbackTripData &&
      this.fallbackTripData.legs
    ) {
      return this.convertStepToPolyline(this.fallbackTripData.legs);
    }
    return [];
  }

  /** Returns this trips fallback longest leap polyline. */
  public getFallbackLongestLeapPoints(): [number, number][][] {
    // ASSUMES: is a fallback trip and has had the cause calculated.
    // Note: causes other than you can't make longest leap should not
    // display this polyline.
    if (
      this.status !== "FALLBACK" ||
      !this.longestLeapData ||
      !this.longestLeapData.legs
    )
      return [];
    return this.convertStepToPolyline(this.longestLeapData.legs);
  }

  /** Clears EV Nav responses and rebuilds parameters, ready to be planned again. */
  public prepForRecalculation() {
    this.evTripData = undefined;
    this.ICETripData = undefined;
    this.fallbackTripData = undefined;
    this.fallbackCause = undefined;
    this.longestLeapData = undefined;
    this.longestLeapEnd = undefined;
    this.longestLeapStart = undefined;
    this.status = undefined;
    this.totalPrivateChargingCost = undefined;
    this.totalPublicChargingCost = undefined;
    this.totalEnergy = undefined;
    this.totalDistance = undefined;
    this.compileParameters(true);
  }

  /**
   * Saves the individual trip to cloud storage.
   */
  public async saveTrip(): Promise<"failed" | "ok"> {
    // format trip for saving
    const saveData = this.prepTripForSave();
    // guard clause exiting early if trip can't be formatted.
    if (!saveData) return "failed";

    // check if trip has been saved before and needs to be overwritten.
    if (this.directusId && !this.needsConverting) {
      // overwrite trip in directus.
      const saveOperationRes = await updatedSavedRoutePlans(
        this.directusId,
        saveData
      );
      return saveOperationRes ? "ok" : "failed";
    }

    // save trip to directus.
    const saveOperationRes = await createSavedRoutePlan(saveData);
    // update Directus id
    if (saveOperationRes) {
      // updated local state
      this.directusId = saveOperationRes.id;
      this.needsConverting = false;
      // exit with success
      return "ok";
    }
    // return operation failed if reached this point and not exited with a success.
    return "failed";
  }

  // -------------------------------------------------------------------- //
  // -------------------------- Private Methods ------------------------- //
  // -------------------------------------------------------------------- //

  // formats each location to a list of `EVNavWaypoint` objects for route planning.
  private collateEVNavWaypoints(locations: TripLocation[]): EVNavWaypoint[] {
    return locations.map((tripLocation) =>
      tripLocation.convertToEVNavWaypoint()
    );
  }

  // compiles an `EVNavRouteParams` object if need and asians it to `this.parameters`.
  private compileParameters(
    isReCalc: boolean,
    relativeSpeed: undefined | number = undefined
  ): void {
    // Check if parameters are in legacy ev nav format.
    // NOTE: if `isReCalc` this step is skipped to force recompiling of all parameters.
    if (
      !isReCalc &&
      this.parameters?.Vehicle.CompatibleConnectors?.length &&
      typeof this.parameters.Vehicle.CompatibleConnectors[0] === "string"
    ) {
      // convert to new ev nav format
      this.parameters = {
        ...this.parameters,
        Vehicle: this.vehicle
          ? relativeSpeed
            ? {
                ...this.vehicle.routePlanningCarParam,
                SpeedAdjustment: relativeSpeed,
              }
            : this.vehicle.routePlanningCarParam
          : { Id: OptimiserDefaultData.modelId },
      };
      return;
    }

    // Check if parameters are already present.
    // NOTE: if `isReCalc` this step is skipped to force recompiling of all parameters.
    if (!isReCalc && this.parameters) return;

    this.parameters = {
      Battery: this.vehicle?.totalBatteryKWh() ?? OptimiserDefaultData.battery,
      Waypoints: this.collateEVNavWaypoints(this.locations),
      IncludePolyline: true,
      Method: 0,
      SOCMin: this.stateOfChargeMin,
      SOCMax: this.stateOfChargeMax,
      SOCEnd: this.stateOfChargeEnd,
      SOCAct: this.stateOfChargeAct,
      Vehicle: this.vehicle
        ? relativeSpeed
          ? {
              ...this.vehicle.routePlanningCarParam,
              SpeedAdjustment: relativeSpeed,
            }
          : this.vehicle.routePlanningCarParam
        : { Id: OptimiserDefaultData.modelId },
    };

    if (this.extraWeight) {
      this.parameters.Vehicle.Mass =
        this.extraWeight +
        (this.vehicle?.evModel?.mass ?? evNavDefaultData.Mass);
    }

    if (this.userSpecifiedNetworks) {
      this.parameters.Networks = this.userSpecifiedNetworks;
    }
  }

  /**
   * Checks if the trip needs to be split into multiple route plans.
   *
   * @returns true = needs to be split, false = can be processed in a single route plan.
   */
  private needsToBeSplit(): boolean {
    let splitThisTrip = false;

    // check trip has additional waypoints above the min 2 expected.
    // Assumes: a direct trip between the starting location and the destination location will be a single leg.
    if (this.locations.length <= 2) return splitThisTrip;

    // check for charge here flag
    this.locations.forEach((location) => {
      if (location.chargeHere) {
        splitThisTrip = true;
      }
    });

    return splitThisTrip;
  }

  /**
   * Compiles locations for each leg.
   *
   * @param locations an array of locations visited in this trip in order of visit.
   * @returns an array containing and array for each leg populated with that legs location objects in order of visit.
   */
  private getLegLocations(): TripLocation[][] {
    const legLocations: TripLocation[][] = [];

    // find indexes of starting locations for each leg
    const startingLocationsIndexes = [0];
    this.locations.forEach((location, index) => {
      location.chargeHere && startingLocationsIndexes.push(index);
    });

    // compile legs
    startingLocationsIndexes.forEach((locationsIndex, index) => {
      const isLastStartingLocation =
        index + 1 === startingLocationsIndexes.length;
      let tempArray: TripLocation[] = [];

      // if last starting location map this leg to the final destination.
      if (isLastStartingLocation) {
        tempArray = this.locations.slice(locationsIndex);
      }

      // else map this leg to have a destination of the next starting destination.
      else {
        tempArray = this.locations.slice(
          locationsIndex,
          startingLocationsIndexes[index + 1] + 1
        );
      }

      // push temp array to legLocations
      legLocations.push(tempArray);
    });

    return legLocations;
  }

  /**
   * Compiles an array of starting chargers for each leg.
   *
   * @param legLocations an array containing and array for each leg populated with that legs location objects in order of visit.
   * @param startingCharge the current charge at the start of the trip. in the format of a float representation of a % e.g 80% = 0.8.
   * @returns an array of starting chargers for each leg in order of visit.
   */
  private getLegStartingSOC(
    legLocations: TripLocation[][],
    startingCharge: number
  ): number[] {
    const legStartingSOC: number[] = [];

    // get SOC act for each leg
    legLocations.forEach((leg, index) => {
      // if the trips starting location use the trips starting charge
      if (index === 0) {
        legStartingSOC.push(startingCharge);
      }
      // else use the user provided SOC they will charge to at this stop
      else {
        const SOC = leg[0].stateOfChargeAfterCharging || startingCharge;
        legStartingSOC.push(SOC);
      }
    });

    return legStartingSOC;
  }

  /**
   * Compiles waypoints for each leg of a trip.
   *
   * @param legLocations an array containing and array for each leg populated with that legs location objects in order of visit.
   * @returns an array containing and array for each leg populated with that legs waypoints for use in route planning API calls.
   */
  private getLegWaypoints(legLocations: TripLocation[][]): EVNavWaypoint[][] {
    const legWaypoints: EVNavWaypoint[][] = [];

    // compile waypoints for each leg
    legLocations.forEach((leg) => {
      legWaypoints.push(this.collateEVNavWaypoints(leg));
    });

    return legWaypoints;
  }

  /**
   * Takes a Trip class object and strips it down to only data needed for saving.
   *
   * @returns a trimmed down 'saveData` object ready for saving to cloud storage.
   */
  private prepTripForSave(): PartialObj<Directus_SavedRoutePlan> | undefined {
    if (!this.parameters) return;

    return {
      EV_Nav_Parameters: this.parameters,
      Locations: this.locations.map((location) =>
        location.convertToSavedLocation()
      ),
      Engin_Type: this.tripFuelType,
      id: this.directusId,
      Trip_Frequency: this.frequency,
      Primary_Time_Location: this.primaryTimeLocation,
      Vehicle: this.vehicleId,
      Name: this.name,
      Total_Public_Charging_Cost: this.totalPublicChargingCost,
      Total_Private_Charging_Cost: this.totalPrivateChargingCost,
      Total_Distance: this.totalDistance,
      Total_Energy: this.totalEnergy,
    };
  }

  /**
   * Returns the closest charger to the target point without going past it.
   *
   * uses Haversine distance from both points to find the charger that is the closest
   * to the location without exceeding the max distance of the trip going past either point.
   *
   * @param targetPoint the waypoint for the location you want to find the closest charger to.
   * @param oppositePoint the waypoint for the location at the other end of the trip.
   * @param chargers the list of `Charger` objects to consider.
   * @param maxDistance the number of meters this trip is intended to cover.
   * @returns a `Charger` object for the closest charger to the target without going past it
   */
  private findClosestCharger(
    targetPoint: EVNavWaypoint,
    oppositePoint: EVNavWaypoint,
    chargers: EVNavCharger[],
    maxDistance: number
  ): EVNavCharger {
    let charger: EVNavCharger;
    let distance: number;

    // filter out chargers that go past the max distance of the trip.
    const filteredList = chargers.filter(
      (listCharger) =>
        haversineDistance(
          [oppositePoint.Longitude, oppositePoint.Latitude],
          [listCharger.Location.Longitude, listCharger.Location.Latitude]
        ) < maxDistance
    );

    // find closest charger to target point.
    filteredList.forEach((listCharger) => {
      // find distance.
      const distanceToTarget = haversineDistance(
        [targetPoint.Longitude, targetPoint.Latitude],
        [listCharger.Location.Longitude, listCharger.Location.Latitude]
      );
      // check if first charger.
      if (!charger) {
        // assumes is first charger.
        charger = listCharger;
        distance = distanceToTarget;
      } else if (distanceToTarget < distance) {
        // assumes is not first charger and has proven to be closer based on distance.
        charger = listCharger;
        distance = distanceToTarget;
      }
    });

    // assumes the `find closest charger to target point` section has returned at least one result
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return charger!;
  }

  /**
   * Helper private that converts steps object polylines into latitude longitude coordinate arrays.
   * Intended to be used by the 'MapPanel` component to return a list formatted in a way it can be
   * used for conditional rendering and passing props to conditionally rendered components.
   *
   * @param steps Array of steps in the shape of `routePlanningStore`,`routePlan`,`steps` property
   * @returns Array of polylines
   *
   * NOTE: each polyline consisting of an array of arrays each containing a latitude longitude coordinate.
   */
  private convertStepToPolyline(steps: Array<EVNavStep | EVNavLeg>) {
    // create temp variable to return once populated
    const tempArray: [number, number][][] = [];

    // iterate through steps and convert each to a polyline to latitude longitude coordinates
    steps.forEach((step) => {
      // check if step contains a polyline.
      if (step.Polyline) {
        // decode polyline
        const decodedPolyline = this.decodePolyline(step.Polyline);
        // add decoded polyline to temp variable
        tempArray.push(decodedPolyline);
      }
    });

    // return populated temp variable
    return tempArray;
  }

  /**
   * Converts a polyline into a coordinates array.
   * @param encodedPolyline an encoded polyline string
   * @returns Array of arrays each containing a latitude longitude coordinate.
   */
  private decodePolyline(encodedPolyline: string) {
    return polyline.decode(encodedPolyline, 6);
  }

  /**
   * get the range for uses in ev nave radar calls.
   *
   * @param range optional parameter if a range is already specified.
   * @returns the range for uses in ev nave radar calls.
   */
  private getRange(range: number | undefined): number {
    if (range) return range;
    if (this.totalDistance) return Math.floor(this.totalDistance / 10);
    if (this.evTripData) {
      let distance = 0;
      this.evTripData.forEach((plan) => {
        distance += plan.distance;
      });
      return Math.floor(distance);
    }
    if (this.fallbackTripData)
      return Math.floor(this.fallbackTripData.distance / 10);
    return 100000;
  }

  // -------------------------------------------------------------------- //
  // -------------------- Trip Stats Specific Methods ------------------- //
  // -------------------------------------------------------------------- //

  private calcAvoidedCO2(
    calcVs: CalcVsType,
    petrolKmPerLitre: number,
    dieselKmPerLitre: number
  ): string | undefined {
    if (this.tripFuelType === "ICE") return;

    // distance in kilometers = (distance in meters / 1000).
    // efficiency = kilometers of travel per litre of fuel consumed.
    // co2PerLitre = CO₂ emitted per litre of fuel used including refinement
    // avoidedCO₂ = (distance in kilometers / efficiency) * co2PerLitre

    // get distance in meters
    let distance = 0;

    if (this.evTripData) {
      this.evTripData.forEach((plan) => {
        distance += plan.distance;
      });
    }

    if (this.fallbackTripData) {
      distance = this.fallbackTripData.distance;
    }

    // user petrol or diesel km traveled per litre of fuel consumed
    const efficiency =
      calcVs === CalcVsType.PETROL ? petrolKmPerLitre : dieselKmPerLitre;

    // 2.8 for petrol and 3.2 for diesel
    const co2PerLitre = calcVs === CalcVsType.PETROL ? 2.8 : 3.2;

    return ((distance / 1000 / efficiency) * co2PerLitre).toFixed(2);
  }

  private calcDrivingKms(): string {
    // n / 1000 = meters to kilometers conversion.
    let distance = 0;

    if (this.evTripData) {
      this.evTripData.forEach((plan) => {
        distance += plan.distance;
      });
    }
    if (this.fallbackTripData) {
      distance = this.fallbackTripData.distance;
    }
    if (this.ICETripData) {
      distance = this.ICETripData.distance;
    }
    return (distance / 1000).toFixed(2);
  }

  private calcDrivingTime(): string {
    // n - charging time / 60 = seconds to minutes conversion, further converted to #hours #minutes format
    let seconds = 0;

    if (this.evTripData) {
      this.evTripData?.forEach((plan) => {
        seconds += plan.time;
      });

      this.evTripData?.forEach((plan) => {
        for (const s of plan.steps) {
          seconds -= s.ChargeTime ?? 0;
        }
      });
    }

    if (this.fallbackTripData) {
      seconds = this.fallbackTripData.travelTime;
    }

    if (this.ICETripData) {
      seconds = this.ICETripData.travelTime;
    }

    return Duration.fromObject({
      hours: 0,
      minutes: Math.floor(seconds / 60),
    })
      .normalize()
      .toHuman({ unitDisplay: "short" })
      .replace(",", "");
  }

  private calcChargingTime(): string | undefined {
    if (!this.evTripData) return;
    let seconds = 0;
    this.evTripData.forEach((plan) => {
      for (const s of plan.steps) {
        seconds += s.ChargeTime ?? 0;
      }
    });
    return Duration.fromObject({
      hours: 0,
      minutes: Math.floor(seconds / 60),
    })
      .normalize()
      .toHuman({ unitDisplay: "short" })
      .replace(",", "");
  }

  private calcChargeKWh(): string | undefined {
    if (!this.evTripData) return;
    let kwh = 0;
    this.evTripData?.forEach((plan) => {
      for (const s of plan.steps) {
        kwh += s.Charge ?? 0;
      }
    });
    return kwh.toFixed(2);
  }

  private calcEmittedCO2(
    calcVs: CalcVsType,
    petrolKmPerLitre: number,
    dieselKmPerLitre: number
  ): string {
    let energy = 0;
    if (this.evTripData) {
      this.evTripData?.forEach((plan) => {
        energy += plan.energy;
      });
    }
    if (this.fallbackTripData) {
      energy = this.fallbackTripData.energy;
    }
    if (this.ICETripData) {
      // user petrol or diesel km traveled per litre of fuel consumed
      const efficiency =
        calcVs === CalcVsType.PETROL ? petrolKmPerLitre : dieselKmPerLitre;

      // 2.8 for petrol and 3.2 for diesel
      const co2PerLitre = calcVs === CalcVsType.PETROL ? 2.8 : 3.2;

      return (
        (this.ICETripData.distance / 1000 / efficiency) *
        co2PerLitre
      ).toFixed(2);
    }
    // 0.13 is a number gathered from google.
    // TODO: find a better solution to calculate this number.
    return Math.round(energy * 0.13).toFixed(2);
  }

  private calcPublicChargingCost(): string | undefined {
    if (!this.evTripData || !this.parameters?.Battery) return;
    let cost = 0;
    this.evTripData.forEach((plan) => {
      if (plan.cost) {
        cost += plan.cost;
      }
    });
    return cost.toFixed(2);
  }

  private calcBattery(): string | undefined {
    if (this.ICETripData) return;
    let kwh = 0;
    if (this.evTripData) {
      this.evTripData.forEach((plan) => {
        for (const s of plan.steps) {
          kwh += s.Charge ?? 0;
        }
      });
    }
    if (this.fallbackTripData) {
      kwh = this.fallbackTripData.energy;
    }
    if (this.parameters) {
      return ((kwh / this.parameters.Battery) * 100).toFixed(0);
    }
  }

  private calcFuelCost(
    calcVs: CalcVsType,
    petrolKmPerLitre: number,
    dieselKmPerLitre: number,
    petrolCostPerLitre: number,
    dieselCostPerLitre: number
  ): string {
    const mPerLitre =
      (calcVs === CalcVsType.PETROL ? petrolKmPerLitre : dieselKmPerLitre) *
      1000;
    const costPerLitre =
      calcVs === CalcVsType.PETROL ? petrolCostPerLitre : dieselCostPerLitre;
    let distance = 0;
    if (this.evTripData) {
      this.evTripData.forEach((plan) => {
        distance += plan.distance;
      });
    }
    if (this.fallbackTripData) {
      distance = this.fallbackTripData.distance;
    }
    if (this.ICETripData) {
      distance = this.ICETripData.distance;
    }
    return ((distance / mPerLitre) * costPerLitre).toFixed(2);
  }

  private calcTotalTime(): string {
    let time = 0;

    this.locations.forEach((location) => {
      time += location.stay || 0;
    });

    if (this.evTripData) {
      this.evTripData?.forEach((plan) => {
        time += plan.time;
      });
    }

    if (this.fallbackTripData) {
      time += this.fallbackTripData.travelTime;
    }

    if (this.ICETripData) {
      time += this.ICETripData.travelTime;
    }

    return Duration.fromObject({ hours: 0, minutes: Math.floor(time / 60) })
      .normalize()
      .toHuman({ unitDisplay: "short" })
      .replace(",", "");
  }

  private calcStayDuration(): string | undefined {
    let time = 0;

    this.locations.forEach((location) => {
      time += location.stay || 0;
    });

    if (!time) return undefined;

    return Duration.fromObject({ hours: 0, minutes: Math.floor(time / 60) })
      .normalize()
      .toHuman({ unitDisplay: "short" })
      .replace(",", "");
  }

  private calcTotalEnergy(): number {
    if (this.totalEnergy) return this.totalEnergy;
    let totalEnergy = 0;
    this.evTripData?.forEach((plan) => {
      totalEnergy += plan.energy;
    });
    this.totalEnergy = totalEnergy;
    return totalEnergy;
  }

  /** Calculates and returns the total cost of private charging for a single run of this trip. */
  public calcPrivateChargingCost(kWhCostHome: number): number | undefined {
    // check if already been calculated this session.
    if (this.totalPrivateChargingCost)
      return to2DP(this.totalPrivateChargingCost);
    // Guard clause
    if (!this.parameters) return;
    let kwh = 0;
    // add starting charge
    kwh += this.parameters.Battery * this.parameters.SOCAct;
    // add charge at waypoints flagged as charge here;
    this.locations
      .filter((location) => location.chargeHere)
      .forEach((location) => {
        if (location.stateOfChargeAfterCharging) {
          const previousLeg = this.evTripData?.find(
            (EVTrip) =>
              EVTrip.steps[EVTrip.steps.length - 1].To === location.localId
          );
          const kwhAfterCharging =
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.parameters!.Battery * location.stateOfChargeAfterCharging;
          const kwhBeforeCharging =
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.parameters!.Battery *
            (previousLeg?.steps[previousLeg.steps.length - 1].EndCharge ??
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              this.parameters!.SOCEnd);
          const kwhCharged = kwhAfterCharging - kwhBeforeCharging;
          kwh += kwhCharged;
        }
      });
    const totalCost = to2DP(kwh * kWhCostHome);
    this.totalPrivateChargingCost = totalCost;
    return totalCost;
  }
}
