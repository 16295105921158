<template>
  <v-card flat class="px-5 pb-3">
    <v-row no-gutters justify="space-between">
      <span>
        <v-card-title>
          <v-btn icon @click="back">
            <v-icon> mdi-chevron-left </v-icon>
          </v-btn>
          Vehicle Settings
        </v-card-title>
      </span>
      <v-btn icon>
        <v-icon @click="close">mdi-close</v-icon>
      </v-btn>
    </v-row>
    <!-- vehicle image gallery -->
    <v-card-text>
      <v-row class="mb-3">
        <v-col cols="4">
          <v-img
            width="100%"
            height="auto"
            :lazy-src="fallbackImgSrc"
            :src="imgSrcList.length ? imgSrcList[0] : fallbackImgSrc"
            class="rounded-lg"
            contain
            aspect-ratio="1"
            max-height="160"
          ></v-img>
        </v-col>
        <v-col cols="8">
          <v-row>
            <v-col
              cols="4"
              v-for="(image, index) in imgSrcList"
              :key="'vehicle-settings-image-' + index"
            >
              <v-img
                width="100%"
                height="auto"
                :lazy-src="fallbackImgSrc"
                :src="image"
                class="rounded-lg"
                contain
                aspect-ratio="1"
                max-height="80"
              ></v-img>
            </v-col>
            <v-col cols="4">
              <v-card
                class="rounded-lg secondary d-flex justify-center align-center"
                style="height: 100%"
                @click="showUploader"
              >
                <v-icon dark x-large>mdi-camera-plus</v-icon>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- image uploader modal -->
    <v-dialog v-model="showingUploader" max-width="300">
      <v-card class="rounded-lg">
        <v-card-title class="d-flex justify-space-between">
          <span> Upload an image </span>
          <v-btn icon @click="hideUploader">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <template v-if="imageUploadStatus">
          <v-card-text
            v-if="imageUploadStatus === 'PROCESSING'"
            class="d-flex flex-column justify-center align-center"
          >
            <LoadingCard />
            <v-row no-gutters class="align-center">
              Processing
              <PulseLoader />
            </v-row>
          </v-card-text>
          <v-card-text
            v-else-if="imageUploadStatus === 'SUCCESS'"
            class="d-flex flex-column justify-center align-center"
          >
            <v-icon color="success" x-large>mdi-check-circle</v-icon>
            <span>Successfully uploaded</span>
          </v-card-text>
          <v-card-text
            v-else-if="imageUploadStatus === 'FAILED'"
            class="d-flex flex-column justify-center align-center"
          >
            <v-icon color="error" x-large>mdi-alert-circle</v-icon>
            <span>Failed to uploaded</span>
          </v-card-text>
        </template>
        <template v-else>
          <v-card-text class="pr-8 pb-0">
            <v-file-input
              label="select image to upload"
              accept="image/*"
              :error-messages="errorMsg"
              @click="clearError"
              v-model="imageFile"
            ></v-file-input>
          </v-card-text>
          <v-card-actions class="d-flex justify-center pb-5">
            <v-btn
              color="primary"
              class="text-none rounded-lg"
              @click="uploadImage"
            >
              Upload image
            </v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
    <!-- settings editing form -->
    <v-form ref="edit-vehicle-form" :disabled="processing || loading">
      <v-card-text>
        <!-- basic settings -->
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field label="Name" v-model="name"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field label="Plate Number" v-model="plate"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field label="VIN" v-model="VIN"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-select
              label="Fuel type (required)"
              :items="['Petrol', 'Diesel', 'Hybrid', 'Electric']"
              v-model="fuelType"
              :rules="[validateFuelType]"
            ></v-select>
          </v-col>
          <v-col cols="12" v-if="fuelType === 'Electric'">
            <v-autocomplete
              v-model="evModel"
              :items="EVModels"
              item-text="name"
              label="EV make/model (optional)"
              :menu-props="{ contentClass: 'pwt-scrollbar-styles' }"
              return-object
              clearable
              @change="handelModelSelect"
            ></v-autocomplete>
          </v-col>
        </v-row>
        Battery Size
        <v-row align="center" justify="center" class="mb-7">
          <v-btn large icon @click="decrementBatterySize">
            <v-icon large> mdi-minus-circle-outline </v-icon>
          </v-btn>
          <div
            class="grey lighten-2 rounded-lg d-flex align-center"
            style="height: 56px; width: 80%"
            id="battery-custom-input"
          >
            <v-text-field
              v-model="batterySize"
              class="right-aligned-input none-underlined-input off-set-input-message pt-5"
              style="max-width: 145px"
              suffix="kWh"
              type="number"
              hide-spin-buttons
              :rules="[validateBattery]"
            ></v-text-field>
          </div>

          <v-btn large icon @click="incrementBatterySize">
            <v-icon large> mdi-plus-circle-outline </v-icon>
          </v-btn>
        </v-row>
        <v-row class="justify-space-between" no-gutters>
          <span>
            Range adjustment
            <StyledToolTip v-if="tooltipContent" :data="tooltipContent" />
          </span>
          <span> {{ SOH }}% or {{ getMaxRange() }} </span>
        </v-row>
        <v-slider
          v-if="fuelType === 'Electric'"
          track-color="grey lighten-2"
          v-model="SOH"
          min="1"
          max="100"
          class="pr-2 pb-3"
          :disabled="processing"
          :messages="batteryAgeMessage"
        ></v-slider>
      </v-card-text>
      <!-- connectors and cables -->
      <v-card-title> Connector Settings </v-card-title>
      <v-card-subtitle>
        These settings override the default settings based on your selected EV
        model.
      </v-card-subtitle>
      <v-card-text>
        <v-card class="mb-8">
          <v-card-title> Tethered Charging Stations </v-card-title>
          <v-card-subtitle>
            Plan trips with compatible chargers by selecting desired connectors.
          </v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col
                cols="4"
                sm="2"
                v-for="(connector, index) in connectors"
                :key="'connector-styled-btn-' + index"
              >
                <!-- custom connector selector button -->
                <v-card
                  class="d-flex flex-column justify-space-between align-center pa-2"
                  style="width: 100%; height: 100%"
                  @click="toggleConnectorSelect(connector)"
                >
                  <div
                    class="rounded-lg pa-1 d-flex align-center justify-center mb-2"
                    :class="
                      connectorIsSelected(connector)
                        ? 'selected_svg_container_background'
                        : ''
                    "
                    style="height: 64px; width: 64px"
                  >
                    <img
                      v-if="!!connector.iconURL"
                      :src="connector.iconURL"
                      class="svg_base"
                      :class="
                        connectorIsSelected(connector)
                          ? 'svg_outline'
                          : 'svg_grayed_out'
                      "
                    />
                    <v-img
                      v-else
                      width="56"
                      height="56"
                      :src="fallbackImgSrc"
                      contain
                      aspect-ratio="1"
                      max-height="56"
                      max-width="56"
                    ></v-img>
                  </div>
                  <span class="text-center text-caption">
                    {{ connector.displayName }}
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-title> Your Cables </v-card-title>
          <v-card-subtitle>
            Select the cables you are bringing with you
          </v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col
                cols="4"
                sm="2"
                v-for="(cable, index) in cables"
                :key="'cable-styled-btn-' + index"
              >
                <!-- custom cable selector button -->
                <v-card
                  class="d-flex flex-column justify-space-between align-center pa-2"
                  style="width: 100%; height: 100%"
                  @click="toggleConnectorSelect(cable)"
                >
                  <div
                    class="rounded-lg pa-1 d-flex align-center justify-center mb-2"
                    :class="
                      connectorIsSelected(cable)
                        ? 'selected_svg_container_background'
                        : ''
                    "
                    style="height: 64px; width: 64px"
                  >
                    <img
                      v-if="!!cable.iconURL"
                      :src="cable.iconURL"
                      class="svg_base"
                      :class="
                        connectorIsSelected(cable)
                          ? 'svg_outline'
                          : 'svg_grayed_out'
                      "
                    />
                    <v-img
                      v-else
                      width="56"
                      height="56"
                      :src="fallbackImgSrc"
                      contain
                      aspect-ratio="1"
                      max-height="56"
                      max-width="56"
                    ></v-img>
                  </div>
                  <span class="text-center text-caption">
                    {{ cable.displayName }}
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card-text>
      <!-- technical advanced settings -->
      <v-card-title> Advanced Settings </v-card-title>
      <v-card-subtitle>
        Danger zone, these settings can greatly impact your trip planning
        experience. Your selected EV model has preset values for these settings.
        You only need change these if your vehicle differs from those.
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="pb-0 text-h6"> Vehicle Physics </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="DragCoefficient"
              label="Aerodynamics (optional)"
              type="number"
              clearable
              :messages="dragCoefficientMessage"
              :rules="[validateDrag]"
              hide-spin-buttons
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="RollingResistanceCoefficient"
              label="Road dynamics (optional)"
              type="number"
              clearable
              :rules="[validateRoleResistance]"
              hide-spin-buttons
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="Mass"
              label="Vehicle weight (optional)"
              type="number"
              clearable
              suffix="kg"
              hide-spin-buttons
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="pb-0 text-h6"> EV Efficiency </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="RegenerativeBreakingEfficiency"
              label="Regenerative breaking efficiency (optional)"
              type="number"
              clearable
              :rules="[validateRegenBreaking]"
              :messages="regenerativeBrakingMessage"
              suffix="%"
              hide-spin-buttons
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="PowerChainEfficiency"
              label="Power chain efficiency (optional)"
              type="number"
              clearable
              :rules="[validatePowerChainEfficiency]"
              :message="powerChainEfficiencyMessage"
              suffix="%"
              hide-spin-buttons
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="pb-0 text-h6"> Power Rating </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="MaxElectricPowerAc"
              label="AC charging speed (optional)"
              type="number"
              clearable
              :rules="[validateMaxAC]"
              suffix="kw"
              hide-spin-buttons
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="MaxElectricPowerDc"
              label="DC charging speed (optional)"
              type="number"
              clearable
              :rules="[validateMaxDC]"
              suffix="kw"
              hide-spin-buttons
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- action buttons -->
        <v-btn
          block
          color="primary"
          elevation="3"
          class="text-none rounded-lg mb-3 mt-5"
          :disabled="processing ? processing : !dataToSave"
          :loading="processing"
          @click="handelSave"
        >
          Save Vehicle Settings
        </v-btn>
        <v-btn
          block
          color="primary"
          text
          tile
          class="text-none rounded-lg"
          @click="back"
        >
          Cancel
        </v-btn>
      </v-card-text>
    </v-form>
    <v-alert type="success" v-if="saveStatus === 'success'" class="mx-2">
      Vehicle updated successfully and saved to the database
    </v-alert>
    <v-alert type="error" v-if="saveStatus === 'failed'" class="mx-2">
      Vehicle was updated locally but failed to be saved to the database
    </v-alert>
  </v-card>
</template>
<script lang="ts">
// frameworks.
import Vue from "vue";
import { mapGetters, mapState } from "vuex";
// helper functions/default data.
import getAssetSrc from "@/logic/utils/getAssetSrc";
import parseIntOrFloat from "@/logic/utils/parseNumOrFloat";
import {
  ConnectorDetailsIconData,
  connectorDetailsDataMap,
  getAllSocketedConnectors,
  getAllTetheredConnectors,
} from "@/logic/data/connectorDetailsData";
import to2DP from "@/logic/utils/to2DP";
import evNavDefaultData from "@/logic/data/eVNavDefaultData";
// types/class definitions.
import Vehicle from "@/logic/classes/vehicle";
import EVModel from "@/logic/classes/evModel";
import {
  ActionTypes,
  GettersTypes,
  MainDialogContent,
  ManagedContentNames,
  MutationTypes,
  type State,
} from "@/logic/store/store_types";
import { FuelType } from "@/logic/types/sheared_local_types";
import type {
  AdvancedConfig,
  DirectusVehicleVariableData,
  UserSelectedPlug,
} from "@/logic/types/directus_vehicle";
import type {
  ConnectorFormat,
  ConnectorType,
} from "@/logic/types/charger_Db_types";
// components
import PulseLoader from "../../ui-elements/PulseLoader.vue";
import LoadingCard from "../../ui-elements/LoadingCard.vue";
import StyledToolTip from "../../ui-elements/StyledToolTip.vue";

interface LocalConnectorData {
  iconURL?: string;
  displayName: string;
  standard: ConnectorType;
  format: ConnectorFormat;
}

export default Vue.extend({
  name: "VehicleSettingsContent",
  data() {
    return {
      // image related data

      /** modal display flag */
      showingUploader: false,
      /** file to be uploaded */
      imageFile: undefined as File | undefined,
      /** image upload progress error message */
      errorMsg: undefined as string | undefined,

      // edit settings form related data
      loading: true,
      processing: false,
      saveStatus: undefined as undefined | "failed" | "success",
      watchedDataToSave: false,

      // this version of vuetify returns null when a text field is cleared.
      // this version of vuetify also returns number type text fields as stringified numbers however can be set for initial values as a number.

      /** The name for this vehicle. */
      name: null as string | null,
      /** The license plate for this vehicle. */
      plate: null as string | null,
      /** the VIN number for this vehicle. */
      VIN: null as string | null,
      /** the selected fuel type for this vehicle. */
      fuelType: "Electric" as FuelType | null,
      /** the selected EV make and model for this vehicle. */
      evModel: null as EVModel | null,
      /** the battery size for this vehicle in kWh. */
      batterySize: null as string | number | null,
      /** the state of health for this vehicle */
      SOH: 100,
      /** the weight of the vehicle in kgs */
      Mass: null as string | number | null,
      /** the drag of the vehicle.
       *
       * Note: this is called a drag coefficient but is calculated more like and aerodynamics resistance
       */
      DragCoefficient: null as string | number | null,
      /** the rolling resistance coefficient of the vehicle. */
      RollingResistanceCoefficient: null as string | number | null,
      /** the percentage of energy recovered by regenerative braking */
      RegenerativeBreakingEfficiency: null as string | number | null,
      /** the percentage of energy that makes it from stored power to locomotion.
       *
       * NOTE: the remaining is consumed/lost in the process of the inner workings of the EV.
       */
      PowerChainEfficiency: null as string | number | null,
      /** The max rate that this vehicle can be charged at by AC chargers. */
      MaxElectricPowerAc: null as string | number | null,
      /** The max rate that this vehicle can be charged at by DC chargers. */
      MaxElectricPowerDc: null as string | number | null,
      selectedConnectors: [] as LocalConnectorData[],
    };
  },
  computed: {
    /** Image source to default filler image. */
    fallbackImgSrc(): string {
      return getAssetSrc("car_images/No_Image_Powersell.png");
    },
    /** List of image sources for vehicle/model images. */
    imgSrcList(): string[] {
      const tempArray: string[] = [];
      if (this.vehicle as Vehicle | undefined) {
        tempArray.push(...(this.vehicle as Vehicle).imageSrcPaths);
        if ((this.vehicle as Vehicle).evModel?.imageSrc)
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          tempArray.push((this.vehicle.evModel as EVModel)!.imageSrc!); // asserted as checked in previous statement however typescript has issues mapping this version of vue.
      }
      return tempArray;
    },
    /** List of all possible hard point connectors */
    connectors(): LocalConnectorData[] {
      return getAllTetheredConnectors().map((connector) => ({
        displayName: connector.displayName,
        format: "CABLE",
        standard: connector.standard,
        iconURL: this.getSrc(connector.iconURL, "cable"),
      }));
    },
    /** List of all possible cables */
    cables(): LocalConnectorData[] {
      return getAllSocketedConnectors().map((connector) => ({
        displayName: connector.displayName,
        format: "SOCKET",
        standard: connector.standard,
        iconURL: this.getSrc(connector.iconURL, "socket"),
      }));
    },
    dataToSave(): boolean {
      // test if vehicle record to compare to exists.
      if (!this.vehicle) return false;
      // test if property values have changed from initial values.
      if (
        this.name !==
        (this.$store.getters[GettersTypes.viewedFleetVehicleData].name ?? null)
      )
        return true;
      if (this.plate !== ((this.vehicle as Vehicle).licensePlate ?? null))
        return true;
      if (this.VIN !== ((this.vehicle as Vehicle).VIN ?? null)) return true;
      if (this.fuelType !== ((this.vehicle as Vehicle).fuelType ?? null))
        return true;
      if (this.evModel !== ((this.vehicle as Vehicle).evModel ?? null))
        return true;
      if (
        this.batterySize &&
        parseIntOrFloat(this.batterySize) !==
          (this.vehicle as Vehicle).batterySize
      )
        return true;
      if (
        this.SOH !==
        ((this.vehicle as Vehicle).stateOfHealth
          ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            (this.vehicle as Vehicle).stateOfHealth! * 100
          : undefined)
      )
        return true;
      if (
        this.Mass &&
        parseIntOrFloat(this.Mass) !== (this.vehicle as Vehicle).mass
      )
        return true;
      if (
        this.DragCoefficient &&
        parseIntOrFloat(this.DragCoefficient) !==
          (this.vehicle as Vehicle).dragCoefficient
      )
        return true;
      if (
        this.RollingResistanceCoefficient &&
        parseIntOrFloat(this.RollingResistanceCoefficient) !==
          (this.vehicle as Vehicle).rollingResistanceCoefficient
      )
        return true;
      if (
        this.RegenerativeBreakingEfficiency &&
        parseIntOrFloat(this.RegenerativeBreakingEfficiency) !==
          (this.vehicle as Vehicle).regenerativeBreakingEfficiency * 100
      )
        return true;
      if (
        this.PowerChainEfficiency &&
        parseIntOrFloat(this.PowerChainEfficiency) !==
          (this.vehicle as Vehicle).powerChainEfficiency * 100
      )
        return true;
      if (
        this.MaxElectricPowerAc &&
        parseIntOrFloat(this.MaxElectricPowerAc) !==
          (this.vehicle as Vehicle).maxElectricPowerAC
      )
        return true;
      if (
        this.MaxElectricPowerDc &&
        parseIntOrFloat(this.MaxElectricPowerDc) !==
          (this.vehicle as Vehicle).maxElectricPowerDC
      )
        return true;
      // connectors.
      const initialConnectors = this.getInitialConnectors();
      // test length.
      if (this.selectedConnectors.length !== initialConnectors.length)
        return true;
      // test same sized arrays have objects with matching content.
      if (
        !initialConnectors.every((connector) =>
          this.connectorIsSelected(connector)
        )
      )
        return true;
      // default return
      return false;
    },

    // input filed feedback messaging

    /** battery health message */
    batteryAgeMessage(): string {
      const degradedPercentage = 100 - this.SOH;
      if (degradedPercentage >= 70)
        return "this looks like a poorly functioning battery";
      const yearsOld = Math.floor(degradedPercentage / 3);
      if (yearsOld <= 0) return "equivalent to a new battery";
      return (
        "equivalent to a " + yearsOld + " year old battery with normal usage"
      );
    },
    /** Provides feedback messaging for the drag coefficient/Road dynamics input filed. */
    dragCoefficientMessage(): string | undefined {
      if (this.DragCoefficient) {
        const value = parseIntOrFloat(this.DragCoefficient);
        if (value && value >= 10)
          return "Are you sure? This is the equivalent of being as aerodynamic as a wall.";
      }
      return undefined;
    },
    /** Provides feedback messaging for the regenerative braking input filed. */
    regenerativeBrakingMessage(): string | undefined {
      if (this.RegenerativeBreakingEfficiency) {
        const value = parseIntOrFloat(this.RegenerativeBreakingEfficiency);
        if (value && value >= 100) return "Wow! Really?";
      }
      return undefined;
    },
    /** Provides feedback messaging for the power chain efficiency input filed. */
    powerChainEfficiencyMessage(): string | undefined {
      if (this.PowerChainEfficiency) {
        const value = parseIntOrFloat(this.PowerChainEfficiency);
        if (value && value >= 100) return "Wow! Really?";
      }
      return undefined;
    },
    ...mapGetters({
      /** `Vehicle` class object for the target vehicle. */
      vehicle: GettersTypes.viewedFleetVehicleData,
    }),
    ...mapState({
      /** status of the current image upload async action. */
      imageUploadStatus: (state: unknown) => (state as State).updateImageStatus,
      /** list of selectable ev models */
      EVModels: (state: unknown) => (state as State).evModels,
      /** styled tool tip content for SOH. */
      tooltipContent: (state: unknown) =>
        (state as State).managedContent.find(
          (content) => content.Name === ManagedContentNames.SoHToolTip
        ),
    }),
  },
  methods: {
    // Helper functions
    convertSelectedConnectorsToUserSelectedPlugs(): UserSelectedPlug[] {
      const tempArray: UserSelectedPlug[] = [];

      this.selectedConnectors.forEach((connector) => {
        const extraData = connectorDetailsDataMap.get(connector.standard);
        if (extraData) {
          tempArray.push({
            standard: connector.standard,
            format: connector.format,
            powerType: extraData.powerType,
          });
        }
      });
      return tempArray;
    },
    connectorIsSelected(connector: LocalConnectorData): boolean {
      return !!this.selectedConnectors.find(
        (selectedConnector) =>
          selectedConnector.standard === connector.standard &&
          selectedConnector.format === connector.format
      );
    },
    getInitialConnectors(): LocalConnectorData[] {
      const typedVehicle: Vehicle | undefined = this.vehicle; // cast type as ts has trouble with typing vuex getters.
      if (typedVehicle) {
        if (
          typedVehicle.userSelectedPlugs &&
          typedVehicle.userSelectedPlugs.length
        )
          return this.getUserSelectedConnectors(typedVehicle.userSelectedPlugs);
        if (typedVehicle.evModel)
          return this.getModelConnectors(typedVehicle.evModel);
      }
      return [];
    },
    getModelConnectors(model: EVModel): LocalConnectorData[] {
      const tempArray: LocalConnectorData[] = [];
      model.compatibleConnectors.forEach((connector) => {
        const extraData = connectorDetailsDataMap.get(connector.standard);
        const alreadyAdded = !!tempArray.find(
          (dataItem) => dataItem.standard === connector.standard
        );
        if (extraData && !alreadyAdded) {
          tempArray.push({
            format: "CABLE",
            standard: connector.standard,
            displayName: extraData.displayName,
            iconURL: this.getSrc(extraData.iconURL, "cable"),
          });
        }
      });
      return tempArray;
    },
    getUserSelectedConnectors(
      userSelectedPlugs: UserSelectedPlug[]
    ): LocalConnectorData[] {
      const tempArray: LocalConnectorData[] = [];
      userSelectedPlugs.forEach((connector) => {
        const extraData = connectorDetailsDataMap.get(connector.standard);
        if (extraData) {
          tempArray.push({
            format: connector.format,
            standard: connector.standard,
            displayName: extraData.displayName,
            iconURL: this.getSrc(
              extraData.iconURL,
              connector.format === "CABLE" ? "cable" : "socket"
            ),
          });
        }
      });
      return tempArray;
    },
    /**
     * Returns the target connectors icon asset url if one exist
     *
     * @param iconUrlData the 'ConnectorDetailsData' `iconUrl` property.
     * @param format the target format
     * @returns complete file path if it exist or undefined if not.
     */
    getSrc(
      iconUrlData: string | ConnectorDetailsIconData | undefined,
      format: "cable" | "socket"
    ): string | undefined {
      let partialFilePath: string | undefined = undefined;
      if (!iconUrlData) return undefined;
      if (typeof iconUrlData === "string") partialFilePath = iconUrlData;
      if (typeof iconUrlData === "object") {
        if (Object.hasOwn(iconUrlData, format))
          partialFilePath = iconUrlData[format];
      }
      if (!partialFilePath) return undefined;
      return getAssetSrc(partialFilePath);
    },

    // Event handlers

    /** Adds/removes connector from selected connectors list.
     *
     * @param connector the target `LocalConnectorData` object for the connector.
     */
    toggleConnectorSelect(connector: LocalConnectorData): void {
      if (this.connectorIsSelected(connector)) {
        // filter out matching connector from array
        this.selectedConnectors = this.selectedConnectors.filter(
          (selectedConnector) => {
            if (selectedConnector.standard !== connector.standard) return true;
            if (
              selectedConnector.standard === connector.standard &&
              selectedConnector.format !== connector.format
            )
              return true;
            return false; // assumes this will be the selected connector that matches both standard and format.
          }
        );
      } else {
        // add connector to array
        this.selectedConnectors.push(connector);
      }
    },
    /** Increases the battery size by 1 kWh. */
    incrementBatterySize(): void {
      // exit early if batterySize is null
      if (!this.batterySize) return;
      // parse value
      const parsedVal = parseIntOrFloat(this.batterySize);
      // exit early if parsing value failed
      if (!parsedVal) return;
      // increment battery size
      this.batterySize = parsedVal + 1;
    },
    /** Reduces the battery size by 1 kWh. */
    decrementBatterySize(): void {
      // exit early if batterySize is null
      if (!this.batterySize) return;
      // parse value
      const parsedVal = parseIntOrFloat(this.batterySize);
      // exit early if parsing value failed
      if (!parsedVal) return;
      // decrement battery size
      this.batterySize = parsedVal - 1;
    },
    /** Returns a display string containing the expected max range in km for this vehicle. */
    getMaxRange(): string {
      const range = (this.vehicle as Vehicle | undefined)?.calcMaxRange(
        (this.$store.state as State).extraWeight,
        this.SOH / 100
      );
      return range
        ? `${Math.floor(range / 1000)}km max range`
        : "0km max range";
    },
    /** closes all modals and returns to the normal vue of the app. */
    close() {
      this.$store.commit(MutationTypes.setMainDialogContent, undefined);
      this.$store.commit(MutationTypes.setViewedFleetVehicle, undefined);
    },
    /** swaps modal content back to the target `Vehicles` vehicle details page */
    back() {
      this.$store.commit(
        MutationTypes.setMainDialogContent,
        MainDialogContent.FLEET_VEHICLE_DETAILS
      );
    },
    /** displays the image uploader modal */
    showUploader() {
      this.showingUploader = true;
    },
    /** closes the image uploader modal */
    hideUploader() {
      this.showingUploader = false;
    },
    /** upload selected image */
    uploadImage() {
      if (!this.imageFile) {
        this.errorMsg = "please select an image";
      } else {
        this.$store.dispatch(ActionTypes.uploadVehicleImage, {
          vehicle: this.vehicle,
          imageFile: this.imageFile,
        });
      }
    },
    /** clears the image uploader's error message. */
    clearError() {
      this.errorMsg = undefined;
    },
    /**
     * Adjusts other values to match the pre set values of the new selected model.
     *
     * @param val the selected `EVModel` - NOTE this is auto applied if triggered off the change event for the autocomplete
     */
    handelModelSelect(val: EVModel | null): void {
      if (val) {
        this.batterySize = val.batterySize;
        this.Mass = val.mass;
        this.DragCoefficient = val.dragCoefficient;
        this.RollingResistanceCoefficient =
          evNavDefaultData.RollingResistanceCoefficient;
        this.RegenerativeBreakingEfficiency = val.regenRecovery * 100; // convert from decimal percentage representation to whole number percentage representation.
        this.PowerChainEfficiency = val.powerChainEfficiency * 100; // convert from decimal percentage representation to whole number percentage representation.
        this.MaxElectricPowerAc = val.maxElectricPowerAC;
        this.MaxElectricPowerDc = val.maxElectricPowerDC;
        this.SOH = val.calcLinearDegradationSOH() * 100; // convert from decimal percentage representation to whole number percentage representation.
        this.selectedConnectors = this.getModelConnectors(val);
      }
    },
    async handelSave(): Promise<void> {
      this.processing = true; // indicate async process has begun.
      if (!this.vehicle) return; // guard clause.
      if (!this.selectedConnectors.length) return; // guard clause.
      // compile data to update
      const vehicle: Vehicle = this.vehicle;
      const dataToUpdate: DirectusVehicleVariableData = {};
      if (this.name !== (vehicle.name ?? null)) {
        vehicle.name = this.name ?? undefined;
        dataToUpdate.name = this.name;
      }
      if (this.plate !== (vehicle.licensePlate ?? null)) {
        vehicle.licensePlate = this.plate ?? undefined;
        dataToUpdate.rego = this.plate;
      }
      if (this.VIN !== (vehicle.VIN ?? null)) {
        vehicle.VIN = this.VIN ?? undefined;
        dataToUpdate.vin = this.VIN;
      }
      if (this.fuelType !== (vehicle.fuelType ?? null)) {
        vehicle.fuelType = this.fuelType ?? undefined;
        dataToUpdate.fuel_type = this.fuelType;
      }
      if (this.evModel !== (vehicle.evModel ?? null)) {
        vehicle.setEVModel(this.evModel ?? undefined);
        dataToUpdate.CDB_Model_ID = this.evModel?.id;
      }
      if (
        this.batterySize &&
        parseIntOrFloat(this.batterySize) !== vehicle.batterySize
      ) {
        const newAdvConf = vehicle.advancedConfig
          ? {
              ...vehicle.advancedConfig,
              BatterySize: parseIntOrFloat(this.batterySize),
            }
          : { BatterySize: parseIntOrFloat(this.batterySize) };
        vehicle.advancedConfig = newAdvConf;
        dataToUpdate.AdvancedConfig = JSON.stringify(newAdvConf);
      }
      if (
        this.SOH !==
        (vehicle.stateOfHealth
          ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            vehicle.stateOfHealth! * 100
          : undefined)
      ) {
        const convertedSOH = to2DP(this.SOH / 100);
        vehicle.userProvidedStateOfHealth = convertedSOH;
        dataToUpdate.soh = convertedSOH;
      }

      // compile advanced config
      const newAdvConf: AdvancedConfig = vehicle.advancedConfig
        ? { ...vehicle.advancedConfig }
        : {};
      if (this.Mass && parseIntOrFloat(this.Mass) !== vehicle.mass) {
        newAdvConf.Mass = parseIntOrFloat(this.Mass);
      }
      if (
        this.DragCoefficient &&
        parseIntOrFloat(this.DragCoefficient) !== vehicle.dragCoefficient
      ) {
        newAdvConf.DragCoefficient = parseIntOrFloat(this.DragCoefficient);
      }
      if (
        this.RollingResistanceCoefficient &&
        parseIntOrFloat(this.RollingResistanceCoefficient) !==
          vehicle.rollingResistanceCoefficient
      ) {
        newAdvConf.RollingResistanceCoefficient = parseIntOrFloat(
          this.RollingResistanceCoefficient
        );
      }
      if (this.RegenerativeBreakingEfficiency) {
        const parsedVal = parseIntOrFloat(this.RegenerativeBreakingEfficiency);
        if (
          parsedVal &&
          parsedVal !== vehicle.rollingResistanceCoefficient * 100
        ) {
          newAdvConf.RegenerativeBreakingEfficiency = to2DP(parsedVal / 100);
        }
      }
      if (this.PowerChainEfficiency) {
        const parsedVal = parseIntOrFloat(this.PowerChainEfficiency);
        if (parsedVal && parsedVal !== vehicle.powerChainEfficiency * 100) {
          newAdvConf.PowerChainEfficiency = to2DP(parsedVal / 100);
        }
      }
      if (
        this.MaxElectricPowerAc &&
        parseIntOrFloat(this.MaxElectricPowerAc) !== vehicle.maxElectricPowerAC
      ) {
        newAdvConf.MaxElectricPowerAc = parseIntOrFloat(
          this.MaxElectricPowerAc
        );
      }
      if (
        this.MaxElectricPowerDc &&
        parseIntOrFloat(this.MaxElectricPowerDc) !== vehicle.maxElectricPowerDC
      ) {
        newAdvConf.MaxElectricPowerDc = parseIntOrFloat(
          this.MaxElectricPowerDc
        );
      }
      // check advanced config has any entries
      if (Object.keys(newAdvConf).length) {
        vehicle.advancedConfig = newAdvConf;
        dataToUpdate.AdvancedConfig = JSON.stringify(newAdvConf);
      }

      // compile connectors and cables

      // check if just model defaults
      if (this.evModel) {
        // find models default connectors
        const modelDefaultConnectors = this.getModelConnectors(this.evModel);
        if (
          this.selectedConnectors.length !== modelDefaultConnectors.length ||
          !modelDefaultConnectors.every((connector) =>
            this.connectorIsSelected(connector)
          )
        ) {
          // ASSUME: not the same and needs override
          const userSelectedPlugs =
            this.convertSelectedConnectorsToUserSelectedPlugs();
          vehicle.userSelectedPlugs = userSelectedPlugs;
          dataToUpdate.UserSelectedPlugs = userSelectedPlugs.map((obj) =>
            JSON.stringify(obj)
          );
        } else {
          // ASSUMES: is default for model and userSelectedPlugs can be removed.
          vehicle.userSelectedPlugs = undefined;
          dataToUpdate.UserSelectedPlugs = null;
        }
      } else {
        // ASSUME: just straight override as no model for comparison.
        const userSelectedPlugs =
          this.convertSelectedConnectorsToUserSelectedPlugs();
        vehicle.userSelectedPlugs = userSelectedPlugs;
        dataToUpdate.UserSelectedPlugs = userSelectedPlugs.map((obj) =>
          JSON.stringify(obj)
        );
      }

      // update DB
      this.saveStatus = await vehicle.updateDirectusData(dataToUpdate);
      // update global state
      this.$store.commit(MutationTypes.updateIndividualVehicle, vehicle);
      // indicate async process has completed
      this.processing = false;
    },

    // form validation
    validateDrag(v: string | number | null): boolean | string {
      if (this.vehicle.fuelType !== "Electric") return true; // no need to validate connectors if not an EV.
      if (!v) return true; // is only an optional property so if not set needs no range checking.
      const value = parseIntOrFloat(v);
      if ((value && value < 0.01) || (value && value > 10))
        return "Please set a value between 0.01 and 10 if setting aerodynamics";
      return true;
    },
    validateRoleResistance(v: string | number | null): boolean | string {
      if (this.vehicle.fuelType !== "Electric") return true; // no need to validate connectors if not an EV.
      if (!v) return true; // is only an optional property so if not set needs no range checking.
      const value = parseIntOrFloat(v);
      if ((value && value < 0.001) || (value && value > 1))
        return "Please set a value between 0.001 and 1 if setting road dynamics";
      return true;
    },
    validateRegenBreaking(v: string | number | null): boolean | string {
      if (this.vehicle.fuelType !== "Electric") return true; // no need to validate connectors if not an EV.
      if (!v) return true; // is only an optional property so if not set needs no range checking.
      const value = parseIntOrFloat(v);
      if ((value && value < 1) || (value && value > 100))
        return "Please set a value between 1% and 100% if setting regenerative breaking efficiency";
      return true;
    },
    validatePowerChainEfficiency(v: string | number | null): boolean | string {
      if (this.vehicle.fuelType !== "Electric") return true; // no need to validate connectors if not an EV.
      if (!v) return true; // is only an optional property so if not set needs no range checking.
      const value = parseIntOrFloat(v);
      if ((value && value < 1) || (value && value > 100))
        return "Please set a value between 1% and 100% if setting power chain efficiency";
      return true;
    },
    validateMaxAC(v: string | number | null): boolean | string {
      if (this.vehicle.fuelType !== "Electric") return true; // no need to validate connectors if not an EV.
      if (!v) return true; // is only an optional property so if not set needs no range checking.
      const value = parseIntOrFloat(v);
      if ((value && value < 0) || (value && value > 50))
        return "Please set a value between 0kw and 50kw if setting AC charging speed";
      return true;
    },
    validateMaxDC(v: string | number | null): boolean | string {
      if (this.vehicle.fuelType !== "Electric") return true; // no need to validate connectors if not an EV.
      if (!v) return true; // is only an optional property so if not set needs no range checking.
      const value = parseIntOrFloat(v);
      if ((value && value < 0) || (value && value > 1000))
        return "Please set a value between 0kw and 1000kw if setting DC charging speed";
      return true;
    },
    validateFuelType(v: string | number | null): boolean | string {
      if (v) return true;
      return "Fuel type is required";
    },
    validateBattery(v: string | number | null): boolean | string {
      if (this.fuelType !== "Electric") return true; // no need to validate connectors if not an EV.
      if (v) {
        const parsedVal = parseIntOrFloat(v);
        if (parsedVal === 0) return "Must be a positive number"; // ensure inputs of "0" are not treated the same as ""
        if (parsedVal && parsedVal < 0) return "Must be a positive number"; // positive numbers only.
        if (!parsedVal) return "Needs to be a number"; // string passed is not parsable as a number, vuetify behavior makes this unlikely.
        if (parsedVal) return true;
      }
      if (v === "") return "Needs to be a number"; // this version of vuetify treats NaN inputs as empty strings.
      return "An EV needs to have a battery"; // this code is only reachable if this is a null.
    },
  },
  watch: {
    /** Watch the image upload status to trigger conditional rendering depending on changes. */
    imageUploadStatus(val: "processing" | "success" | "failed" | null) {
      if (val === "success" || val === "failed") {
        setTimeout(() => {
          this.showingUploader = false;
          this.imageFile = undefined;
        }, 500);
      }
    },
  },
  components: { PulseLoader, LoadingCard, StyledToolTip },
  mounted() {
    this.$nextTick(() => {
      if (this.vehicle as Vehicle | undefined) {
        // set initial values
        this.name = (this.vehicle as Vehicle).name ?? null;
        this.plate = (this.vehicle as Vehicle).licensePlate ?? null;
        this.VIN = (this.vehicle as Vehicle).VIN ?? null;
        this.fuelType = (this.vehicle as Vehicle).fuelType ?? "Electric";
        this.evModel = (this.vehicle as Vehicle).evModel ?? null;
        this.batterySize = (this.vehicle as Vehicle).batterySize;
        this.SOH = (this.vehicle as Vehicle).stateOfHealth
          ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            (this.vehicle as Vehicle).stateOfHealth! * 100
          : 100;
        this.Mass = (this.vehicle as Vehicle).mass ?? null;
        this.DragCoefficient =
          (this.vehicle as Vehicle).dragCoefficient ?? null;
        this.RollingResistanceCoefficient =
          (this.vehicle as Vehicle).rollingResistanceCoefficient ?? null;
        this.RegenerativeBreakingEfficiency = (this.vehicle as Vehicle)
          .regenerativeBreakingEfficiency
          ? (this.vehicle as Vehicle).regenerativeBreakingEfficiency * 100
          : null;
        this.MaxElectricPowerAc =
          (this.vehicle as Vehicle).maxElectricPowerAC ?? null;
        this.MaxElectricPowerDc =
          (this.vehicle as Vehicle).maxElectricPowerDC ?? null;
        this.PowerChainEfficiency = (this.vehicle as Vehicle)
          .powerChainEfficiency
          ? (this.vehicle as Vehicle).powerChainEfficiency * 100
          : null;
        this.selectedConnectors = this.getInitialConnectors();
      }
      this.loading = false;
    });
  },
});
</script>
<style scoped>
#battery-custom-input {
  /* position off set centered element 145px width within a 80% width container */
  padding-left: calc(40% - 108px);
  padding-right: calc(40% - 37px);
}
.right-aligned-input >>> input {
  text-align: right;
}

.none-underlined-input >>> .v-input__slot::before {
  border-style: none !important;
}

.off-set-input-message >>> .v-messages__message {
  padding-top: 16px;
}

.pwt-scrollbar-styles {
  scrollbar-color: #ffffff #e0e0e0;
  scrollbar-width: thin;
}

.pwt-scrollbar-styles:hover {
  scrollbar-color: #eeeeee #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar {
  width: 6px;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track:hover {
  background: #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb:hover {
  background: #eeeeee;
}

* >>> .v-slider--horizontal {
  margin-left: unset;
  margin-right: unset;
}
* >>> .v-slider--horizontal .v-slider__track-container {
  height: 6px; /* override default slider thickness */
}

* >>> .v-slider__track-fill {
  border-radius: 2px; /* override default slider border-radius */
}

* >>> .v-slider__track-background {
  border-radius: 2px; /* override default slider border-radius */
}

.svg_base {
  width: 56px;
  height: 56px;
}
.svg_outline {
  filter: drop-shadow(1px 1px 0 white) drop-shadow(-1px -1px 0 white)
    drop-shadow(-1px 1px 0 white) drop-shadow(1px -1px 0 white);
}

.svg_grayed_out {
  filter: grayscale(1);
}

.selected_svg_container_background {
  background-color: #adedd2;
}
</style>
