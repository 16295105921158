<template>
  <v-card flat v-if="locationData" class="px-8">
    <v-card-title class="pt-0">{{ locationData.address }}</v-card-title>
    <v-card-text>
      <ul style="list-style: none" class="pl-0">
        <li v-if="locationData.time">
          {{ getTimeDisplayMessage(locationData.id, locationData.time) }}
        </li>
        <li
          v-if="locationData.id === 'location-start' && tripData"
          class="pt-2"
        >
          Departing charge {{ Math.round(tripData.parameters.SOCAct * 100) }}%
        </li>
        <li
          v-else-if="locationData.id === 'location-end' && tripData"
          class="pt-2"
        >
          Arriving charge {{ Math.round(tripData.parameters.SOCEnd * 100) }}%
        </li>
        <li v-if="locationData.stay" class="pt-2">
          You are scheduled to be at this location for
          {{ calcStayDuration(locationData.stay) }}
        </li>
        <li
          v-if="locationData.chargeHere && locationData.SOC_AfterCharge"
          class="pt-2"
        >
          You have indicated that you will charge to
          {{ locationData.SOC_AfterCharge * 100 }}% while at this location.
        </li>
      </ul>
    </v-card-text>
    <v-expansion-panels
      class="mb-5"
      v-if="
        locationData.id !== 'location-start' &&
        locationData.id !== 'location-end'
      "
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="d-flex text-body-2 font-weight-medium">
          <span>
            <v-icon color="primary" class="mr-1"> $stationsIcon </v-icon>
            <span>
              {{ closeByChargers.length || "No" }} charging station{{
                closeByChargers.length > 1 ? "s" : ""
              }}
              near by</span
            >
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card
            v-for="(station, index) in closeByChargers"
            :key="`close-by-station-${index}`"
            @click="openChargingStationDetails(station.id)"
          >
            <v-card-title class="font-weight-bold text-body-2">
              {{ station.name }}
            </v-card-title>
            <v-card-subtitle class="grey--text text--darken-2">
              {{ station.simpleDisplayRating }}
            </v-card-subtitle>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
  <v-card flat v-else>
    <v-card-text>
      Whoops! there was a problem in loading this locations details, please
      close this popup and try again.
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import Vue from "vue";
import { mapGetters } from "vuex";
import { Duration } from "luxon";
import TripLocation from "@/logic/classes/tripLocation";
import Charger from "@/logic/classes/charger";
import { GettersTypes, type State } from "@/logic/store/store_types";
import Trip from "@/logic/classes/trip";
import { MutationTypes } from "@/logic/store/store_types";
import { MainDialogContent } from "@/logic/store/store_types";
import TspTrip from "@/logic/classes/tspTrip";

export default Vue.extend({
  name: "LocationDetailsContent",
  computed: {
    closeByChargers(): Charger[] {
      const trip: Trip | TspTrip | undefined =
        this.$store.getters[GettersTypes.selectedTripData];
      const location: TripLocation | undefined =
        this.$store.getters[GettersTypes.selectedLocationData];
      const chargers: Charger[] = (this.$store.state as State).chargers;
      if (trip instanceof Trip && location) {
        return location.getCloseChargers(
          chargers,
          trip.chargersAlongRouteCDBIDs
        );
      }
      if (trip instanceof TspTrip && location) {
        return location.getCloseChargers(chargers);
      }
      return [];
    },
    ...mapGetters({
      locationData: GettersTypes.selectedLocationData,
      tripData: GettersTypes.selectedTripData,
    }),
  },
  methods: {
    calcStayDuration(staySeconds: number): string {
      return Duration.fromObject({
        hours: 0,
        minutes: Math.round(staySeconds / 60),
      })
        .normalize()
        .toHuman({ unitDisplay: "narrow" })
        .replace(",", "");
    },
    getTimeDisplayMessage(id: string, time: string): string {
      switch (id) {
        case "location-start":
          return `Departure time ${time}`;
        case "location-end":
        default:
          return `Arrival time ${time}`;
      }
    },
    openChargingStationDetails(id: string): void {
      this.$store.commit(MutationTypes.setSelectedCharger, id);
      this.$store.commit(MutationTypes.setTertiaryDialogContent, undefined);
      this.$store.commit(
        MutationTypes.setMainDialogContent,
        MainDialogContent.CHARGING_STATION_DETAILS
      );
    },
  },
});
</script>
