<template>
  <v-card flat style="height: 100%" class="transparent">
    <v-card-text v-if="status === 'PROCESSING'" class="primary--text">
      <v-progress-circular :width="3" indeterminate color="primary" />
      Processing...
    </v-card-text>
    <v-card-text v-else-if="status === 'SUCCESS'" class="success--text">
      <v-icon color="success"> mdi-check-circle </v-icon>
      Deleted successfully.
    </v-card-text>
    <v-card-text v-else-if="status === 'FAILED'" class="error--text">
      <v-icon color="error"> mdi-alert-circle </v-icon>
      Whoops! Something went wrong and this trip was not deleted.
    </v-card-text>
    <v-card-text v-else>
      Are you sure you want to delete your trip{{
        tripToDelete
          ? ` to ${
              tripToDelete.locations[tripToDelete.locations.length - 1].address
            }`
          : ""
      }}. This can not be undone.
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="primary"
        class="rounded-lg text-none flex-grow-1"
        dark
        @click="handelDelete"
        v-if="status === undefined"
      >
        Delete
      </v-btn>
      <v-btn
        v-else-if="status !== 'PROCESSING'"
        color="primary"
        class="rounded-lg text-none flex-grow-1"
        dark
        @click="handleClose"
      >
        Ok
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import { ActionTypes } from "@/logic/store/store_types";
import {
  AsyncStatus,
  GettersTypes,
  MutationTypes,
  type State,
} from "@/logic/store/store_types";
import Vue from "vue";
import { mapGetters, mapState } from "vuex";

/** Vue Component: renders are you sure you want to delete this trip
 * confirmation content. To be used in `SecondaryDialog` component. */
export default Vue.extend({
  name: "DeleteTripConfirmationCard",
  computed: {
    ...mapGetters({
      tripToDelete: GettersTypes.queuedForDeleteTripData,
    }),
    ...mapState({
      status: (state: unknown): AsyncStatus | undefined =>
        (state as State).deleteTripStatus,
    }),
  },
  methods: {
    handelDelete() {
      // Notify analytics server
      // eslint-disable-next-line
      // @ts-ignore
      Vue.prototype.$Countly.q.push([
        "add_event",
        {
          key: "Trip Deleted",
          count: 1,
        },
      ]);
      if (this.tripToDelete) {
        this.$store.dispatch(ActionTypes.deleteTrip);
      }
    },
    handleClose() {
      this.$store.commit(MutationTypes.setSecondaryDialogContent, undefined);
      this.$store.commit(MutationTypes.setQueuedTripForDelete, undefined);
    },
  },
});
</script>
