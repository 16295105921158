<template>
  <v-card flat>
    <v-card class="rounded-lg mt-5" style="position: relative">
      <v-card-text>
        <v-row class="justify-space-between" no-gutters>
          <v-container style="width: 40px" class="d-flex flex-column">
            <!-- <v-icon color="primary" large> mdi-map-marker </v-icon> -->
            <v-icon color="primary" @click="handleMoveWaypoint('up')">
              mdi-arrow-up-bold
            </v-icon>
            <v-icon color="primary" @click="handleMoveWaypoint('down')">
              mdi-arrow-down-bold
            </v-icon>
          </v-container>
          <v-container
            class="flex-grow-1 flex-shrink-1 mx-1"
            style="max-width: calc(100% - 88px)"
          >
            <AddressAutocompleteInput
              :id="locationData.localId"
              :initialValue="
                locationData.address
                  ? {
                      address: locationData.address,
                      coordinates: locationData.coordinates,
                    }
                  : undefined
              "
              label="Heading to"
              @update="handleAddressChange"
              :errorMsg="errorMsg"
              :loading="loading"
            />
            <v-card-text class="pl-0 pt-1 text-caption pb-1 secondary--text">
              Stay Duration
            </v-card-text>
            <StayDurationInput
              :identifier="locationData.localId"
              :initialValue="locationData.stay"
              @update="handleStayChange"
            />
            <SOCSlider
              v-if="chargeHere"
              :identifier="locationData.localId"
              label="How much will you charge to"
              @update="handleSOCChange"
            />
            <!-- more details section -->
            <v-expansion-panels accordion v-model="expanded" flat>
              <v-expansion-panel>
                <v-expansion-panel-header class="primary--text px-0">{{
                  expanded === 0 ? "Less details" : "Add more details"
                }}</v-expansion-panel-header>
                <v-expansion-panel-content class="ml-n6 mr-n10 pr-4">
                  <TimePickerInput
                    :identifier="locationData.localId"
                    :initialValue="locationData.time"
                    label="Arrive at"
                    @update="handleTimeChange"
                  />
                  <DatePickerInput
                    :identifier="locationData.localId"
                    :initialValue="locationData.date"
                    label="Arrival date"
                    @update="handleDateChange"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-container>
          <v-container style="width: 40px" class="d-flex flex-column pt-7">
            <v-btn icon @click="handleRemoveWaypoint">
              <v-icon id="pwt-remove-waypoint-btn" large>mdi-trash-can</v-icon>
            </v-btn>
          </v-container>
        </v-row>
      </v-card-text>
      <v-tooltip
        left
        attach
        open-delay="300"
        content-class="rounded-lg"
        color="primary"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
            style="position: absolute; top: 5px; right: 5px"
            small
          >
            mdi-help-circle
          </v-icon>
        </template>
        <span>
          Fill this is if you need to stop somewhere before your final
          destination
        </span>
      </v-tooltip>
    </v-card>
    <v-row no-gutters class="pt-3">
      <v-btn
        color="primary"
        dark
        class="text-none rounded-lg mr-2 px-2 px-sm-3 px-md-4"
        @click="toggleChargeHere"
      >
        {{ chargeHere ? "Don't charge here" : "Charge here" }}
      </v-btn>
      <v-btn
        color="primary"
        text
        class="text-none rounded-lg flex-grow-1 mr-1 px-2 px-sm-3 px-md-4"
        @click="handleAddWaypoint"
      >
        Add a stop
      </v-btn>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import StayDurationInput, {
  StayDurationInputUpdateObj,
} from "../../../ui-elements/StayDurationInput.vue";
import AddressAutocompleteInput, {
  AddressAutocompleteInputUpdateObj,
} from "../../../ui-elements/AddressAutocompleteInput.vue";
import TimePickerInput, {
  TimePickerInputUpdateObj,
} from "../../../ui-elements/TimePickerInput.vue";
import SOCSlider, {
  SOCSliderUpdateObj,
} from "../../../ui-elements/SOCSlider.vue";
import DatePickerInput, {
  DatePickerInputUpdateObj,
} from "../../../ui-elements/DatePickerInput.vue";
import { processedAddressObj } from "@/logic/utils/processAddressSearchResults";
import TripLocation from "@/logic/classes/tripLocation";
import GeoLocation from "@/logic/classes/geoLocation";

/** Vue Component: renders additional waypoints to be used in the `NewTripForm` component.
 *
 * @props `locationData` - the whole `locationData` object for this location.
 */
export default Vue.extend({
  name: "AdditionalWaypointCard",
  props: {
    locationData: Object as PropType<TripLocation>,
    index: Number,
    errorMsg: {
      type: String as PropType<string | null>,
      default: null,
    },
    loading: {
      type: Boolean as PropType<boolean | undefined>,
      default: false,
    },
  },
  data() {
    return {
      waypointNeedsUpdate: false,
      address: null as processedAddressObj | null,
      time: "",
      stay: 0,
      chargeHere: false,
      SOC: undefined as number | undefined,
      date: undefined as string | undefined,
      expanded: undefined as number | undefined,
    };
  },
  methods: {
    flagForUpdate() {
      this.waypointNeedsUpdate = true;
    },
    handleAddressChange(val: AddressAutocompleteInputUpdateObj) {
      if (val.addressData) {
        this.address = val.addressData;
      } else {
        this.address = null;
      }
      // flag for update
      this.flagForUpdate();
    },
    handleAddWaypoint() {
      this.$emit("add", this.index + 1);
    },
    handleRemoveWaypoint() {
      this.$emit("remove", this.locationData.localId);
    },
    handleStayChange(val: StayDurationInputUpdateObj) {
      // not null guard clause
      if (!val.duration) return;
      // update local state
      this.stay = val.duration;
      // flag for update
      this.flagForUpdate();
    },
    handleTimeChange(val: TimePickerInputUpdateObj) {
      this.time = val.time;
      // flag for update
      this.flagForUpdate();
    },
    handleMoveWaypoint(move: "up" | "down") {
      const newIndex = move === "up" ? this.index - 1 : this.index + 1;
      this.$emit("move", {
        id: this.locationData.localId,
        oldIndex: this.index,
        newIndex,
      });
    },
    handleDateChange(val: DatePickerInputUpdateObj) {
      this.date = val.date ? val.date : undefined;
      // flag for update
      this.flagForUpdate();
    },
    handleSOCChange(val: SOCSliderUpdateObj) {
      // not null guard clause
      if (!val.SOC) return;
      this.SOC = val.SOC;
    },

    toggleChargeHere() {
      this.chargeHere = !this.chargeHere;
      // flag for update
      this.flagForUpdate();
    },
  },
  watch: {
    waypointNeedsUpdate(val: boolean) {
      if (val) {
        this.waypointNeedsUpdate = false;
        // construct new waypoint obj
        const newData: TripLocation = new TripLocation({
          localId: this.locationData.localId,
          address: this.address
            ? this.address.address
            : this.locationData.address,
          coordinates: this.address
            ? new GeoLocation({
                latitude: this.address.coordinates.Latitude,
                longitude: this.address.coordinates.Longitude,
              })
            : this.locationData.coordinates,
          time: this.time ? this.time : this.locationData.time,
          chargeHere: this.chargeHere,
          date: this.date || this.locationData.date,
        });
        // check if optional properties need to be included
        // "stay"
        if (this.stay) {
          newData.stay = this.stay;
        }
        // "SOC_AfterCharge"
        if (this.chargeHere) {
          newData.stateOfChargeAfterCharging = this.SOC;
        }

        this.$emit("update", newData);
      }
    },
  },
  components: {
    StayDurationInput,
    AddressAutocompleteInput,
    TimePickerInput,
    SOCSlider,
    DatePickerInput,
  },
});
</script>
<style scoped>
#pwt-remove-waypoint-btn {
  color: var(--v-secondary-base);
}

#pwt-remove-waypoint-btn:hover {
  color: var(--v-error-base);
}
</style>
