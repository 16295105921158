import { PartialItem } from "@directus/sdk";
import { type CollectionNames } from "../../clients/directusClient";
import {
  directusCreateOne,
  directusDeleteOne,
  directusReadByQuery,
  directusUpdateOne,
  cleanOutSystemGeneratedProperties,
} from "./helperFunctions";
import { TripFrequency } from "@/logic/types/trip_specific_types";
import { EVNavRouteParams } from "@/logic/types/ev_nav_types";

const collectionName: CollectionNames = "Saved_Route_Plans";

/**
 * Fetches all records from the `Saved_Route_Plans` directus collection created by the the given user.
 *
 * @param userID the UUID for the directus user.
 * @returns a list of saved route plans if successful, undefined if not.
 */
export async function fetchSavedRoutePlansByUser(
  userID: string
): Promise<Directus_SavedRoutePlan[] | undefined> {
  return await directusReadByQuery<Directus_SavedRoutePlan>(collectionName, {
    filter: {
      user_created: { _eq: userID },
    },
  });
}

/**
 * Create a new directus `Saved_Route_Plans` collection record.
 *
 * @param saveData partial object to create
 * @returns the newly created record if successful, undefined if not.
 */
export async function createSavedRoutePlan(
  saveData: PartialItem<Directus_SavedRoutePlan>
): Promise<Directus_SavedRoutePlan | undefined> {
  // Ensure no system generate properties that should be only auto updated are included.
  const preppedData =
    cleanOutSystemGeneratedProperties<Directus_SavedRoutePlan>(saveData);
  // check there is still something to update. Exit early if not.
  if (!Object.keys(preppedData).length) return;
  return await directusCreateOne<Directus_SavedRoutePlan>(
    collectionName,
    saveData
  );
}

/**
 * Updates an existing directus `Saved_Route_Plans` collection record.
 *
 * @param recordId the directus `Saved_Route_Plans` collection record id.
 * @param data partial object to update
 * @returns the updated record if successful, undefined if not.
 */
export async function updatedSavedRoutePlans(
  recordId: string | number,
  data: PartialItem<Directus_SavedRoutePlan>
): Promise<Directus_SavedRoutePlan | undefined> {
  // Ensure no system generate properties that should be only auto updated are included.
  const preppedData =
    cleanOutSystemGeneratedProperties<Directus_SavedRoutePlan>(data);

  // check there is still something to update. Exit early if not.
  if (!Object.keys(preppedData).length) return;

  // update directus record.
  return await directusUpdateOne<Directus_SavedRoutePlan>(
    collectionName,
    recordId,
    preppedData
  );
}

/**
 * Delete the target record from the directus `Saved_Route_Plans`
 * collection.
 *
 * @param directusId the record id of the target record.
 * @returns status message outlining the outcome of the operation.
 */
export async function deleteSavedRoutePlans(
  directusId: string | number
): Promise<"ok" | "failed"> {
  return await directusDeleteOne(collectionName, directusId);
}

export interface Directus_SavedRoutePlan {
  id: string | number;
  user_created: string | null;
  date_created: string | null;
  user_updated: string | null;
  date_updated: string | null;
  Locations: Directus_SavedRoutePlan_Location[] | null;
  Name: string | null;
  Vehicle: number | null;
  Total_Distance: number | null;
  Total_Energy: number | null;
  Total_Public_Charging_Cost: number | null;
  Total_Private_Charging_Cost: number | null;
  Primary_Time_Location: string | null;
  Trip_Frequency: TripFrequency | null;
  Engin_Type: "ELECTRIC" | "ICE" | null;
  EV_Nav_Parameters: EVNavRouteParams | null;
}

export interface Directus_SavedRoutePlan_Location {
  address?: string | null;
  Latitude?: number | null;
  Longitude?: number | null;
  Time?: string | null;
  Date?: string | null;
  Stay?: number | null;
  Charge_Here?: boolean;
  SOC_After_Charge?: number | null;
  Location_id?: string | null;
}
