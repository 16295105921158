<template>
  <v-card flat v-if="loading" class="px-16 pb-16 pt-8">
    <v-card-text class="primary--text text-center text-h5">
      Power trip is planning your trip
    </v-card-text>
    <v-card-text class="d-flex justify-center primary--text text-center">
      <LoadingCard />
    </v-card-text>
    <v-card-text class="primary--text text-center text-subtitle-2">
      This may take a few moments
    </v-card-text>
  </v-card>

  <v-card flat class="pa-5" v-else>
    <v-card-title class="tertiary--text pt-5"> Plan a new trip </v-card-title>
    <!-- starting location section -->
    <v-card
      class="rounded-lg py-3 pr-5"
      :key="'location-start' + locations[0].address"
    >
      <v-row no-gutters>
        <v-col
          cols="1"
          class="align-center col col-1 d-flex flex-column pt-2 pl-4"
        >
          <div
            class="rounded-circle primary"
            style="height: 18px; width: 18px"
          ></div>
          <v-icon
            color="primary"
            @click="
              handleMoveLocation({
                id: locations[0].localId,
                oldIndex: 0,
                newIndex: 1,
              })
            "
          >
            mdi-arrow-down-bold
          </v-icon>
        </v-col>
        <v-col cols="11">
          <v-card-text class="py-0">
            <AddressAutocompleteInput
              v-if="!useCarLastKnownLocation"
              :id="locations[0].localId"
              label="Starting from"
              @update="handleAddressChange"
              :initialValue="{
                address: locations[0].address,
                waypoint: locations[0].coordinates,
              }"
              :errorMsg="addressErrors"
              :loading="addressLoading"
            />
            <v-switch
              v-if="selectedVehicleData"
              v-model="useCarLastKnownLocation"
              inset
              :label="
                hasNoLastKnownLocation
                  ? 'Your selected EV has no last known location'
                  : 'Use your EV last known location and charge'
              "
              color="primary"
              @change="handleUseCarToggle"
              :disabled="hasNoLastKnownLocation"
            />
            <SOCSlider
              :initialValue="startingCharge"
              :identifier="locations[0].localId"
              label="Departing charge"
              @update="handleSOCChange"
            />
            <!-- more details section -->
            <v-expansion-panels accordion v-model="startExpanded" flat>
              <v-expansion-panel>
                <v-expansion-panel-header class="primary--text px-0">{{
                  startExpanded === 0 ? "Less details" : "Add more details"
                }}</v-expansion-panel-header>
                <v-expansion-panel-content class="ml-n6 mr-n6">
                  <TimePickerInput
                    :identifier="locations[0].localId"
                    label="Depart at"
                    @update="handleTimeChange"
                    :initialValue="locations[0].time"
                  />
                  <DatePickerInput
                    :identifier="locations[0].localId"
                    label="Departing date"
                    @update="handleDateChange"
                    :initialValue="locations[0].date"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>

    <!-- additional stop locations section -->
    <v-row no-gutters>
      <v-col cols="1">
        <v-divider vertical class="ml-7"></v-divider>
      </v-col>
      <v-col cols="11" class="pb-3">
        <v-row no-gutters class="pt-3">
          <v-btn
            color="primary"
            text
            class="text-none rounded-lg flex-grow-1 mr-1"
            @click="handleAddWaypoint(1)"
          >
            Add a stop
          </v-btn>
        </v-row>
        <template v-for="(location, index) in locations">
          <AdditionalWaypointCard
            v-if="index !== 0 && index !== locations.length - 1"
            :key="location.localId + location.address"
            :locationData="location"
            :index="index"
            @remove="handleRemoveWaypoint"
            @add="handleAddWaypoint"
            @update="handleUpdateWaypoint"
            @move="handleMoveLocation"
            :errorMsg="addressErrors"
            :loading="addressLoading"
          />
        </template>
      </v-col>
    </v-row>

    <!-- destination location section -->
    <v-card
      class="rounded-lg primary white--text py-3"
      :key="'location-end' + locations[locations.length - 1].address"
    >
      <v-row no-gutters>
        <v-col
          cols="1"
          class="align-center col col-1 d-flex flex-column pt-2 pl-4"
        >
          <v-icon
            color="white"
            @click="
              handleMoveLocation({
                id: locations[locations.length - 1].localId,
                oldIndex: locations.length - 1,
                newIndex: locations.length - 2,
              })
            "
          >
            mdi-arrow-up-bold
          </v-icon>
          <div
            class="rounded-circle white"
            style="height: 18px; width: 18px"
          ></div>
        </v-col>
        <v-col cols="11">
          <v-card-text class="py-0 pr-9">
            <AddressAutocompleteInput
              :id="locations[locations.length - 1].localId"
              label="Final destination"
              @update="handleAddressChange"
              dark
              :initialValue="{
                address: locations[locations.length - 1].address,
                waypoint: locations[locations.length - 1].coordinates,
              }"
              :errorMsg="addressErrors"
              :loading="addressLoading"
            />
            <SOCSlider
              :initialValue="finalCharge"
              :identifier="locations[locations.length - 1].localId"
              label="Minimum charge on arrival"
              @update="handleSOCChange"
              dark
            />
            <!-- more details section -->
            <v-expansion-panels
              accordion
              class="primary"
              v-model="endExpanded"
              dark
              flat
            >
              <v-expansion-panel class="primary">
                <v-expansion-panel-header class="px-0">{{
                  endExpanded === 0 ? "Less details" : "Add more details"
                }}</v-expansion-panel-header>
                <v-expansion-panel-content class="ml-n6 mr-n6">
                  <TimePickerInput
                    :identifier="locations[locations.length - 1].localId"
                    label="Arrive at"
                    @update="handleTimeChange"
                    dark
                    :initialValue="locations[locations.length - 1].time"
                  />
                  <DatePickerInput
                    :identifier="locations[locations.length - 1].localId"
                    label="Arrival date"
                    @update="handleDateChange"
                    dark
                    :initialValue="locations[locations.length - 1].date"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>

    <!-- frequency and actions section -->
    <v-row no-gutters>
      <v-col cols="1">
        <v-divider vertical class="ml-7"></v-divider>
      </v-col>
      <v-col cols="11"> </v-col>
    </v-row>

    <v-card flat>
      <v-card-text class="pb-0">
        Add this trip to your list of frequent trips to calculate your long-term
        savings
      </v-card-text>
      <FrequencySelectInput @update="handelFrequencyChange" />
      <PrimaryTimeSelectContent
        :locationsWithTime="locationsWithTime"
        :showDialog="showPrimaryTimeSelectDialog"
        @update="handelPrimaryTimeSelect"
      />
      <v-btn
        block
        color="primary"
        class="text-none rounded-lg mb-3"
        @click="planTrip"
        :disabled="preventPlaning || addressLoading"
        :loading="addressLoading"
      >
        Plan trip
      </v-btn>
      <v-btn
        @click="back"
        :color="darkBlue"
        block
        outlined
        class="text-none rounded-lg"
      >
        Cancel
      </v-btn>
      <v-card-text
        v-if="!!errorMsg || !!addressErrors"
        class="error--text pt-1"
      >
        Whoops! {{ errorMsg ?? addressErrors }}
      </v-card-text>
    </v-card>
  </v-card>
</template>
<script lang="ts">
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import AdditionalWaypointCard from "./AdditionalWaypointCard.vue";
import TimePickerInput, {
  TimePickerInputUpdateObj,
} from "../../../ui-elements/TimePickerInput.vue";
import AddressAutocompleteInput, {
  AddressAutocompleteInputUpdateObj,
} from "../../../ui-elements/AddressAutocompleteInput.vue";
import SOCSlider, {
  SOCSliderUpdateObj,
} from "../../../ui-elements/SOCSlider.vue";
import DatePickerInput, {
  DatePickerInputUpdateObj,
} from "../../../ui-elements/DatePickerInput.vue";
import PrimaryTimeSelectContent from "../dialog-content/PrimaryTimeSelectContent.vue";
import FrequencySelectInput from "../../../ui-elements/FrequencySelectInput.vue";
import TripLocation from "../../../../../logic/classes/tripLocation";
import type {
  TripFrequency,
  TripPlanningFormData,
} from "../../../../../logic/types/trip_specific_types";
import LoadingCard from "../../../ui-elements/LoadingCard.vue";
import { ActionTypes, MutationTypes, State } from "@/logic/store/store_types";
import Vehicle from "@/logic/classes/vehicle";
import { powerTripDarkBlue } from "@/logic/data/const";
import { quickTripCheck } from "@/logic/api/calls/valhalla_calls";
import { QuickTripCheckReturn } from "@/logic/types/valhalla_types";

export default Vue.extend({
  name: "TripPlanningForm",
  data() {
    return {
      darkBlue: powerTripDarkBlue,
      locations: [new TripLocation({}), new TripLocation({})] as TripLocation[],
      frequency: undefined as TripFrequency | undefined,
      primaryTime: null as string | undefined | null,
      errorMsg: null as string | null,
      showPrimaryTimeSelectDialog: false,
      startExpanded: undefined as undefined | number,
      endExpanded: undefined as undefined | number,
      useCarLastKnownLocation: false,
      hasNoLastKnownLocation: false,
      addressErrors: null as string | null,
      addressLoading: false,
      preventPlaning: false,
    };
  },
  components: {
    AdditionalWaypointCard,
    TimePickerInput,
    AddressAutocompleteInput,
    FrequencySelectInput,
    SOCSlider,
    DatePickerInput,
    PrimaryTimeSelectContent,
    LoadingCard,
  },
  methods: {
    prepForm() {
      this.locations = [new TripLocation({}), new TripLocation({})];
      this.errorMsg = null;
      this.frequency = undefined;
      this.primaryTime = null;
      this.showPrimaryTimeSelectDialog = false;
      this.useCarLastKnownLocation = false;
      this.hasNoLastKnownLocation = false;
    },
    findLocation() {
      if (
        this.selectedVehicleData &&
        this.selectedVehicleData.latitude &&
        this.selectedVehicleData.longitude
      ) {
        this.locations[0] = new TripLocation({
          ...this.locations[0],
          address: "EV last known location",
          coordinates: {
            latitude: this.selectedVehicleData.latitude,
            longitude: this.selectedVehicleData.longitude,
          },
        });
        if ((this.selectedVehicleData as Vehicle).stateOfCharge) {
          this.$store.commit(
            MutationTypes.setSOCAct,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            (this.selectedVehicleData as Vehicle).stateOfCharge! / 100
          );
        }
        return;
      }
      this.useCarLastKnownLocation = false;
      this.hasNoLastKnownLocation = true;
    },
    handleUseCarToggle(val: boolean) {
      if (val) {
        this.findLocation();
      } else {
        this.locations[0] = new TripLocation({});
      }
    },
    handleAddWaypoint(insertAtIndex: number) {
      // Notify analytics server
      // eslint-disable-next-line
      // @ts-ignore
      Vue.prototype.$Countly.q.push([
        "add_event",
        {
          key: "Waypoint added",
          count: 1,
        },
      ]);
      if (insertAtIndex === this.locations.length) {
        this.locations.push(new TripLocation({}));
      } else {
        this.locations.splice(insertAtIndex, 0, new TripLocation({}));
      }
    },
    handleRemoveWaypoint(waypointId: string) {
      // Notify analytics server
      // eslint-disable-next-line
      // @ts-ignore
      Vue.prototype.$Countly.q.push([
        "add_event",
        {
          key: "Waypoint Removed",
          count: 1,
        },
      ]);

      this.locations = this.locations.filter(
        (location) => location.localId !== waypointId
      );
    },
    handleMoveLocation({
      id,
      oldIndex,
      newIndex,
    }: {
      id: string;
      oldIndex: number;
      newIndex: number;
    }) {
      const location = this.locations.find(
        (location) => location.localId === id
      );
      // not undefined guard clause.
      if (!location) return;

      this.locations = TripLocation.reOrderTripLocations(
        this.locations,
        oldIndex,
        newIndex
      );
      return;
    },
    handleUpdateWaypoint(locationData: TripLocation) {
      const locationIndex = this.locations.findIndex(
        (location) => location.localId === locationData.localId
      );
      if (locationIndex === -1) return;
      this.locations.splice(locationIndex, 1, locationData);
    },
    handleAddressChange(val: AddressAutocompleteInputUpdateObj) {
      // not null guard clause
      if (!val.addressData) return;
      // find location to update
      const location = this.locations.find(
        (location) => location.localId === val.id
      );
      const locationIndex = this.locations.findIndex(
        (location) => location.localId === val.id
      );
      // find operation failed guard clause
      if (!location) return;
      if (locationIndex === -1) return;
      // create new object
      const tempObj: TripLocation = new TripLocation({
        ...location,
        address: val.addressData.address,
        coordinates: {
          latitude: val.addressData.coordinates.Latitude,
          longitude: val.addressData.coordinates.Longitude,
        },
      });

      // update local state
      this.locations.splice(locationIndex, 1, tempObj);
    },
    handleTimeChange(val: TimePickerInputUpdateObj) {
      // find location to update
      const location = this.locations.find(
        (location) => location.localId === val.identifier
      );
      const locationIndex = this.locations.findIndex(
        (location) => location.localId === val.identifier
      );
      // find operation failed guard clause
      if (!location) return;
      if (locationIndex === -1) return;

      // create new object
      const tempObj: TripLocation = new TripLocation({
        ...location,
        time: val.time,
      });

      // update local state
      this.locations.splice(locationIndex, 1, tempObj);
    },
    handleDateChange(val: DatePickerInputUpdateObj) {
      // find location to update
      const location = this.locations.find(
        (location) => location.localId === val.identifier
      );
      const locationIndex = this.locations.findIndex(
        (location) => location.localId === val.identifier
      );
      // find operation failed guard clause
      if (!location) return;
      if (locationIndex === -1) return;

      // create new object
      const tempObj: TripLocation = new TripLocation({
        ...location,
        date: val.date ? val.date : undefined,
      });

      // update local state
      this.locations.splice(locationIndex, 1, tempObj);
    },
    handleSOCChange(val: SOCSliderUpdateObj) {
      // not null guard clause
      if (!val.SOC) return;

      // find location to update
      const location = this.locations.find(
        (location) => location.localId === val.identifier
      );
      const locationIndex = this.locations.findIndex(
        (location) => location.localId === val.identifier
      );
      // find operation failed guard clause
      if (!location) return;
      if (locationIndex === -1) return;
      // create new object
      const tempObj: TripLocation = new TripLocation({
        ...location,
        stateOfChargeAfterCharging: val.SOC,
      });

      // update local state
      this.locations.splice(locationIndex, 1, tempObj);
      // update global state
      if (val.identifier === this.locations[0].localId) {
        this.$store.commit(MutationTypes.setSOCAct, val.SOC);
      }
      if (
        val.identifier === this.locations[this.locations.length - 1].localId
      ) {
        this.$store.commit(MutationTypes.setSOCEnd, val.SOC);
      }
    },
    handelFrequencyChange(val: TripFrequency | undefined) {
      this.frequency = val;
    },
    handelPrimaryTimeSelect(val: string | undefined) {
      this.showPrimaryTimeSelectDialog = false;
      this.primaryTime = val;
      this.planTrip();
    },
    planTrip() {
      // clear error messages
      this.errorMsg = null;

      // validation
      const startLocation = this.locations[0];
      const hasStartLocation = !!startLocation;
      const startLocationHasAddress = !!startLocation?.address;
      const startLocationHasCoordinates = !!(
        !!startLocation?.coordinates.latitude &&
        !!startLocation?.coordinates.longitude
      );

      if (
        startLocation &&
        hasStartLocation &&
        startLocationHasAddress &&
        startLocationHasCoordinates
      ) {
        // do nothing as it passed validation.
      } else {
        // error start address failed validation.
        this.errorMsg = "Please add a starting location";
        return;
      }

      const destinationLocation = this.locations[this.locations.length - 1];
      const hasDestinationLocation = !!destinationLocation;
      const destinationLocationHasAddress = !!destinationLocation?.address;
      const destinationLocationHasCoordinates = !!(
        !!destinationLocation?.coordinates.latitude &&
        !!destinationLocation?.coordinates.longitude
      );

      if (
        destinationLocation &&
        hasDestinationLocation &&
        destinationLocationHasAddress &&
        destinationLocationHasCoordinates
      ) {
        // do nothing as it passed validation.
      } else {
        // error destination address failed validation.
        this.errorMsg = "Please add a destination location";
        return;
      }

      const locationsWithTime = this.locations.filter(
        (location) => location.time
      );

      if (!locationsWithTime.length && this.primaryTime === null) {
        this.primaryTime = undefined;
      }

      if (locationsWithTime.length === 1 && this.primaryTime === null) {
        this.primaryTime = locationsWithTime[0].localId;
      }

      if (locationsWithTime.length > 1 && this.primaryTime === null) {
        // open primary time picker
        this.showPrimaryTimeSelectDialog = true;
        return;
      }

      if (this.primaryTime === null) {
        this.errorMsg = "Please select a primary time";
        return;
      }

      const payload: TripPlanningFormData = {
        locations: this.locations,
        vehicle: this.selectedVehicleData,
        frequency: this.frequency,
        SOCAct: this.startingCharge,
        SOCEnd: this.finalCharge,
        primaryTimeLocation: this.primaryTime,
      };

      // plan trip
      this.$store.dispatch(ActionTypes.createNewTrip, payload);

      // Notify analytics server
      // eslint-disable-next-line
      // @ts-ignore
      Vue.prototype.$Countly.q.push([
        "add_event",
        {
          key: "New Trip Planned",
          count: 1,
        },
      ]);

      // clean up from
      this.prepForm();
    },
    back() {
      this.$router.back();
    },
  },
  computed: {
    ...mapGetters({
      selectedVehicleData: "selectedVehicleData",
    }),
    ...mapState({
      loading: (state: unknown): boolean => (state as State).routePlanningFlag,
      startingCharge: (state: unknown): number => (state as State).SOCAct,
      finalCharge: (state: unknown): number => (state as State).SOCEnd,
    }),
    locationsWithTime(): TripLocation[] {
      return this.locations.filter((location) => location.time);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.prepForm();
    });
  },
  beforeDestroy() {
    this.prepForm();
  },
  watch: {
    async locations(val: TripLocation[]) {
      if (val.length >= 2 && val.every((location) => !location.isNullIsland)) {
        this.addressLoading = true;
        const quickCheckRes = await quickTripCheck(
          val.map((location) => ({
            lat: location.coordinates.latitude,
            lon: location.coordinates.longitude,
          }))
        );
        if (quickCheckRes === QuickTripCheckReturn.routable) {
          // clear address errors as routable.
          this.addressErrors = null;
          this.preventPlaning = false;
        } else if (quickCheckRes === QuickTripCheckReturn.unconnected_regions) {
          this.addressErrors = "locations are in unconnected regions";
          this.preventPlaning = true;
        } else if (quickCheckRes === QuickTripCheckReturn.not_routable) {
          this.addressErrors = "locations are not routable";
          this.preventPlaning = true;
        }
        this.addressLoading = false;
      }
    },
  },
});
</script>
