import type {
  EVNavEnergy,
  EVNavEnergyParams,
  EVNavNearbyChargersRes,
  EVNavNearbyParams,
  EVNavRadarParams,
  EVNavRadarResponse,
  EVNavRouteParams,
} from "@/logic/types/ev_nav_types";
import {
  EV_NAV_ENERGY_ENDPOINT,
  EV_NAV_MULTI_ROUTES_ENDPOINT,
  EV_NAV_NEARBY_ENDPOINT,
  EV_NAV_RADAR_ENDPOINT,
  EV_NAV_ROUTE_ENDPOINT,
} from "../clients/evNavClient";
import Vue from "vue";

/** Notify analytics of a passed error. */
function notifyAnalytics(error: Error, functionName: string) {
  if (process.env.NODE_ENV === "development")
    console.log(
      "#ERROR DEV NOTIFICATION: `" + functionName + "` errored with",
      error
    );
  Vue.prototype.$Countly.q.push([
    "recordError",
    { stack: error.message },
    true,
    {
      type: "api call",
      filePath: "src\\logic\\api\\calls\\ev_nav_calls.ts",
      functionName,
    },
  ]);
}

/**
 * Fetch a list of chargers with in range of a full trip
 *
 * @param radarParams parameters for range service - see `routPlaningStore` for object shape.
 * @returns an array of charger objects with in range
 */
export async function evnavRadarCall(
  radarParams: EVNavRadarParams
): Promise<EVNavRadarResponse | undefined> {
  try {
    const response = await fetch(EV_NAV_RADAR_ENDPOINT, {
      method: "POST",
      body: JSON.stringify(radarParams),
      headers: { "Content-Type": "application/json" },
    });
    const convertedResponse: { result: EVNavRadarResponse } =
      await response.json();
    return convertedResponse.result;
  } catch (error) {
    notifyAnalytics(error as Error, "evnavRadarCall");
    return;
  }
}

/**
 * Fetch multiple planned routes in one call form the ev nav service
 *
 * @param routeParams an array of `RouteParams` objects - see types for object shape.
 * @returns multiple fully planed routes, if a route is possible else one of the servers other status messages - see `routePlanningStore` for potential responses.
 */
export async function fetchMultiRoutePlans(routeParams: EVNavRouteParams[]) {
  try {
    const response = await fetch(EV_NAV_MULTI_ROUTES_ENDPOINT, {
      method: "POST",
      body: JSON.stringify(routeParams),
      headers: { "Content-Type": "application/json" },
    });
    const convertedResponse = await response.json();
    return convertedResponse.result;
  } catch (error) {
    notifyAnalytics(error as Error, "fetchMultiRoutePlans");
    return;
  }
}

/**
 * Fetch a planed route form the ev nav service
 *
 * @param routeParams a populated `EVNavRouteParams` object.
 * @returns a fully planed route, if a route is possible else one of the servers other status messages - see `routePlanningStore` for potential responses.
 */
export async function fetchRoutePlan(routeParams: EVNavRouteParams) {
  try {
    const response = await fetch(EV_NAV_ROUTE_ENDPOINT, {
      method: "POST",
      body: JSON.stringify(routeParams),
      headers: { "Content-Type": "application/json" },
    });
    const convertedResponse = await response.json();
    return convertedResponse.result;
  } catch (error) {
    notifyAnalytics(error as Error, "fetchRoutePlan");
    return;
  }
}

/**
 * Fetch a list of chargers reachable by the current EV based on a single point.
 *
 * @param nearbyParams parameters for range service - see `routPlaningStore` for object shape.
 * @returns an array of charger objects.
 */
export async function fetchNearbyChargers(
  nearbyParams: EVNavNearbyParams
): Promise<EVNavNearbyChargersRes[] | undefined> {
  try {
    const response = await fetch(EV_NAV_NEARBY_ENDPOINT, {
      method: "POST",
      body: JSON.stringify(nearbyParams),
      headers: { "Content-Type": "application/json" },
    });
    const convertedResponse: {
      result: EVNavNearbyChargersRes[];
    } = await response.json();
    return convertedResponse.result;
  } catch (error) {
    notifyAnalytics(error as Error, "fetchNearbyChargers");
    return;
  }
}

export async function fetchEnergyNeeded(
  routeParams: EVNavRouteParams | EVNavEnergyParams
): Promise<EVNavEnergy | undefined> {
  try {
    const response = await fetch(EV_NAV_ENERGY_ENDPOINT, {
      method: "POST",
      body: JSON.stringify(routeParams),
      headers: { "Content-Type": "application/json" },
    });
    const convertedResponse: {
      result: EVNavEnergy;
    } = await response.json();
    return convertedResponse.result;
  } catch (error) {
    notifyAnalytics(error as Error, "fetchEnergyNeeded");
    return;
  }
}
