<template>
  <!-- processing update view -->
  <v-card
    flat
    class="px-5 d-flex flex-column justify-center align-center"
    v-if="processing"
  >
    <v-card-title>
      Processing
      <PulseLoader />
    </v-card-title>
    <LoadingCard />
  </v-card>
  <!-- normal view -->
  <v-card flat class="px-5" v-else>
    <v-card-text class="d-flex pb-0">
      <v-avatar color="primary" size="100" class="mr-3">
        <v-img v-if="avatarSrc" :src="avatarSrc" />
        <v-icon v-else dark size="60"> mdi-account-circle </v-icon>
      </v-avatar>
      <v-col>
        <v-row>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="text-none rounded-lg mt-n2"
            @click="saveAndUpload"
          >
            Save changes
          </v-btn>
        </v-row>
        <v-file-input
          accept="image/*"
          label="Upload a new profile image"
          dense
          class="pt-5"
          v-model="avatar"
          prepend-icon="mdi-camera"
        ></v-file-input>
      </v-col>
    </v-card-text>
    <v-card-text>
      <v-row no-gutters>
        <v-text-field
          class="rounded-lg mr-2"
          label="First name"
          outlined
          v-model="firstName"
        ></v-text-field>
        <v-text-field
          class="rounded-lg ml-2"
          label="Last name"
          outlined
          v-model="lastName"
        ></v-text-field>
      </v-row>
      <v-textarea
        class="rounded-lg"
        label="Bio"
        outlined
        v-model="bio"
      ></v-textarea>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import { mapState } from "vuex";
import LoadingCard from "../../../ui-elements/LoadingCard.vue";
import PulseLoader from "../../../ui-elements/PulseLoader.vue";
import User from "@/logic/classes/user";
import Driver from "@/logic/classes/driver";
import { type State } from "@/logic/store/store_types";
import type { DataToUpdate } from "../../../../../logic/types/directus_types";
import { ActionTypes } from "@/logic/store/store_types";

export default Vue.extend({
  name: "EditUserContent",
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
    driver: {
      type: Object as PropType<Driver>,
      required: false,
    },
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      bio: "",
      avatar: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.setInitialValues();
    });
  },
  methods: {
    setInitialValues() {
      this.firstName = this.user.firstName ?? "";
      this.lastName = this.user.lastName ?? "";
      this.bio = this.driver?.bio ?? "";
    },
    saveAndUpload() {
      // check if data has changed from initial values and prep object for dispatch.
      const data: DataToUpdate = {
        avatar: this.avatar ?? undefined,
        bio: this.bio !== this.driver?.bio ? this.bio : undefined,
        firstName:
          this.firstName !== this.user.firstName ? this.firstName : undefined,
        lastName:
          this.lastName !== this.user.lastName ? this.lastName : undefined,
      };
      this.$store.dispatch(ActionTypes.updateUser, data);
    },
  },
  computed: {
    ...mapState({
      processing: (state: unknown) => (state as State).updatingUserStatus,
    }),
    avatarSrc(): string | undefined {
      return (this.driver as Driver | undefined)?.profilePicSrc;
    },
  },
  components: { LoadingCard, PulseLoader },
});
</script>
@/logic/data/user
