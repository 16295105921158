<template>
  <v-dialog
    :value="!!mainDialogContent"
    :width="$vuetify.breakpoint.mdAndUp ? '70%' : '80%'"
    :content-class="$vuetify.breakpoint.xs ? '' : 'rounded-lg'"
    style="z-index: 1200"
    @click:outside="clear()"
    @keydown="keyDown($event)"
    scrollable
    :max-width="$vuetify.breakpoint.mdAndUp ? '800px' : '650px'"
    :min-width="$vuetify.breakpoint.mdAndUp ? '600px' : '350px'"
    :fullscreen="$vuetify.breakpoint.xs"
    retain-focus
  >
    <v-sheet
      class="overflow-hidden py-5"
      :style="
        $vuetify.breakpoint.xs ? 'min-height: 100vh;' : 'min-height: 85vh;'
      "
    >
      <!-- conditionally rendered tool bar -->
      <v-toolbar
        flat
        v-if="
          // mainDialogContent !== 'charging station details' &&
          mainDialogContent !== 'fleetVehicleDetails' &&
          mainDialogContent !== 'vehicle settings' &&
          mainDialogContent !== 'vehicle booking' &&
          mainDialogContent !== 'vehicle gallery custom' &&
          mainDialogContent !== 'vehicle gallery connected' &&
          mainDialogContent !== 'vehicle gallery generic' &&
          mainDialogContent !== 'needsRecalc'
        "
        :extended="
          ($vuetify.breakpoint.xsOnly && mainDialogContent === 'guide') ||
          ($vuetify.breakpoint.xs && mainDialogContent === 'savings settings')
        "
        extension-height="24"
      >
        <!-- conditionally rendered icons -->
        <v-icon v-if="!!icon" class="mx-3" color="primary" large>{{
          icon
        }}</v-icon>

        <!-- conditionally rendered titles -->
        <h2
          v-if="title"
          class="primary--text font-weight-regular"
          :style="
            $vuetify.breakpoint.xs && mainDialogContent === 'savings settings'
              ? 'padding-top: 1.5rem;'
              : ''
          "
        >
          {{ title }}
        </h2>

        <h2
          class="primary--text font-weight-regular pt-3 pt-sm-0"
          v-if="mainDialogContent === 'guide'"
          :style="
            $vuetify.breakpoint.smAndDown
              ? 'font-size: 1.1rem'
              : 'font-size: 1.4rem'
          "
        >
          Guide to route planning and charging station booking
        </h2>

        <v-spacer></v-spacer>
        <v-btn icon @click="clear()" class="align-self-start">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <div
        class="pwt-scroll-container pwt-scrollbar-styles overflow-y-auto overflow-x-hidden px-2 px-sm-0"
        :style="
          mainDialogContent !== 'charging station details' &&
          mainDialogContent !== 'fleetVehicleDetails' &&
          mainDialogContent !== 'vehicle settings' &&
          mainDialogContent !== 'vehicle booking' &&
          mainDialogContent !== 'vehicle gallery custom' &&
          mainDialogContent !== 'vehicle gallery connected' &&
          mainDialogContent !== 'vehicle gallery generic' &&
          mainDialogContent !== 'needsRecalc'
            ? 'height: calc(100% - 64px)'
            : 'height: 100%'
        "
      >
        <!-- conditionally rendered content -->
        <GuideContent v-if="mainDialogContent === 'guide'" />
        <ChargingStationDetailsContent
          v-if="mainDialogContent === 'charging station details'"
        />
        <SavingsSettingsContent
          v-if="mainDialogContent === 'savings settings'"
        />
        <AllVehiclesGalleryContent
          v-if="mainDialogContent === 'vehicle gallery'"
        />
        <VehicleGalleryContent
          v-if="mainDialogContent === 'vehicle gallery custom'"
          type="CUSTOM_VEHICLES"
        />
        <VehicleGalleryContent
          v-if="mainDialogContent === 'vehicle gallery connected'"
          type="CONNECTED_VEHICLES"
        />
        <VehicleGalleryContent
          v-if="mainDialogContent === 'vehicle gallery generic'"
          type="GENERIC_VEHICLES"
        />
        <NetworkSettings v-if="mainDialogContent === 'network settings'" />
        <FleetVehicleDetailsContent
          v-if="mainDialogContent === 'fleetVehicleDetails'"
        />
        <EditUserContent
          v-if="mainDialogContent === 'edit user'"
          :user="user"
          :driver="driver"
        />
        <AddVehicleContent v-if="mainDialogContent === 'add vehicle'" />
        <VehicleSettingsContent
          v-if="mainDialogContent === 'vehicle settings'"
        />
        <VehicleBookingContent v-if="mainDialogContent === 'vehicle booking'" />
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script lang="ts">
import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import GuideContent from "../help-content/dialog-content/GuideContent.vue";
import ChargingStationDetailsContent from "../charging-stations/details/dialog-content/ChargingStationDetailsContent.vue";
import SavingsSettingsContent from "../settings/dialog-content/SavingsSettingsContent.vue";
import VehicleGalleryContent from "../vehicles/dialog-content/VehicleGalleryContent.vue";
import AllVehiclesGalleryContent from "../vehicles/dialog-content/AllVehiclesGalleryContent.vue";
import NetworkSettings from "../settings/dialog-content/NetworkSettings.vue";
import FleetVehicleDetailsContent from "../vehicles/dialog-content/VehicleDetailsContent.vue";
import {
  MainDialogContent,
  MutationTypes,
  type State,
} from "../../../logic/store/store_types";
import EditUserContent from "../user/profile/dialog-content/EditUserContent.vue";
import { GettersTypes } from "../../../logic/store/store_types";
import AddVehicleContent from "../vehicles/dialog-content/AddVehicleContent.vue";
import VehicleSettingsContent from "../vehicles/dialog-content/VehicleSettingsContent.vue";
import VehicleBookingContent from "../vehicles/dialog-content/VehicleBookingContent.vue";

/** `Vue Component:` renders the main dialog wrapper for other content. To be used in the `app` component. */
export default Vue.extend({
  name: "MainDialog",
  components: {
    GuideContent,
    ChargingStationDetailsContent,
    SavingsSettingsContent,
    VehicleGalleryContent,
    NetworkSettings,
    FleetVehicleDetailsContent,
    EditUserContent,
    AddVehicleContent,
    VehicleSettingsContent,
    VehicleBookingContent,
    AllVehiclesGalleryContent,
  },
  computed: {
    ...mapGetters({
      driver: GettersTypes.currentDriver,
    }),
    ...mapState({
      mainDialogContent: (state: unknown): MainDialogContent | undefined =>
        (state as State).mainDialogContent,
      title: (
        state: unknown
      ):
        | "Adjust your savings calculation"
        | "Your settings"
        | "Vehicle Categories"
        | "Network settings"
        | "Charging station details"
        | "Edit your details"
        | "Add a new custom vehicle"
        | undefined => {
        switch ((state as State).mainDialogContent) {
          case MainDialogContent.SAVINGS_SETTINGS:
            return "Adjust your savings calculation";
          case MainDialogContent.VEHICLE_GALLERY:
            return "Vehicle Categories";
          case MainDialogContent.NETWORK_SETTINGS:
            return "Network settings";
          case MainDialogContent.CHARGING_STATION_DETAILS:
            return "Charging station details";
          case MainDialogContent.EDIT_USER:
            return "Edit your details";
          case MainDialogContent.ADD_VEHICLE:
            return "Add a new custom vehicle";
          default:
            return;
        }
      },
      icon: (
        state: unknown
      ):
        | "mdi-cog-outline"
        | "$vehiclesIcon"
        | "mdi-help-circle"
        | "$stationsIcon"
        | "mdi-pencil"
        | undefined => {
        switch ((state as State).mainDialogContent) {
          case MainDialogContent.SAVINGS_SETTINGS:
            return "mdi-cog-outline";
          case MainDialogContent.VEHICLE_GALLERY:
          case MainDialogContent.ADD_VEHICLE:
            return "$vehiclesIcon";
          case MainDialogContent.GUIDE:
            return "mdi-help-circle";
          case MainDialogContent.NETWORK_SETTINGS:
          case MainDialogContent.CHARGING_STATION_DETAILS:
            return "$stationsIcon";
          case MainDialogContent.EDIT_USER:
            return "mdi-pencil";
          default:
            return;
        }
      },
      user: (state: unknown) => (state as State).user,
    }),
  },
  methods: {
    clear() {
      this.$store.commit(MutationTypes.setMainDialogContent, undefined);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    keyDown(event: any) {
      if (event.key === "Escape") this.clear();
    },
  },
  watch: {
    mainDialogContent(val: MainDialogContent) {
      if (val) {
        // Notify analytics server
        // eslint-disable-next-line
        // @ts-ignore
        Vue.prototype.$Countly.q.push(["track_pageview", val]);
      }
    },
  },
});
</script>
<style scoped>
.pwt-scroll-container {
  width: 100%;
  height: 100%;
}

.pwt-scrollbar-styles {
  scrollbar-color: #ffffff #e0e0e0;
  scrollbar-width: thin;
}

.pwt-scrollbar-styles:hover {
  scrollbar-color: #eeeeee #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar {
  width: 6px;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track:hover {
  background: #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb:hover {
  background: #eeeeee;
}
</style>
