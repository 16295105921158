import Vue from "vue";
import directus, {
  chargerCustomEndpoint,
  directusAssetsRoute,
  modelsCustomEndpoint,
} from "../clients/directusClient";
import type { CDB_EVModel, Location } from "@/logic/types/charger_Db_types";
import type VehicleData from "@/logic/types/directus_vehicle";
import type DirectusUser from "@/logic/types/directus_user";
import type CompanyData from "@/logic/types/directus_company";
import type { TripSaveData } from "@/logic/types/trip_specific_types";
import type {
  DirectusModelImageLink,
  DirectusLoginParams,
  DirectusNetworkBranding,
  UpdateUserRes,
  DriverDataToUpdate,
  UserDataToUpdate,
  DataToUpdate,
  uploadFileRes,
  updateSavedTripReturnObj,
  saveTripReturnObj,
  directusFileObj,
  UpdateVehicleImagesRes,
  ManagedContent,
  DirectusFolderObj,
} from "@/logic/types/directus_types";
import { settings } from "@/logic/types/directus_types";
import Trip from "@/logic/classes/trip";
import DriverData from "@/logic/types/directus_driver";
import {
  DirectusVehicleVariableData,
  UserSelectedPlug,
} from "@/logic/types/directus_vehicle";
import ScoringConfigData from "@/logic/types/directus_scoring_config";
import User from "@/logic/classes/user";

function notifyAnalytics(error: Error, functionName: string) {
  if (process.env.NODE_ENV === "development")
    console.log(
      "#ERROR DEV NOTIFICATION: `" + functionName + "` errored with",
      error
    );
  Vue.prototype.$Countly.q.push([
    "recordError",
    { stack: error.message },
    true,
    {
      type: "api call",
      filePath: "src\\logic\\api\\calls\\directus_calls.ts",
      functionName,
    },
  ]);
}

/** Charger DB version of the fetch all charging stations request. */
export async function fetchChargingStationsDb(): Promise<
  Location[] | undefined
> {
  try {
    const res = await fetch(chargerCustomEndpoint, {
      headers: {
        Authorization: `Bearer ${directus.auth.token}`,
      },
    });

    const convertedResponse: Location[] = await res.json();
    return convertedResponse;
  } catch (error) {
    notifyAnalytics(error as Error, "fetchChargingStations");
  }
}

/**
 * API call to fetch the user object from directus.
 *
 * @returns User object from directus - see `fetchUserRes` for data shape.
 */
export async function fetchUser(): Promise<DirectusUser | undefined> {
  try {
    const userData = await directus.users.me.read();
    return userData as DirectusUser | undefined;
  } catch (error) {
    notifyAnalytics(error as Error, "fetchUser");
  }
}

/** API Call to fetch the company details from the DB.
 *
 * @returns a user `CompanyData` object if successful. Undefined if not.
 */
export async function fetchFleetData(): Promise<CompanyData | undefined> {
  try {
    const res = await directus.items("Company").readByQuery({
      fields: ["id", "Name", "Logo", "ManualVehicleMatching"],
    });

    return res as CompanyData | undefined;
  } catch (error) {
    notifyAnalytics(error as Error, "fetchFleetData");
  }
}

/** API Call to fetch the config details from the DB.
 *
 * @returns a user `DirectusFleetConfig` object if successful. Undefined if not.
 */
export async function fetchFleetConfigData(): Promise<
  ScoringConfigData | undefined
> {
  try {
    const res = await directus.items("ScoringConfig").readByQuery({
      fields: [
        "dcCostPerKwh",
        "acCostPerKwh",
        "petrolCostPerLitre",
        "dieselCostPerLitre",
      ],
    });

    return res as ScoringConfigData | undefined;
  } catch (error) {
    notifyAnalytics(error as Error, "fetchFleetConfigData");
  }
}

/**
 * Fetches an individual driver record based on the passed id.
 *
 * @param driverId the id of the users driver record
 * @returns a user `DriverData` object if successful. Undefined if not.
 */
export async function fetchDriverData(
  driverId: number
): Promise<DriverData | undefined> {
  try {
    const res = await directus.items("Driver").readOne(driverId, {
      fields: ["id", "Name", "Driving", "ProfilePic", "Bio"],
    });
    return res as DriverData | undefined;
  } catch (error) {
    notifyAnalytics(error as Error, "fetchDriverData");
  }
}

/**
 * API call to fetch the users saved trips.
 *
 * @param userID the directus UUID for the current user.
 * @returns a list of saved trips.
 */
export async function fetchSavedTrips(
  userID: string,
  savedTripsFolderUUID: string
): Promise<TripSaveData[] | undefined> {
  const res = await directus.files.readByQuery({
    filter: {
      _and: [
        { uploaded_by: { _eq: userID } },
        { folder: { _eq: savedTripsFolderUUID } },
      ],
    },
  });

  if (res?.data) {
    const returnArray = [];

    for (let index = 0; index < res.data.length; index++) {
      try {
        const fileRes = await fetch(
          `${directusAssetsRoute}${res.data[index].id}?download`,
          { method: "GET" }
        );
        if (fileRes.ok) {
          const convertedResponse = await fileRes.json();
          returnArray.push(convertedResponse);
        }
      } catch (error) {
        notifyAnalytics(error as Error, "fetchSavedTrips");
      }
    }
    return returnArray;
  }
}

/**
 * API call to fetch fleet vehicles.
 *
 * @returns a list of directus `VehicleData` collection objects.
 */
export async function fetchFleetVehicles(): Promise<VehicleData[] | undefined> {
  try {
    const res = await directus.items("Car_Record").readByQuery({
      limit: -1,
      fields: [
        "id",
        "Driver",
        "rego",
        "vin",
        "name",
        "fuel_type",
        "soh",
        "UserSelectedPlugs",
        "Latitude",
        "Longitude",
        "speed",
        "LastKnown",
        "StateOfCharge",
        "Ignition_On",
        "Images.directus_files_id",
        "CDB_Model_ID",
        "AdvancedConfig",
        "from_slurper",
      ],
    });
    return res.data as VehicleData[];
  } catch (error) {
    notifyAnalytics(error as Error, "fetchFleetVehicles");
  }
}

/**
 * API call to fetch image asset links for all supported EV models from directus.
 *
 * @returns a list of `DirectusModelImageLink` link objects.
 */
export async function fetchEVModelImageLinks(): Promise<
  DirectusModelImageLink[] | undefined
> {
  try {
    const res = await directus
      .items("EV_Model_Assets")
      .readByQuery({ limit: -1 });
    return res.data as DirectusModelImageLink[];
  } catch (error) {
    notifyAnalytics(error as Error, "fetchEVModelImageLinks");
    return;
  }
}

/**
 * API Call to check if current token exists and is valid.
 * This will authenticate the directus connection if successful.
 *
 * @returns `true` if token auth successful `false` if failed.
 */
export async function checkToken(): Promise<boolean> {
  let authenticated = false;

  if (authenticated) return authenticated;

  // Try to authenticate
  await directus.auth
    .refresh()
    .then(() => {
      authenticated = true;
    })
    .catch((err: unknown) => {
      // no notifying of analytics as this is expected to fail it token is not present or expired.
      if (process.env.NODE_ENV === "development") console.log(err);
    });

  if (authenticated) return authenticated;

  // token failed to auth
  return authenticated;
}

/**
 * API Call to login.
 * This will authenticate the directus connection if successful.
 *
 * @returns `true` if token auth successful `false` if failed.
 */
export async function login(
  credentials: DirectusLoginParams
): Promise<boolean> {
  let authenticated = false;

  try {
    await directus.auth
      .login({ email: credentials.email, password: credentials.password })
      .then(() => {
        authenticated = true;
      });
  } catch (error) {
    notifyAnalytics(error as Error, "login");
  }

  return authenticated;
}

/** API Call to fetch network branding for all networks that have charging stations in DB. */
export async function fetchNetworkBranding(): Promise<
  DirectusNetworkBranding[] | undefined
> {
  try {
    const res = await directus
      .items("NetworkBranding")
      .readByQuery({ limit: -1 });
    if (res.data) return res.data as DirectusNetworkBranding[];
  } catch (error) {
    notifyAnalytics(error as Error, "fetchNetworkBranding");
  }
}

/**
 * Fetches the users optimiser settings object if there is one alternatively for first time users
 * creates a record and returns that with the default values.
 *
 * @param userID the user directus user id
 * @returns a `settings` object if successful see types for details. or undefined if an error occurs.
 */
export async function fetchSettings(
  userID: string
): Promise<settings | undefined> {
  const data: settings[] = [];
  try {
    const fetchReqRes = await directus
      .items("Optimiser_Settings")
      .readByQuery({ filter: { user_created: { _eq: userID } } });

    // check if a record was retrieved
    if (fetchReqRes.data?.length) {
      data.push(...(fetchReqRes.data as settings[]));
      return data[0] as settings;
    }

    // create new record
    const createReqRes = await directus
      .items("Optimiser_Settings")
      .createOne({});
    data.push(createReqRes as settings);
    if (data.length) {
      return data[0] as settings;
    }
  } catch (error) {
    notifyAnalytics(error as Error, "fetchSettings");
    return;
  }
}

/**
 * Delete a target trips saved data record from directus and cloud storage.
 *
 * @param directusID the `save_id` of the target trip
 * @returns status string either `ok` or `failed`
 */
export async function deleteSavedTrip(trip: Trip): Promise<"ok" | "failed"> {
  try {
    const directusFileID = trip.directusId;
    if (directusFileID) {
      await directus.files.deleteOne(directusFileID);
      return "ok";
    }
    return "failed";
  } catch (error) {
    notifyAnalytics(error as Error, "deleteSavedTrip");
    return "failed";
  }
}

/**
 * API Call to logout the user.
 * This will invalidate the directus connection if successful and is a fire and forget style function.
 */
export async function logout() {
  try {
    await directus.auth.logout();
    directus.storage.delete("auth_refresh_token");
    directus.storage.delete("auth_expires_at");
  } catch (error) {
    notifyAnalytics(error as Error, "logout");
  }
}

/**
 * Sends a user a reset password email to the email address given if it is attached to an account.
 *
 * @param email the email address the user thinks is attached to an account
 * @returns status of request.
 */
export async function resetPassword(email: string) {
  try {
    const res = await directus.auth.password.request(email);
    return res;
  } catch (error) {
    notifyAnalytics(error as Error, "resetPassword");
  }
}

/**
 * Updates the user/driver data for the current user with any new values provided.
 *
 * @param dataToUpdate object containing the data to be updated.
 * @param userID the users directus id.
 * @param driverId the users directus driver collection related item id.
 * @returns and object containing the status of the request and the new directus data objects if applicable.
 */
export async function updateUser(
  dataToUpdate: DataToUpdate,
  user: User
): Promise<UpdateUserRes> {
  // guard clause
  if (!Object.keys(dataToUpdate).length) return { success: false }; // ASSUMES: an empty object was provided and no need to update.

  let directusDriverID = user.driverDirectusId;
  const driverDataToUpdate: DriverDataToUpdate = {};
  const userDataToUpdate: UserDataToUpdate = {};

  // Upload profile pic if needed.
  if (dataToUpdate.avatar) {
    try {
      const uploadRes = await uploadFile(dataToUpdate.avatar, true);
      if (uploadRes.success && uploadRes.UUID) {
        driverDataToUpdate.ProfilePic = uploadRes.UUID;
        userDataToUpdate.avatar = uploadRes.UUID;
      }
    } catch (error) {
      notifyAnalytics(error as Error, "updateUser");
    }
  }

  // create Driver account if needed.
  if (!directusDriverID) {
    try {
      const driverCreateRes = await directus.items("Driver").createOne({
        Name: user.fullName,
        Email: user.email ?? null,
      });
      directusDriverID = (driverCreateRes as DriverData).id;
      userDataToUpdate.Driver = (driverCreateRes as DriverData).id;
    } catch (error) {
      notifyAnalytics(error as Error, "updateUser");
    }
  }

  // Prep name for updating if needed.
  if (dataToUpdate.firstName) {
    userDataToUpdate.first_name = dataToUpdate.firstName;
  }
  if (dataToUpdate.lastName) {
    userDataToUpdate.last_name = dataToUpdate.lastName;
  }
  if (dataToUpdate.firstName || dataToUpdate.lastName) {
    driverDataToUpdate.Name = `${dataToUpdate.firstName ?? ""} ${
      dataToUpdate.lastName ?? ""
    }`;
  }

  // Prep bio for updating.
  if (dataToUpdate.bio) {
    driverDataToUpdate.Bio = dataToUpdate.bio;
  }

  // Prep cars for updating.
  if (dataToUpdate.Cars) {
    userDataToUpdate.Cars = dataToUpdate.Cars;
  }

  // Prep return object
  const returnObj: UpdateUserRes = {
    success: false,
  };

  // update directus collections
  if (Object.keys(userDataToUpdate).length) {
    // ASSUMES: values are present that need to be updated on the user collection as object is not empty.
    try {
      const userUpdateRes = await directus.users.me.update(userDataToUpdate);
      returnObj.updatedUser = userUpdateRes as DirectusUser;
    } catch (error) {
      notifyAnalytics(error as Error, "updateUser");
    }
  }
  if (Object.keys(driverDataToUpdate).length && directusDriverID) {
    // ASSUMES: values are present that need to be updated on the drive collection as object is not empty.
    try {
      const driverUpdateRes = await directus
        .items("Driver")
        .updateOne(directusDriverID, driverDataToUpdate);
      returnObj.updatedDriver = driverUpdateRes as DriverData;
    } catch (error) {
      notifyAnalytics(error as Error, "updateUser");
    }
  }

  // check at least on collection was updates
  if (returnObj.updatedDriver || returnObj.updatedUser) {
    returnObj.success = true;
  }

  return returnObj;
}

export async function uploadFile(
  file: File,
  isImage = false
): Promise<uploadFileRes> {
  try {
    let resizedImg: Blob | undefined = undefined;
    if (isImage) {
      resizedImg = await resizeImg(file);
    }
    const form_data = new FormData();
    const blob = resizedImg ?? new Blob([file]);
    form_data.append("file", blob);
    const fileCreateRes = await directus.files.createOne(form_data);
    return {
      success: true,
      UUID: fileCreateRes?.id,
    };
  } catch (error) {
    notifyAnalytics(error as Error, "uploadFile");
    return {
      success: false,
    };
  }
}

// Helper function to support image uploads.
async function resizeImg(file: File): Promise<Blob | undefined> {
  return new Promise((resolve, reject) => {
    const blobCallback = async (blob: Blob | null) => {
      if (blob) {
        resolve(blob);
      } else {
        reject(undefined);
      }
    };

    const MAX_WIDTH = 1920;
    const MAX_HEIGHT = 1080;
    const MIME_TYPE = file.type ?? "image/jpeg";
    const QUALITY = 0.7;

    const blobURL = URL.createObjectURL(file);
    const img = new Image();
    img.src = blobURL;
    img.onerror = function () {
      URL.revokeObjectURL(img.src);
      if (process.env.NODE_ENV === "development")
        console.log(
          "#ERROR DEV NOTIFICATION: `resizeImg` errored with an unspecified error"
        );
      Vue.prototype.$Countly.q.push([
        "recordError",
        true,
        {
          type: "image file accessing",
          filePath: "src\\logic\\api\\calls\\directus_calls.ts",
          functionName: "resizeImg",
        },
      ]);
    };
    img.onload = function () {
      URL.revokeObjectURL(img.src);
      const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
      const canvas = document.createElement("canvas");
      canvas.width = newWidth;
      canvas.height = newHeight;
      const ctx = canvas.getContext("2d");
      ctx?.drawImage(img, 0, 0, newWidth, newHeight);
      canvas.toBlob((blob) => blobCallback(blob), MIME_TYPE, QUALITY);
    };
  });
}

// Helper function to support image uploads.
function calculateSize(
  img: HTMLImageElement,
  maxWidth: number,
  maxHeight: number
): [number, number] {
  let width = img.width;
  let height = img.height;

  // calculate the width and height, constraining the proportions
  if (width > height) {
    if (width > maxWidth) {
      height = Math.round((height * maxWidth) / width);
      width = maxWidth;
    }
  } else {
    if (height > maxHeight) {
      width = Math.round((width * maxHeight) / height);
      height = maxHeight;
    }
  }
  return [width, height];
}

/**
 * Requests a file be deleted on directus, note this should be a cascading delete also removing the file from S3 storage.
 *
 * @param fileUUID the UUID of the file to delete.
 * @returns if process was successful or not
 */
export async function deleteFile(
  fileUUID: string
): Promise<{ success: boolean }> {
  try {
    await directus.files.deleteOne(fileUUID);
    return { success: true };
  } catch (error) {
    notifyAnalytics(error as Error, "deleteFile");
    return { success: false };
  }
}

/**
 * Update a target trips JSON data in cloud storage while also updating directus's meta data.
 *
 * @param tripData the whole `StoredTrip` object to replace the existing.
 * @returns a `updateSavedTripReturnObj` object with the `status` and an optional error `message` if applicable.
 */
export async function updateSavedTrip(
  tripData: TripSaveData
): Promise<updateSavedTripReturnObj> {
  if (!tripData.directusID)
    return {
      status: "failed",
      message: "ERROR: not a saved trip please use saveTrip instead.",
    };

  const data = JSON.stringify(tripData);

  const form_data = new FormData();

  const blob = new Blob([data], { type: "application/json" });

  form_data.append("title", `Saved_Trip_${tripData.directusID}`);
  form_data.append("file", blob);

  try {
    await directus.files.updateOne(tripData.directusID, form_data);
    return { status: "ok" };
  } catch (error) {
    notifyAnalytics(error as Error, "updateSavedTrip");
    return { status: "failed", message: (error as Error).message };
  }
}

/**
 * Saves a passed trip to directus (reference and linking table updated) and
 * cloud storage (the actual object data).
 *
 * @param tripData the full `StoredTrip` object that needs to be stored
 * @returns a status object in the form of `saveTripReturnObj`
 */
export async function saveTrip(
  tripData: TripSaveData,
  savedTripsFolderUUID: string
): Promise<saveTripReturnObj> {
  if (tripData.directusID)
    return {
      status: "failed",
      message:
        "Error: This trip has already been saved use updateTrip instead.",
    };

  try {
    const data = JSON.stringify(tripData);

    const form_data = new FormData();

    const blob = new Blob([data], { type: "application/json" });

    // THIS NEEDS TO BE REWORKED
    // TODO: fix hardcoded folder to get dynamic folder structure from directus.
    // TODO: add fall back if folder does not exist.

    form_data.append("folder", savedTripsFolderUUID);
    form_data.append("file", blob);

    const fileCreateRes = await directus.files.createOne(form_data);
    if (!fileCreateRes || !(fileCreateRes as directusFileObj | undefined)?.id)
      return {
        status: "failed",
        message:
          "Error: This trip has already been saved use updateTrip instead.",
      };

    const updatedTrips: TripSaveData = {
      ...tripData,
      directusID: fileCreateRes.id,
    };
    const updatedData = JSON.stringify(updatedTrips);
    const updatedBlob = new Blob([updatedData], { type: "application/json" });
    const updatedForm = new FormData();
    updatedForm.append("title", `Saved_Trip_${fileCreateRes.id}`);
    updatedForm.append("folder", savedTripsFolderUUID);
    updatedForm.append("file", updatedBlob);

    await directus.files.updateOne(fileCreateRes.id, updatedForm);
    return {
      status: "ok",
      id: fileCreateRes.id,
    };
  } catch (error) {
    notifyAnalytics(error as Error, "saveTrip");
    return {
      status: "failed",
      message:
        "Error: This trip has already been saved use updateTrip instead.",
    };
  }
}

export async function updateVehicleImages(
  directus_vehicle_id: number,
  imageToAdd: File
): Promise<UpdateVehicleImagesRes> {
  try {
    const uploadRes = await uploadFile(imageToAdd, true);
    if (uploadRes.success && uploadRes.UUID) {
      await directus.items("Vehicle_files").createOne({
        Vehicle_id: directus_vehicle_id,
        directus_files_id: uploadRes.UUID,
      });
      return {
        success: true,
        imageUUID: uploadRes.UUID,
      };
    } else {
      return { success: false };
    }
  } catch (error) {
    notifyAnalytics(error as Error, "updateVehicleImages");
    return { success: false };
  }
}

/** API Call to update a vehicle records model in directus. */
export async function updateVehicleSOH(vehicleID: number, newSOH: number) {
  try {
    const res = await directus
      .items("Car_Record")
      .updateOne(vehicleID, { soh: newSOH });
    return res;
  } catch (error) {
    notifyAnalytics(error as Error, "updateVehicleSOH");
  }
}

/** API Call to update a vehicle records model in directus. */
export async function updateVehicleModel(vehicleID: number, modelID?: string) {
  try {
    const res = await directus
      .items("Car_Record")
      .updateOne(vehicleID, { EVModel: modelID ? modelID.toString() : null });
    return res;
  } catch (error) {
    notifyAnalytics(error as Error, "updateVehicleModel");
  }
}

/** API Call to update a `Vehicle` collection records `UserSelectedPlugs` array in directus. */
export async function updateVehiclePlugs(
  vehicleID: number,
  plugs: UserSelectedPlug[] | null
) {
  let convertedPlugs: string[] | null = null;
  if (plugs) {
    convertedPlugs = plugs.map((plug) => JSON.stringify(plug));
  }

  try {
    const res = await directus
      .items("Car_Record")
      .updateOne(vehicleID, { UserSelectedPlugs: convertedPlugs });

    return res;
  } catch (error) {
    notifyAnalytics(error as Error, "updateVehiclePlugs");
  }
}

/** API call to fetch all details relating to EV models from the DB.
 * @returns an array of EVModel objects
 */
export async function fetchEVmodels(): Promise<CDB_EVModel[] | undefined> {
  try {
    const response = await fetch(modelsCustomEndpoint, {
      headers: {
        Authorization: `Bearer ${directus.auth.token}`,
      },
    });
    const convertedResponse: CDB_EVModel[] = await response.json();
    return convertedResponse;
  } catch (error) {
    notifyAnalytics(error as Error, "fetchVehicleDetails");
  }
}

/**
 * Updates a fields value on the optimiser settings table in directus.
 *
 * @param id tables record ID.
 * @param field the target field.
 * @param val the value to change the target field to.
 */
export async function updateSetting(id: number, field: string, val: unknown) {
  try {
    await directus.items("Optimiser_Settings").updateOne(id, { [field]: val });
    return;
  } catch (error) {
    notifyAnalytics(error as Error, "updateSetting");
    return;
  }
}

/** API Call to fetch managed content from DB. */
export async function fetchManagedContent(): Promise<
  ManagedContent[] | undefined
> {
  try {
    const res = await directus
      .items("ManagedContent")
      .readByQuery({ limit: -1 });
    if (res.data) return res.data as ManagedContent[];
  } catch (error) {
    notifyAnalytics(error as Error, "fetchManagedContent");
  }
}

/**
 * API call to create a new directus `Vehicle` collection record.
 *
 * @param vehicleData the whole `DirectusVehicleCreationData` object.
 * @returns a new directus `Vehicle` collection record if successful (`VehicleData` object).
 */
export async function createNewVehicle(
  vehicleData: DirectusVehicleVariableData
): Promise<VehicleData | undefined> {
  try {
    const res = await directus.items("Car_Record").createOne(vehicleData);
    return res as unknown as VehicleData;
  } catch (error) {
    notifyAnalytics(error as Error, "createNewVehicle");
  }
}

export async function updateVehicle(
  directusId: number,
  dataToUpdate: DirectusVehicleVariableData
) {
  try {
    const res = await directus
      .items("Car_Record")
      .updateOne(directusId, dataToUpdate);
    return res as unknown as VehicleData;
  } catch (error) {
    notifyAnalytics(error as Error, "updateVehicle");
  }
}

/**
 * Fetches the data for the target folder from the linked directus instance.
 *
 * @returns a `DirectusFolderObj' for the target folder if it exists, undefined if not
 */
export async function fetchFolderDataByName(
  folderName: string
): Promise<DirectusFolderObj | undefined> {
  try {
    const res = await directus.folders.readByQuery({
      filter: { name: { _eq: folderName } },
      limit: 1,
    });

    // ASSUMES: that this will only have one folder called "Saved_Trips"
    return (
      (
        res as unknown as {
          data: DirectusFolderObj[];
        }
      ).data[0] ?? undefined
    );
  } catch (error) {
    notifyAnalytics(error as Error, "fetchFolderDataByName");
  }
}

/**
 * Creates a new directus folder with the passed folder name.
 *
 * @param folderName the name to assign to the newly created folder.
 * @returns the newly created `DirectusFolderObj` is successful
 */
export async function createDirectusFolder(folderName: string) {
  try {
    const res = await directus.folders.createOne({ name: folderName });
    return res as unknown as DirectusFolderObj;
  } catch (error) {
    notifyAnalytics(error as Error, "createDirectusFolder");
  }
}
