<template>
  <v-card flat class="px-5 pb-3">
    <v-row no-gutters justify="space-between">
      <span>
        <v-card-title>
          <v-btn icon @click="back">
            <v-icon> mdi-chevron-left </v-icon>
          </v-btn>
          Vehicle Settings
        </v-card-title>
      </span>
      <v-btn icon>
        <v-icon @click="close">mdi-close</v-icon>
      </v-btn>
    </v-row>
    <v-card-text>
      <BookingCalendar :assetId="vehicle.directusId" :assetType="'vehicle'" />
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import Vue from "vue";
import { mapGetters } from "vuex";
import {
  GettersTypes,
  MainDialogContent,
  MutationTypes,
} from "@/logic/store/store_types";
import BookingCalendar from "../../booking/BookingCalendar.vue";

export default Vue.extend({
  name: "VehicleBookingContent",
  methods: {
    /** closes all modals and returns to the normal vue of the app. */
    close() {
      this.$store.commit(MutationTypes.setMainDialogContent, undefined);
      this.$store.commit(MutationTypes.setViewedFleetVehicle, undefined);
    },
    /** swaps modal content back to the target `Vehicles` vehicle details page */
    back() {
      this.$store.commit(
        MutationTypes.setMainDialogContent,
        MainDialogContent.FLEET_VEHICLE_DETAILS
      );
    },
  },
  components: { BookingCalendar },
  computed: {
    ...mapGetters({
      vehicle: GettersTypes.viewedFleetVehicleData,
    }),
  },
});
</script>
