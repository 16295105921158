<template>
  <v-card flat>
    <v-card-text> Limit chargers by network </v-card-text>
    <v-card-actions>
      <v-btn
        class="text-none rounded-lg"
        color="primary"
        @click="openNetworkSettings"
      >
        <v-icon class="mr-1"> mdi-cog </v-icon>
        Network Settings
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import {
  MutationTypes,
  SecondaryDialogContent,
} from "@/logic/store/store_types";
import Vue from "vue";

/** Vue Component: renders the main setting icon button. To be used in the `App` component. */
export default Vue.extend({
  name: "SettingsBtn",
  methods: {
    openNetworkSettings() {
      this.$store.commit(
        MutationTypes.setSecondaryDialogContent,
        SecondaryDialogContent.NETWORK_SETTINGS
      );
    },
  },
});
</script>
