<template>
  <v-card flat>
    <!-- reset form -->
    <template v-if="!requestSent">
      <v-card-text>
        <v-text-field
          v-model="email"
          label="Your email address"
          outlined
          rounded
          color="primary"
          autofocus
          placeholder="example@e-mail.com"
          hide-details
        />
      </v-card-text>
      <v-card-actions class="px-5 pb-5">
        <v-btn
          class="text-none rounded-lg flex-grow-1"
          color="primary"
          dark
          @click="handleReset"
        >
          Email me a reset link
        </v-btn>
      </v-card-actions>
    </template>
    <!-- user feedback -->
    <template v-else>
      <v-card-text class="d-flex">
        <v-icon color="green" class="mt-n6 mr-1"> mdi-check-circle </v-icon>
        <span>
          If you have an account, we've sent you a secure link to reset your
          password.
        </span>
      </v-card-text>
    </template>
  </v-card>
</template>
<script lang="ts">
import { ActionTypes, MutationTypes } from "@/logic/store/store_types";
import Vue from "vue";

export default Vue.extend({
  name: "ResetPasswordContent",
  data() {
    return {
      email: "",
      requestSent: false,
    };
  },
  methods: {
    handleReset() {
      this.$store.dispatch(ActionTypes.forgotPassword, this.email);
      this.requestSent = true;
      setTimeout(() => {
        this.handleClose();
      }, 3000);
    },
    handleClose() {
      this.$store.commit(MutationTypes.setSecondaryDialogContent, undefined);
      this.requestSent = false;
    },
  },
});
</script>
