import {
  ConnectorDetailsData,
  connectorDetailsDataMap,
} from "../data/connectorDetailsData";
import { ConnectorFormat, ConnectorType } from "../types/charger_Db_types";

/**
 * Returns a user friendly display string for an OCPI standard connector type.
 *
 * @param connector the OCPI standard `ConnectorType`.
 * @returns a display string if found or 'unknown connector type.' if not found.
 */
export function getConnectorFriendlyName(
  connector: ConnectorType
): string | string[] {
  const value = connectorDetailsDataMap.get(connector);

  if (!value) return "unknown connector type.";

  if (Array.isArray(value.format))
    return [
      `${value.displayName} - Tethered`,
      `${value.displayName} - Socketed`,
    ]; // ASSUMES if array is both tethered and socketed.

  return (
    value.displayName +
    (value.format === "CABLE" ? " - Tethered" : " - Socketed")
  );
}

/**
 * Returns an OCPI standard connector type from a user friendly display string.
 *
 * @param displayName the friendly display string.
 * @returns the OCPI standard `ConnectorType` string if found or undefined if not found.
 */
export function getOCPIStandard(
  displayName: string
): { standard: ConnectorType; format: ConnectorFormat } | undefined {
  // prep display name.
  let extrapolatedDisplayName = displayName;

  if (displayName.includes(" - Tethered"))
    extrapolatedDisplayName = displayName.replace(" - Tethered", "");
  if (displayName.includes(" - Socketed"))
    extrapolatedDisplayName = displayName.replace(" - Socketed", "");

  let data: ConnectorDetailsData | undefined = undefined;

  for (const [, value] of connectorDetailsDataMap.entries()) {
    if (value.displayName === extrapolatedDisplayName) data = value;
  }

  if (data) {
    if (Array.isArray(data.format)) {
      return {
        standard: data.standard,
        format: displayName.includes(" - Tethered") ? "CABLE" : "SOCKET",
      };
    }

    return {
      standard: data.standard,
      format: data.format,
    };
  }
}
