import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import DashboardMainVue from "@/ui/components/dashboard/DashboardMain.vue";

export enum RouteNames {
  home = "home",
  trips = "trips",
  planning = "planning",
  itinerary = "itinerary",
  tripEdit = "tripEdit",
  stats = "stats",
  saved = "saved",
  unsaved = "unsaved",
  stations = "stations",
  vehicles = "vehicles",
  optimisedPlanning = "optimisedPlanning",
  optimisedEdit = "optimisedEdit",
}

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: RouteNames.home,
    component: DashboardMainVue,
  },
  {
    path: "/trips",
    name: RouteNames.trips,
    component: () => import("@/ui/components/trips/history/HistoryMain.vue"),
  },
  {
    path: "/trips/saved",
    name: RouteNames.saved,
    component: () =>
      import("@/ui/components/trips/history/SavedTripsContent.vue"),
  },
  {
    path: "/trips/unsaved",
    name: RouteNames.unsaved,
    component: () =>
      import("@/ui/components/trips/history/UnsavedTripsContent.vue"),
  },
  {
    path: "/trip/planning",
    name: RouteNames.planning,
    component: () =>
      import(
        "@/ui/components/trips/planing/info-panel-content/TripPlanningForm.vue"
      ),
  },
  {
    path: "/trip/itinerary",
    name: RouteNames.itinerary,
    component: () =>
      import(
        "@/ui/components/trips/Itinerary/info-panel-content/ItineraryMain.vue"
      ),
  },
  {
    path: "/trip/edit",
    name: RouteNames.tripEdit,
    component: () =>
      import(
        "@/ui/components/trips/planing/info-panel-content/TripRecalculationForm.vue"
      ),
  },
  {
    path: "/trip/stats",
    name: RouteNames.stats,
    component: () =>
      import(
        "@/ui/components/trips/statistics/info-panel-content/TripStatsWrapper.vue"
      ),
  },
  {
    path: "/trip/optimised-route/planning",
    name: RouteNames.optimisedPlanning,
    component: () =>
      import(
        "@/ui/components/trips/planing/info-panel-content/TspTripPlanningForm.vue"
      ),
  },
  {
    path: "/trip/optimised-route/edit",
    name: RouteNames.optimisedEdit,
    component: () =>
      import(
        "@/ui/components/trips/planing/info-panel-content/TspTripEditWrapper.vue"
      ),
  },
  {
    path: "/stations",
    name: RouteNames.stations,
    component: () =>
      import(
        "@/ui/components/charging-stations/details/info-panel-content/StationsContent.vue"
      ),
  },
  {
    path: "/vehicles",
    name: RouteNames.vehicles,
    component: () =>
      import("@/ui/components/vehicles/info-panel-content/VehicleContent.vue"),
  },
];

const basename = (function () {
  return window.location.pathname;
})();

const router = new VueRouter({
  // mode: "history",
  base: basename,
  routes,
});

export default router;
