<template>
  <v-card
    elevation="4"
    class="grey lighten-2"
    @click="openChargingStationDetails"
  >
    <v-row no-gutters class="pt-3">
      <v-col cols="1">
        <div
          class="rounded-circle grey darken-1 mx-5 mt-1"
          style="width: 20px; height: 20px"
        ></div>
      </v-col>
      <v-col cols="8">
        <v-card-title
          v-if="!!step.Charger"
          class="font-weight-bold text-body-2 pt-0"
          style="word-break: normal; word-wrap: normal"
        >
          Charging at {{ step.Charger.ParkName }}
        </v-card-title>
        <v-card-subtitle
          v-if="!!step.Charger"
          class="grey--text text--darken-2 d-flex flex-column justify-start align-start"
        >
          <span>
            {{ simpleDisplayRating() }}
          </span>
          <span>
            {{ Math.round(step.ArrivalCharge * 100) }}% -
            {{ Math.round(step.StartCharge * 100) }}%
          </span>
        </v-card-subtitle>
      </v-col>
      <v-col cols="3">
        <OperatorLogo :networkBranding="branding" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="1"></v-col>
      <v-col cols="10">
        <v-card-text
          class="d-flex flex-column justify-start align-start text-body-2 font-weight-medium pt-0"
        >
          <span class="d-flex align-center">
            <div
              class="rounded-circle grey darken-1 mr-3"
              style="width: 12px; height: 12px"
            ></div>
            {{ chargingDuration }}
          </span>
          <span class="d-flex align-center">
            <div
              class="rounded-circle grey darken-1 mr-3"
              style="width: 12px; height: 12px"
            ></div>
            ~${{ step.ChargeCost ? Math.round(step.ChargeCost) : "?.??" }}
          </span>
          <span
            class="d-flex align-center"
            v-if="storedTrip.primaryTimeLocation"
          >
            <div
              class="rounded-circle grey darken-1 mr-3"
              style="width: 12px; height: 12px"
            ></div>
            {{
              getTimeData.displayArrivalTime
                ? `${getTimeData.displayArrivalTime} Arrival`
                : "Whoops! something went wrong in calculating your arrival time."
            }}
          </span>
          <span
            class="d-flex align-center"
            v-if="storedTrip.primaryTimeLocation"
          >
            <div
              class="rounded-circle grey darken-1 mr-3"
              style="width: 12px; height: 12px"
            ></div>
            {{
              getTimeData.displayDepartureTime
                ? `${getTimeData.displayDepartureTime} Departure`
                : "Whoops! something went wrong in calculating your departure time."
            }}
          </span>
          <span
            v-for="(cable, index) in byoCables"
            :key="`byo-cable-${index}`"
            class="red--text d-flex align-center"
          >
            <v-icon color="red" small> mdi-alert-circle </v-icon>
            BYO {{ cable.displayName }}
          </span>
        </v-card-text>
      </v-col>
      <v-col cols="1"></v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { Duration } from "luxon";
import OperatorLogo from "../../../charging-stations/details/common/OperatorLogo.vue";
import calcTimeData, {
  calcTimeDataReturnObj,
} from "../../../../../logic/utils/calcTimeData";
import {
  MainDialogContent,
  MutationTypes,
  type State,
} from "@/logic/store/store_types";
import type { EVNavStep } from "@/logic/types/ev_nav_types";
import Trip from "@/logic/classes/trip";
import Charger from "@/logic/classes/charger";
import { ConnectorType } from "@/logic/types/charger_Db_types";
import {
  ConnectorDetailsData,
  connectorDetailsDataMap,
} from "@/logic/data/connectorDetailsData";
import { ACPowerTypeList } from "@/logic/classes/connector";
import NetworkBranding from "@/logic/classes/networkBranding";

/** Vue Component: renders charging stop details, to be used in the `ItineraryContent` component.
 *
 * @prop `step` - the object for the current step in the route plan. See `routePlanningStore` for details.
 * @prop `stepIndex` - the position of this step in `steps` property array.
 * @prop `plannedTripIndex` - the index of the current leg of the trip.
 * @prop `storedTrip` - the full stored trip object.
 */
export default Vue.extend({
  name: "ChargingStopCard",
  props: {
    step: Object as PropType<EVNavStep>,
    stepIndex: Number,
    plannedTripIndex: Number,
    storedTrip: Object as PropType<Trip>,
  },
  computed: {
    byoCables(): ConnectorDetailsData[] {
      const chargers: Charger[] = (this.$store.state as State).chargers;
      const charger: Charger | undefined = chargers.find(
        (charger) => charger.id === this.step.Charger?.CDBID
      );

      if (charger && this.storedTrip.vehicle) {
        const compatibility = charger.isCompatible(this.storedTrip.vehicle);
        if (compatibility === "compatible with cable only") {
          const tempArray: ConnectorType[] = [];
          const connectors = charger.evses.flatMap((evse) => evse.connectors);
          connectors.forEach((connector) => {
            if (
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              connector.isCompatible(this.storedTrip.vehicle!) ===
                "compatible with cable only" &&
              !tempArray.includes(connector.standard)
            )
              tempArray.push(connector.standard);
          });
          const returnArray: ConnectorDetailsData[] = [];
          tempArray.forEach((connector) => {
            const data = connectorDetailsDataMap.get(connector);
            if (data) returnArray.push(data);
          });
          return returnArray;
        }
      }

      return [];
    },
    chargingDuration() {
      const time = (this.step as EVNavStep).ChargeTime;

      // is not undefined guard clause
      if (!time) {
        // get raw string as "0 hours, 0 minutes"
        const rawString = Duration.fromObject({ hours: 0, minutes: 0 })
          .normalize()
          .toHuman({ unitDisplay: "narrow" }) as string;
        // remove unwanted comma
        return rawString.replace(",", "");
      }

      // get raw string as "[hours traveled] hours, [minutes traveled] minutes"
      const rawString = Duration.fromObject({
        hours: 0,
        minutes: Math.floor(time / 60),
      })
        .normalize()
        .toHuman({ unitDisplay: "narrow" }) as string;
      // remove unwanted comma
      return rawString.replace(",", "");
    },
    getTimeData(): calcTimeDataReturnObj {
      return calcTimeData(this.storedTrip, this.stepIndex);
    },
    branding(): NetworkBranding | undefined {
      if (!this.step.Charger) return undefined;
      return (this.$store.state as State).networkBranding.find(
        (network) =>
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          network.isThisNetwork(this.step.Charger!.Network) // assertion due to ts not mapping this property correctly with previous statement.
      );
    },
  },
  methods: {
    openChargingStationDetails(): void {
      if ((this.step as EVNavStep).Charger?.CDBID) {
        // open charger details in modal
        this.$store.commit(
          MutationTypes.setSelectedCharger,
          this.step.Charger?.CDBID
        );
        this.$store.commit(
          MutationTypes.setMainDialogContent,
          MainDialogContent.CHARGING_STATION_DETAILS
        );
      }
    },
    simpleDisplayRating(): string {
      let ratingInWatts = 0;
      let bestCurrent = "";
      const hasDC = this.step.Charger?.Ports?.some(
        (port) => port.PowerType === "DC"
      );
      const hasAC = this.step.Charger?.Ports?.some((port) =>
        ACPowerTypeList.includes(port.PowerType)
      );

      if (hasDC) {
        bestCurrent = "DC";
        const dcConnectors =
          this.step.Charger?.Ports?.filter((port) => port.PowerType === "DC") ??
          [];
        dcConnectors.forEach((port) => {
          if (port.MaxElectricPower && port.MaxElectricPower > ratingInWatts)
            ratingInWatts = port.MaxElectricPower;
        });
      } else if (hasAC) {
        bestCurrent = "AC";
        const dcConnectors =
          this.step.Charger?.Ports?.filter((port) => port.PowerType === "DC") ??
          [];
        dcConnectors.forEach((port) => {
          if (port.MaxElectricPower && port.MaxElectricPower > ratingInWatts)
            ratingInWatts = port.MaxElectricPower;
        });
      }

      if (ratingInWatts && bestCurrent) {
        return `${ratingInWatts / 1000}KW ${bestCurrent} charger`;
      }
      return "";
    },
  },
  components: { OperatorLogo },
});
</script>
