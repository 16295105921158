<template>
  <TripStatsContent v-if="isTrip" />
  <TspTripStatsContent v-else-if="isTspTrip" :trip="trip" />
  <TripStatsEmptyStateContent v-else />
</template>
<script lang="ts">
import Trip from "@/logic/classes/trip";
import TspTrip from "@/logic/classes/tspTrip";
import { GettersTypes } from "@/logic/store/store_types";
import Vue from "vue";
import { mapGetters } from "vuex";
import TripStatsContent from "./TripStatsContent.vue";
import TspTripStatsContent from "./TspTripStatsContent.vue";
import TripStatsEmptyStateContent from "./TripStatsEmptyStateContent.vue";

export default Vue.extend({
  name: "TripStatsWrapper",
  computed: {
    ...mapGetters({
      trip: GettersTypes.selectedTripData,
    }),
    isTrip(): boolean {
      return (this.trip as Trip | TspTrip | undefined) instanceof Trip;
    },
    isTspTrip(): boolean {
      return (this.trip as Trip | TspTrip | undefined) instanceof TspTrip;
    },
  },
  components: {
    TripStatsContent,
    TspTripStatsContent,
    TripStatsEmptyStateContent,
  },
});
</script>
