<template>
  <v-card flat>
    <v-card-title class="font-weight-regular d-flex justify-space-between">
      <span> Driving </span>
      <v-btn
        class="rounded-lg text-none"
        color="primary"
        :disabled="!canBeRecalculate"
        v-if="!!displayedTrip"
        @click="handleRecalculateDisplayedTrip()"
        min-width="124"
      >
        <v-progress-circular
          v-if="progress"
          indeterminate
          size="20"
        ></v-progress-circular>
        <span v-else> Recalculate </span>
      </v-btn>
    </v-card-title>
    <v-card-subtitle>
      See how your trip changes with different driving. These settings apply to
      you as a driver an override the default settings for trip planning.
    </v-card-subtitle>

    <v-card-title> Speed </v-card-title>
    <v-card-subtitle>
      How fast do you drive compared to the speed?
    </v-card-subtitle>
    <v-card-text>
      <div
        class="d-flex justify-center align-center grey lighten-4 rounded-lg pa-3"
      >
        {{
          (relativeSpeed < 0
            ? `${Math.round(relativeSpeed).toString().replace("-", "")}% slower`
            : `${Math.round(relativeSpeed)}% faster`) +
          ` or ${Math.round(
            50 * ((100 + relativeSpeed) / 100)
          )}kmh in a 50kmh zone`
        }}
      </div>
    </v-card-text>
    <v-slider
      min="-50"
      max="50"
      class="mx-2"
      color="primary"
      track-color="grey lighten-2"
      :value="relativeSpeed"
      @input="handleRelativeSpeedSet"
    ></v-slider>
    <!-- charging range section -->
    <v-card-title> Charging Range </v-card-title>
    <v-card-text class="pb-0">
      <v-row>
        <v-col cols="6">
          <v-text-field
            label="Minimum Charge"
            filled
            v-model="displayRange[0]"
            suffix="%"
            type="number"
            hide-spin-buttons
            :rules="[validateRange]"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Maximum Charge"
            filled
            v-model="displayRange[1]"
            suffix="%"
            type="number"
            hide-spin-buttons
            :rules="[validateRange]"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-range-slider
      @end="handelRangeSet"
      v-model="displayRange"
      color="primary"
      track-color="grey lighten-2"
      class="px-2 pt-0"
      min="1"
      max="100"
      hide-details
    ></v-range-slider>
    <!-- Extra Weight -->
    <v-card-title> Extra Weight </v-card-title>
    <v-card-text>
      <v-row align="center" justify="center" class="mb-7">
        <v-btn large icon @click="decrementExtraWeight">
          <v-icon large> mdi-minus-circle-outline </v-icon>
        </v-btn>
        <div
          class="grey lighten-2 rounded-lg d-flex align-center"
          style="height: 56px; width: 80%"
          id="battery-custom-input"
        >
          <v-text-field
            :value="extraWeight ?? null"
            class="right-aligned-input none-underlined-input off-set-input-message pt-5"
            style="max-width: 145px"
            suffix="kg"
            type="number"
            hide-spin-buttons
            :rules="[validateExtraWeight]"
            @input="handleSetExtraWeight"
          ></v-text-field>
        </div>

        <v-btn large icon @click="incrementExtraWeight">
          <v-icon large> mdi-plus-circle-outline </v-icon>
        </v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import Trip from "@/logic/classes/trip";
import {
  ActionTypes,
  GettersTypes,
  MutationTypes,
  type State,
} from "@/logic/store/store_types";
import parseIntOrFloat from "@/logic/utils/parseNumOrFloat";
import Vue from "vue";
import { mapGetters, mapState } from "vuex";

/** Vue Component: renders driver based adjustable settings. To be used in the `VehicleContent` component. */
export default Vue.extend({
  name: "DriverProfile",
  methods: {
    handelRangeSet(val: [number, number]) {
      this.$store.commit(MutationTypes.setSOCMin, Math.round(val[0]) / 100);
      this.$store.commit(MutationTypes.setSOCMax, Math.round(val[1]) / 100);
      this.canBeRecalculate = true;
    },
    handleSetExtraWeight(val: string | number | null | undefined) {
      if (val && val !== 0 && val !== "0") {
        this.$store.commit(MutationTypes.setExtraWeight, parseIntOrFloat(val));
      } else {
        this.$store.commit(MutationTypes.setExtraWeight, undefined);
      }
      this.canBeRecalculate = true;
    },
    handleRelativeSpeedSet(val: number) {
      this.$store.commit(MutationTypes.setRelativeSpeed, val / 100);
      this.canBeRecalculate = true;
    },
    handleRecalculateDisplayedTrip() {
      if (!this.displayedTrip) return;
      (this.displayedTrip as Trip).stateOfChargeMin =
        Math.round(this.range[0]) / 100;
      (this.displayedTrip as Trip).stateOfChargeMax =
        Math.round(this.range[1]) / 100;

      if (this.extraWeight && !(this.displayedTrip as Trip).vehicle?.evModel)
        return;

      const totalWeight =
        ((this.displayedTrip as Trip).vehicle?.evModel?.mass ?? 0) +
        (this.extraWeight ?? 0);

      const copiedTrip: Trip = new Trip({ ...this.displayedTrip });

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      copiedTrip.parameters!.Vehicle.Mass = totalWeight;
      copiedTrip.extraWeight = this.extraWeight;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      copiedTrip.setVehicle((this.displayedTrip as Trip).vehicle!);

      this.$store.dispatch(ActionTypes.recalculateTrip, copiedTrip);
      this.canBeRecalculate = false;
    },
    validateRange(val: number | string): boolean | string {
      const parsedVal = parseIntOrFloat(val);
      if (!parsedVal) return "needs to be a number";
      if (parsedVal < 1 || parsedVal > 100) return "needs to be 1-100";
      return true;
    },
    /** Increases the extra weight by 1 kg. */
    incrementExtraWeight(): void {
      // exit early if extra weight is null
      if (!this.extraWeight) {
        this.handleSetExtraWeight(1);
        return;
      }
      // parse value
      const parsedVal = parseIntOrFloat(this.extraWeight);
      // exit early if parsing value failed
      if (!parsedVal) return;
      // increment extra weight
      this.handleSetExtraWeight(parsedVal + 1);
    },
    /** Reduces the extra weight by 1 kg. */
    decrementExtraWeight(): void {
      // exit early if extra weight is null
      if (!this.extraWeight) return;
      // parse value
      const parsedVal = parseIntOrFloat(this.extraWeight);
      // exit early if parsing value failed
      if (!parsedVal) return;
      // decrement extra weight
      this.handleSetExtraWeight(parsedVal - 1);
    },
    validateExtraWeight(val: number | string | null): boolean | string {
      // allow no extra wight to pass validation
      if (val === null || val === 0 || val === "0") return true;
      const parsedVal = parseIntOrFloat(val);
      if (!parsedVal) return "needs to be a number";
      return true;
    },
  },
  data() {
    return {
      displayRange: [10, 90],
      canBeRecalculate: false,
    };
  },
  computed: {
    ...mapGetters({
      displayedTrip: GettersTypes.selectedTripData,
    }),
    ...mapState({
      range: (state: unknown): [number, number] => [
        Math.round((state as State).SOCMin * 100),
        Math.round((state as State).SOCMax * 100),
      ],
      extraWeight: (state: unknown) => (state as State).extraWeight,
      relativeSpeed: (state: unknown) => (state as State).relativeSpeed * 100,
      progress: (state: unknown) => (state as State).routePlanningStatus,
    }),
  },
  mounted() {
    this.$nextTick(() => {
      this.displayRange = this.range;
    });
  },
});
</script>
<style scoped>
* >>> .v-slider--horizontal .v-slider__track-container {
  height: 6px; /* override default slider thickness */
}

* >>> .v-slider__track-fill {
  border-radius: 2px; /* override default slider border-radius */
}

* >>> .v-slider__track-background {
  border-radius: 2px; /* override default slider border-radius */
}

#battery-custom-input {
  /* position off set centered element 145px width within a 80% width container */
  padding-left: calc(40% - 108px);
  padding-right: calc(40% - 37px);
}
.right-aligned-input >>> input {
  text-align: right;
}

.none-underlined-input >>> .v-input__slot::before {
  border-style: none !important;
}

.off-set-input-message >>> .v-messages__message {
  padding-top: 16px;
}
</style>
