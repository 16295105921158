<template>
  <v-col cols="12" sm="6" md="4">
    <v-card
      height="100%"
      max-height="350px"
      min-height="200px"
      class="d-flex flex-column justify-center rounded-lg secondary"
      @click="handelAddVehicle"
    >
      <v-icon size="70" color="white">mdi-plus</v-icon>
    </v-card>
  </v-col>
</template>

<script lang="ts">
import { MainDialogContent, MutationTypes } from "@/logic/store/store_types";
import Vue from "vue";

export default Vue.extend({
  name: "CardLikeButton",
  methods: {
    handelAddVehicle() {
      this.$store.commit(
        MutationTypes.setMainDialogContent,
        MainDialogContent.ADD_VEHICLE
      );
    },
  },
});
</script>
