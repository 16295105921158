<template>
  <v-card flat v-if="!trip && !status">
    <v-card flat class="d-flex flex-column justify-center align-center">
      <v-card-title class="tertiary--text">No Trip Selected</v-card-title>
      <v-card-subtitle>
        Plan a new trip or select a previous one
      </v-card-subtitle>
    </v-card>
    <v-card-text>
      <v-btn
        color="primary"
        block
        class="text-none rounded-lg mb-3"
        @click="toPlanning"
      >
        Plan a New Trip
      </v-btn>
      <v-btn
        :color="powerTripDarkBlue"
        block
        outlined
        class="text-none rounded-lg mb-3"
        @click="toOptimisedTripPlanning"
      >
        Plan a New Optimised Trip
      </v-btn>
      <v-btn
        color="primary"
        block
        text
        class="text-none rounded-lg"
        @click="toTrips"
      >
        Select previous trips
      </v-btn>
    </v-card-text>
  </v-card>
  <v-card
    v-else-if="status === 'PROCESSING'"
    flat
    class="d-flex justify-center align-center"
    style="height: 80vh"
  >
    <LoadingCard />
  </v-card>
  <ItineraryEVContent v-else-if="trip.status === 'EV-SUCCESS'" :trip="trip" />
  <ItineraryFallbackContent
    v-else-if="trip.status === 'FALLBACK'"
    :trip="trip"
  />
  <ItineraryICEContent v-else-if="trip.status === 'ICE-SUCCESS'" :trip="trip" />
  <FailedTripCard v-else-if="trip.status === 'FAILED'" />
  <TspTripItinerary v-else-if="trip.status === 'optimised-trip'" :trip="trip" />
</template>
<script lang="ts">
import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import ItineraryEVContent from "./ItineraryEVContent.vue";
import ItineraryFallbackContent from "./ItineraryFallbackContent.vue";
import ItineraryICEContent from "./ItineraryICEContent.vue";
import { AsyncStatus, GettersTypes, State } from "@/logic/store/store_types";
import LoadingCard from "@/ui/components/ui-elements/LoadingCard.vue";
import FailedTripCard from "./FailedTripCard.vue";
import { powerTripDarkBlue } from "@/logic/data/const";
import { RouteNames } from "@/logic/router";
import TspTripItinerary from "./TspTripItinerary.vue";

export default Vue.extend({
  name: "ItineraryMain",
  computed: {
    ...mapGetters({
      trip: GettersTypes.selectedTripData,
    }),
    ...mapState({
      status: (state: unknown): AsyncStatus | undefined =>
        (state as State).routePlanningStatus,
    }),
  },
  components: {
    ItineraryEVContent,
    ItineraryFallbackContent,
    ItineraryICEContent,
    LoadingCard,
    FailedTripCard,
    TspTripItinerary,
  },
  methods: {
    pushRoute(routeName: RouteNames) {
      this.$router.push({ name: routeName });
    },
    toPlanning() {
      this.pushRoute(RouteNames.planning);
    },
    toTrips() {
      this.pushRoute(RouteNames.trips);
    },
    toOptimisedTripPlanning() {
      this.pushRoute(RouteNames.optimisedPlanning);
    },
  },
  data() {
    return {
      powerTripDarkBlue: powerTripDarkBlue,
    };
  },
});
</script>
