<template>
  <v-card
    outlined
    class="mb-2 rounded-lg elevation-2 grey lighten-3"
    max-width="100%"
    @click="openVehicleDetails"
  >
    <v-row no-gutters>
      <v-col cols="1">
        <v-img class="my-3 ml-2" contain max-width="50" :src="imageSrc"></v-img>
      </v-col>
      <v-col cols="11" class="d-flex justify-space-between align-center">
        <div>
          <v-card-title class="pt-1"> {{ displayName }} </v-card-title>
          <v-card-subtitle class="pb-2">
            {{ vehicle.evModel ? vehicle.evModel.name : "model not specified" }}
          </v-card-subtitle>
        </div>
        <div>
          <v-btn
            v-if="selected"
            color="primary"
            class="text-none rounded-lg mr-2"
            disabled
            style="min-width: 101px"
          >
            Selected
          </v-btn>
          <v-btn
            v-else
            color="primary"
            class="text-none rounded-lg mr-2"
            style="min-width: 101px"
            @click.stop="select"
          >
            Select
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import Vehicle from "@/logic/classes/vehicle";
import {
  ActionTypes,
  MainDialogContent,
  MutationTypes,
  SecondaryDialogContent,
  type State,
} from "@/logic/store/store_types";
import Vue, { PropType } from "vue";

/** `Vue component:` renders a list tile card with limited vehicle details
 * to be displayed in the `VehicleContent` component.
 *
 * @props `vehicle` - the full `Vehicle` object. See `vehicleStore` for more details.
 * @props `selected` - boolean indicating this is the selected styled card.
 * */
export default Vue.extend({
  name: "VehicleListCard",
  props: {
    selected: Boolean as PropType<boolean>,
    vehicle: Object as PropType<Vehicle>,
  },
  computed: {
    displayName(): string {
      if (this.vehicle.licensePlate) return this.vehicle.licensePlate;
      if (this.vehicle.name) return this.vehicle.name;
      if (this.$vuetify.breakpoint.smAndDown) return "no license plate";
      return "unspecified license plate";
    },
    imageSrc(): string | undefined {
      const company = (this.$store.state as State).fleet;
      if (company && this.vehicle.companyDirectusId === company.directusId) {
        return company.logoSrc;
      }
      return undefined;
    },
    getMaxRange(): string {
      const range = this.vehicle.calcMaxRange(
        (this.$store.state as State).extraWeight
      );
      return ` - ${Math.floor(range / 1000)}km max range`;
    },
  },
  methods: {
    select() {
      this.$store.dispatch(ActionTypes.selectVehicle, this.vehicle);
    },
    setInitialValues(vehicle: Vehicle) {
      this.SOHVal = vehicle.stateOfHealth ? vehicle.stateOfHealth * 100 : 50;
    },
    showHelp() {
      this.$store.commit(
        MutationTypes.setSecondaryDialogContent,
        SecondaryDialogContent.BATTERY_HEALTH_HELP
      );
    },
    openVehicleDetails() {
      this.$store.commit(
        MutationTypes.setViewedFleetVehicle,
        this.vehicle.localId
      );
      this.$store.commit(
        MutationTypes.setMainDialogContent,
        MainDialogContent.FLEET_VEHICLE_DETAILS
      );
    },
  },
  data() {
    return {
      SOHVal: 50,
      showTooltip: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.vehicle) {
        this.setInitialValues(this.vehicle);
      }
    });
  },
});
</script>

<style scoped>
* >>> .v-slider--horizontal .v-slider__track-container {
  height: 6px; /* override default slider thickness */
}

* >>> .v-slider__track-fill {
  border-radius: 2px; /* override default slider border-radius */
}

* >>> .v-slider__track-background {
  border-radius: 2px; /* override default slider border-radius */
}
</style>
