import { DateTime, Duration } from "luxon";
import Trip from "../classes/trip";
import TripLocation from "../classes/tripLocation";

export interface simpleTripTimeDataReturnObj {
  arrivalTime: string | undefined;
  departureTime: string | undefined;
  differenceType: "before" | "after" | undefined;
  timeDifference: string | undefined;
}

export default function simpleTripTimeData(
  trip: Trip,
  index: number
): simpleTripTimeDataReturnObj {
  // variables to capture outcomes.
  let arrivalTime: string | undefined = undefined;
  let departureTime: string | undefined = undefined;
  let differenceType: "before" | "after" | undefined = undefined;
  let timeDifference: string | undefined = undefined;

  // find base time.
  let baseTime: DateTime | undefined = undefined;
  let baseLocation: TripLocation | undefined = undefined;

  if (trip.primaryTimeLocation) {
    baseLocation = trip.locations.find(
      (l) => l.localId === trip.primaryTimeLocation
    );
    if (baseLocation && baseLocation.time) {
      // create new luxon dateTime object from the base time.
      baseTime = DateTime.fromISO(baseLocation.time);
    }
  }

  if (baseTime && baseLocation) {
    let durationAtBaseTime: number | undefined = undefined;

    if (trip.status === "EV-SUCCESS" && trip.evTripData) {
      // iterate through steps
      const flatStepsList = trip.evTripData.flatMap((plane) => plane.steps);

      if (baseLocation.localId === trip.locations[0].localId) {
        // ASSUMES: all times will be additions from the trips departure time.

        // find duration that the base time is at.
        durationAtBaseTime = 0;
      }
      if (
        baseLocation.localId ===
        trip.locations[trip.locations.length - 1].localId
      ) {
        // ASSUMES: all times will be subtractions from the trips final destination arrivalTime time.

        // find duration that the base time is at.
        let accumulatedSeconds = 0;

        trip.locations.forEach((l) => {
          accumulatedSeconds += l.stay ?? 0;
        });

        trip.evTripData.forEach((t) => {
          accumulatedSeconds += t.time;
        });

        durationAtBaseTime = accumulatedSeconds;
      }
      if (
        baseLocation.localId !== trip.locations[0].localId &&
        baseLocation.localId !==
          trip.locations[trip.locations.length - 1].localId
      ) {
        // ASSUMES: is waypoint and requires extra computation to figure out if is before or after this point.

        // find duration that the base time is at.
        const primaryStepIndex = flatStepsList.findIndex(
          (s) => s.From === trip.primaryTimeLocation
        );
        let accumulatedSeconds = 0;

        for (let i = 0; i < primaryStepIndex; i++) {
          accumulatedSeconds += flatStepsList[i].TravelTime;
          accumulatedSeconds += flatStepsList[i].ChargeTime ?? 0;
          if (flatStepsList[i].From.includes("location-stop")) {
            accumulatedSeconds +=
              trip.locations.find((l) => l.localId === flatStepsList[i].From)
                ?.stay ?? 0;
          }
        }

        durationAtBaseTime = accumulatedSeconds;
      }

      // find duration at the arrival time of this step
      let durationAtStepStart = 0;

      for (let i = 0; i < index; i++) {
        durationAtStepStart += flatStepsList[i].TravelTime;
        durationAtStepStart += flatStepsList[i].ChargeTime ?? 0;
        if (flatStepsList[i].From.includes("location-stop")) {
          durationAtStepStart +=
            trip.locations.find((l) => l.localId === flatStepsList[i].From)
              ?.stay ?? 0;
        }
      }

      // find difference between base time duration and duration at this step.
      const difference =
        Math.max(durationAtBaseTime ?? 0, durationAtStepStart) -
        Math.min(durationAtBaseTime ?? 0, durationAtStepStart);

      timeDifference = Duration.fromObject({
        hours: 0,
        minutes: Math.round(difference / 60),
      })
        .normalize()
        .toHuman({ listStyle: "narrow" })
        .replace(",", "");

      let stepArrivalTime: DateTime | undefined = undefined;

      // add or subtract difference from luxon dateTime object.
      if (
        durationAtBaseTime !== undefined &&
        durationAtBaseTime < durationAtStepStart
      ) {
        stepArrivalTime = baseTime.minus({
          minutes: Math.round(difference / 60),
        });
        differenceType = "before";
      }

      if (
        durationAtBaseTime !== undefined &&
        durationAtBaseTime > durationAtStepStart
      ) {
        stepArrivalTime = baseTime.plus({
          minutes: Math.round(difference / 60),
        });
        differenceType = "after";
      }

      // format luxon date time object for display.
      arrivalTime = stepArrivalTime?.toLocaleString(DateTime.TIME_SIMPLE);

      if (flatStepsList[index].From.includes("location-stop")) {
        // ASSUMES: step starts from a waypoint.
        const thisLocation = trip.locations.find(
          (l) => l.localId === flatStepsList[index].From
        );
        departureTime = stepArrivalTime
          ?.plus({ minutes: Math.round((thisLocation?.stay ?? 0) / 60) })
          .toLocaleString(DateTime.TIME_SIMPLE);
      }

      if (flatStepsList[index].Charge) {
        // ASSUMES: is a charging stop not a waypoint.
        departureTime = stepArrivalTime
          ?.plus({
            minutes: Math.round((flatStepsList[index].ChargeTime ?? 0) / 60),
          })
          .toLocaleString(DateTime.TIME_SIMPLE);
      }
    }

    if (trip.status === "ICE-SUCCESS" && trip.ICETripData) {
      // iterate through legs

      if (baseLocation.localId === trip.locations[0].localId) {
        // ASSUMES: all times will be additions from the trips departure time.

        // find duration that the base time is at.
        durationAtBaseTime = 0;
      }
      if (
        baseLocation.localId ===
        trip.locations[trip.locations.length - 1].localId
      ) {
        // ASSUMES: all times will be subtractions from the trips final destination arrivalTime time.

        // find duration that the base time is at.
        let accumulatedSeconds = trip.ICETripData.travelTime;

        trip.locations.forEach((l) => {
          accumulatedSeconds += l.stay ?? 0;
        });

        durationAtBaseTime = accumulatedSeconds;
      }
      if (
        baseLocation.localId !== trip.locations[0].localId &&
        baseLocation.localId !==
          trip.locations[trip.locations.length - 1].localId
      ) {
        // ASSUMES: is waypoint and requires extra computation to figure out if is before or after this point.

        // find duration that the base time is at.

        // ASSUMES: leg of the same index as location will be the leg that starts from that location.
        // NOTE: this is not true for the last location however that is handled differently above.
        const primaryLegIndex = trip.locations.findIndex(
          (l) => l.localId === trip.primaryTimeLocation
        );
        let accumulatedSeconds = 0;

        for (let i = 0; i < primaryLegIndex; i++) {
          accumulatedSeconds += trip.ICETripData.legs?.[i].TravelTime ?? 0;
          accumulatedSeconds += trip.locations[i].stay ?? 0;
        }

        durationAtBaseTime = accumulatedSeconds;
      }

      // find duration at the arrivalTime at this leg
      let durationAtLegStart = 0;

      for (let i = 0; i < index; i++) {
        durationAtLegStart += trip.ICETripData.legs?.[i].TravelTime ?? 0;
        durationAtLegStart += trip.locations[i].stay ?? 0;
      }

      // find difference between base time duration and duration at this leg.
      const difference =
        Math.max(durationAtBaseTime ?? 0, durationAtLegStart) -
        Math.min(durationAtBaseTime ?? 0, durationAtLegStart);

      timeDifference = Duration.fromObject({
        hours: 0,
        minutes: Math.round(difference / 60),
      })
        .normalize()
        .toHuman({ listStyle: "narrow" })
        .replace(",", "");

      let legArrivalTime: DateTime | undefined = undefined;

      // add or subtract difference from luxon dateTime object.
      if (
        durationAtBaseTime !== undefined &&
        durationAtBaseTime < durationAtLegStart
      ) {
        legArrivalTime = baseTime.minus({
          minutes: Math.round(difference / 60),
        });
        differenceType = "before";
      }

      if (
        durationAtBaseTime !== undefined &&
        durationAtBaseTime > durationAtLegStart
      ) {
        legArrivalTime = baseTime.plus({
          minutes: Math.round(difference / 60),
        });
        differenceType = "after";
      }

      // format luxon date time object for display.
      arrivalTime = legArrivalTime?.toLocaleString(DateTime.TIME_SIMPLE);
      departureTime = legArrivalTime
        ?.plus({ minutes: Math.round(trip.locations[index].stay ?? 0) })
        .toLocaleString(DateTime.TIME_SIMPLE);
    }
  }

  return {
    arrivalTime,
    departureTime,
    differenceType,
    timeDifference,
  };
}
