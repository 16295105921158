<template>
  <v-card flat class="pb-5 px-5">
    <v-card-text>
      <v-form :disabled="!!processingStatus" ref="form">
        <v-text-field
          v-model="vehicleName"
          label="Name your vehicle (optional)"
          autofocus
          clearable
        ></v-text-field>
        <v-text-field
          v-model="plate"
          label="License Plate (optional)"
          clearable
        ></v-text-field>
        <v-text-field
          v-model="VIN"
          label="VIN (optional)"
          clearable
        ></v-text-field>
        <v-tabs
          grow
          color="white"
          class="pwt-tabs-border rounded-t-lg"
          slider-size="47"
          active-class="pwt-custom-active-tab-text"
          v-model="tab"
        >
          <v-tabs-slider id="pwt-custom-active-tab"> </v-tabs-slider>
          <v-tab class="rounded-t-lg text-none">Basic settings</v-tab>
          <v-tab class="rounded-t-lg text-none">Advanced settings</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item :ripple="false" class="pt-5">
            <v-select
              label="Fuel type (required)"
              :items="['Petrol', 'Diesel', 'Hybrid', 'Electric']"
              v-model="fuelType"
              :rules="[validateFuelType]"
            ></v-select>
            <v-autocomplete
              v-if="fuelType === 'Electric'"
              v-model="evModel"
              :items="EVModels"
              item-text="name"
              label="EV make/model (optional)"
              :menu-props="{ contentClass: 'pwt-scrollbar-styles' }"
              return-object
              clearable
              @change="handelModelSelect"
            ></v-autocomplete>
            <v-text-field
              v-if="fuelType === 'Electric'"
              v-model="batterySize"
              label="Battery size (required)"
              type="number"
              clearable
              suffix="kWh"
            ></v-text-field>
            <v-slider
              v-if="fuelType === 'Electric'"
              :label="'Range adjustment ' + SOH + '%'"
              track-color="grey lighten-2"
              v-model="SOH"
              min="1"
              max="100"
              class="pr-2 pb-3"
              :disabled="!!processingStatus"
              :rules="[validateBattery]"
              :messages="batteryAgeMessage"
            ></v-slider>
            <v-autocomplete
              label="Connectors (required)"
              v-if="fuelType === 'Electric'"
              chips
              clearable
              multiple
              :menu-props="{ contentClass: 'pwt-scrollbar-styles' }"
              return-object
              v-model="selectedConnectors"
              :items="connectors"
              item-text="displayName"
              :rules="[validateSelectedConnectors]"
            ></v-autocomplete>
          </v-tab-item>
          <v-tab-item :ripple="false" class="pt-5">
            <v-select
              label="Fuel type (required)"
              :items="['Petrol', 'Diesel', 'Hybrid', 'Electric']"
              v-model="fuelType"
              :rules="[validateFuelType]"
            ></v-select>
            <v-autocomplete
              v-if="fuelType === 'Electric'"
              v-model="evModel"
              :items="EVModels"
              item-text="name"
              label="EV make/model (optional)"
              :menu-props="{ contentClass: 'pwt-scrollbar-styles' }"
              return-object
              clearable
              @change="handelModelSelect"
            ></v-autocomplete>
            <v-text-field
              v-if="fuelType === 'Electric'"
              v-model="batterySize"
              label="Battery size (required)"
              type="number"
              clearable
              :rules="[validateBattery]"
              suffix="kWh"
            ></v-text-field>
            <v-slider
              v-if="fuelType === 'Electric'"
              :label="'Range adjustment ' + SOH + '%'"
              track-color="grey lighten-2"
              v-model="SOH"
              min="1"
              max="100"
              class="pr-2 pb-3"
              :disabled="!!processingStatus"
              :messages="batteryAgeMessage"
            ></v-slider>
            <v-autocomplete
              label="Connectors (required)"
              v-if="fuelType === 'Electric'"
              chips
              clearable
              multiple
              :menu-props="{ contentClass: 'pwt-scrollbar-styles' }"
              return-object
              v-model="selectedConnectors"
              :items="connectors"
              item-text="displayName"
              :rules="[validateSelectedConnectors]"
            ></v-autocomplete>
            <p>
              The following advanced settings are only optional and only
              suggested to be changed if you know what you are doing.
            </p>
            <p>
              The EV make/model has preset values for these settings. You only
              need change these if your vehicle differs from those.
            </p>
            <v-text-field
              v-if="fuelType === 'Electric'"
              v-model="Mass"
              label="Vehicle weight (optional)"
              type="number"
              clearable
              suffix="kg"
            ></v-text-field>
            <v-text-field
              v-if="fuelType === 'Electric'"
              v-model="DragCoefficient"
              label="Aerodynamics (optional)"
              type="number"
              clearable
              :messages="dragCoefficientMessage"
              :rules="[validateDrag]"
            ></v-text-field>
            <v-text-field
              v-if="fuelType === 'Electric'"
              v-model="RollingResistanceCoefficient"
              label="Road dynamics (optional)"
              type="number"
              clearable
              :rules="[validateRoleResistance]"
            ></v-text-field>
            <v-text-field
              v-if="fuelType === 'Electric'"
              v-model="RegenerativeBreakingEfficiency"
              label="Regenerative breaking efficiency (optional)"
              type="number"
              clearable
              :rules="[validateRegenBreaking]"
              :messages="regenerativeBrakingMessage"
              suffix="%"
            ></v-text-field>
            <v-text-field
              v-if="fuelType === 'Electric'"
              v-model="PowerChainEfficiency"
              label="Power chain efficiency (optional)"
              type="number"
              clearable
              :rules="[validatePowerChainEfficiency]"
              :message="powerChainEfficiencyMessage"
              suffix="%"
            ></v-text-field>
            <v-text-field
              v-if="fuelType === 'Electric'"
              v-model="MaxElectricPowerAc"
              label="AC charging speed (optional)"
              type="number"
              clearable
              :rules="[validateMaxAC]"
              suffix="kw"
            ></v-text-field>
            <v-text-field
              v-if="fuelType === 'Electric'"
              v-model="MaxElectricPowerDc"
              label="DC charging speed (optional)"
              type="number"
              clearable
              :rules="[validateMaxDC]"
              suffix="kw"
            ></v-text-field>
          </v-tab-item>
        </v-tabs-items>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="primary"
        class="rounded-lg text-none"
        @click="handelSubmit"
        :loading="!!processingStatus"
        :disabled="!!processingStatus"
      >
        Save
      </v-btn>
      <v-btn
        color="primary"
        class="rounded-lg text-none"
        @click="handelReset"
        :disabled="!!processingStatus"
      >
        {{ saveStatus ? "Add another" : "Reset" }}
      </v-btn>
      <v-btn
        color="primary"
        class="rounded-lg text-none"
        @click="handelCancel"
        :disabled="!!processingStatus"
      >
        {{ saveStatus ? "Close" : "Cancel" }}
      </v-btn>
    </v-card-actions>
    <v-alert type="success" v-if="processingStatus === 'SUCCESS'" class="mx-2">
      Vehicle created successfully and saved to the database
    </v-alert>
    <v-alert type="error" v-if="processingStatus === 'FAILED'" class="mx-2">
      Vehicle was created locally but failed to be saved to the database
    </v-alert>
  </v-card>
</template>
<script lang="ts">
import EVModel from "@/logic/classes/evModel";
import { mapState } from "vuex";
import Vue from "vue";
import {
  ActionTypes,
  AsyncStatus,
  MutationTypes,
  type State,
} from "@/logic/store/store_types";
import type { FuelType } from "@/logic/types/sheared_local_types";
import evNavDefaultData from "@/logic/data/eVNavDefaultData";
import {
  ConnectorDetailsData,
  getAllTetheredConnectors,
} from "@/logic/data/connectorDetailsData";
import parseIntOrFloat from "@/logic/utils/parseNumOrFloat";
import Vehicle from "@/logic/classes/vehicle";

export default Vue.extend({
  name: "AddVehicleContent",
  methods: {
    async handelSubmit(): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const valid = await (this.$refs?.form as any)?.validate(); // validate provided data
      if (!valid) {
        return; // exit early as failed validation.
      }

      // create new vehicle class object
      const newVehicle = Vehicle.fromFormData({
        batterySize: this.batterySize,
        DragCoefficient: this.DragCoefficient,
        evModel: this.evModel,
        fuelType: this.fuelType,
        Mass: this.Mass,
        MaxElectricPowerAc: this.MaxElectricPowerAc,
        MaxElectricPowerDc: this.MaxElectricPowerDc,
        plate: this.plate,
        PowerChainEfficiency: this.PowerChainEfficiency,
        RegenerativeBreakingEfficiency: this.RegenerativeBreakingEfficiency,
        RollingResistanceCoefficient: this.RollingResistanceCoefficient,
        selectedConnectors: this.selectedConnectors,
        SOH: this.SOH,
        vehicleName: this.vehicleName,
        VIN: this.VIN,
        companyDirectusID: this.companyDirectusID,
        driverDirectusID: this.driverDirectusID,
      });

      // update DB
      // this.saveStatus = await newVehicle.saveVehicle();
      this.$store.dispatch(ActionTypes.saveVehicle, newVehicle);

      // add to global state
      this.$store.commit(MutationTypes.addIndividualVehicle, newVehicle);

      // indicate process has finished
    },
    handelCancel(): void {
      this.$store.commit(MutationTypes.setMainDialogContent, undefined);
    },
    handelModelSelect(val: EVModel | null): void {
      if (val) {
        this.batterySize = val.batterySize;
        this.Mass = val.mass;
        this.DragCoefficient = val.dragCoefficient;
        this.RollingResistanceCoefficient =
          evNavDefaultData.RollingResistanceCoefficient;
        this.RegenerativeBreakingEfficiency = val.regenRecovery * 100; // convert from decimal percentage representation to whole number percentage representation.
        this.PowerChainEfficiency = val.powerChainEfficiency * 100; // convert from decimal percentage representation to whole number percentage representation.
        this.MaxElectricPowerAc = val.maxElectricPowerAC;
        this.MaxElectricPowerDc = val.maxElectricPowerDC;
        this.SOH = val.calcLinearDegradationSOH() * 100; // convert from decimal percentage representation to whole number percentage representation.
        // connectors
        const tempArray: ConnectorDetailsData[] = [];
        val.compatibleConnectors.forEach((connector) => {
          const data = this.connectors.find(
            (dataItem) => dataItem.standard === connector.standard
          );
          const alreadyAdded = !!tempArray.find(
            (dataItem) => dataItem.standard === connector.standard
          );
          if (data && !alreadyAdded) tempArray.push(data);
        });
        this.selectedConnectors = tempArray;
      }
    },
    handelReset(): void {
      this.evModel = null;
      this.batterySize = null;
      this.SOH = 100;
      this.fuelType = "Electric";
      this.vehicleName = null;
      this.plate = null;
      this.VIN = null;
      this.Mass = evNavDefaultData.Mass;
      this.DragCoefficient = evNavDefaultData.DragCoefficient;
      this.RollingResistanceCoefficient =
        evNavDefaultData.RollingResistanceCoefficient;
      this.RegenerativeBreakingEfficiency =
        evNavDefaultData.RegenerativeBreakingEfficiency * 100; // convert from decimal percentage representation to whole number percentage representation.
      this.PowerChainEfficiency = evNavDefaultData.PowerChainEfficiency * 100; // convert from decimal percentage representation to whole number percentage representation.
      this.MaxElectricPowerAc = evNavDefaultData.MaxElectricPowerAc;
      this.MaxElectricPowerDc = evNavDefaultData.MaxElectricPowerDc;
      this.selectedConnectors = [];
      this.saveStatus = undefined;
    },
    validateSelectedConnectors(v: ConnectorDetailsData[]): boolean | string {
      if (this.fuelType !== "Electric") return true; // no need to validate connectors if not an EV.
      if (v.length) return true;
      return "Please select at least one connector";
    },
    validateFuelType(v: string | number | null): boolean | string {
      if (v) return true;
      return "Fuel type is required";
    },
    validateBattery(v: string | number | null): boolean | string {
      if (this.fuelType !== "Electric") return true; // no need to validate connectors if not an EV.
      if (v) return true;
      return "An EV needs to have a battery";
    },
    validateDrag(v: string | number | null): boolean | string {
      if (this.fuelType !== "Electric") return true; // no need to validate connectors if not an EV.
      if (!v) return true; // is only an optional property so if not set needs no range checking.
      const value = parseIntOrFloat(v);
      if ((value && value < 0.01) || (value && value > 10))
        return "Please set a value between 0.01 and 10 if setting aerodynamics";
      return true;
    },
    validateRoleResistance(v: string | number | null): boolean | string {
      if (this.fuelType !== "Electric") return true; // no need to validate connectors if not an EV.
      if (!v) return true; // is only an optional property so if not set needs no range checking.
      const value = parseIntOrFloat(v);
      if ((value && value < 0.001) || (value && value > 1))
        return "Please set a value between 0.001 and 1 if setting road dynamics";
      return true;
    },
    validateRegenBreaking(v: string | number | null): boolean | string {
      if (this.fuelType !== "Electric") return true; // no need to validate connectors if not an EV.
      if (!v) return true; // is only an optional property so if not set needs no range checking.
      const value = parseIntOrFloat(v);
      if ((value && value < 1) || (value && value > 100))
        return "Please set a value between 1% and 100% if setting regenerative breaking efficiency";
      return true;
    },
    validatePowerChainEfficiency(v: string | number | null): boolean | string {
      if (this.fuelType !== "Electric") return true; // no need to validate connectors if not an EV.
      if (!v) return true; // is only an optional property so if not set needs no range checking.
      const value = parseIntOrFloat(v);
      if ((value && value < 1) || (value && value > 100))
        return "Please set a value between 1% and 100% if setting power chain efficiency";
      return true;
    },
    validateMaxAC(v: string | number | null): boolean | string {
      if (this.fuelType !== "Electric") return true; // no need to validate connectors if not an EV.
      if (!v) return true; // is only an optional property so if not set needs no range checking.
      const value = parseIntOrFloat(v);
      if ((value && value < 0) || (value && value > 50))
        return "Please set a value between 0kw and 50kw if setting AC charging speed";
      return true;
    },
    validateMaxDC(v: string | number | null): boolean | string {
      if (this.fuelType !== "Electric") return true; // no need to validate connectors if not an EV.
      if (!v) return true; // is only an optional property so if not set needs no range checking.
      const value = parseIntOrFloat(v);
      if ((value && value < 0) || (value && value > 1000))
        return "Please set a value between 0kw and 1000kw if setting DC charging speed";
      return true;
    },
  },
  data() {
    return {
      tab: 0,
      evModel: null as EVModel | null, // Note this version of vuetify returns null when input is cleared.
      batterySize: null as string | number | null, // Note this version of vuetify returns stringified numbers form number type v-text-field component.
      SOH: 100,
      fuelType: "Electric" as FuelType,
      vehicleName: null as string | null,
      plate: null as string | null,
      VIN: null as string | null,
      Mass: evNavDefaultData.Mass as string | number | null,
      DragCoefficient: evNavDefaultData.DragCoefficient as
        | string
        | number
        | null,
      RollingResistanceCoefficient:
        evNavDefaultData.RollingResistanceCoefficient as string | number | null,
      RegenerativeBreakingEfficiency:
        (evNavDefaultData.RegenerativeBreakingEfficiency * 100) as
          | string
          | number
          | null, // convert from decimal percentage representation to whole number percentage representation.
      PowerChainEfficiency: (evNavDefaultData.PowerChainEfficiency * 100) as
        | string
        | number
        | null, // convert from decimal percentage representation to whole number percentage representation.
      MaxElectricPowerAc: evNavDefaultData.MaxElectricPowerAc as
        | string
        | number
        | null,
      MaxElectricPowerDc: evNavDefaultData.MaxElectricPowerDc as
        | string
        | number
        | null,
      selectedConnectors: [] as ConnectorDetailsData[],
      saveStatus: undefined as undefined | "failed" | "success",
    };
  },
  computed: {
    batteryAgeMessage(): string {
      const degradedPercentage = 100 - this.SOH;
      if (degradedPercentage >= 70)
        return "this looks like a poorly functioning battery";
      const yearsOld = Math.floor(degradedPercentage / 3);
      if (yearsOld <= 0) return "equivalent to a new battery";
      return (
        "equivalent to a " + yearsOld + " year old battery with normal usage"
      );
    },
    dragCoefficientMessage(): string | undefined {
      if (this.DragCoefficient) {
        const value = parseIntOrFloat(this.DragCoefficient);
        if (value && value >= 10)
          return "Are you sure? This is the equivalent of being as aerodynamic as a wall.";
      }
      return undefined;
    },
    regenerativeBrakingMessage(): string | undefined {
      if (this.RegenerativeBreakingEfficiency) {
        const value = parseIntOrFloat(this.RegenerativeBreakingEfficiency);
        if (value && value >= 100) return "Wow! Really?";
      }
      return undefined;
    },
    powerChainEfficiencyMessage(): string | undefined {
      if (this.PowerChainEfficiency) {
        const value = parseIntOrFloat(this.PowerChainEfficiency);
        if (value && value >= 100) return "Wow! Really?";
      }
      return undefined;
    },
    connectors(): ConnectorDetailsData[] {
      return getAllTetheredConnectors();
    },
    ...mapState({
      EVModels: (state: unknown): EVModel[] => (state as State).evModels,
      companyDirectusID: (state: unknown): number | undefined =>
        (state as State).user?.companyDirectusId,
      driverDirectusID: (state: unknown): number | undefined =>
        (state as State).user?.driverDirectusId,
      processingStatus: (state: unknown): AsyncStatus | undefined =>
        (state as State).vehicleSavingStatus,
    }),
  },
});
</script>
<style scoped>
#pwt-custom-active-tab {
  border-radius: 10px 10px 0 0;
  background-color: var(--v-primary-base);
}

.pwt-custom-active-tab-text {
  z-index: 1500;
}

.pwt-tabs-border {
  border-bottom: 2px solid var(--v-primary-base);
}

.pwt-scrollbar-styles {
  scrollbar-color: #ffffff #e0e0e0;
  scrollbar-width: thin;
}

.pwt-scrollbar-styles:hover {
  scrollbar-color: #eeeeee #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar {
  width: 6px;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track:hover {
  background: #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb:hover {
  background: #eeeeee;
}

* >>> .v-slider--horizontal {
  margin-left: unset;
  margin-right: unset;
}
* >>> .v-slider--horizontal .v-slider__track-container {
  height: 6px; /* override default slider thickness */
}

* >>> .v-slider__track-fill {
  border-radius: 2px; /* override default slider border-radius */
}

* >>> .v-slider__track-background {
  border-radius: 2px; /* override default slider border-radius */
}
</style>
