<template>
  <v-card
    flat
    v-if="trip && trip.fallbackTripData && trip.parameters && trip.locations"
    class="px-5 mb-5"
  >
    <ItineraryHeader :trip="trip" />
    <WhatWentWrongCard :trip="trip" />
    <FallbackDepartureCard />
    <FallbackTravelSection />
    <FallbackArrivalCard />
  </v-card>
  <v-card v-else>
    <v-card-title>Whoops! something went wrong!</v-card-title>
    <v-card-text>
      We appear to be having issues loading this trip please unselect the trip
      and try again. If this continues please contact support.
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import FallbackArrivalCard from "./FallbackArrivalCard.vue";
import FallbackDepartureCard from "./FallbackDepartureCard.vue";
import FallbackTravelSection from "./FallbackTravelSection.vue";
import ItineraryHeader from "./ItineraryHeader.vue";
import WhatWentWrongCard from "./WhatWentWrongCard.vue";
import Trip from "@/logic/classes/trip";

export default Vue.extend({
  name: "ItineraryFallbackContent",
  props: {
    trip: {
      required: true,
      type: Object as PropType<Trip>,
    },
  },
  components: {
    ItineraryHeader,
    WhatWentWrongCard,
    FallbackDepartureCard,
    FallbackTravelSection,
    FallbackArrivalCard,
  },
});
</script>
