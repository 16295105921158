<template>
  <v-card flat class="pt-5">
    <v-card-text>
      <v-card class="rounded-lg px-3" elevation="3" @click="openVehicleDetails">
        <v-row>
          <v-col cols="3" class="py-5 pl-5">
            <v-skeleton-loader
              type="image"
              width="100%"
              height="65px"
              v-if="loading"
            />
            <v-img
              v-else
              contain
              :src="imageSrc"
              aspect-ratio="1"
              width="100%"
              height="100%"
            ></v-img>
          </v-col>
          <v-col cols="9" style="position: relative">
            <v-skeleton-loader
              class="mt-n2"
              type="list-item-three-line"
              max-width="70%"
              v-if="loading"
            />
            <template v-else-if="vehicle">
              <v-card-title>
                {{ vehicle.name ?? "unnamed vehicle" }}
              </v-card-title>
              <v-card-subtitle class="d-flex flex-column">
                <span>
                  {{
                    vehicle.evModel
                      ? vehicle.evModel.name
                      : "model not specified"
                  }}
                </span>
                <span>
                  {{ vehicle.licensePlate ?? "unknown plate number" }}
                </span>
              </v-card-subtitle>
            </template>
            <template v-else>
              <v-card-title>No selected vehicle</v-card-title>
            </template>
            <v-icon style="position: absolute; top: 5px; right: 5px">
              mdi-dots-vertical
            </v-icon>
          </v-col>
        </v-row>
      </v-card>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import EVModel from "@/logic/classes/evModel";
import Vehicle from "@/logic/classes/vehicle";
import {
  GettersTypes,
  MutationTypes,
  State,
  MainDialogContent,
} from "@/logic/store/store_types";
import getAssetSrc from "@/logic/utils/getAssetSrc";
import Vue from "vue";
import { mapGetters, mapState } from "vuex";

export default Vue.extend({
  name: "SelectedVehicleCard",
  computed: {
    /** Image source for vehicle/model image, provides default filler image if no image. */
    imageSrc(): string {
      if (this.vehicle as Vehicle | undefined) {
        // get uploaded image to car in first instance.
        const images = (this.vehicle as Vehicle).imageSrcPaths;
        if (images.length) return images[0];
        // get model image in second instance.
        if ((this.vehicle as Vehicle).evModel)
          return (
            (this.vehicle.evModel as EVModel)?.imageSrc ??
            getAssetSrc("car_images/No_Image_Powersell.png")
          );
        // get company logo image in third instance.
        const company = (this.$store.state as State).fleet;
        if (
          company &&
          this.vehicle.companyDirectusId === company.directusId &&
          company.logoSrc
        ) {
          return company.logoSrc;
        }
      }
      // get default image in fourth instance.
      return getAssetSrc("car_images/No_Image_Powersell.png");
    },
    ...mapGetters({
      vehicle: GettersTypes.selectedVehicleData,
    }),
    ...mapState({
      loading: (state: unknown) =>
        (state as State).fleetFetchingFlag || (state as State).userFetchingFlag,
    }),
  },
  methods: {
    openVehicleDetails() {
      this.$store.commit(
        MutationTypes.setViewedFleetVehicle,
        this.vehicle.localId
      );
      this.$store.commit(
        MutationTypes.setMainDialogContent,
        MainDialogContent.FLEET_VEHICLE_DETAILS
      );
    },
    getMaxRange(): string {
      const range = this.vehicle.calcMaxRange(
        (this.$store.state as State).extraWeight
      );
      return ` or ${Math.floor(range / 1000)}km max range`;
    },
  },
});
</script>
