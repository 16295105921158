<template>
  <v-container>
    <v-card v-for="evse in evses" :key="evse.uid" flat>
      <template v-for="connector in evse.connectors">
        <v-card
          :key="connector.id"
          @click.stop="selectConnector(connector)"
          :class="
            connector.id === selectedConnectorId
              ? 'py-2 mb-5 rounded-lg'
              : vehicle && connector.isCompatible(vehicle) !== 'incompatible'
              ? 'mb-5 rounded-lg'
              : 'grey lighten-3 mb-5 rounded-lg'
          "
          :style="
            connector.id === selectedConnectorId
              ? 'border: 2px solid #2196f3 !important'
              : vehicle && connector.isCompatible(vehicle) !== 'incompatible'
              ? ''
              : 'filter: grayscale(1); cursor: unset !important;'
          "
        >
          <v-row no-gutters align="center">
            <v-card flat class="transparent ml-3" height="60" width="60">
              <v-img
                contained
                :src="connector.getPlugIconSrc()"
                height="60"
                width="60"
              ></v-img>
            </v-card>
            <v-card flat class="transparent">
              <v-card-title>{{ connector.displayName }}</v-card-title>
              <v-card-subtitle>
                {{ connector.ratingDisplayStr }}
              </v-card-subtitle>
            </v-card>
            <v-card
              flat
              class="transparent"
              v-if="
                vehicle &&
                connector.isCompatible(vehicle) === 'compatible with cable only'
              "
            >
              <v-card-title class="error--text">BYO Cable</v-card-title>
            </v-card>
          </v-row>
        </v-card>
      </template>
    </v-card>
  </v-container>
</template>
<script lang="ts">
import Connector from "@/logic/classes/connector";
import EVSE from "@/logic/classes/evse";
import Vehicle from "@/logic/classes/vehicle";
import { GettersTypes } from "@/logic/store/store_types";
import Vue, { PropType } from "vue";
import { mapGetters } from "vuex";
export default Vue.extend({
  name: "EvseCard",
  props: {
    evses: {
      required: true,
      type: Array as PropType<EVSE[]>,
    },
  },
  computed: {
    ...mapGetters({
      vehicle: GettersTypes.selectedVehicleData,
    }),
  },
  methods: {
    selectConnector(connector: Connector): void {
      if (
        this.vehicle &&
        connector.isCompatible(this.vehicle) !== "incompatible"
      ) {
        this.selectedConnectorId = connector.id;
        this.$emit("selectConnector", connector.id);
      }
    },
  },
  data() {
    return {
      selectedConnectorId: undefined as string | undefined,
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.evses) {
        const vehicle: Vehicle | undefined = this.vehicle;
        if (vehicle) {
          const connectors = this.evses
            .flatMap((evse) => evse.connectors)
            .filter(
              (connector) => connector.isCompatible(vehicle) !== "incompatible"
            );
          this.selectConnector(connectors[0]);
        } else {
          this.selectConnector(this.evses[0].connectors[0]);
        }
      }
    });
  },
});
</script>
