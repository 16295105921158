<template>
  <v-card
    class="rounded-lg pb-5"
    :class="
      location.localId === storedTrip.locations[0].localId
        ? 'primary white--text'
        : ''
    "
    elevation="4"
  >
    <v-row no-gutters class="pt-3">
      <v-col cols="1">
        <div
          class="primary rounded-circle ml-3 mt-1"
          style="height: 20px; width: 20px; border: 3px solid white !important"
          v-if="location.localId === storedTrip.locations[0].localId"
        ></div>
        <v-icon color="primary" large class="ml-1" v-else>
          mdi-map-marker
        </v-icon>
      </v-col>
      <v-col cols="10">
        <v-card-title
          class="font-weight-bold text-body-1 pt-0"
          style="word-break: normal"
        >
          {{ location.address }}
        </v-card-title>
        <!-- initial departure extra displayed data -->
        <template v-if="location.localId === storedTrip.locations[0].localId">
          <v-card-subtitle v-if="!!timeData.displayDepartureTime">
            {{ timeData.displayDepartureTime }} Departure
          </v-card-subtitle>
          <v-card-subtitle
            v-if="timeData.differenceType === 'before'"
            class="error--text pt-0 mt-n2"
          >
            <v-icon color="error">mdi-alert-circle</v-icon>
            you need to leave {{ timeData.timeDifference }} earlier than you
            expected to make your main appointment
          </v-card-subtitle>
          <v-card-subtitle
            v-if="timeData.differenceType === 'after'"
            class="primary--text pt-0 mt-n2"
          >
            you don't need to leave for another {{ timeData.timeDifference }}
          </v-card-subtitle>
        </template>
        <!-- start of trip leg extra displayed data -->
        <template v-else>
          <v-card flat>
            <v-card-text
              class="d-flex justify-space-between text-body-2 font-weight-medium"
              v-if="storedTrip.primaryTimeLocation"
            >
              <span>
                <v-icon color="blue lighten-4">$vehiclesIcon</v-icon>
                <v-icon class="ml-n3" color="primary">mdi-map-marker</v-icon>
                Arrive at
              </span>
              <span>{{ timeData.displayArrivalTime }}</span>
            </v-card-text>
            <v-card-text
              v-if="storedTrip.primaryTimeLocation"
              class="text-caption pt-0 mt-n2 ml-10"
            >
              <span
                v-if="timeData.differenceType === 'before'"
                class="primary--text"
              >
                {{ timeData.timeDifference }} earlier than expected
              </span>
              <span
                v-if="timeData.differenceType === 'after'"
                class="error--text"
              >
                <v-icon color="error" small>mdi-alert-circle</v-icon>
                {{ timeData.timeDifference }} later than expected
              </span>
            </v-card-text>
            <v-card-text
              class="d-flex justify-space-between text-body-2 font-weight-medium pt-0"
              v-if="!storedTrip.primaryTimeLocation"
            >
              <span>
                <v-icon color="primary" class="ml-2 mr-1">
                  mdi-clock-outline
                </v-icon>
                Stay for
              </span>
              <span>{{ calcStay(location.stay || 0) }}</span>
            </v-card-text>
            <v-card-text
              class="d-flex justify-space-between text-body-2 font-weight-medium pt-0"
              v-if="storedTrip.primaryTimeLocation"
            >
              <span>
                <v-icon color="primary lighten-4">mdi-map-marker</v-icon>
                <v-icon class="ml-n3" color="primary">$vehiclesIcon</v-icon>
                Depart at
              </span>
              <span>{{ timeData.displayDepartureTime }}</span>
            </v-card-text>
          </v-card>
        </template>
        <!-- SOC displayed data -->
        <v-card-text
          class="d-flex justify-space-between pr-10"
          v-if="location.localId === storedTrip.locations[0].localId"
        >
          <span>Departure charge</span>
          <span>{{ Math.floor(step.ArrivalCharge * 100) }}%</span>
        </v-card-text>
        <v-card-text v-else-if="storedTrip.evTripData" class="pr-10 pt-0">
          <v-icon color="primary" class="pl-2 pr-1"> $stationsIcon </v-icon>
          Charge here from
          {{
            Math.floor(
              storedTrip.evTripData[tripIndex - 1].steps[
                storedTrip.evTripData[tripIndex - 1].steps.length - 1
              ].EndCharge * 100
            )
          }}% to {{ Math.floor(step.ArrivalCharge * 100) }}%
        </v-card-text>
      </v-col>
      <v-col cols="1"></v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { Duration } from "luxon";
import type { EVNavStep } from "@/logic/types/ev_nav_types";
import Trip from "@/logic/classes/trip";
import TripLocation from "@/logic/classes/tripLocation";
import calcTimeData, {
  type calcTimeDataReturnObj,
} from "@/logic/utils/calcTimeData";

/** Vue Component: renders the first card in a trips itinerary, to be used in the `ItineraryContent` component.
 *
 * @prop `step` - the object for the current step in the route plan. See `Step` in types for details.
 * @prop `location` - the location object for matching this stop of the trip.
 * @prop `stepIndex` - the position of this step in `steps` property array.
 * @prop `tripIndex` - the index of the current leg of the trip.
 * @prop `storedTrip` - the full stored trip object.
 */
export default Vue.extend({
  name: "DepartureCard",
  props: {
    step: Object as PropType<EVNavStep>,
    location: Object as PropType<TripLocation>,
    tripIndex: Number,
    stepIndex: Number,
    storedTrip: Object as PropType<Trip>,
  },
  methods: {
    calcStay(seconds: number) {
      return Duration.fromObject({ seconds: seconds })
        .shiftTo("hours", "minutes")
        .normalize()
        .toHuman({ listStyle: "narrow" })
        .replace(",", "");
    },
  },
  computed: {
    timeData(): calcTimeDataReturnObj {
      return calcTimeData(
        this.storedTrip,
        this.stepIndex,
        this.location.localId
      );
    },
  },
});
</script>
