<template>
  <v-card class="rounded-lg" elevation="4">
    <v-row no-gutters class="pt-3">
      <v-col cols="1">
        <v-icon color="primary" large class="ml-1">mdi-map-marker</v-icon>
      </v-col>
      <v-col cols="10">
        <v-card-title class="pt-0 font-weight-bold text-body-2">
          Scheduled stop
        </v-card-title>
        <v-card-subtitle class="d-flex font-weight-bold text-body-2">
          <span>{{ details ? details.address : "unknown location" }}</span>
        </v-card-subtitle>
        <v-card-text
          class="d-flex justify-space-between text-body-2 font-weight-medium"
          v-if="storedTrip.primaryTimeLocation"
        >
          <span>
            <v-icon color="primary lighten-4">$vehiclesIcon</v-icon>
            <v-icon class="ml-n3" color="primary">mdi-map-marker</v-icon>
            Arrive at
          </span>
          <span>{{ timeData.displayArrivalTime }}</span>
        </v-card-text>
        <v-card-text
          v-if="storedTrip.primaryTimeLocation"
          class="text-caption pt-0 mt-n2 ml-10"
        >
          <span
            v-if="timeData.differenceType === 'before'"
            class="primary--text"
          >
            {{ timeData.timeDifference }} earlier than expected
          </span>
          <span v-if="timeData.differenceType === 'after'" class="error--text">
            <v-icon color="error" small>mdi-alert-circle</v-icon>
            {{ timeData.timeDifference }} later than expected
          </span>
        </v-card-text>
        <v-card-text
          class="d-flex justify-space-between text-body-2 font-weight-medium pt-0"
          v-if="!storedTrip.primaryTimeLocation"
        >
          <span>
            <v-icon color="primary" class="ml-2 mr-1">
              mdi-clock-outline
            </v-icon>
            Stay for
          </span>
          <span>{{ stayTime }}</span>
        </v-card-text>
        <v-card-text
          class="d-flex justify-space-between text-body-2 font-weight-medium pt-0"
          v-if="storedTrip.primaryTimeLocation"
        >
          <span>
            <v-icon color="primary lighten-4">mdi-map-marker</v-icon>
            <v-icon class="ml-n3" color="primary">$vehiclesIcon</v-icon>
            Depart at
          </span>
          <span>{{ timeData.displayDepartureTime }}</span>
        </v-card-text>
        <v-card-text
          class="d-flex justify-space-between text-body-2 font-weight-medium pt-0"
        >
          <span>
            <v-icon color="primary" class="ml-2 mr-1">
              mdi-battery-charging
            </v-icon>
            Charge remaining
          </span>
          <span>{{ Math.round(step.ArrivalCharge * 100) }}%</span>
        </v-card-text>

        <v-expansion-panels class="mb-5">
          <v-expansion-panel>
            <v-expansion-panel-header
              class="d-flex text-body-2 font-weight-medium"
            >
              <span>
                <v-icon color="primary" class="mr-1"> $stationsIcon </v-icon>
                <span>
                  {{ closeByChargers.length || "No" }} chargers near by</span
                >
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card
                v-for="(station, index) in closeByChargers"
                :key="`close-by-station-${index}`"
                @click="openChargingStationDetails(station.id)"
              >
                <v-card-title class="font-weight-bold text-body-2">
                  {{ station.name }}
                </v-card-title>
                <v-card-subtitle class="grey--text text--darken-2">
                  {{ station.simpleDisplayRating }}
                </v-card-subtitle>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="1"></v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { Duration } from "luxon";
import { EVNavStep } from "@/logic/types/ev_nav_types";
import Trip from "@/logic/classes/trip";
import TripLocation from "@/logic/classes/tripLocation";
import calcTimeData, {
  calcTimeDataReturnObj,
} from "@/logic/utils/calcTimeData";
import {
  GettersTypes,
  MainDialogContent,
  MutationTypes,
  State,
} from "@/logic/store/store_types";
import Charger from "@/logic/classes/charger";

/** Vue Component: renders a scheduled stop for a given trip, to be used in the `ItineraryContent` component.
 *
 * @prop `waypointName` - the name property for the waypoint provided back from the API call.
 * @prop `step` - the object for the current step in the route plan.
 * @prop `stepIndex` - the position of this step in `steps` property array.
 * @prop `storedTrip` - the full stored trip object.
 */
export default Vue.extend({
  name: "PlannedStopCard",
  props: {
    waypointName: String as PropType<string>,
    step: Object as PropType<EVNavStep>,
    stepIndex: Number as PropType<number>,
    storedTrip: Object as PropType<Trip>,
  },
  computed: {
    closeByChargers(): Charger[] {
      const trip: Trip | undefined =
        this.$store.getters[GettersTypes.selectedTripData];
      const location: TripLocation | undefined = (
        this.storedTrip as Trip
      ).locations.find((location) => location.localId === this.waypointName);
      const chargers: Charger[] = (this.$store.state as State).chargers;
      if (trip && location) {
        return location.getCloseChargers(
          chargers,
          trip.chargersAlongRouteCDBIDs
        );
      }
      return [];
    },
    details(): TripLocation | undefined {
      // extrapolate ID from waypointName
      const locationID: string = this.waypointName;
      // find LocationData
      const data = (this.storedTrip.locations as TripLocation[]).find(
        (location) => location.localId === locationID
      );
      // confirm find operation was successful
      if (!data) return;
      // return LocationData
      return data;
    },
    timeData(): calcTimeDataReturnObj {
      // extrapolate ID from waypointName
      const locationID: string = this.waypointName;
      return calcTimeData(this.storedTrip, this.stepIndex, locationID);
    },
    stayTime(): string {
      // extrapolate ID from waypointName
      const locationID: string = this.waypointName;
      const stay = (this.storedTrip.locations as TripLocation[]).find(
        (location) => location.localId === locationID
      )?.stay;

      return Duration.fromObject({
        hours: 0,
        minutes: stay ? Math.floor(stay / 60) : 0,
      })
        .normalize()
        .toHuman({ unitDisplay: "narrow" })
        .replace(",", "");
    },
  },
  methods: {
    openChargingStationDetails(id: string): void {
      this.$store.commit(MutationTypes.setSelectedCharger, id);
      this.$store.commit(
        MutationTypes.setMainDialogContent,
        MainDialogContent.CHARGING_STATION_DETAILS
      );
    },
  },
});
</script>
