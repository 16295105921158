import DirectusUserData from "../types/directus_user";

export default class User {
  // -------------------------------------------------------------------- //
  // ------------------------------- State ------------------------------ //
  // -------------------------------------------------------------------- //

  /** Directus `Company` collection record id. */
  directusId?: string;

  /** The users first name. */
  firstName?: string;

  /** The users last name. */
  lastName?: string;

  /** The users email address. */
  email?: string;

  /** Related directus `Driver` collection record id. */
  driverDirectusId?: number;

  /** Related directus `Company` collection record id. */
  companyDirectusId?: number;

  /** Related directus `Role` collection record id. */
  directusRoleUUID?: string;

  /** List of related directus car record collection ids */
  cars: number[];

  // -------------------------------------------------------------------- //
  // --------------------------- Constructor ---------------------------- //
  // -------------------------------------------------------------------- //

  constructor({
    directusId = undefined,
    firstName = undefined,
    lastName = undefined,
    email = undefined,
    driverDirectusId = undefined,
    companyDirectusId = undefined,
    directusRoleUUID = undefined,
    cars = [],
  }: {
    directusId?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    driverDirectusId?: number;
    companyDirectusId?: number;
    directusRoleUUID?: string;
    cars?: number[];
  }) {
    this.directusId = directusId;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.driverDirectusId = driverDirectusId;
    this.companyDirectusId = companyDirectusId;
    this.directusRoleUUID = directusRoleUUID;
    this.cars = cars;
  }

  static fromDirectusData(data: DirectusUserData) {
    return new User({
      directusId: data.id,
      firstName: data.first_name ?? undefined,
      lastName: data.last_name ?? undefined,
      email: data.email ?? undefined,
      driverDirectusId: data.Driver ?? undefined,
      companyDirectusId: data.Company ?? undefined,
      directusRoleUUID: data.role ?? undefined,
      cars: data.Cars ?? undefined,
    });
  }

  // -------------------------------------------------------------------- //
  // ------------------------------ Getters ----------------------------- //
  // -------------------------------------------------------------------- //

  /** Returns the users full name in a displayable string. */
  public get fullName(): string {
    return (this.firstName ? this.firstName + " " : "") + (this.lastName ?? "");
  }

  // -------------------------------------------------------------------- //
  // ------------------------------ Methods ----------------------------- //
  // -------------------------------------------------------------------- //
}
