<template>
  <v-card flat>
    <v-img
      contain
      :max-height="size"
      :max-width="size"
      :src="getSrc('Loading-Blue.gif')"
    ></v-img>
  </v-card>
</template>
<script lang="ts">
import getAssetSrc from "../../../logic/utils/getAssetSrc";
import Vue from "vue";

export default Vue.extend({
  name: "LoadingCard",
  methods: {
    getSrc(partialFilePath: string): string {
      return getAssetSrc(partialFilePath);
    },
  },
  props: {
    size: {
      type: Number,
      default: 150,
    },
  },
});
</script>
