<template>
  <v-card class="pb-5" flat>
    <v-card-text>
      Find chargers you can reach from as specific location
    </v-card-text>
    <v-card-text>
      <v-autocomplete
        no-filter
        v-model="address"
        clearable
        filled
        solo
        :search-input.sync="query"
        :items="items"
        item-text="address"
        item-value="waypoint"
        prepend-inner-icon="mdi-pencil"
        return-object
        hide-no-data
        hide-details
        background-color="background"
        class="rounded-lg"
        attach
        placeholder="Enter an address"
        persistent-placeholder
      ></v-autocomplete>
    </v-card-text>
    <v-card-text class="py-0">
      <SOCSlider
        :initialValue="soc"
        label="Current charge"
        @update="handleSOCChange"
      />
    </v-card-text>
    <v-card-actions :class="processing ? 'justify-center align-center' : ''">
      <template v-if="processing">
        <LoadingCard />
      </template>
      <template v-else>
        <v-btn
          color="primary"
          dark
          class="text-none rounded-lg flex-grow-1"
          @click="handelFindChargers"
        >
          Find chargers
        </v-btn>
        <v-btn
          :color="pwtDarkBlue"
          outlined
          class="text-none flex-grow-1 rounded-lg"
          @click="handelClear"
        >
          Clear chargers in range
        </v-btn>
        <span class="error--text ml-3" v-if="errorMsg">
          Whoops! {{ errorMsg }}
        </span>
      </template>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import SOCSlider, {
  type SOCSliderUpdateObj,
} from "../../ui-elements/SOCSlider.vue";
import processAddressSearchResults, {
  type AutoCompleteReturnObject,
  type processedAddressObj,
} from "@/logic/utils/processAddressSearchResults";
import {
  ActionTypes,
  GettersTypes,
  MutationTypes,
  type State,
} from "@/logic/store/store_types";
import { EVNavNearbyParams } from "@/logic/types/ev_nav_types";
import Vehicle from "@/logic/classes/vehicle";
import EVModel from "@/logic/classes/evModel";
import GeoLocation from "@/logic/classes/geoLocation";
import { fetchLocationAutoCompleteDetails } from "../../../../logic/api/calls/maps_little_monkey_calls";
import LoadingCard from "../../ui-elements/LoadingCard.vue";
import { powerTripDarkBlue } from "@/logic/data/const";

export default Vue.extend({
  name: "ChargersNearbyCard",
  data() {
    return {
      pwtDarkBlue: powerTripDarkBlue,
      expand: false,
      address: null as processedAddressObj | null,
      query: null,
      results: null as Array<AutoCompleteReturnObject> | null,
      errorMsg: null as string | null,
      soc: 1,
    };
  },
  computed: {
    items() {
      // is not null guard clause.
      if (!this.results || !this.query) return []; //exit function returning an empty array
      // process API search results into usable items
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const items = processAddressSearchResults(this.results!, this.query!);
      return items;
    },
    EVmodelDetails(): EVModel | undefined {
      const vehicle: Vehicle | undefined =
        this.$store.getters[GettersTypes.selectedVehicleData];
      return vehicle?.evModel;
    },
    batteryCapacity(): number | undefined {
      const vehicle: Vehicle | undefined =
        this.$store.getters[GettersTypes.selectedVehicleData];
      return vehicle?.totalBatteryKWh();
    },
    ...mapGetters({
      vehicle: GettersTypes.selectedVehicleData,
    }),
    ...mapState({
      startingCharge: (state: unknown): number => (state as State).SOCAct,
      minCharge: (state: unknown): number => (state as State).SOCMin,
      userGeoLocation: (state: unknown): GeoLocation | undefined =>
        (state as State).userGeoIPData,
      processing: (state: unknown): boolean =>
        (state as State).nearbyFetchingFlag,
    }),
  },
  watch: {
    query(val: string) {
      fetchLocationAutoCompleteDetails(val, this.userGeoLocation).then(
        (data) => {
          this.results = data;
        }
      );
    },
  },
  methods: {
    handleSOCChange(val: SOCSliderUpdateObj) {
      this.soc = val.SOC;
    },
    handelFindChargers() {
      // clear error messages
      this.errorMsg = null;
      // guard clauses
      if (!this.address) {
        this.errorMsg = "Please enter an address.";
        return;
      }
      if (!this.vehicle) {
        this.errorMsg = "Please select a vehicle to user.";
        return;
      }
      // create API call parameters object.
      const tempObj: EVNavNearbyParams = {
        Location: this.address.coordinates,
        Vehicle: (this.vehicle as Vehicle).routePlanningCarParam,
        Battery: (this.vehicle as Vehicle).totalBatteryKWh() * this.soc,
        SOCAct: this.startingCharge,
        SOCMin: this.minCharge,
      };
      // dispatch to store to call async function as process results
      this.$store.dispatch(ActionTypes.fetchNearbyChargersData, tempObj);
      // Notify analytics server
      // eslint-disable-next-line
      // @ts-ignore
      Vue.prototype.$Countly.q.push([
        "add_event",
        {
          key: "Find chargers nearby feature used",
          count: 1,
        },
      ]);
    },
    handelClear() {
      this.$store.commit(MutationTypes.setNearbyChargersData, undefined);
      this.$store.commit(MutationTypes.setShowNearbyChargersOnly, false);
    },
  },
  components: { SOCSlider, LoadingCard },
});
</script>
