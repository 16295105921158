<template>
  <v-card flat class="pl-3">
    <v-card-title class="primary--text" style="word-break: normal">
      How are you calculating my route?
    </v-card-title>
    <v-card-text>
      Intro to the route planer and how calculations are done goes here
    </v-card-text>
    <v-card-title class="primary--text"> What do the icons mean </v-card-title>
    <v-card flat class="pb-1">
      <v-row>
        <v-col cols="2">
          <img
            :src="getSrc('icons/DCCharger2.svg')"
            style="width: 50px; height: 50px"
            class="ml-2 mr-0"
          />
        </v-col>
        <v-col cols="10">
          <v-card-title class="pt-0">
            Fast charging stations (DC chargers)
          </v-card-title>
          <v-card-subtitle>
            More powerful (faster) charging stations show more lighting bolts
          </v-card-subtitle>
        </v-col>
      </v-row>
    </v-card>
    <v-card flat class="pb-1">
      <v-row>
        <v-col cols="2">
          <img
            :src="getSrc('icons/ACCharger1.svg')"
            style="width: 50px; height: 50px"
            class="ml-2 mr-0"
          />
        </v-col>
        <v-col cols="10">
          <v-card-title class="pt-0">
            Destination charging stations (AC chargers)
          </v-card-title>
          <v-card-subtitle>
            More powerful (faster) charging stations show more lighting bolts
          </v-card-subtitle>
        </v-col>
      </v-row>
    </v-card>
    <v-card flat class="pb-1">
      <v-row>
        <v-col cols="2">
          <img
            :src="getSrc('plug_images/chademo.png')"
            style="width: 50px; height: 50px; filter: hue-rotate(90deg)"
            class="ml-2 mr-0"
          />
        </v-col>
        <v-col cols="10">
          <v-card-title class="pt-0" style="word-break: normal">
            Compatible cable
          </v-card-title>
          <v-card-subtitle>
            Charging stations that supply a cable that will fit your chosen EV
          </v-card-subtitle>
        </v-col>
      </v-row>
    </v-card>
    <v-card flat class="pb-1">
      <v-row>
        <v-col cols="2">
          <img
            :src="getSrc('plug_images/type_2_socketed.png')"
            style="width: 50px; height: 50px"
            class="ml-2 mr-0"
          />
        </v-col>
        <v-col cols="10">
          <v-card-title class="pt-0" style="word-break: normal">
            Brin your own (BYO) cable
          </v-card-title>
          <v-card-subtitle>
            Charging stations with a socket that you can plug your own cable in
            to
          </v-card-subtitle>
        </v-col>
      </v-row>
    </v-card>
    <v-card flat class="pb-1">
      <v-row>
        <v-col cols="2">
          <img
            :src="getSrc('plug_images/chademo.png')"
            style="width: 50px; height: 50px; filter: grayscale(100%)"
            class="ml-2 mr-0"
          />
        </v-col>
        <v-col cols="10">
          <v-card-title class="pt-0" style="word-break: normal">
            Incompatible cable
          </v-card-title>
          <v-card-subtitle>
            Charging stations that supply cable that won't fit your chosen EV
          </v-card-subtitle>
        </v-col>
      </v-row>
    </v-card>
    <v-card flat class="pb-3">
      <v-row>
        <v-col cols="2" align-self="center">
          <v-icon x-large class="ml-3 mr-0" color="primary">
            mdi-calendar-check
          </v-icon>
        </v-col>
        <v-col cols="10">
          <v-card-title class="pt-0" style="word-break: normal">
            Bookable charging station
          </v-card-title>
          <v-card-subtitle class="pb-0">
            Charging stations that are within your bookable charging network
          </v-card-subtitle>
        </v-col>
      </v-row>
    </v-card>

    <v-expansion-panels accordion flat>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-card-title class="primary--text pl-0" style="word-break: normal">
            Terms and conditions of use
          </v-card-title>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card-text class="pb-5 d-flex flex-column text-center">
            <span>POWER TRIP</span>
            <span>PLATFORM END USER LICENCE AGREEMENT</span>
            <span> NOVEMBER 2022 </span>
          </v-card-text>

          <v-card-text> 1. Agreement </v-card-text>
          <v-card-text>
            <ul class="pwt-t-and-c-ul px-0">
              <li class="d-flex">
                <span class="pr-2"> 1.1 </span>
                <span>
                  This End User Licence Agreement (EULA) governs the licensing
                  and support of the Power Trip platform including Game Plan™,
                  Optimiser™, Network™ and Steward™, and related
                  administration software (Software) by Power Trip Limited
                  (Power Trip, we, us, our).
                </span>
              </li>
              <li class="d-flex">
                <span class="pr-2">1.2</span
                ><span
                  >By clicking the “accept” button and/or by downloading, using
                  or otherwise accessing the Software, you agree yourself, and
                  on behalf of your organization and/or all users within your
                  organization, (together Licensee, you, your) to comply with
                  the terms of this EULA and you agree that all use of, and
                  access to, the Software by you is governed by the terms of
                  this EULA which are legally binding on you.</span
                >
              </li>
              <li class="d-flex">
                <span class="pr-2">1.3</span
                ><span
                  >If you do not agree to any of the terms of this EULA then you
                  should not download and/or use the Software and you should
                  delete any copy of it in your possession or control.</span
                >
              </li>
              <li class="d-flex">
                <span class="pr-2">1.4</span
                ><span
                  >You are responsible for ensuring that all of your users who
                  are permitted by you to use the Software (Permitted Users)
                  review and accept the terms of this EULA and you are liable
                  for all breaches of this EULA by your Permitted Users.</span
                >
              </li>
            </ul>
          </v-card-text>
          <v-card-text> 2. Software licence </v-card-text>
          <v-card-text>
            <ul class="pwt-t-and-c-ul px-0">
              <li class="d-flex">
                <span class="pr-2">2.1</span
                ><span
                  >Subject to the terms and conditions of this EULA, we grant
                  you a non-exclusive, non-transferable, non-sub-licensable and
                  revocable licence to download and use the Software for your
                  internal business purposes.</span
                >
              </li>
              <li class="d-flex">
                <span class="pr-2">2.2 </span
                ><span>
                  You will access the Software via a login we provide for that
                  purpose.</span
                >
              </li>
              <li class="d-flex">
                <span class="pr-2">2.3 </span
                ><span>
                  Except as expressly permitted under this EULA, you must not:
                  <ul class="pwt-t-and-c-ul">
                    <li class="d-flex">
                      <span class="pr-2">(a)</span
                      ><span
                        >sell, resell, rent, lease, loan, supply, publish or
                        distribute;</span
                      >
                    </li>
                    <li class="d-flex">
                      <span class="pr-2">(b)</span
                      ><span>modify, edit or adapt; or</span>
                    </li>
                    <li class="d-flex">
                      <span class="pr-2">(c)</span
                      ><span
                        >copy, decompile, reverse engineer, disassemble, attempt
                        to derive the source code of, or create derivative works
                        of, the Software or any part of it.</span
                      >
                    </li>
                  </ul>
                </span>
              </li>
              <li class="d-flex">
                <span class="pr-2">2.4</span
                ><span
                  >You must not allow any login details or passwords to be
                  shared with any third party and you must ensure only Permitted
                  Users within your organization are given access to the
                  Software.</span
                >
              </li>
              <li class="d-flex">
                <span class="pr-2">2.5</span
                ><span
                  >You must not use the Software for any illegal or unlawful
                  purpose.</span
                >
              </li>
            </ul>
          </v-card-text>
          <v-card-text> 3. Information and data </v-card-text>
          <v-card-text>
            <ul class="pwt-t-and-c-ul px-0">
              <li class="d-flex">
                <span class="pr-2">3.1</span
                ><span
                  >All data we collect through your use of the Software remains
                  yours but we may use, process and retain a copy of that data
                  for the purposes of improving our Software and providing our
                  products and services.</span
                >
              </li>
              <li class="d-flex">
                <span class="pr-2">3.2</span
                ><span
                  >You must obtain all consents and approvals from your
                  Permitted Users to enable them to use the Software in the
                  manner intended and for us to collect and process the data
                  generated through that use.</span
                >
              </li>
              <li class="d-flex">
                <span class="pr-2">3.3</span
                ><span
                  >You agree that you are responsible for keeping the
                  information connected with you up to date.</span
                >
              </li>
              <li class="d-flex">
                <span class="pr-2">3.4</span>
                <span>
                  You agree that, whenever you provide us with any information
                  or materials, either through the Software or in any other way:

                  <ul class="pwt-t-and-c-ul">
                    <li class="d-flex">
                      <span class="pr-2">(a)</span>
                      <span
                        >all information that you provide to us will be
                        accurate, complete and up to date, and you will provide
                        us with all information requested;
                      </span>
                    </li>
                    <li class="d-flex">
                      <span class="pr-2">(b)</span>
                      <span
                        >you have all the rights necessary to provide the
                        information and materials to us and for us to use that
                        information for the purposes of providing the Software
                        to you; and</span
                      >
                    </li>
                    <li class="d-flex">
                      <span class="pr-2">(c)</span>
                      <span
                        >for any personal information you provide to us about
                        any person, you have obtained that person’s consent to
                        provide us with that information and for us to use that
                        information in accordance with this EULA and in
                        accordance with our Privacy Policy.</span
                      >
                    </li>
                  </ul>
                </span>
              </li>
            </ul>
          </v-card-text>

          <v-card-text> 4. Intellectual property rights </v-card-text>
          <v-card-text>
            <ul class="pwt-t-and-c-ul px-0">
              <li class="d-flex">
                <span class="pr-2">4.1</span
                ><span
                  >We own all intellectual property rights in and to the
                  Software and you obtain no rights, title or interest in or to
                  the Software other than the limited licence rights set out in
                  this EULA.</span
                >
              </li>
              <li class="d-flex">
                <span class="pr-2">4.2</span
                ><span
                  >All rights not expressly granted in this EULA are reserved by
                  us. In particular, this EULA does not grant you any rights to
                  use any intellectual property rights other than our Software,
                  including any of our trade marks or logos.</span
                >
              </li>
            </ul>
          </v-card-text>
          <v-card-text> 5. Term and termination </v-card-text>
          <v-card-text>
            <ul class="pwt-t-and-c-ul px-0">
              <li class="d-flex">
                <span class="pr-2">5.1</span
                ><span
                  >The term of your agreement with us commences upon the date
                  you accept this EULA (Commencement Date) and continues, unless
                  terminated early in accordance with its terms, for so long as
                  you use the Software.</span
                >
              </li>
              <li class="d-flex">
                <span class="pr-2">5.2</span
                ><span>
                  We may terminate this agreement and your rights to use the
                  Software at any time:

                  <ul class="pwt-t-and-c-ul">
                    <li class="d-flex">
                      <span class="pr-2">(a)</span>
                      <span
                        >if you or your Permitted Users breach any provision of
                        this EULA;</span
                      >
                    </li>
                    <li class="d-flex">
                      <span class="pr-2">(b)</span>
                      <span>
                        if you fail to pay any fees due for the use of the
                        Software by the due date; or
                      </span>
                    </li>
                    <li class="d-flex">
                      <span class="pr-2">(c)</span>
                      <span>
                        if we decide to discontinue provision of the Software to
                        all Customers.
                      </span>
                    </li>
                  </ul>
                </span>
              </li>
              <li class="d-flex">
                <span class="pr-2"></span><span></span>
                5.3 You must cease all use of the Software on termination of
                this EULA.
              </li>
            </ul>
          </v-card-text>

          <v-card-text> 6. Fees and payment </v-card-text>
          <v-card-text>
            <ul class="pwt-t-and-c-ul px-0">
              <li class="d-flex">
                <span class="pr-2">6.1</span
                ><span
                  >You must pay any applicable fees for the Software in the
                  amounts and in accordance with the payment instructions
                  provide by us from time time.</span
                >
              </li>
              <li class="d-flex">
                <span class="pr-2">6.2</span
                ><span>
                  Payment may be direct to us or to our resellers or channel
                  partners.</span
                >
              </li>
            </ul>
          </v-card-text>
          <v-card-text> 7. Warranties and liability </v-card-text>
          <v-card-text>
            <ul class="pwt-t-and-c-ul px-0">
              <li class="d-flex">
                <span class="pr-2">7.1</span
                ><span>
                  Subject to clause 7.3:
                  <ul class="pwt-t-and-c-ul">
                    <li class="d-flex">
                      <span class="pr-2">(a)</span>
                      <span
                        >we make no representations or warranties about the
                        suitability or performance of the Software for any
                        purpose; and</span
                      >
                    </li>
                    <li class="d-flex">
                      <span class="pr-2">(b)</span
                      ><span
                        >the Software is provided “as is” and any express or
                        implied warranties, including, but not limited to,
                        implied warranties or merchantability and fitness for a
                        particular purpose, are disclaimed to the extent
                        permitted by law.</span
                      >
                    </li>
                  </ul>
                </span>
              </li>
              <li class="d-flex">
                <span class="pr-2">7.2</span
                ><span>
                  You acknowledge that software is never wholly free from
                  defects, errors and bugs and the internet is not totally
                  secure and, accordingly, we do not warrant or represent that:
                  <ul class="pwt-t-and-c-ul">
                    <li class="d-flex">
                      <span class="pr-2"> (a) </span>
                      <span>
                        the Software will be free from defects, errors or bugs;
                      </span>
                    </li>
                    <li class="d-flex">
                      <span class="pr-2">(b)</span>
                      <span>
                        the Software will be unaffected by cyber attacks or
                        viruses; or
                      </span>
                    </li>
                    <li class="d-flex">
                      <span class="pr-2">(c)</span>
                      <span>
                        your use of the Software will be uninterrupted, timely
                        or secure, or that the Software will always be available
                        for use or available for any minimum periods of uptime
                        unless otherwise agreed in writing.
                      </span>
                    </li>
                  </ul>
                </span>
              </li>
              <li class="d-flex">
                <span class="pr-2">7.3</span
                ><span
                  >Certain laws in your jurisdiction may imply terms into some
                  contracts for the supply of goods and services and prohibit
                  the exclusion, restriction and modification of such terms
                  (Implied Terms). Nothing in this EULA operates to exclude,
                  limit, restrict or modify:

                  <ul class="pwt-t-and-c-ul">
                    <li class="d-flex">
                      <span class="pr-2">(a)</span>
                      <span>the application of the Implied Terms;</span>
                    </li>
                    <li class="d-flex">
                      <span class="pr-2">(b)</span>
                      <span>
                        our liability for breach of an Implied Term (except to
                        the extent that liability may be restricted in
                        accordance with clauses 7.4 and 7.5 below); or
                      </span>
                    </li>
                    <li class="d-flex">
                      <span class="pr-2">(c)</span>
                      <span>
                        any other liability that may not be excluded, limited,
                        restricted or modified under any applicable laws.
                      </span>
                    </li>
                  </ul>
                </span>
              </li>
              <li class="d-flex">
                <span class="pr-2">7.4</span>
                <span>
                  Subject to clause 7.3, in no event will we will liable,
                  whether in contract, tort (including negligence), statute or
                  in any other way, to you for:
                  <ul class="pwt-t-and-c-ul">
                    <li class="d-flex">
                      <span class="pr-2">(a)</span>
                      <span>
                        loss of revenue or profit, loss of anticipated savings,
                        loss of goodwill or opportunity, loss of production,
                        loss or corruption of data; or
                      </span>
                    </li>
                    <li class="d-flex">
                      <span class="pr-2">(b)</span>
                      <span>
                        loss, damage, cost or expense of any kind whatsoever
                        that is indirect, consequential, or of a special nature,
                        arising, directly or indirectly, out of the use or
                        licensing of the Software, or otherwise arising out of
                        this Agreement, even if we had been advised of the
                        possibility of such damages, and even if such loss,
                        damage, cost or expense was reasonably foreseeable by
                        us.
                      </span>
                    </li>
                  </ul>
                </span>
              </li>
              <li class="d-flex">
                <span class="pr-2">7.5</span>
                <span>
                  Subject to clause 7.3, our maximum liability to you in
                  relation to the Software or your use of the Software or
                  otherwise arising out of or in connection with this EULA, is
                  limited to the fees you pay for the Software.
                </span>
              </li>
              <li class="d-flex">
                <span class="pr-2">7.6</span
                ><span
                  >Our Software may be used in conjunction with third party
                  software and/or hardware which is not supplied by us and for
                  which we are not responsible, and you acknowledge and agree
                  that we are not responsible for the performance or
                  availability of such third party software and/or
                  hardware.</span
                >
              </li>
            </ul>
          </v-card-text>
          <v-card-text> 8. Privacy policy </v-card-text>
          <v-card-text>
            <ul class="pwt-t-and-c-ul px-0">
              <li class="d-flex">
                <span class="pr-2">8.1</span
                ><span
                  >Your privacy is important to us. We gather, process, use,
                  store and disclose information about you and your use of the
                  Software in accordance with our privacy policy, which may be
                  found here.</span
                >
              </li>
              <li class="d-flex">
                <span class="pr-2">8.2</span
                ><span
                  >Our privacy policy forms part of this EULA, and by
                  downloading and/or using our Software you agree to our privacy
                  policy.</span
                >
              </li>
            </ul>
          </v-card-text>
          <v-card-text> 9. General </v-card-text>
          <v-card-text>
            <ul class="pwt-t-and-c-ul px-0">
              <li class="d-flex">
                <span class="pr-2">9.1</span
                ><span>We may amend or modify this EULA at any time.</span>
              </li>
              <li class="d-flex">
                <span class="pr-2">9.2</span
                ><span
                  >Any change or variation to these EULA shall be notified to
                  you the next time you use the Software and shall become
                  effective on the date so notified.</span
                >
              </li>
              <li class="d-flex">
                <span class="pr-2">9.3</span
                ><span
                  >We will not be deemed to have waived any provision of this
                  EULA unless that waiver is in writing and signed by us. Any
                  such waiver will not be, or deemed to be, a waiver of any
                  other right under this EULA. Any failure or delay by us to
                  enforce any provision of this EULA will not be considered to
                  be a waiver of that provision.</span
                >
              </li>
              <li class="d-flex">
                <span class="pr-2">9.4</span
                ><span>
                  We may assign, novate or otherwise transfer any of our rights
                  or obligations under this EULA to any other person. You agree
                  to execute any documentation that we may reasonably require to
                  give effect to this clause including a form of novation.
                </span>
              </li>
              <li class="d-flex">
                <span class="pr-2">9.5</span
                ><span
                  >If any provision of this EULA is held to be illegal, invalid
                  or unenforceable, then (to the maximum extent permitted by
                  law), that provision will be severed from this EULA and this
                  will not affect the validity or enforceability of the other
                  provisions of this EULA.</span
                >
              </li>
              <li class="d-flex">
                <span class="pr-2">9.6</span
                ><span
                  >If any provision of this EULA is determined by any court or
                  other competent authority to be unlawful and/or unenforceable,
                  the other provisions of this EULA will continue in effect. If
                  any unlawful and/or unenforceable provision would be lawful or
                  enforceable if part of it were deleted, that part will be
                  deemed to be deleted, and the rest of the provision will
                  continue in effect (unless that would contradict the clear
                  intention of the parties, in which case the entirety of the
                  relevant provision will be deemed to be deleted).</span
                >
              </li>
              <li class="d-flex">
                <span class="pr-2">9.7</span
                ><span
                  >This EULA shall be governed by and construed in accordance
                  with the laws of New Zealand. Your use of the Software may
                  also be subject to other local, state, national, or
                  international laws.</span
                >
              </li>
            </ul>
          </v-card-text>
          <v-card-text> Last updated [November 2022] </v-card-text>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script lang="ts">
import getAssetSrc from "@/logic/utils/getAssetSrc";
import Vue from "vue";
/** `Vue Component:` renders FAQs and map legend, intended to be used with in
 * the `SecondaryDialog` and `MobileDialog` components */
export default Vue.extend({
  name: "GuideContent",
  methods: {
    getSrc(partialFilePath: string): string {
      return getAssetSrc(partialFilePath);
    },
  },
});
</script>
<style scoped>
.pwt-t-and-c-ul {
  list-style: none;
}
</style>
