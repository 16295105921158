import Vue from "vue";
import App from "./App.vue";
import store from "./logic/store";
import vuetify from "./logic/plugins/vuetify";
import Vuetify from "vuetify";
import VueCountly from "vue-countly";
import vueCustomElement from "vue-custom-element";
import Countly from "countly-sdk-web";
import "./ui/assets/reset.scss";
import { LMap, LTileLayer, LMarker, LIcon } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";
import { Vue2LeafletMarkerCluster } from "vue2-leaflet-markercluster";
import router from "./logic/router";

// Analytics
// eslint-disable-next-line
// @ts-ignore
Vue.use(VueCountly, Countly, {
  app_key: "e66a954417bb786838bfaaf34e71eebcb89876cf",
  url: "https://analytics.littlemonkey.co.nz",
  app_version: process.env.VUE_APP_VERSION,
});

// Imbedding
Vue.use(vueCustomElement);

// map support
Vue.component("l-marker-cluster", Vue2LeafletMarkerCluster);
Vue.component("l-map", LMap);
Vue.component("l-tile-layer", LTileLayer);
Vue.component("l-marker", LMarker);
Vue.component("l-icon", LIcon);

// icon data
type D = Icon.Default & {
  _getIconUrl?: string;
};

delete (Icon.Default.prototype as D)._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

// vue app
Vue.config.productionTip = false;

// eslint-disable-next-line
// @ts-ignore
Vue.use(vuetify);

// eslint-disable-next-line
// @ts-ignore
Vue.use(store);

export default new Vuetify();

document
  ?.querySelector("powertrip-optimiser")
  ?.setAttribute("data-vuetify", "");
Vue.customElement("powertrip-optimiser", {
  router,
  vuetify,
  store,
  props: ["backend"],
  components: {
    App,
  },
  template: '<App backend="https://sandbox.gameplan.powertrip.earth"/>',
  created() {
    console.log("Analytics is running:", Vue.prototype.$Countly === Countly);
    // Track sessions automatically (recommended)
    Vue.prototype.$Countly.q.push(["track_sessions"]);
    // Track web page views automatically (recommended)
    Vue.prototype.$Countly.q.push(["track_pageview", "landing"]);
    // report unhandled errors to analytics.
    Vue.prototype.$Countly.q.push(["track_errors"]);
  },
});
