<template>
  <v-container>
    <v-card>
      <v-card-title class="primary--text"> Trip planning failed </v-card-title>
      <v-card-text>
        If you are seeing this page it is most likely caused by a technical
        issue. Please refresh and try again, if this continues please contact
        support.
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" class="rounded-lg text-none" @click="clear">
          Refresh
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script lang="ts">
import { MutationTypes } from "@/logic/store/store_types";
import Vue from "vue";
export default Vue.extend({
  name: "FailedTripCard",
  methods: {
    clear() {
      this.$store.commit(MutationTypes.setSelectedTrip, undefined);
      this.$store.commit(MutationTypes.setRoutePlanningStatus, undefined);
    },
  },
});
</script>
