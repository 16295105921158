// Color palate
export const powerTripDarkBlue = "#2E46ff";
export const powerTripBlue = "#2EAEFF";
export const powerTripWhite = "#FBFBFB";
export const powerTripDarkGrey = "#2b2b2b";
export const powerTripLightGrey = "#9E9E9E";
export const powerTripRed = "#FF5B5B";

// commonly used strings
export const Saved_Trips = "Saved_Trips";
