<template>
  <v-btn
    v-if="!expanded"
    icon
    id="pwt-info-panel-expand-btn"
    style="z-index: 1100; background-color: var(--v-background-base) !important"
    @click="expand"
  >
    <v-icon color="grey">mdi-menu </v-icon>
  </v-btn>
  <!-- expanded view -->
  <div v-else>
    <!-- floating minimize controls -->
    <v-btn
      v-if="$vuetify.breakpoint.smAndUp"
      icon
      id="pwt-info-panel-minimize-btn"
      @click="minimize"
      style="background-color: var(--v-background-base) !important"
      :class="classes"
    >
      <v-icon color="grey">mdi-window-minimize </v-icon>
    </v-btn>
    <!-- main card -->

    <InfoPanel @minimize="minimize" :animation="animation" />
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import InfoPanel from "./InfoPanel.vue";

export default Vue.extend({
  name: "InfoPanelControls",
  data() {
    return {
      expanded: true,
      animation: null as "slide-left" | "slide-right" | null,
    };
  },
  methods: {
    expand() {
      this.animation = "slide-right";
      this.expanded = true;
    },
    minimize() {
      this.animation = "slide-left";
      setTimeout(() => {
        this.expanded = false;
      }, 500);
    },
  },
  components: {
    InfoPanel,
  },
  computed: {
    classes(): string {
      if (this.animation == "slide-left") {
        return this.$vuetify.breakpoint.xs
          ? "ma-sm-2"
          : "ma-sm-2 pwt-info-panel-btn-right-to-left-animation";
      }
      if (this.animation == "slide-right") {
        return this.$vuetify.breakpoint.xs
          ? "ma-sm-2"
          : "ma-sm-2 pwt-info-panel-btn-left-to-right-animation";
      }

      return "ma-sm-2";
    },
  },
});
</script>
<style scoped>
#pwt-info-panel-expand-btn {
  z-index: 1102;
  position: absolute;
  top: 8px;
  left: 8px;
}

#pwt-info-panel-minimize-btn {
  z-index: 1102;
  position: absolute;
  top: 0;
  left: clamp(410px, calc(40% + 10px), 610px);
}

#pwt-info-panel-floating-controls-overlay {
  z-index: 1102;
  position: relative;
  min-width: 400px;
  max-width: 600px;
  width: 40%;
  height: calc(100% - 30px) !important;
  background-color: transparent;
}

.pwt-info-panel-btn-left-to-right-animation {
  left: -56%;
  -webkit-animation: btn-left-to-right 700ms linear forwards;
  animation: btn-left-to-right 700ms linear forwards;
}

.pwt-info-panel-btn-right-to-left-animation {
  left: 0;
  -webkit-animation: btn-right-to-left 1s linear forwards;
  animation: btn-right-to-left 1s linear forwards;
}

@-webkit-keyframes btn-left-to-right {
  from {
    left: 0;
  }
  to {
    left: clamp(410px, calc(40% + 10px), 610px);
  }
}
@-webkit-keyframes btn-right-to-left {
  from {
    left: clamp(410px, calc(40% + 10px), 610px);
  }
  to {
    left: -56%;
  }
}

@keyframes btn-left-to-right {
  from {
    left: 0;
  }
  to {
    left: clamp(410px, calc(40% + 10px), 610px);
  }
}
@keyframes btn-right-to-left {
  from {
    left: clamp(410px, calc(40% + 10px), 610px);
  }
  to {
    left: -56%;
  }
}
</style>
