<template>
  <v-card flat>
    <v-card-title class="tertiary--text pt-5">
      <BackBtn />
      Favourite Trips
    </v-card-title>
    <FiveYearSavings />
    <v-card-text>
      <v-card
        flat
        v-if="fetchingTrips"
        class="d-flex justify-center align-center"
      >
        <LoadingCard />
      </v-card>
      <template v-else>
        <StoredTripsGroupCard
          v-for="(group, groupIndex) in savedTripsGroupedByEV"
          :key="`saved-trip-group-${groupIndex}`"
          :groupedTrips="group"
        />
        <v-card v-if="!savedTripsGroupedByEV.length" flat>
          <v-card-text class="pt-0">
            You currently have no saved trips.
          </v-card-text>
        </v-card>
      </template>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import Vue from "vue";
import type { SortedTripsGroup } from "@/logic/types/sheared_local_types";
import { type State } from "@/logic/store/store_types";
import hardcodedStandardEfficiencyBackup from "@/logic/data/standardEfficiencyDefaultData";
import { mapState } from "vuex";
import StoredTripsGroupCard from "./StoredTripsGroupCard.vue";
import FiveYearSavings from "../statistics/info-panel-content/FiveYearSavings.vue";
import LoadingCard from "../../ui-elements/LoadingCard.vue";
import BackBtn from "../../ui-elements/BackBtn.vue";

export default Vue.extend({
  name: "SavedTripContent",
  computed: {
    ...mapState({
      savedTripsGroupedByEV: (state: unknown): SortedTripsGroup[] => {
        const typedState = state as State;
        const trips = typedState.trips
          .filter((trip) => !!trip.directusId)
          .reverse();
        const returnArray: SortedTripsGroup[] = [];
        const usedEVs: string[] = [];
        const unsortedGroup: SortedTripsGroup = {
          evModel: "unknown",
          trips: [],
        };
        trips.forEach((trip) => {
          let modelID: string | number | undefined = trip.vehicle?.eVModelId;
          if (!modelID) unsortedGroup.trips.push(trip);

          if (typeof modelID === "number") {
            // ASSUMES: if number is a legacy ID and needs converting.
            modelID = hardcodedStandardEfficiencyBackup.find(
              (model) => model.legacyId === modelID
            )?.id;
          }
          if (modelID && usedEVs.includes(modelID)) {
            const index = returnArray.findIndex(
              (group) => group.evModel === (modelID as string)
            );
            if (index !== -1) {
              returnArray[index].trips.push(trip);
            } else {
              returnArray.push({
                evModel: modelID,
                trips: [trip],
              });
            }
          } else if (modelID) {
            returnArray.push({
              evModel: modelID,
              trips: [trip],
            });
            usedEVs.push(modelID);
          }
        });
        if (unsortedGroup.trips.length) returnArray.push(unsortedGroup);
        return returnArray;
      },
      fetchingTrips: (state: unknown): boolean =>
        (state as State).tripFetchingFlag,
    }),
  },
  components: { StoredTripsGroupCard, FiveYearSavings, LoadingCard, BackBtn },
});
</script>
