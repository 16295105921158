<template>
  <v-dialog
    v-model="open"
    max-width="350px"
    content-class="rounded-lg"
    @click:outside="resetAndClose"
    @keydown="keyDown($event)"
    style="z-index: 1102"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :label="label"
        :value="date"
        type="date"
        readonly
        class="pwt-text-input-text"
        required
        append-icon="mdi-pencil"
        @click="openDatePicker"
        :dark="dark"
        v-on="on"
        @click:clear="clear"
        clearable
      />
    </template>

    <v-card>
      <v-date-picker full-width v-model="date" />
      <v-card-actions>
        <v-btn
          color="primary"
          class="rounded-lg text-none flex-grow-1"
          dark
          @click="updateDate"
        >
          Set Date
        </v-btn>
        <v-btn
          :color="powerTripDarkBlue"
          class="rounded-lg text-none"
          outlined
          @click="resetAndClose"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import { powerTripDarkBlue } from "@/logic/data/const";
import Vue, { PropType } from "vue";

export interface DatePickerInputUpdateObj {
  identifier: string;
  date: string;
}

/** Vue component: renders a reusable input filed and date picker modal that
 * emits an object with a passed identifier and a selected date.
 *
 * @prop `identifier` - string - passed identifier included in the emitted return.
 * @prop `initialValue` - string in 24hr date e.g. "09:00".
 * @prop `label` - string - the label to be displayed on the input element.
 * @prop `dark` - (optional) boolean - flags for input field to be rendered in dark mode, false by default.
 * @emits update - with a value in the shape of:
 * `{
 *    identifier: string \\ tha passed id value
 *    date: string \\ the selected value
 * }`
 */
export default Vue.extend({
  name: "DatePickerInput",
  props: {
    initialValue: String as PropType<string | undefined>,
    identifier: String,
    label: String,
    dark: {
      type: Boolean as PropType<boolean | undefined>,
      default: false,
    },
  },
  data() {
    return {
      date: "",
      open: false,
      powerTripDarkBlue: powerTripDarkBlue,
    };
  },
  methods: {
    setInitialValue() {
      if (this.initialValue) {
        this.date = this.initialValue;
      } else {
        this.date = "";
      }
    },
    openDatePicker() {
      this.open = true;
    },
    closeTimePicker() {
      this.open = false;
    },
    resetAndClose() {
      this.setInitialValue();
      this.closeTimePicker();
    },
    updateDate() {
      this.$emit("update", {
        identifier: this.identifier,
        date: this.date,
      } as DatePickerInputUpdateObj);
      this.closeTimePicker();
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    keyDown(event: any) {
      if (event.key === "Escape") this.resetAndClose();
    },
    clear() {
      this.date = "";
      this.updateDate();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setInitialValue();
    });
  },
});
</script>
<style scoped>
.pwt-text-input-text input {
  cursor: text;
}
</style>
