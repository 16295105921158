/** base url for ev nav endpoints to be used with final end point strings e.g. ``` ROUTE_ENDPOINT=`${evNavEndpoint}/route` ```. */
export const evNavBaseUrl = "https://evnav.pt.appstack.me/api/v1";

/** Endpoint for ev nav route planing service. */
export const EV_NAV_ROUTE_ENDPOINT = `${evNavBaseUrl}/route`;

/** Endpoint for ev nav route planing service. */
export const EV_NAV_MULTI_ROUTES_ENDPOINT = `${evNavBaseUrl}/multiroute`;

/** Endpoint for ev nav generic EV's service. */
export const EV_NAV_GENERIC_CARS_ENDPOINT = `${evNavBaseUrl}/cars`;

/** Endpoint for ev nav range queries. */
export const EV_NAV_RADAR_ENDPOINT = `${evNavBaseUrl}/radar`;

/** Endpoint for ev nav nearby queries */
export const EV_NAV_NEARBY_ENDPOINT = `${evNavBaseUrl}/nearby`;

/** Endpoint for ev nav energy queries */
export const EV_NAV_ENERGY_ENDPOINT = `${evNavBaseUrl}/energy`;

/** Endpoint to get ev nav's ev models data. */
export const EV_NAV_GENERIC_MODELS_ENDPOINT = `${evNavBaseUrl}/vehicles`;
