import levenshtein from "js-levenshtein";
import { Coordinates } from "../types/trip_specific_types";

export interface AutoCompleteReturnObject {
  geometry: {
    coordinates: [
      number, //Longitude
      number, //Latitude
    ];
    type: "Point";
  };
  type: "Feature";
  properties: {
    osm_id: number;
    osm_type: string; // TODO: make union when we have all data variants. Currently: "R", "N" , "W"
    extent?: [
      number, //Longitude
      number, //Latitude
      number, //Longitude
      number, //Latitude
    ];
    osm_key: string; // TODO: make union when we have all data variants. Currently: "boundary", "place"
    osm_value: string; // TODO: make union when we have all data variants. Currently: "administrative", "house"
    name?: string;
    type: string; // TODO: make union when we have all data variants. Currently: "region", "house", "city", "locality",
    country: string;
    housenumber?: string;
    city?: string;
    district?: string;
    street?: string;
    postcode?: string;
    state?: string;
  };
}

export interface processedAddressObj {
  address: string;
  coordinates: Coordinates;
}

export default function processAddressSearchResults(
  searchResults: Array<AutoCompleteReturnObject>,
  searchQuery: string
) {
  // create empty array to be populated later.
  const tempArray: Array<processedAddressObj> = [];
  // sort suburbs to the front.
  const sortedArray = sortAddressesByLevenshteinDistance(
    searchResults,
    searchQuery
  );
  // iterate through `searchResults`.
  sortedArray.forEach((result) => {
    // create temp variable to be pushed to temp array.
    let tempString = "";

    // check if optional property `name` is present and add to temp variable if so.
    if (result.properties.name) {
      // check if extra space is needed
      if (tempString.length) {
        tempString = tempString + " " + result.properties.name;
      } else {
        tempString = tempString + result.properties.name;
      }
    }

    // check if optional property `housenumber` is present and add to temp variable if so.
    if (result.properties.housenumber) {
      // check if extra space is needed
      if (tempString.length) {
        tempString = tempString + " " + result.properties.housenumber;
      } else {
        tempString = tempString + result.properties.housenumber;
      }
    }

    // check if optional property `street` is present and add to temp variable if so.
    if (result.properties.street) {
      // check if extra space is needed
      if (tempString.length) {
        // check if `housenumber` is the item prior to this.
        if (result.properties.housenumber) {
          tempString = tempString + " " + result.properties.street;
        } else {
          tempString = tempString + " " + result.properties.street;
        }
      } else {
        tempString = tempString + result.properties.street;
      }
    }

    // check if optional property `district` is present and add to temp variable if so.
    if (result.properties.district) {
      // check if extra space is needed
      if (tempString.length) {
        tempString = tempString + " " + result.properties.district;
      } else {
        tempString = tempString + result.properties.district;
      }
    }

    // check if optional property `city` is present and add to temp variable if so.
    if (result.properties.city) {
      // check if extra space is needed
      if (tempString.length) {
        tempString = tempString + " " + result.properties.city;
      } else {
        tempString = tempString + result.properties.city;
      }
    }

    // check if optional property `state` is present and add to temp variable if so.
    if (result.properties.state) {
      // check if extra space is needed
      if (tempString.length) {
        tempString = tempString + " " + result.properties.state;
      } else {
        tempString = tempString + result.properties.state;
      }
    }

    // check if optional property `postcode` is present and add to temp variable if so.
    if (result.properties.postcode) {
      // check if extra space is needed
      if (tempString.length) {
        tempString = tempString + " " + result.properties.postcode;
      } else {
        tempString = tempString + result.properties.postcode;
      }
    }

    // check if extra space is needed
    if (tempString.length) {
      // add `country`to temp variable if so.
      tempString = tempString + " " + result.properties.country;
    } else {
      // add `country`to temp variable if so.
      tempString = tempString + result.properties.country;
    }

    // push populate `tempString` to `tempArray`.

    const addressObj: processedAddressObj = {
      address: tempString,
      coordinates: {
        Latitude: result.geometry.coordinates[1],
        Longitude: result.geometry.coordinates[0],
      },
    };

    tempArray.push(addressObj);
  });
  // return populated `tempArray`.
  return tempArray;
}

function sortAddressesByLevenshteinDistance(
  array: AutoCompleteReturnObject[],
  comparator: string
): AutoCompleteReturnObject[] {
  const filteredArray = array.filter(
    (address) => address.properties.osm_value === "suburb"
  );
  const sortedArray = filteredArray.sort((a, b) => {
    const aString =
      (a.properties.name ?? "") +
      " " +
      (a.properties.district ?? "") +
      " " +
      (a.properties.city ?? "") +
      " " +
      (a.properties.postcode ?? "");
    const bString =
      (b.properties.name ?? "") +
      " " +
      (b.properties.district ?? "") +
      " " +
      (b.properties.city ?? "") +
      " " +
      (b.properties.postcode ?? "");

    // undefined guard clauses
    if (aString && !bString) return -1;
    if (!aString && bString) return 1;
    if (!aString && !bString) return 0;

    // get levenshtein distance for both strings from comparator.
    const aLevenshteinDistance = levenshtein(aString, comparator);
    const bLevenshteinDistance = levenshtein(bString, comparator);

    if (aLevenshteinDistance < bLevenshteinDistance) {
      return -1;
    }

    if (aLevenshteinDistance > bLevenshteinDistance) {
      return 1;
    }

    return 0;
  });
  return [
    ...sortedArray,
    ...array.filter((address) => address.properties.osm_value !== "suburb"),
  ];
}
