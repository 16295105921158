<template>
  <v-card flat class="pt-5 pb-10">
    <v-card-text>
      <ProfileCard />
    </v-card-text>
    <v-card-title class="tertiary--text pt-5 pb-0 mb-0"> Driving </v-card-title>
    <SelectedVehicleCard />
  </v-card>
</template>
<script lang="ts">
import Vue from "vue";
import ProfileCard from "@/ui/components/user/profile/info-panel-content/ProfileCard.vue";
import SelectedVehicleCard from "../vehicles/info-panel-content/SelectedVehicleCard.vue";
export default Vue.extend({
  name: "DashboardMain",
  components: {
    ProfileCard,
    SelectedVehicleCard,
  },
});
</script>
