<template>
  <v-card flat>
    <v-card-title
      class="font-weight-bold text-body-2 pt-0 d-flex justify-space-between"
      style="word-break: normal"
    >
      <span>
        Driving {{ driveTime }} for {{ Math.round(step.Distance / 1000) }}km
      </span>
      <span> Battery {{ chargeUsed }}% </span>
    </v-card-title>
    <v-card-subtitle
      class="grey--text text--darken-2 pt-1"
      v-if="step.FerryTime"
    >
      <v-row no-gutters>
        <v-icon color="primary" class="mr-1">mdi-ferry</v-icon>
        Ferry for {{ ferryTime }}
      </v-row>
    </v-card-subtitle>
    <v-card
      class="blue-grey ml-4 my-auto pa-0 overflow-hidden d-flex justify-end rounded-lgs"
      style="width: calc(100% - 28px); height: 10px"
      flat
    >
      <v-card
        class="primary ma-0 pa-0"
        :width="`${
          100 -
          Math.round(step.EndCharge * 100) -
          (100 - Math.round(step.StartCharge * 100))
        }%`"
        flat
        tile
      ></v-card>
      <v-card
        class="grey lighten-2 ma-0 pa-0"
        :width="`${100 - Math.round(step.StartCharge * 100)}%`"
        flat
        tile
      ></v-card>
    </v-card>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { Duration } from "luxon";
import type { EVNavStep } from "@/logic/types/ev_nav_types";

/** Vue Component: renders the driving leg inset trailing info cards, to be used in the `ItineraryContent` component.
 *
 * @props `step` - the object for the current step in the route plan. See `routePlanningStore` for details.
 * @props `isFinaleStep` - Boolean, is this the last step in the route plan to be trailed by the `ArrivalCard` component.
 */
export default Vue.extend({
  name: "DrivingLegDetailsCard",
  props: {
    step: Object as PropType<EVNavStep>,
    isFinaleStep: Boolean as PropType<boolean>,
  },
  computed: {
    duration(): string {
      const time = (this.step as EVNavStep).TravelTime;

      // is not undefined guard clause
      if (!time) {
        // get raw string as "0 hours, 0 minutes"
        const rawString = Duration.fromObject({ hours: 0, minutes: 0 })
          .normalize()
          .toHuman({ listStyle: "narrow" }) as string;
        // remove unwanted comma
        return rawString.replace(",", "");
      }

      // get raw string as "[hours traveled] hours, [minutes traveled] minutes"
      const rawString = Duration.fromObject({
        hours: 0,
        minutes: Math.round(time / 60),
      })
        .normalize()
        .toHuman({ listStyle: "narrow" }) as string;
      // remove unwanted comma
      return rawString.replace(",", "");
    },
    chargeUsed(): number {
      const startCharge = (this.step as EVNavStep).StartCharge || 0;
      const finalCharge = (this.step as EVNavStep).EndCharge || 0;

      return Math.round((startCharge - finalCharge) * 100);
    },
    driveTime(): string {
      const time = (this.step as EVNavStep).TravelTime;
      // const ferryTime = (this.step as Step).FerryTime;

      return Duration.fromObject({
        hours: 0,
        minutes: Math.round(time / 60),
      })
        .normalize()
        .toHuman({ listStyle: "narrow" })
        .replace(",", "");
    },
    ferryTime(): string {
      const ferryTime = (this.step as EVNavStep).FerryTime;

      return Duration.fromObject({
        hours: 0,
        minutes: Math.round(ferryTime / 60),
      })
        .normalize()
        .toHuman({ listStyle: "narrow" })
        .replace(",", "");
    },
  },
});
</script>
