<template>
  <v-card flat style="height: 100%" class="d-flex flex-column transparent">
    <v-card-text>
      <v-checkbox
        label="select all"
        @click="toggleSelectAll"
        :indeterminate="selectAllValue === undefined"
        v-model="selectAllValue"
      />
      <v-checkbox
        v-for="(network, index) in networks"
        :key="index"
        :label="network.displayName"
        class="mt-0"
        color="info"
        hide-details
        :value="network"
        v-model="selectedNetworks"
      />
    </v-card-text>
    <v-card-actions class="mt-auto mb-0 pl-4">
      <v-btn
        dark
        color="primary"
        class="text-none rounded-lg"
        @click="handelUpdateNetwork"
      >
        Update network settings
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import NetworkBranding from "@/logic/classes/networkBranding";
import { type State, MutationTypes } from "@/logic/store/store_types";
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "NetworkSettings",
  mounted() {
    this.$nextTick(() => {
      this.setInitialValue();
    });
  },
  computed: {
    ...mapState({
      networks: (state: unknown): NetworkBranding[] =>
        (state as State).networkBranding,
    }),
  },
  data() {
    return {
      selectedNetworks: [] as NetworkBranding[],
      selectAllValue: undefined as boolean | undefined,
    };
  },
  methods: {
    setInitialValue() {
      if ((this.$store.state as State).networkSelectionOverride) {
        const tempArray: NetworkBranding[] = [];
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (this.$store.state as State).networkSelectionOverride!.forEach(
          (networkName) => {
            const networkData = (
              this.$store.state as State
            ).networkBranding.find((item) => item.isThisNetwork(networkName));
            if (networkData) {
              const hasBeenUsed = !!tempArray.find(
                (branding) => branding.localId === networkData.localId
              );
              if (!hasBeenUsed) {
                tempArray.push(networkData);
              }
            }
          }
        );
        if (tempArray.length) {
          this.selectedNetworks = tempArray;
          return;
        }
      }
      this.selectedNetworks = (this.$store.state as State).networkBranding;
    },
    handelUpdateNetwork() {
      // Notify analytics server
      // eslint-disable-next-line
      // @ts-ignore
      Vue.prototype.$Countly.q.push([
        "add_event",
        {
          key: "Preferred charger networks settings adjusted",
          count: 1,
        },
      ]);

      const selectedNetworks = this.selectedNetworks;
      const allNetworks = this.networks;

      const useAllNetworks = allNetworks.every((v) =>
        selectedNetworks.includes(v)
      );

      if (useAllNetworks) {
        // if all networks there is no need for an override so return to default.
        this.$store.commit(
          MutationTypes.setNetworkSelectionOverride,
          undefined
        );
      } else {
        const tempArray: string[] = [];
        this.selectedNetworks.forEach((network) => {
          tempArray.push(...network.allKnownAliases);
        });

        // apply selected override.
        this.$store.commit(
          MutationTypes.setNetworkSelectionOverride,
          tempArray.length ? tempArray : undefined // prevent empty results being committed to sate. THIS ASSUMES: case of invalidate all chargers and cause routing to fail if not with in a single charge range is still unwanted.
        );
      }

      // close modal
      this.handleClose();
    },
    handleClose() {
      this.$store.commit(MutationTypes.setSecondaryDialogContent, undefined);
    },
    toggleSelectAll() {
      if (this.selectAllValue) {
        this.selectedNetworks = this.networks;
      } else {
        this.selectedNetworks = [];
      }
    },
  },
  watch: {
    selectedNetworks(val: NetworkBranding[]) {
      const useAllNetworks = this.networks.every((v) => val.includes(v));
      if (useAllNetworks) {
        this.selectAllValue = true;
      } else if (!val.length) {
        this.selectAllValue = false;
      } else {
        this.selectAllValue = undefined;
      }
    },
  },
});
</script>
