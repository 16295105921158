<template>
  <v-card flat class="px-4 pb-4 pt-2">
    <v-card-text class="pt-0">
      <v-radio-group :value="calcVs" @change="changeCalcVs" hide-details dense>
        <v-radio
          label="Compare savings against a petrol vehicle"
          value="PETROL"
          color="primary"
        ></v-radio>
        <v-radio
          label="Compare savings against a diesel vehicle"
          value="DIESEL"
          color="primary"
        ></v-radio>
      </v-radio-group>
    </v-card-text>
    <v-col>
      <v-row align-content="center" v-if="calcVs === 'PETROL'">
        <v-col cols="4" sm="3">
          <v-text-field
            type="number"
            solo
            :value="petrolCostPerLitre"
            hide-spin-buttons
            hide-details
            dense
            class="pa-0 elevation-3 pwt-number-font"
            @input="changePetrolCostPerLitre"
            @change="notifyAnalytics"
            prefix="$"
          />
        </v-col>
        <v-col>
          <v-card-text class="grey--text py-0 py-sm-2 px-0">
            Cost of petrol per litre
          </v-card-text>
        </v-col>
      </v-row>
      <v-row align-content="center" v-if="calcVs === 'PETROL'">
        <v-col cols="4" sm="3">
          <v-text-field
            type="number"
            solo
            :value="petrolKmPerLitre"
            hide-details
            hide-spin-buttons
            dense
            class="pa-0 elevation-3 pwt-number-font"
            @input="changePetrolKmPerLitre"
            @change="notifyAnalytics"
          />
        </v-col>
        <v-col>
          <v-card-text class="grey--text py-0 py-sm-2 px-0">
            km / litre if you drove a petrol car instead
          </v-card-text>
        </v-col>
      </v-row>
      <v-row align-content="center" v-if="calcVs === 'DIESEL'">
        <v-col cols="4" sm="3">
          <v-text-field
            type="number"
            solo
            :value="dieselCostPerLitre"
            hide-spin-buttons
            hide-details
            dense
            class="pa-0 elevation-3 pwt-number-font"
            @input="changeDieselCostPerLitre"
            @change="notifyAnalytics"
            prefix="$"
          />
        </v-col>
        <v-col>
          <v-card-text class="grey--text py-0 py-sm-2 px-0">
            Cost of diesel per litre
          </v-card-text>
        </v-col>
      </v-row>
      <v-row align-content="center" v-if="calcVs === 'DIESEL'">
        <v-col cols="4" sm="3">
          <v-text-field
            type="number"
            solo
            :value="dieselKmPerLitre"
            hide-spin-buttons
            hide-details
            dense
            class="pa-0 elevation-3 pwt-number-font"
            @change="notifyAnalytics"
            @input="changeDieselKmPerLitre"
          />
        </v-col>
        <v-col>
          <v-card-text class="grey--text py-0 py-sm-2 px-0">
            km / litre if you drove a petrol car instead
          </v-card-text>
        </v-col>
      </v-row>
      <v-row align-content="center" v-if="calcVs === 'DIESEL'">
        <v-col cols="4" sm="3">
          <v-text-field
            type="number"
            solo
            :value="roadUserCharges"
            hide-spin-buttons
            hide-details
            dense
            class="pa-0 elevation-3 pwt-number-font"
            @input="changeRoadUserCharges"
            @change="notifyAnalytics"
            prefix="$"
          />
        </v-col>
        <v-col>
          <v-card-text class="grey--text py-0 py-sm-2 px-0">
            Road User Charges in $ per thousand km
          </v-card-text>
        </v-col>
      </v-row>
      <v-row align-content="center">
        <v-col cols="4" sm="3">
          <v-text-field
            type="number"
            solo
            :value="kWhCostHome"
            hide-spin-buttons
            hide-details
            dense
            class="pa-0 elevation-3 pwt-number-font"
            @input="changeKWhCostHome"
            @change="notifyAnalytics"
            prefix="$"
          />
        </v-col>
        <v-col>
          <v-card-text class="grey--text py-0 py-sm-2 px-0">
            Cost per kWh of electricity for private charging (e.g. at home)
          </v-card-text>
        </v-col>
      </v-row>
      <v-row align-content="center">
        <v-col cols="4" sm="3">
          <v-text-field
            type="number"
            solo
            :value="kWhCostPublic"
            hide-spin-buttons
            hide-details
            dense
            class="pa-0 elevation-3 pwt-number-font"
            @input="changeKWhCostPublic"
            @change="notifyAnalytics"
            prefix="$"
          />
        </v-col>
        <v-col>
          <v-card-text class="grey--text py-0 py-sm-2 px-0">
            Cost per kWh of electricity in public
          </v-card-text>
        </v-col>
      </v-row>
      <v-row align-content="center">
        <v-col cols="4" sm="3">
          <v-text-field
            type="number"
            solo
            :value="electricityCostPerMinPublic"
            hide-spin-buttons
            hide-details
            dense
            class="pa-0 elevation-3 pwt-number-font"
            @input="changeElectricityCostPerMinPublic"
            @change="notifyAnalytics"
            prefix="$"
          />
        </v-col>
        <v-col>
          <v-card-text class="grey--text py-0 py-sm-2 px-0">
            Cost per minute of electricity in public
          </v-card-text>
        </v-col>
      </v-row>
      <v-row align-content="center">
        <v-col cols="4" sm="3">
          <v-text-field
            type="number"
            solo
            :value="perAnnumMaintenanceCurrentV"
            hide-spin-buttons
            hide-details
            dense
            class="pa-0 elevation-3 pwt-number-font"
            @input="changePerAnnumMaintenanceCurrentV"
            @change="notifyAnalytics"
            prefix="$"
          />
        </v-col>
        <v-col>
          <v-card-text class="grey--text py-0 py-sm-2 px-0">
            Annual maintenance on your current car
          </v-card-text>
        </v-col>
      </v-row>
      <v-row align-content="center">
        <v-col cols="4" sm="3">
          <v-text-field
            type="number"
            solo
            :value="perAnnumMaintenanceEV"
            hide-spin-buttons
            hide-details
            dense
            class="pa-0 elevation-3 pwt-number-font"
            @input="changePerAnnumMaintenanceEV"
            @change="notifyAnalytics"
            prefix="$"
          />
        </v-col>
        <v-col>
          <v-card-text class="grey--text py-0 py-sm-2 px-0">
            Annual maintenance on an electric car
          </v-card-text>
        </v-col>
      </v-row>
    </v-col>
  </v-card>
</template>

<script lang="ts">
import { MutationTypes } from "@/logic/store/store_types";
import { type State } from "@/logic/store/store_types";
import Vue from "vue";
import { mapState } from "vuex";

/** `Vue component:` renders savings settings to be displayed
 * in the `SecondaryDialog` and `MobileDialog` components*/
export default Vue.extend({
  name: "SavingsSettingsContent",
  computed: {
    ...mapState({
      calcVs: (state: unknown) => (state as State).calcVs,
      petrolCostPerLitre: (state: unknown) =>
        (state as State).petrolCostPerLitre,
      petrolKmPerLitre: (state: unknown) => (state as State).petrolKmPerLitre,
      dieselCostPerLitre: (state: unknown) =>
        (state as State).dieselCostPerLitre,
      dieselKmPerLitre: (state: unknown) => (state as State).dieselKmPerLitre,
      roadUserCharges: (state: unknown) => (state as State).roadUserCharges,
      kWhCostHome: (state: unknown) => (state as State).defaultHomeCostPerKWh,
      kWhCostPublic: (state: unknown) =>
        (state as State).defaultPublicCostPerKWh,
      electricityCostPerMinPublic: (state: unknown) =>
        (state as State).defaultCostPerMinDC,
      perAnnumMaintenanceCurrentV: (state: unknown) =>
        (state as State).perAnnumMaintenanceCurrentV,
      perAnnumMaintenanceEV: (state: unknown) =>
        (state as State).perAnnumMaintenanceEV,
    }),
  },
  methods: {
    changeCalcVs(val: string) {
      this.$store.commit(MutationTypes.setCalcVs, val);
      this.notifyAnalytics();
    },
    changePetrolCostPerLitre(val: string) {
      this.$store.commit(MutationTypes.setPetrolCostPerLitre, parseFloat(val));
    },
    changePetrolKmPerLitre(val: string) {
      this.$store.commit(MutationTypes.setPetrolKmPerLitre, parseFloat(val));
    },
    changeDieselCostPerLitre(val: string) {
      this.$store.commit(MutationTypes.setDieselCostPerLitre, parseFloat(val));
    },
    changeDieselKmPerLitre(val: string) {
      this.$store.commit(MutationTypes.setDieselKmPerLitre, parseFloat(val));
    },
    changeRoadUserCharges(val: string) {
      this.$store.commit(MutationTypes.setRoadUserCharges, parseFloat(val));
    },
    changeKWhCostHome(val: string) {
      this.$store.commit(
        MutationTypes.setDefaultHomeCostPerKWh,
        parseFloat(val)
      );
    },
    changeKWhCostPublic(val: string) {
      this.$store.commit(
        MutationTypes.setDefaultPublicCostPerKWh,
        parseFloat(val)
      );
    },
    changeElectricityCostPerMinPublic(val: string) {
      this.$store.commit(MutationTypes.setDefaultCostPerMinDC, parseFloat(val));
    },
    changePerAnnumMaintenanceCurrentV(val: string) {
      this.$store.commit(
        MutationTypes.setPerAnnumMaintenanceCurrentV,
        parseFloat(val)
      );
    },
    changePerAnnumMaintenanceEV(val: string) {
      this.$store.commit(
        MutationTypes.setPerAnnumMaintenanceEV,
        parseFloat(val)
      );
    },
    notifyAnalytics() {
      // Notify analytics server
      Vue.prototype.$Countly.q.push([
        "add_event",
        {
          key: "Trip Stats Summary settings adjusted",
          count: 1,
        },
      ]);
    },
  },
});
</script>
