<template>
  <v-card
    class="rounded-lg primary white--text"
    elevation="4"
    v-if="trip && trip.locations && trip.parameters && trip.fallbackTripData"
  >
    <v-row no-gutters class="pt-3">
      <v-col cols="1">
        <div
          class="white rounded-circle ml-3 mt-1"
          style="height: 20px; width: 20px"
        ></div>
      </v-col>
      <v-col cols="10">
        <v-card-title
          class="font-weight-bold text-body-1 pt-0"
          style="word-break: normal"
        >
          {{ trip.locations[trip.locations.length - 1].address }}
        </v-card-title>
        <v-card-text class="d-flex justify-space-between pr-10">
          <span>Required charge to reach this point</span>
          <span
            >{{
              chargeUsed(trip.fallbackTripData.energy, trip.parameters.Battery)
            }}%</span
          >
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>
<script lang="ts">
import { GettersTypes } from "@/logic/store/store_types";
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "FallbackArrivalCard",
  computed: {
    ...mapGetters({
      trip: GettersTypes.selectedTripData,
    }),
  },
  methods: {
    chargeUsed(energy: number, battery: number): number {
      return Math.round((100 / battery) * energy);
    },
  },
});
</script>
