<template>
  <v-card flat>
    <v-card-text>
      <p>Map settings</p>
      <v-checkbox
        @change="changeShowAllChargingStations"
        v-model="showAllChargingStations"
        label="Show all charging stations"
        color="info"
        true-value="showAllChargingStations"
        class="mt-4"
        hide-details
      ></v-checkbox>
      <v-checkbox
        @change="changeShowPrivateACChargingStations"
        v-model="showPrivateACChargingStations"
        label="Show private AC charging stations"
        color="info"
        true-value="showPrivateACChargingStations"
        class="mt-0"
        hide-details
      ></v-checkbox>
      <v-checkbox
        @change="changeShowPublicACChargingStations"
        v-model="showPublicACChargingStations"
        label="Show public AC charging stations"
        color="info"
        true-value="showPublicACChargingStations"
        class="mt-0"
        hide-details
      ></v-checkbox>
      <v-checkbox
        @change="changeShowPrivateDCChargingStations"
        v-model="showPrivateDCChargingStations"
        label="Show private DC charging stations"
        color="info"
        true-value="showPrivateDCChargingStations"
        class="mt-0"
        hide-details
      ></v-checkbox>
      <v-checkbox
        @change="changeShowPublicDCChargingStations"
        v-model="showPublicDCChargingStations"
        label="Show public DC charging stations"
        color="info"
        true-value="showPublicDCChargingStations"
        class="mt-0"
        hide-details
      ></v-checkbox>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { MutationTypes } from "@/logic/store/store_types";
import { SecondaryDialogContent } from "@/logic/store/store_types";
import { State } from "@/logic/store/store_types";
import Vue from "vue";
import { mapState } from "vuex";

/** `Vue component:` renders map settings to be displayed
 * in the `MainDialog` and `MobileDialog` components*/
export default Vue.extend({
  name: "SettingsContent",
  computed: {
    ...mapState({
      showPrivateACChargingStations: (state: unknown): boolean =>
        (state as State).showPrivateACChargers,
      showPublicACChargingStations: (state: unknown): boolean =>
        (state as State).showPublicACChargers,
      showPrivateDCChargingStations: (state: unknown): boolean =>
        (state as State).showPrivateDCChargers,
      showPublicDCChargingStations: (state: unknown): boolean =>
        (state as State).showPublicDCChargers,
      showAllChargingStations: (state: unknown): boolean =>
        (state as State).showPrivateACChargers &&
        (state as State).showPublicACChargers &&
        (state as State).showPrivateDCChargers &&
        (state as State).showPublicDCChargers,
    }),
  },
  methods: {
    changeShowAllChargingStations(value: boolean) {
      if (value) {
        this.$store.commit(MutationTypes.setShowPrivateACChargers, value);
        this.$store.commit(MutationTypes.setShowPrivateDCChargers, value);
        this.$store.commit(MutationTypes.setShowPublicACChargers, value);
        this.$store.commit(MutationTypes.setShowPublicDCChargers, value);
        this.notifyAnalytics();
      }
    },
    changeShowPrivateACChargingStations(value: boolean) {
      this.$store.commit(MutationTypes.setShowPrivateACChargers, value);
      this.notifyAnalytics();
    },
    changeShowPublicACChargingStations(value: boolean) {
      this.$store.commit(MutationTypes.setShowPublicACChargers, value);
      this.notifyAnalytics();
    },
    changeShowPrivateDCChargingStations(value: boolean) {
      this.$store.commit(MutationTypes.setShowPrivateDCChargers, value);
      this.notifyAnalytics();
    },
    changeShowPublicDCChargingStations(value: boolean) {
      this.$store.commit(MutationTypes.setShowPublicDCChargers, value);
      this.notifyAnalytics();
    },
    openNetworkSettings() {
      this.$store.commit(
        MutationTypes.setSecondaryDialogContent,
        SecondaryDialogContent.NETWORK_SETTINGS
      );
    },
    notifyAnalytics() {
      // Notify analytics server
      Vue.prototype.$Countly.q.push([
        "add_event",
        {
          key: "Map settings adjusted",
          count: 1,
        },
      ]);
    },
  },
});
</script>
