<template>
  <v-btn icon @click="back">
    <v-icon> mdi-chevron-left </v-icon>
  </v-btn>
</template>
<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  name: "BackBtn",
  methods: {
    back(): void {
      this.$router.back();
    },
  },
});
</script>
