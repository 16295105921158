<template>
  <v-card class="mt-3 rounded-lg" elevation="4">
    <v-card-text>
      <v-autocomplete
        :value="comparison.evModel"
        :items="EVModels"
        item-text="name"
        label="EV make/model"
        :menu-props="{ contentClass: 'pwt-scrollbar-styles' }"
        return-object
        clearable
        @change="handelModelSelect"
        hide-details
        :loading="loading"
        :disabled="loading"
        auto-focus
      ></v-autocomplete>
      <v-radio-group
        :value="comparison.calcAsUsed"
        @change="handleUsedNewSelect"
        :disabled="loading"
      >
        <v-radio label="Calculate as new (100% SoH)" :value="false"></v-radio>
        <v-radio
          label="Calculate as used (degraded SoH)"
          :value="true"
        ></v-radio>
      </v-radio-group>
      <v-card-text v-if="!!comparison.energyData">
        <v-row justify="space-between" no-gutter>
          <span> Energy </span>
          <span> {{ comparison.totalEnergyDisplayString }} </span>
        </v-row>
        <v-row justify="space-between" no-gutter>
          {{ comparison.numberOfChargesDisplayString }}
        </v-row>
      </v-card-text>
    </v-card-text>
    <v-card-text v-if="showError">
      <v-alert type="error" color="error" class="rounded-lg">
        Whoops! something went wrong calculating this comparison
      </v-alert>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import { mapState } from "vuex";
import Vue, { PropType } from "vue";
import EVModel from "@/logic/classes/evModel";
import { type State } from "@/logic/store/store_types";
import TspTripComparison from "@/logic/classes/tspTripComparison";

export default Vue.extend({
  name: "TspComparisonCard",
  props: {
    comparison: {
      type: Object as PropType<TspTripComparison>,
      required: true,
    },
    polyline: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState({
      EVModels: (state: unknown): EVModel[] => (state as State).evModels,
    }),
  },
  methods: {
    emitUpdatedComparison(updatedComparison: TspTripComparison): void {
      this.$emit("update", updatedComparison);
    },
    async handelModelSelect(val: EVModel | null) {
      const updatedComparison = this.comparison;
      updatedComparison.setEVModel(val ?? undefined);
      if (val) {
        // identify async operations has started.
        this.loading = true;
        // clear previous error if any.
        this.showError = false;
        // fetch energy data from service.
        const outcome = await updatedComparison.calcEnergyUsage(this.polyline);
        // show error if failed
        if (outcome === "failed") this.showError = true;
        // identify async operations has completed.
        this.loading = false;
      }
      this.emitUpdatedComparison(updatedComparison);
    },
    handleUsedNewSelect(val: boolean) {
      const updatedComparison = this.comparison;
      updatedComparison.calcAsUsed = val;
      this.emitUpdatedComparison(updatedComparison);
    },
  },
  data() {
    return {
      loading: false,
      showError: false,
    };
  },
});
</script>
