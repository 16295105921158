import generateUniqueLocalID from "../utils/generateUniqueLocalID";
import DirectusDriverData from "../types/directus_driver";
import getDirectusImgUrl from "../utils/getDirectusImgUrl";

export default class Driver {
  // -------------------------------------------------------------------- //
  // ------------------------- Global class state ----------------------- //
  // -------------------------------------------------------------------- //

  // global record of class instance ids this session.
  static usedIds: string[] = [];

  // -------------------------------------------------------------------- //
  // ------------------------------- State ------------------------------ //
  // -------------------------------------------------------------------- //

  /** local unique id. */
  localId: string;

  /** Directus `Driver` collection record id. */
  directusId?: number;

  /** Display name for this driver. */
  name?: string;

  /** Drivers profile pic asset file UUID. */
  profilePic?: string;

  /** Drivers bio for display. */
  bio?: string;

  /** Related directus `Vehicle` collection record id. */
  assignedVehicleDirectusId?: number;

  // -------------------------------------------------------------------- //
  // --------------------------- Constructor ---------------------------- //
  // -------------------------------------------------------------------- //

  constructor({
    localId = undefined,
    directusId = undefined,
    name = undefined,
    profilePic = undefined,
    bio = undefined,
    assignedVehicleDirectusId = undefined,
  }: {
    localId?: string;
    directusId?: number;
    name?: string;
    profilePic?: string;
    bio?: string;
    assignedVehicleDirectusId?: number;
  }) {
    this.localId = localId ?? generateUniqueLocalID(Driver.usedIds, "driver");
    this.directusId = directusId;
    this.name = name;
    this.profilePic = profilePic;
    this.bio = bio;
    this.assignedVehicleDirectusId = assignedVehicleDirectusId;

    // add id to list of used unique ids
    Driver.usedIds.push(this.localId);
  }

  static fromDirectusData(data: DirectusDriverData) {
    return new Driver({
      directusId: data.id,
      name: data.Name ?? undefined,
      profilePic: data.ProfilePic ?? undefined,
      bio: data.Bio ?? undefined,
      assignedVehicleDirectusId: data.Driving ?? undefined,
    });
  }

  // -------------------------------------------------------------------- //
  // ------------------------------ Getters ----------------------------- //
  // -------------------------------------------------------------------- //

  /** Returns the src path for this drivers profile pic if they have one. */
  public get profilePicSrc(): string | undefined {
    if (this.profilePic) return getDirectusImgUrl(this.profilePic);
  }

  // -------------------------------------------------------------------- //
  // ------------------------------ Methods ----------------------------- //
  // -------------------------------------------------------------------- //
}
