var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('ItineraryHeader',{attrs:{"trip":_vm.trip}}),_vm._l((_vm.trip.evTripData),function(leg,legIndex){return _vm._l((leg.steps),function(step,index){return _c('v-card',{key:`step-${legIndex}-${index}`,staticClass:"px-5",attrs:{"flat":""}},[(step.From === _vm.trip.locations[0].localId)?_c('DepartureCard',{attrs:{"step":step,"location":_vm.startingLocations[legIndex],"legIndex":legIndex,"storedTrip":_vm.trip,"stepIndex":_vm.getStepIndex(index, legIndex, _vm.trip)}}):_vm._e(),(!!step.Charger)?_c('ChargingStopCard',{attrs:{"step":step,"stepIndex":_vm.getStepIndex(index, legIndex, _vm.trip),"legIndex":legIndex,"storedTrip":_vm.trip}}):_vm._e(),(
          step.From.includes('location-') &&
          step.From !== _vm.trip.locations[0].localId &&
          step.From !== _vm.trip.locations[_vm.trip.locations.length - 1].localId
        )?_c('PlannedStopCard',{attrs:{"waypointName":step.From,"step":step,"stepIndex":_vm.getStepIndex(index, legIndex, _vm.trip),"storedTrip":_vm.trip}}):_vm._e(),_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"py-10",attrs:{"cols":"11"}},[_c('DrivingLegDetailsCard',{attrs:{"step":step,"isFinaleStep":index === _vm.steps.length - 1}})],1)],1)],1)})}),_c('div',{staticClass:"px-5"},[(_vm.trip.evTripData)?_c('ArrivalCard',{attrs:{"step":_vm.trip.evTripData[_vm.trip.evTripData.length - 1].steps[
          _vm.trip.evTripData[_vm.trip.evTripData.length - 1].steps.length - 1
        ],"storedTrip":_vm.trip}}):_vm._e()],1),_c('div',{staticClass:"px-5 mt-5"},[_c('ItineraryBringSection')],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }