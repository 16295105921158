import {
  QuickTripCheckReturn,
  Valhalla_CostingModel,
  Valhalla_Location,
  Valhalla_OptimizedRouteRes,
  Valhalla_OptimizedRouteError,
} from "@/logic/types/valhalla_types";
import { valhallaBaseUrl } from "../clients/valhallaClient";

export async function quickTripCheck(
  locations: Valhalla_Location[],
  costing: Valhalla_CostingModel = Valhalla_CostingModel.auto
): Promise<QuickTripCheckReturn> {
  try {
    const json = {
      locations: locations,
      costing: costing,
    };

    const res = await fetch(
      `${valhallaBaseUrl}route?json=${JSON.stringify(json)}`
    );
    const convertedResponse = await res.json();
    if (Object.hasOwn(convertedResponse, "trip")) {
      return QuickTripCheckReturn.routable;
    } else if (
      Object.hasOwn(convertedResponse, "error_code") &&
      convertedResponse.error_code === 170
    ) {
      return QuickTripCheckReturn.unconnected_regions;
    } else {
      return QuickTripCheckReturn.not_routable;
    }
  } catch (error) {
    // handled in previous code block.
    return QuickTripCheckReturn.not_routable;
  }
}

export async function fetchOptimizedRoutePlan(
  locations: Valhalla_Location[],
  costing: Valhalla_CostingModel = Valhalla_CostingModel.auto
): Promise<
  Valhalla_OptimizedRouteRes | Valhalla_OptimizedRouteError | undefined
> {
  try {
    const json = {
      locations: locations,
      costing: costing,
    };

    const res = await fetch(
      `${valhallaBaseUrl}optimized_route?json=${JSON.stringify(json)}`
    );

    if (res.status === 200) {
      const convertedResponse: Valhalla_OptimizedRouteRes = await res.json();
      return convertedResponse;
    }

    if (res.status === 400) {
      const convertedResponse: Valhalla_OptimizedRouteError = await res.json();
      return convertedResponse;
    }
  } catch (error) {
    console.log("fetchTspTripRoutePlan errored with", error);
  }
}
