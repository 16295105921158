<template>
  <v-card class="mb-3 rounded-lg">
    <v-card flat v-if="user">
      <v-row no-gutters class="pt-4">
        <v-col cols="9">
          <v-card-subtitle class="d-flex flex-column grey--text pt-0">
            <span class="font-weight-medium text-h6">
              {{ user.fullName }}
            </span>
            <span>
              {{ user.email }}
            </span>
          </v-card-subtitle>
          <template v-if="company">
            <v-card-text class="d-flex align-center py-0">
              <img
                v-if="logoSrc"
                :alt="`${company.name} logo`"
                :src="logoSrc"
                style="height: 40px; width: auto"
                class="mr-3"
              />
              <span
                v-if="company.name"
                class="font-weight-medium text-h6 grey--text"
              >
                {{ company.name }}
              </span>
            </v-card-text>
          </template>
          <template v-else-if="user.companyDirectusId">
            <v-skeleton-loader
              type="list-item-avatar"
              width="70%"
              class="mr-4"
            />
          </template>
        </v-col>
        <v-col cols="3" class="d-flex justify-end">
          <v-icon
            class="mr-2 ml-n1"
            color="grey"
            @click="handelEditUser"
            style="cursor: pointer"
          >
            mdi-pencil
          </v-icon>
          <v-avatar color="primary" class="mr-5">
            <img v-if="avatarSrc" :src="avatarSrc" />
            <v-icon v-else dark> mdi-account-circle </v-icon>
          </v-avatar>
        </v-col>
      </v-row>
      <template v-if="driver">
        <v-card-text v-if="driver.bio"> {{ driver.bio }} </v-card-text>
      </template>
      <template v-else-if="user.driverDirectusId">
        <v-row no-gutters>
          <v-col cols="10">
            <v-card-text>
              <v-skeleton-loader type="paragraph" />
            </v-card-text>
          </v-col>
        </v-row>
      </template>
      <v-card-text class="d-flex justify-end">
        <v-btn
          color="primary"
          dark
          class="rounded-lg text-none"
          @click="handleLogout"
        >
          <v-icon class="mr-1">mdi-logout</v-icon>
          Logout
        </v-btn>
      </v-card-text>
    </v-card>
    <!-- loader skeleton -->
    <v-card flat v-else>
      <v-row no-gutters class="pt-4">
        <v-col cols="10">
          <v-skeleton-loader
            type="card-heading, list-item-avatar, list-item-three-line"
          />
        </v-col>
        <v-col cols="2">
          <v-skeleton-loader type="avatar" />
        </v-col>
      </v-row>
      <v-card-text class="d-flex justify-end">
        <v-btn
          color="primary"
          dark
          class="rounded-lg text-none"
          @click="handleLogout"
        >
          <v-icon class="mr-1">mdi-logout</v-icon>
          Logout
        </v-btn>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script lang="ts">
import Driver from "@/logic/classes/driver";
import Fleet from "@/logic/classes/fleet";
import {
  ActionTypes,
  GettersTypes,
  MainDialogContent,
  MutationTypes,
  State,
} from "@/logic/store/store_types";
import Vue from "vue";
import { mapGetters, mapState } from "vuex";

/** Vue Component: renders the user profile. To be used in the 'TripsContent' component. */
export default Vue.extend({
  name: "ProfileCard",
  methods: {
    handleLogout() {
      this.$store.dispatch(ActionTypes.logoutUser);
    },
    handelEditUser() {
      this.$store.commit(
        MutationTypes.setMainDialogContent,
        MainDialogContent.EDIT_USER
      );
    },
  },
  computed: {
    avatarSrc(): string | undefined {
      const driver: Driver | undefined =
        this.$store.getters[GettersTypes.currentDriver];
      return driver?.profilePicSrc;
    },
    logoSrc(): string | undefined {
      const fleet: Fleet | undefined = (this.$store.state as State).fleet;
      return fleet?.logoSrc;
    },
    ...mapGetters({
      driver: GettersTypes.currentDriver,
      vehicle: GettersTypes.selectedVehicleData,
    }),
    ...mapState({
      authenticated: (state: unknown): boolean =>
        (state as State).authorizedFlag,
      user: (state: unknown) => (state as State).user,
      company: (state: unknown): Fleet | undefined => (state as State).fleet,
    }),
  },
});
</script>
